import * as React from 'react'
import * as pages from '../../pages'
import { Header, Footer, Loading, DefaultThemeProvider } from '../_components'
import { makeStyles } from '@material-ui/core'
import { show as showItem, earliestDeliveryDates, Item } from '../../services/ItemService'
import { getUser, User } from '../../services/UserService'
import { createOrder } from '../../services/OrderService'
import { showStore, Store } from '../../services/StoreService'
import {
  OrderSessions,
  OrderOptions,
  OrderAddresses,
  OrderPayments,
  OrderItem,
  ConfirmSessions,
  ConfirmOptions,
  ConfirmAddresses,
  OrderConfirms,
} from './components'
import { FormOptions } from './components/OrderOptions'
import { FormAddresses } from './components/OrderAddresses'
import { FormPayments } from './components/OrderPayments'
import { constants } from '../../_assets'

interface IProps {
  signedIn: boolean
  params: IParams
}

interface IParams {
  id: string
  store?: string
}

const Index: React.FC<IProps> = (props) => {
  const classes = useStyles()

  const [user, setUser] = React.useState<User | undefined>(undefined)
  const [guestEmail, setGuestEmail] = React.useState<string | undefined>(undefined)
  const [loadedUser, setLoadedUser] = React.useState(false)

  const [item, setItem] = React.useState<Item | null>(null)
  const [store, setStore] = React.useState<Store | null>(null)

  React.useEffect(() => {
    fetch()
  }, [])

  React.useEffect(() => {
    if (item) {
      if (item.slug) {
        handleUseEffectItem()
      }
      if (Number.isInteger(item.stock) && item.stock <= 0) {
        window.flashMessages.addErrorMessage('商品の在庫が０のため、現在注文できません。')
      }
    }
  }, [item])

  const handleUseEffectItem = async () => {
    const response = await earliestDeliveryDates(item.slug)
    setStates({
      ...states,
      prefecture: response.params.prefecture,
      earliestDelivaryDate: response.earliest_delivary_date,
      earliestDelivaryDays: response.earliest_delivary_days,
      orderLimitDate: response.order_limit_date,
    })
  }

  const fetch = async (page?: string) => {
    try {
      if (props.params.store) {
        const { json: fetchedStore } = await showStore(props.params.store)
        if (fetchedStore.slug) {
          setStore(fetchedStore)
        }
      }
      const { json: fetchedData } = await showItem(props.params.id, props.params.store)
      setItem(fetchedData)
      const fetchedUser = await getUser()
      setUser(fetchedUser)
      setLoadedUser(true)
    } catch (e) {
      window.flashMessages.addErrorMessage('データが取得できませんでした')
      // setTimeout(() => {
      //   window.location.href = '/'
      // }, 1300)
    }
  }

  // order
  const [processing, setProcessing] = React.useState(false)

  const onSubmit = async () => {
    setProcessing(true)

    try {
      const { json: orderData } = await createOrder({
        item_id: item.slug,
        name_tag: formOptions.nameTag,
        congrat_label: formOptions.congratLabel,
        wrapping_label: formOptions.wrapping,
        recipient: formAddresses.recipient,
        zipcode: formAddresses.zipcode,
        prefecture: formAddresses.prefecture,
        address1: formAddresses.address1,
        address2: formAddresses.address2,
        phone: formAddresses.phone,
        dear: formAddresses.dear,
        delivery_date: formAddresses.deliveryDate,
        delivery_time: `${formAddresses.deliveryTimeFrom} - ${formAddresses.deliveryTimeTo}`,
        note: formAddresses.note,
        payment_method: formPayments.paymentMethod,
        amazon_order_reference_id: formPayments.amazonOrderReferenceId,
        guestEmail: guestEmail,
        soegaki: formAddresses.isAffectNameTag ? `${formAddresses.recipient} 様` : formOptions.soegaki,
      })

      window.location.href = `${pages.ORDERS_COMPLETE}?no=${orderData.order_no}`
    } catch (e) {
      window.flashMessages.addErrorMessage('エラーが発生しました | 在庫状況やお支払い情報を確認してください')
      setProcessing(false)
    }
  }

  // states
  const [states, setStates] = React.useState({
    shortestDelivery: true,
    selectedDate: null,
    prefecture: null,
    earliestDelivaryDate: null,
    earliestDelivaryDays: null,
    orderLimitDate: null,
  })

  const [step, setStep] = React.useState(1)

  const [formOptions, setFormOptions] = React.useState<FormOptions | undefined>(undefined)
  const [formAddresses, setFormAddresses] = React.useState<FormAddresses | undefined>(undefined)
  const [formPayments, setFormPayments] = React.useState<FormPayments | undefined>(undefined)

  return (
    <React.Fragment>
      <DefaultThemeProvider>
        <Header signedIn={user ? true : false} disabledToRoot={props.params.store ? true : false} />
        <div className={classes.root}>
          {item ? (
            <>
              <div className={classes.orderContainer}>
                <OrderSessions
                  currentStep={step}
                  setCurrentStep={setStep}
                  handleNext={() => {
                    setStep(2)
                    document.getElementById('step-1').scrollIntoView({
                      behavior: 'smooth',
                    })
                  }}
                  user={user}
                  loaded={loadedUser}
                  guestEmail={guestEmail}
                  setGuestEmail={(email: string) => {
                    setUser({
                      email: email,
                      is_personal_use: true,
                    })
                    setGuestEmail(email)
                    // setStep(2)
                  }}
                />
                <OrderOptions
                  currentStep={step}
                  setCurrentStep={setStep}
                  handleNext={() => {
                    setStep(3)
                    document.getElementById('step-2').scrollIntoView({
                      behavior: 'smooth',
                    })
                  }}
                  item={item}
                  setFormOptions={setFormOptions}
                  hasSession={user ? true : false}
                  store={store}
                />
                <OrderAddresses
                  currentStep={step}
                  setCurrentStep={setStep}
                  handleNext={() => {
                    setStep(4)
                    document.getElementById('step-3').scrollIntoView({
                      behavior: 'smooth',
                    })
                  }}
                  setFormAddresses={setFormAddresses}
                  itemSlug={item.slug}
                  store={store}
                  user={user}
                />
                <OrderPayments
                  currentStep={step}
                  setCurrentStep={setStep}
                  processing={processing}
                  handleNext={() => {
                    setStep(5)
                    document.getElementById('step-4').scrollIntoView({
                      behavior: 'smooth',
                    })
                  }}
                  handleSubmit={() => {
                    onSubmit()
                  }}
                  setFormPayments={setFormPayments}
                  hasSession={user ? true : false}
                  guestEmail={guestEmail}
                  disableInvoicePayment={store?.disable_invoice ? true : false}
                />

                <OrderConfirms
                  currentStep={step}
                  user={user}
                  formOptions={formOptions}
                  formAddresses={formAddresses}
                  processing={processing}
                  handleSubmit={() => {
                    onSubmit()
                  }}
                />
              </div>
              <div className={classes.itemContainer}>
                {!(user || guestEmail) ? <OrderItem item={item} user={user} /> : <> </>}

                <div className={classes.confirmContainer}>
                  {user || guestEmail ? <ConfirmSessions user={user} guestEmail={guestEmail} /> : <></>}

                  {formOptions ? (
                    <ConfirmOptions
                      options={formOptions}
                      disableCongrat={store?.disable_congrat}
                      addresses={formAddresses}
                      store={store}
                    />
                  ) : (
                    <></>
                  )}

                  {formAddresses ? <ConfirmAddresses addresses={formAddresses} /> : <></>}
                </div>

                {user || guestEmail ? <OrderItem item={item} user={user} /> : <> </>}
              </div>
            </>
          ) : (
            <Loading />
          )}
        </div>
        <Footer hideItemLinks={!!props.params.store} hidePriceLinks={!!props.params.store} />
      </DefaultThemeProvider>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: constants.BG_COLOR_GRAY,
    minHeight: `calc(100vh - ${constants.HEIGHT_HEADER + constants.HEIGHT_FOOTER}px)`,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',

    [theme.breakpoints.down('sm')]: {
      padding: 16,
    },

    position: 'relative',
  },
  orderContainer: {
    width: '60%',
    maxWidth: 700,
    padding: 32,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
      alignItems: 'flex-start',
    },
  },

  itemContainer: {
    width: '40%',
    padding: 32,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
      alignItems: 'flex-start',
    },
  },

  rootContainer: {},

  confirmContainer: {
    display: 'block',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

export default Index
