import axios from 'axios'

interface IAPIResponse {
  status: 'ok' | 'Error'
}

interface IGetAddressByzipcode extends IAPIResponse {
  address1: string
  address2: string
  address3: string
}

interface IErrors extends IAPIResponse {
  code: string
  message: string
}

export const getAddressByzipcode = async (zipcode: string) => {
  const res = await axios.get('/api/fetch_address_by_zipcode/' + zipcode)

  if (res && res.data.status === 'ok') {
    return res.data as IGetAddressByzipcode
  } else {
    throw res.data
  }
}
