import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../constants'
import * as pages from '../../pages'
import { Header, Footer } from '../_components'
import { Form, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { signin, IInputSignin } from '../../services/UserService'
import SocialLogins from './_components/socialLogins'

interface Props {}

const Index: React.FC<Props> = () => {
  // form
  const { register, handleSubmit, setValue, errors, getValues, setError } = useForm<IInputSignin>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const onSubmit = async (data: IInputSignin) => {
    try {
      await signin(data)
      window.location.reload()
    } catch (e) {
      setError('password', { type: 'manual', message: e.data.error })
    }
  }

  return (
    <React.Fragment>
      <Header />

      <Root>
        <div className="rootContainer">
          <div className="signinContainer">
            <img className="signinContainer__logo" src="/images/logo.svg" alt="logo" />

            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="メールアドレス"
                  ref={register({ required: 'メールアドレスは必須です' })}
                />
                {errors.email && <small style={{ color: 'red' }}>{errors.email.message}</small>}
              </div>

              <div>
                <Form.Control
                  name="password"
                  type="password"
                  placeholder="パスワード"
                  ref={register({ required: 'パスワードは必須です' })}
                />
                {errors.password && <small style={{ color: 'red' }}>{errors.password.message}</small>}
              </div>

              <Button type="submit" className="mb-2">
                ログイン
              </Button>
            </form>

            <div className="sessions">
              <a href={pages.SIGNUP}>会員登録はこちら</a>
              <a href={pages.RESTORE}>パスワードを忘れた方はこちら</a>
            </div>

            <div className="federals">
              <div className="federals__title">または</div>
              <p>他のサービスでログイン</p>
              <SocialLogins />
            </div>
          </div>
        </div>
      </Root>

      <Footer />
    </React.Fragment>
  )
}

const Root = styled.div`
  background-color: ${constants.BG_COLOR_GRAY};
  min-height: calc(100vh - ${constants.HEIGHT_HEADER + constants.HEIGHT_FOOTER}px);
  display: flex;
  justify-content: center;
  padding: 32px;
  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    padding: 8px;
  }

  .rootContainer {
    width: 100%;
    max-width: 400px;

    .signinContainer {
      background-color: #fff;
      // width: 400px;
      padding: 16px;
      box-shadow: 0 3px 8px rgba(50, 50, 50, 0.17);

      h3 {
        margin: 16px 0;
        text-align: center;
        font-size: 25px;
      }

      &__logo {
        width: 100%;
        margin: 16px 0 32px;
        height: 60px;
        object-fit: contain;
      }

      form {
        div {
          margin-bottom: 16px;
          input {
            height: 50px;
            border-radius: 0;
            background-color: #f2f2f2;
          }
        }

        button {
          width: 100%;
          border-radius: 0;
          height: 50px;
          color: #fff;
        }
      }

      .sessions {
        margin: 16px 0;
        display: flex;
        flex-wrap: wrap;

        a {
          width: 100%;
          margin-bottom: 4px;
        }
      }

      .federals {
        p {
          text-align: center;
        }

        &__title {
          display: flex;
          align-items: center;
          &::before {
            content: '';
            flex-grow: 1;
            height: 1px;
            display: block;
            margin-right: 0.4em;
            background: linear-gradient(-90deg, #aaa, transparent);
          }

          &::after {
            content: '';
            flex-grow: 1;
            height: 1px;
            display: block;
            margin-left: 0.4em;
            background: linear-gradient(90deg, #aaa, transparent);
          }
        }

        &__buttons {
          display: flex;
          padding: 16px 0;
          justify-content: center;
          flex-wrap: wrap;

          &__button {
            display: flex;
            align-items: center;
            border-radius: 20px;
            border-color: #aaa;
            color: #333;
            img {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
`

export default Index
