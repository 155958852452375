import * as React from 'react'
import { makeStyles } from '@material-ui/core'

interface Props {
  title: string
}

export const ConfirmSection: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <label className={classes.title}>{props.title}</label>
      <div className={classes.body}>{props.children}</div>
    </div>
  )
}

export const ConfirmSectionLabel: React.FC<{ label: string }> = (props) => {
  const classes = useStyles()

  return <label className={classes.label}>{props.label}</label>
}

export const ConfirmSectionContent: React.FC<{ content: string; gray?: boolean }> = (props) => {
  const classes = useStyles()

  return (
    <p className={classes.content} style={{ color: props.gray ? 'darkgray' : '#333' }}>
      {props.content}
    </p>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 16,
  },

  title: {
    display: 'block',
    fontWeight: 'bold',
    fontSize: 14,
  },

  body: {
    padding: 8,
    backgroundColor: '#f3f3f3',
  },

  label: {
    color: '#777',
    fontWeight: 'bold',
    marginBottom: 0,
    fontSize: 14,
  },

  content: {
    marginBottom: 4,
    paddingLeft: 4,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
  },
}))
