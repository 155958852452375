export const SIGNIN = '/signin'
export const SIGNUP = '/signup'
export const SIGNUP_COMPLETE = '/signup/complete'
export const SIGNOUT = '/logout'
export const RESTORE = '/restore'
export const CONFIRM = '/confirm'
export const ITEMS_INDEX = '/items'
export const ITEMS_SHOW = (id: string) => `/items/${id}`
export const MYPAGE = '/mypage'
export const NAME_TAGS = '/name_tags'
export const REFERRAL_CODE = '/referral_code'
export const CHANGE_PASSWORD = '/change_password'
export const HISTORIES = '/histories'
export const HISTORIES_SHOW = (id: string) => `/histories/${id}`
export const ITEM_REVIEW = (orderId: string, itemId) => `/histories/${orderId}/review/${itemId}`
export const ORDERS_NEW = '/orders/new'
export const ORDERS_CONFIRM = '/orders/confirm'
export const ORDERS_COMPLETE = '/orders/complete'
export const INVOICE = (id: string) => `/invoice/${id}`
export const RECEIPT = (id: string) => `/receipt/${id}`
export const STORES_SHOW = (id: string) => `/stores/${id}`

export const ADMIN_ORDERS = '/admin/orders'
export const ADMIN_ORDERS_EDIT = (id: string) => `/admin/orders/${id}/edit`
export const ADMIN_CATEGORIES = '/admin/categories'
export const ADMIN_CATEGORIES_NEW = '/admin/categories/new'
export const ADMIN_CATEGORIES_EDIT = (id: string) => `/admin/categories/${id}/edit`
export const ADMIN_ITEMS = '/admin/items'
export const ADMIN_ITEMS_NEW = '/admin/items/new'
export const ADMIN_ITEMS_EDIT = (id: string) => `/admin/items/${id}/edit`
export const ADMIN_ITEMS_REVIEW = (id: string) => `/admin/items/${id}/review`
export const ADMIN_ITEMS_REVIEW_NEW = (id: string) => `/admin/items/${id}/review/new`
export const ADMIN_ITEMS_REVIEW_EDIT = (itemId: string, id: string, backUrl?: string) =>
  `/admin/items/${itemId}/review/${id}/edit${backUrl ? `?backUrl=${backUrl}` : ''}`
export const ADMIN_USERS = '/admin/users'
export const ADMIN_USERS_EDIT = (id: string) => `/admin/users/${id}/edit`
export const ADMIN_INTRODUCERS = '/admin/introducers'
export const ADMIN_INTRODUCERS_NEW = '/admin/introducers/new'
export const ADMIN_INTRODUCERS_EDIT = (id: string) => `/admin/introducers/${id}/edit`
export const ADMIN_TRADERS = '/admin/traders'
export const ADMIN_TRADERS_NEW = '/admin/traders/new'
export const ADMIN_TRADERS_EDIT = (id: string) => `/admin/traders/${id}/edit`
export const ADMIN_MONTH_SALES = '/admin/month_sales'
export const ADMIN_INTRODUCER_SALES = '/admin/introducer_sales'
export const ADMIN_INTRODUCER_SALES_SHOW = (id: string, yearMonth?: string) =>
  `/admin/introducer_sales/${id}?yearMonth=${yearMonth ? yearMonth : ''}`
export const ADMIN_CONTACTS = '/admin/contacts'
export const ADMIN_ADMINS = '/admin/admins'
export const ADMIN_ADMINS_NEW = '/admin/admins/new'
export const ADMIN_CONGRATS = '/admin/congrats'
export const ADMIN_CONGRATS_NEW = '/admin/congrats/new'
export const ADMIN_CONGRATS_EDIT = (id: string) => `/admin/congrats/${id}/edit`
export const ADMIN_BENEFITS = '/admin/benefits'
export const ADMIN_BENEFITS_NEW = '/admin/benefits/new'
export const ADMIN_BENEFITS_EDIT = (id: string) => `/admin/benefits/${id}/edit`
export const ADMIN_ITEM_REVIEWS = '/admin/item_reviews'

export const ADMIN_STORES = '/admin/stores'
export const ADMIN_STORES_NEW = '/admin/stores/new'
export const ADMIN_STORES_EDIT = (id: string) => `/admin/stores/${id}/edit`
export const ADMIN_STORES_ITEMS = (id: string) => `/admin/stores/${id}/items`
