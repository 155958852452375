import * as React from 'react'
import styled from 'styled-components'
import { PaymentMethod } from '../../constants'
import * as pages from '../../pages'
import { Header, Footer, Breadcrumbs, DefaultButton, ImagePreview } from '../_components'
import { index } from '../../services/OrderService'
import IWindow from '../../interfaces/IWindow'
import { createMuiTheme, makeStyles } from '@material-ui/core'
import { format } from 'date-fns'
import { MypageContainer } from '../_components/MypageContainer'
import { constants } from '../../_assets'

declare const window: IWindow

interface Props {
  //
}

const tableHeaders = [
  { label: '対応状況' },
  { label: '注文番号' },
  { label: '購入日時' },
  { label: '購入方法' },
  { label: '購入金額' },
  { label: 'お届け指定日' },
  {},
]

const Index: React.FC<Props> = (props) => {
  const classes = useStyles()
  const [user, setUser] = React.useState(null)
  const [data, setData] = React.useState(null)

  React.useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    try {
      const { json: fetchedData } = await index()
      setData(fetchedData)
    } catch (e) {
      window.flashMessages.addErrorMessage('購入履歴を取得できませんでした')
    }
  }

  return (
    <React.Fragment>
      <Header signedIn={true} />

      <MypageContainer active="histories" maxWidth={700}>
        <Breadcrumbs>購入履歴</Breadcrumbs>

        {data != null && (
          <>
            {data.length > 0 ? (
              <>
                {data.map((item, index) => (
                  <CardContainer key={`history-${index}`}>
                    <CardContainerHeader>
                      <div className="flexBoxContainer">
                        <div className="flexBox">
                          <div className="title">購入日</div>
                          <div className="value">{format(new Date(item.created_at), 'yyyy年MM月dd日')}</div>
                        </div>
                        <div className="flexBox">
                          <div className="title">合計</div>
                          <div className="value">¥{item.price.toLocaleString()}</div>
                        </div>
                      </div>

                      <div className="flexBoxContainer">
                        <div className="flexBox">
                          <div className="title">注文状況</div>
                          <div className="value">{constants.getOrderStatusName(item.status)}</div>
                        </div>
                        <div>
                          <div className="title">注文番号: {item.order_no}</div>
                          <div className="value" style={{ textAlign: 'right' }}>
                            <a href={pages.HISTORIES_SHOW(item.order_no)}>注文の詳細</a>
                          </div>
                        </div>
                      </div>
                    </CardContainerHeader>
                    <CardContainerBody>
                      <div style={{ width: '100%', display: 'flex' }}>
                        <ImagePreview src={item.item.image_url} size={90} />
                        <div className={classes.infoContainer}>
                          <div className="value itemName" style={{ marginBottom: 4 }}>
                            <a href={pages.ITEMS_SHOW(item.item.slug)}>{item.item.name}</a>
                          </div>
                          <div className={classes.buttons}>
                            {item.status === constants.OrderStatus.Delivered && (
                              <DefaultButton
                                size="small"
                                color="default"
                                style={{ display: 'block', marginBottom: 8 }}
                                onClick={() => {
                                  window.location.href = pages.ITEM_REVIEW(item.order_no, item.item.slug)
                                }}>
                                レビューを書く
                              </DefaultButton>
                            )}

                            {item.payment_method === PaymentMethod.Card &&
                              item.status === constants.OrderStatus.Delivered && (
                                <DefaultButton
                                  size="small"
                                  color="default"
                                  style={{ display: 'block' }}
                                  onClick={() => {
                                    window.open(pages.RECEIPT(item.order_no))
                                  }}>
                                  領収書発行
                                </DefaultButton>
                              )}
                          </div>
                        </div>
                      </div>
                    </CardContainerBody>
                  </CardContainer>
                ))}
              </>
            ) : (
              <>購入履歴はまだありません</>
            )}
          </>
        )}
      </MypageContainer>

      <Footer />
    </React.Fragment>
  )
}

export const CardContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  overflow: hidden;
`

export const CardContainerHeader = styled.div`
  background-color: #f3f3f3;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;

  .flexBoxContainer {
    display: flex;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      justify-content: space-between;
      width: 100%;
      margin-bottom: 8px;
    }
  }

  .flexBox {
    margin-right: 16px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      margin-right: 0px;
    }
  }

  .title {
    font-size: 10px;
    font-weight: bold;
  }

  .value {
    font-size: 12px;

    > a {
      text-decoration: underline;
    }
  }
`

export const CardContainerBody = styled.div`
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px;

  .infoContainer {
    padding-left: 8px;

    .value {
      font-size: 12px;
      padding-left: 4px;
    }

    .itemName {
      color: ${constants.MAIN_COLOR};
      text-decoration: underline;
    }
  }
`

const theme = createMuiTheme({
  palette: {
    primary: {
      main: constants.MAIN_COLOR,
    },
    secondary: {
      main: constants.SECONDARY_COLOR,
    },
  },
})

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    paddingLeft: 8,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',

    '& .value': {
      fontSize: 12,
      paddingLeft: 4,
      paddingRight: 8,
    },

    '& .itemName': {
      color: constants.MAIN_COLOR,
      textDecoration: 'underline',
    },
  },
  buttons: {
    // margin: '0 auto',
    // maxWidth: 500,
  },
}))

export default Index
