import * as React from 'react'
import { constants, pages } from '../../_assets'
import { makeStyles } from '@material-ui/core'
import { priceTypes } from '../items'

interface Props {
  hideItemLinks: boolean
  hidePriceLinks: boolean
}

const Index: React.FC<Props> = (props) => {
  const { hideItemLinks, hidePriceLinks } = props

  const classes = useStyles({
    // size: props.size || 300,
  })

  const getURL = (name: string) => {
    return document.querySelector(`meta[name="${name}"]`).getAttribute('content')
  }
  return (
    <div className={classes.root}>
      <div className={classes.footerSiteInfos}>
        <div className={classes.footerSiteInfo}>
          {!hideItemLinks && (
            <div className={classes.footerSiteInfoItem}>
              <div className={classes.footerSiteInfoItemTitle}>商品一覧</div>
              <div className={classes.footerLinks}>
                <a href="/items">すべての商品</a>
                <a href="/categories">カテゴリ一覧</a>
                {/* <a href="#">人気商品</a> */}
              </div>
            </div>
          )}

          {!hidePriceLinks && (
            <div className={classes.footerSiteInfoItem}>
              <div className={classes.footerSiteInfoItemTitle}>価格で選ぶ</div>
              <div className={classes.footerLinks}>
                {priceTypes.map((priceType, index) => (
                  <a
                    key={`footer-priceType-${index}`}
                    href={`${pages.ITEMS_INDEX}?min_price=${priceType.minPrice}&max_price=${priceType.maxPrice}`}>
                    {priceType.label}
                  </a>
                ))}
              </div>
            </div>
          )}

          {/*           
          <div className={classes.footerSiteInfoItem}>
            <div className={classes.footerSiteInfoItemTitle}>カテゴリ一覧</div>
            <div className={classes.footerLinks}>
              <a href="#">ガーデニング</a>
              <a href="#">花・植物</a>
              <a href="#">インテリア</a>
              <a href="#">胡蝶蘭</a>
            </div>
          </div> */}
          <div className={classes.footerSiteInfoItem}>
            <div className={classes.footerSiteInfoItemTitle}>ご利用ガイド</div>
            <div className={classes.footerLinks}>
              <a href="https://lp.hana-tec.jp/what.html" target="_blank" rel="noreferrer">
                はなテックとは？
              </a>
              <a href="https://lp.hana-tec.jp/quick.html" target="_blank" rel="noreferrer">
                クイックフラワーについて
              </a>
              <a href="https://lp.hana-tec.jp/okuyami.html" target="_blank" rel="noreferrer">
                葬儀対応について
              </a>
              <a href="https://lp.hana-tec.jp/payment.html" target="_blank" rel="noreferrer">
                お支払いについて
              </a>
              <a href="https://lp.hana-tec.jp/chouhyou.html" target="_blank" rel="noreferrer">
                見積書・請求書・領収書
              </a>
            </div>
          </div>
        </div>

        <div className={classes.footerSocials}>
          <div className={classes.footerSocial}>
            <a
              href="https://www.instagram.com/swell_inc_official/"
              style={{ marginRight: 8 }}
              target="_blank"
              rel="noreferrer">
              <img src="/images/svg/instagram.svg" width={20} height={20} />
            </a>

            <a
              href="https://www.youtube.com/channel/UCyJE2RZC1z0ZpN7QpdlX-7g"
              target="_blank"
              style={{ marginRight: 8 }}
              rel="noreferrer">
              <img src="/images/svg/youtube.svg" width={20} height={20} />
            </a>

            <a href="https://lin.ee/AOoUL4V" target="_blank" rel="noreferrer">
              <img src="/images/svg/line.svg" width={20} height={20} />
            </a>
          </div>
          <div className={classes.footerCredits}>
            <div>
              <img src="/images/card_brand/visa.png" alt="visa" />
              <img src="/images/card_brand/jcb.png" alt="jcb" />
              <img src="/images/card_brand/mastercard.png" alt="mastercard" />
              <img src="/images/card_brand/discover.png" alt="discover" />
              <img src="/images/card_brand/dinersclub.png" alt="dinersclub" />
              <img src="/images/card_brand/americanexpress.png" alt="americanexpress" />
            </div>
          </div>
          <div className={classes.footerLogos}>
            <img src="/images/logo.svg" alt="はなテック" />
          </div>
        </div>
      </div>

      <div className={classes.footerCopyrights}>
        <div className={classes.footerCompanyLinks}>
          <a href={getURL('url-about-site')} target="_blank" rel="noreferrer">
            サイト概要
          </a>
          <a href={getURL('url-company')} target="_blank" rel="noreferrer">
            運営会社情報
          </a>
          <a href={getURL('url-policy-law')} target="_blank" rel="noreferrer">
            特定商取引法表示
          </a>
          <a href={getURL('url-term')} target="_blank" rel="noreferrer">
            利用規約
          </a>
          <a href={getURL('url-privacy-policy')} target="_blank" rel="noreferrer">
            プライバシーポリシー
          </a>
        </div>
        <div className={classes.footerCopyright}>© 2020, {constants.APP_NAME}</div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background: constants.BG_COLOR_GRAY,
    fontSize: 12,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '16px 32px',
    borderTop: '1px solid #e5e5e5',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 16px',
    },
  },
  footerCopyrights: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& a': {
      '&:after': {
        content: '"|"',
        fontSize: 9,
        color: '#657086',
        padding: '2px 6px',
        borderRadius: 3,
        fontWeight: 'bold',
        marginLeft: 4,
        marginRight: 4,
      },

      '&:last-child:after': {
        content: '""',
      },
      [theme.breakpoints.down('sm')]: {
        '&:after': {
          content: '""',
        },
      },
    },
  },

  footerCompanyLinks: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',

      '& a': {
        width: '100%',
        marginBottom: 4,
      },
    },
  },

  footerSocials: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },

  footerSocial: {
    textAlign: 'right',
    '& svg': {
      width: 20,
      height: 20,
      // &:first-child {
      //   margin-right: 16px;
      // }
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  footerSiteInfos: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 32,
  },
  footerSiteInfo: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  footerSiteInfoItem: {
    width: 200,

    [theme.breakpoints.down('sm')]: {
      marginBottom: 16,
    },
  },

  footerSiteInfoItemTitle: {
    fontWeight: 'bold',
    marginBottom: 8,
  },

  footerLinks: {
    display: 'flex',
    flexWrap: 'wrap',
    '& a': {
      width: '100%',
      marginBottom: 4,
      paddingLeft: 8,
    },
  },

  footerCredits: {
    width: '100%',
    padding: '16px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg': {
      width: 20,
      height: 20,
      // &:first-child {
      //   margin-right: 16px;
      // }
    },

    '& img': {
      height: 20,
      marginLeft: 8,
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'flex-end',
    },
  },

  footerLogos: {
    textAlign: 'center',
    '& img': {
      height: 30,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
    },
  },

  footerCopyright: {
    //
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
}))

export default Index
