import * as React from 'react'
import { constants } from '../../../_assets'
import { Card, makeStyles, Theme, Typography } from '@material-ui/core'
import { OrderSectionNumber } from './OrderSectionNumber'
import { OrderSectionTriangle } from './OrderSectionTriangle'
import { DefaultButton } from '../../_components'

interface Props {
  step: number
  currentStep: number
  setCurrentStep: (step: number) => void
  title: string
  disableNextStep?: boolean
}
export const OrderSection: React.FC<Props> = (props) => {
  const classes = useStyles({ active: props.step === props.currentStep })

  return (
    <div id={`step-${props.step}`} className={classes.root}>
      <Card variant="outlined" className={classes.cardContainer}>
        <div className={classes.titleContainer}>
          <Typography className={classes.title} variant="h6" component="h3" style={{ fontWeight: 'bold' }}>
            <OrderSectionNumber title={props.step.toString()} active={props.step === props.currentStep} />
            {props.title}
          </Typography>
          {props.currentStep > props.step ? (
            <DefaultButton
              buttonclassname={classes.edit}
              variant="outlined"
              size="small"
              onClick={() => {
                props.setCurrentStep(props.step)
              }}>
              変更
            </DefaultButton>
          ) : (
            <></>
          )}
        </div>

        <div className={classes.bodyContainer} style={{ display: props.step === props.currentStep ? 'block' : 'none' }}>
          <OrderSectionTriangle className={classes.triangle} />
          {props.children}
        </div>
      </Card>

      {!props.disableNextStep ? (
        <div className={classes.triangleContainer}>
          <OrderSectionTriangle />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

const useStyles = makeStyles<Theme, { active: boolean }>((theme) => ({
  root: {
    marginBottom: 16,
  },

  cardContainer: {
    marginBottom: 16,
    overflow: 'visible',
  },

  titleContainer: (props) => ({
    backgroundColor: props.active ? constants.MAIN_COLOR_DARK : '#fff',
    padding: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),

  title: (props) => ({
    display: 'flex',
    alignItems: 'center',
    color: props.active ? '#fff' : constants.MAIN_COLOR_DARK,
  }),

  edit: {
    color: constants.MAIN_COLOR_DARK,
    backgroundColor: '#fff',
    borderColor: constants.MAIN_COLOR_DARK,
  },

  bodyContainer: {
    position: 'relative',
    borderTop: '1px solid #e3e3e3',
    padding: 32,

    [theme.breakpoints.down('sm')]: {
      paddingTop: 32,
      padding: 16,
    },
  },

  triangle: {
    position: 'absolute',
    top: -1,
    left: 20,
  },

  triangleContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}))
