import * as React from 'react'
import { constants } from '../../../_assets'
import { makeStyles } from '@material-ui/core'

interface IProps {
  rootStyle?: React.CSSProperties
  children: React.ReactNode
  required?: boolean
}

const Index: React.FC<IProps> = (props) => {
  const classes = useStyles()

  return (
    <div className={`${classes.root} root`} style={props.rootStyle}>
      <label className={`${classes.label} ${props.required ? 'required' : ''}`}>{props.children}</label>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 250,
    width: 250,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 8,
    },
  },

  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 'bold',

    '&.required': {
      '&:after': {
        content: '"必須"',
        fontSize: 9,
        background: '#eaedf3',
        color: '#657086',
        padding: '2px 6px',
        borderRadius: 3,
        fontWeight: 'bold',
        marginLeft: 8,
      },
    },
  },
}))

export default Index
