import { sendPost, sendGet } from './httpClient'
import { parseResourceData } from './JsonApiSerializer'
import { IOption } from './admin/ItemService'
import { PaymentMethod } from '../constants'

export const index = async (page?: string) => {
  try {
    const { data } = await sendGet(`/api/orders?page=${page || 1}`)
    const json = parseResourceData(data.json)
    const meta = data.json.meta
    return { json, meta }
  } catch (e) {
    throw e.response
  }
}

export const show = async (id: string) => {
  try {
    const { data } = await sendGet(`/api/orders/${id}`)
    const json = parseResourceData(data.json)
    return { json }
  } catch (e) {
    throw e.response
  }
}

export interface IInputCreate {
  item_id: string
  name_tag: string
  congrat_label: string
  wrapping_label: IOption
  recipient: string
  zipcode: string
  prefecture: string
  address1: string
  address2: string
  phone: string
  dear: string
  delivery_date: string
  delivery_time: string
  note: string
  payment_method: PaymentMethod
  amazon_order_reference_id?: string
  guestEmail?: string
  soegaki?: string
}

export const createOrder = async (formData: IInputCreate) => {
  try {
    const { data } = await sendPost('/api/orders', {
      order: {
        item_id: formData.item_id,
        name_tag: formData.name_tag,
        congrat_label: formData.congrat_label,
        wrapping_label: formData.wrapping_label,
        recipient: formData.recipient,
        zipcode: formData.zipcode,
        prefecture: formData.prefecture,
        address1: formData.address1,
        address2: formData.address2,
        phone: formData.phone,
        dear: formData.dear,
        delivery_date: formData.delivery_date,
        delivery_time: formData.delivery_time,
        note: formData.note,
        payment_method: formData.payment_method,
        soegaki: formData.soegaki,
      },
      amazon: {
        order_reference_id: formData.amazon_order_reference_id,
      },
      user: {
        email: formData.guestEmail,
      },
    })
    const json = parseResourceData(data.json)
    return { json }
  } catch (e) {
    throw e.response || e
  }
}
