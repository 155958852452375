import { sendGet } from './httpClient'
import { parseResourceData } from './JsonApiSerializer'

export const recentReview = async () => {
  try {
    const { data } = await sendGet(`/api/recent_reviews`)
    const json = parseResourceData(data.json)
    return { json }
  } catch (e) {
    throw e.response
  }
}

export const indexItemReview = async (itemId: string, page?: string) => {
  try {
    const { data } = await sendGet(`/api/review/${itemId}?page=${page || 1}`)
    const json = parseResourceData(data.json)
    const meta = data.json.meta
    return { json, meta, averageRating: data.average_rating }
  } catch (e) {
    throw e.response
  }
}
