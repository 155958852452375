import { makeStyles } from '@material-ui/core'
import React from 'react'

interface Props {
  id: string
  title: string
  subTitle: string
}

export const SectionTitle: React.FC = (props: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.categoryContainerTitle}>
      <h3 id={props.id} className={classes.title}>
        {props.title}
      </h3>
      <h4>{props.subTitle}</h4>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  categoryContainerTitle: {
    '& h3': {
      width: '100%',
      fontSize: '1.2rem',
      fontWeight: 'bold',
    },

    '& h4': {
      width: '100%',
      fontSize: '0.9rem',
      color: '#ccc',
    },
  },

  title: {
    width: '100%',
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },

  subTitle: {
    width: '100%',
    fontSize: '0.9rem',
    color: '#ccc',
  },
}))
