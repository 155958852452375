import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../../constants'
import * as pages from '../../../pages'
import { Wrapper, Pagination, Title, PanelWrapper, BasePanel } from '../_components'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { ja } from 'date-fns/locale'
import { MuiThemeProvider, createMuiTheme, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import { index } from '../../../services/admin/MonthSalesService'
import { format } from 'date-fns'

interface IProps {
  id: string
}

const Index: React.FC<IProps> = (props) => {
  const [data, setData] = React.useState(null)
  const [pagination, setPagination] = React.useState<any | null>(null)
  const [minDate, setMinDate] = React.useState(null)
  const [info, setInfo] = React.useState(null)

  React.useEffect(() => {
    fetch()
  }, [])

  const fetch = async (page?: string, yearMonth?: string) => {
    try {
      const { json, minDate, meta, info } = await index(page, yearMonth)
      setData(json)
      setMinDate(new Date(format(new Date(minDate), 'yyyy-MM') + '-01'))
      setPagination(meta)
      setInfo(info)
    } catch (e) {
      window.flashMessages.addErrorMessage('データが取得できませんでした')
    }
  }

  const onChangePageHandler = async (page) => {
    fetch(page, format(selectedDate, 'yyyy-MM'))
  }

  // calendar
  const [selectedDate, setSelectedDate] = React.useState(new Date())

  const handleDateChange = (value: Date) => {
    setSelectedDate(value)
    fetch(null, format(value, 'yyyy-MM'))
  }
  return (
    <React.Fragment>
      <Wrapper active="monthSale">
        <Title title={'売上（月別）'} />

        <PanelWrapper>
          <BasePanel>
            <PanelHeader>
              <div className="panelHeader__search">
                <MuiThemeProvider theme={theme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>
                    <DatePicker
                      views={['year', 'month']}
                      openTo="month"
                      format="yyyy年MM月"
                      inputVariant="outlined"
                      minDate={minDate ? new Date(minDate) : new Date()}
                      maxDate={new Date()}
                      minDateMessage="選択可能な日付をお選びください"
                      maxDateMessage="選択可能な日付をお選びください"
                      invalidDateMessage="日付のフォーマットが不正です"
                      value={selectedDate}
                      onChange={handleDateChange}
                    />
                  </MuiPickersUtilsProvider>
                </MuiThemeProvider>
                <span>の売上</span>
              </div>
            </PanelHeader>
            <PanelBody>
              <div
                className="panelBody__formContainer"
                style={{
                  padding: '8px 16px',
                }}>
                {info && (
                  <div className="w100 dflex">
                    <div className="w25">
                      <label>顧客数</label>
                      <p>{info.num_user}</p>
                    </div>
                    <div className="w25">
                      <label>月間注文数</label>
                      <p>{info.num_order}</p>
                    </div>
                    <div className="w25">
                      <label>販売商品数</label>
                      <p>{info.num_item}</p>
                    </div>
                    <div className="w25">
                      <label>月間総売上</label>
                      <p>¥{info.total.toLocaleString()}</p>
                    </div>
                  </div>
                )}
              </div>
            </PanelBody>
            <PanelBody>
              {data && (
                <>
                  {data.length > 0 ? (
                    <>
                      <Table className="admin-table" size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>注文日時</TableCell>
                            <TableCell>注文番号</TableCell>
                            <TableCell>注文者</TableCell>
                            <TableCell>商品名</TableCell>
                            <TableCell>価格</TableCell>
                            <TableCell>支払い方法</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.map((d, index) => (
                            <TableRow key={`order-${index}`}>
                              <TableCell className="">{format(new Date(d.created_at), 'yyyy-MM-dd')}</TableCell>
                              <TableCell className="">
                                <a href={pages.ADMIN_ORDERS_EDIT(d.id)} target="_blank" rel="noreferrer">
                                  {d.order_no}
                                </a>
                              </TableCell>
                              <TableCell className="">{d.user.email}</TableCell>
                              <TableCell className="">
                                <a href={pages.ITEMS_SHOW(d.item.slug)} target="_blank" rel="noreferrer">
                                  {d.item.name}
                                </a>
                              </TableCell>
                              <TableCell className="">¥{d.price.toLocaleString()}</TableCell>
                              <TableCell className="">
                                {constants.getPaymentMethodName(d.payment_method, d.user.is_personal_use)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>

                      {pagination && (
                        <Pagination
                          onChangePageHandler={onChangePageHandler}
                          currentPage={pagination.current_page}
                          prevPage={pagination.prev_page}
                          nextPage={pagination.next_page}
                          totalPages={pagination.total_pages}
                          totalCount={pagination.total_count}
                        />
                      )}
                    </>
                  ) : (
                    <div style={{ padding: '16px 0', textAlign: 'center' }}>データがありません</div>
                  )}
                </>
              )}
            </PanelBody>
          </BasePanel>
        </PanelWrapper>
      </Wrapper>
    </React.Fragment>
  )
}

const PanelHeader = styled.div`
  padding: 16px;

  .panelHeader {
    font-size: 16px;
    &__search {
      align-items: center;
      display: flex;

      input {
        padding: 10px 16px;
        width: 100px;
      }

      span {
        margin-left: 8px;
      }
    }
  }
`

const PanelBody = styled.div`
  .panelBody {
    padding: 8px 32px;

    &__formContainer {
      flex-wrap: wrap;
      border-bottom: 1px solid #ccc;
      label {
        font-weight: bold;
        font-size: 12px;
      }
    }
  }
`

const theme = createMuiTheme({
  palette: {
    primary: {
      main: constants.MAIN_COLOR,
    },
    secondary: {
      main: constants.SECONDARY_COLOR,
    },
  },
})

export default Index
