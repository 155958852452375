import * as React from 'react'
import { constants } from '../../../_assets'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { DefaultButton, LabelWithLine } from '../../_components'
import { OrderSessionsSignIn } from './OrderSessionsSignIn'
import { OrderSessionsSignUp } from './OrderSessionsSignUp'
import { OrderSection } from '.'
import { User } from '../../../services/UserService'
import { SIGNOUT } from '../../../pages'
import SocialLogins from '../../sessions/_components/socialLogins'
import { ProfileForms } from '../../mypage/_components/Profiles'

interface Props {
  currentStep: number
  setCurrentStep: (step: number) => void
  handleNext: () => void
  user?: User
  loaded: boolean
  guestEmail?: string
  setGuestEmail: (email: string) => void
}

const SESSION_STEP = 1

export const OrderSessions: React.FC<Props> = (props) => {
  const classes = useStyles()

  const [wannaSignUp, setWannaSignUp] = React.useState(false)

  const handleSignOut = async () => {
    window.location.href = SIGNOUT
  }

  const [editProfile, setEditProfile] = React.useState(false)

  React.useEffect(() => {
    if (props.guestEmail) {
      setEditProfile(true)
    }
  }, [props.guestEmail])

  return (
    <OrderSection
      step={SESSION_STEP}
      currentStep={props.currentStep}
      setCurrentStep={props.setCurrentStep}
      title={props.user ? 'ユーザー情報確認' : 'ログイン・会員登録'}>
      <div className={classes.root}>
        {!props.loaded ? (
          <CircularProgress />
        ) : (
          <>
            {props.user ? (
              <>
                <div className={classes.userInfoContainer}>
                  <div className={classes.userInfoDivider}>
                    <div className={classes.title}>次のユーザーとして注文を承ります</div>
                    <p className={classes.description}>{props.user.email}</p>

                    <div
                      style={{
                        width: '100%',
                        textAlign: 'right',
                      }}>
                      <DefaultButton
                        onClick={() => {
                          handleSignOut()
                        }}>
                        ログアウトして変更する
                      </DefaultButton>
                    </div>
                  </div>

                  <ProfileForms
                    user={props.user}
                    afterOnSubmit={props.handleNext}
                    buttonLabel={'次へ'}
                    guestEmail={props.guestEmail}
                    disableAlert={true}
                  />
                  {/* {editProfile ? (
                    <ProfileForms
                      user={props.user}
                      afterOnSubmit={props.handleNext}
                      buttonLabel={'次へ'}
                      guestEmail={props.guestEmail}
                    />
                  ) : (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                      <DefaultButton
                        onClick={() => {
                          props.handleNext()
                        }}>
                        次へ
                      </DefaultButton>
                    </div>
                  )} */}
                </div>
              </>
            ) : (
              <>
                {!wannaSignUp ? (
                  <>
                    <div className={classes.signInContainer}>
                      <div className={classes.title}>既に会員のお客様</div>
                      <OrderSessionsSignIn setStep={props.setCurrentStep} />
                    </div>
                    <div className={classes.signUpTutorialContainer}>
                      <div className={classes.title}>はじめてご来店・未登録のお客様</div>
                      <p className={classes.description}>
                        はなテックでは、注文時に会員登録をお願いしております。初回注文時に会員登録すると次回以降スピーディに注文できるにようになります。
                      </p>

                      <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                        <DefaultButton
                          onClick={() => {
                            setWannaSignUp(true)
                          }}>
                          会員登録
                        </DefaultButton>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={classes.signUpContainer}>
                    <div className={classes.title}>会員登録</div>

                    <OrderSessionsSignUp
                      handleOnCancel={() => {
                        setWannaSignUp(false)
                      }}
                      setGuestEmail={props.setGuestEmail}
                    />
                  </div>
                )}
              </>
            )}

            {!props.user ? (
              <>
                <LabelWithLine
                  title={`他のサービスで${wannaSignUp ? '会員登録' : 'ログイン'}`}
                  style={{ marginTop: 16 }}
                />
                <SocialLogins />
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </OrderSection>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },

  userInfoContainer: {
    width: '100%',
  },

  userInfoDivider: {
    paddingBottom: 16,
    marginBottom: 16,
    borderBottom: '1px solid #e3e3e3',
  },

  signInContainer: {
    width: '49%',
    // paddingRight: 16,
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingBottom: 8,
      marginBottom: 8,
    },
  },

  signUpTutorialContainer: {
    width: '49%',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  signUpContainer: {
    width: '100%',
  },

  title: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 8,
  },

  description: {
    fontSize: 14,
    color: '#555',
    whiteSpace: 'pre-wrap',
    paddingLeft: 8,
  },
}))
