import { sendGet } from './httpClient'
import { parseResourceData } from './JsonApiSerializer'

export const index = async (page?: string) => {
  try {
    const { data } = await sendGet(`/api/addresses`)
    const json = parseResourceData(data.json)
    const meta = data.json.meta
    return { json, meta }
  } catch (e) {
    throw e.response
  }
}
