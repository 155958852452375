import { sendDelete, sendPost, sendGet } from '../httpClient'
import { parseResourceData } from '../JsonApiSerializer'

export const index = async () => {
  try {
    const { data } = await sendGet('/admin/api/admins')
    return parseResourceData(data.admins)
  } catch (e) {
    throw e.response
  }
}

export interface IInputAdminCreate {
  email: string
}

export const create = async (formData: IInputAdminCreate) => {
  const { email } = formData

  try {
    const response = await sendPost('/admin/api/admins', {
      admin: { email },
    })
    return response
  } catch (e) {
    throw e.response
  }
}

export const destroy = async (id: string) => {
  try {
    const response = await sendDelete('/admin/api/admins/' + id)
    return response
  } catch (e) {
    throw e.response
  }
}
