import { memoize } from 'lodash'

export const parseResourceData = (response) => {
  if (!response.data) {
    return response.data
  } else if (Array.isArray(response.data)) {
    return response.data.map((dataObj) => parseResourceDataObject(response, dataObj))
  } else {
    return parseResourceDataObject(response, response.data)
  }
}

export const parseResourceDataObject = (response, data) => {
  const result = {}

  Object.keys(data.attributes).forEach((attributeName) => {
    Object.defineProperty(result, attributeName, {
      value: data.attributes[attributeName],
      enumerable: true,
    })
  })

  if (!data.relationships) {
    return result
  }

  Object.keys(data.relationships).forEach((relationshipName) => {
    const relationshipData = data.relationships[relationshipName].data

    if (Array.isArray(relationshipData)) {
      Object.defineProperty(result, relationshipName, {
        get: memoize(() => {
          const related = relationshipData.map((relationshipItem) => {
            const resdata = response.included.find(
              (included) => included.id === relationshipItem.id && included.type === relationshipItem.type,
            )
            if (resdata) {
              return parseResourceDataObject(response, resdata)
            }
          })
          return related.filter((relatedItem) => relatedItem !== undefined)
        }),
        enumerable: true,
      })
    } else if (relationshipData) {
      Object.defineProperty(result, relationshipName, {
        get: memoize(() => {
          const resdata = response.included.find(
            (included) => included.id === relationshipData.id && included.type === relationshipData.type,
          )
          return resdata ? parseResourceDataObject(response, resdata) : null
        }),
        enumerable: true,
      })
    }
  })

  return result
}
