import * as React from 'react'
import * as pages from '../../../pages'
import { Wrapper, Pagination, Title, PanelWrapper, BasePanel, TableCellImage } from '../_components'
import { Button, makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { indexStores } from '../../../services/admin/StoreService'
import { format } from 'date-fns'

interface IProps {
  params: IParams
}

interface IParams {
  page: string
}

const Index: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const [data, setData] = React.useState(null)
  const [pagination, setPagination] = React.useState<any | null>(null)

  React.useEffect(() => {
    fetch()
  }, [])

  const fetch = async (page?: string) => {
    try {
      const { json, meta } = await indexStores(page)
      setData(json)
      setPagination(meta)
    } catch (e) {
      window.flashMessages.addErrorMessage('データが取得できませんでした')
    }
  }

  const onChangePageHandler = async (page) => {
    fetch(page)
  }

  const handleURLCopy = (id: string) => {
    const element = document.createElement('input')
    element.value = `${window.location.origin}${pages.STORES_SHOW(id)}`
    document.body.appendChild(element)
    element.select()
    document.execCommand('copy')
    document.body.removeChild(element)
    window.flashMessages.addSuccessMessage('コピーしました')
  }

  return (
    <React.Fragment>
      <Wrapper active="store">
        <Title
          title={'専用ストアページ'}
          action={
            <Button
              color="primary"
              size="small"
              variant="outlined"
              onClick={() => {
                window.location.href = pages.ADMIN_STORES_NEW
              }}>
              新規登録
            </Button>
          }
        />
        <PanelWrapper>
          <BasePanel>
            {data && (
              <>
                {data.length > 0 ? (
                  <>
                    <Table className="admin-table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>イメージ</TableCell>
                          <TableCell>店舗名</TableCell>
                          <TableCell>オーナー名</TableCell>
                          <TableCell>作成日時</TableCell>
                          <TableCell>期間（〜まで）</TableCell>
                          <TableCell>ステータス</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.map((d, index) => (
                          <TableRow key={`order-${index}`}>
                            <TableCell>
                              <TableCellImage src={d.image_store_url} />
                            </TableCell>

                            <TableCell className="">
                              <div className={classes.dataColumns}>
                                <a href={pages.ADMIN_STORES_EDIT(d.id)}>{d.name}</a>
                              </div>
                            </TableCell>
                            <TableCell className="">
                              <div className={classes.dataColumns}>{d.owner_name}</div>
                            </TableCell>
                            <TableCell className="">
                              <div className={classes.dataColumns}>{format(new Date(d.created_at), 'yyyy-MM-dd')}</div>
                            </TableCell>
                            <TableCell className=" ">
                              <div className={classes.dataColumns}>
                                {d.until_at && format(new Date(d.until_at), 'yyyy-MM-dd')}
                              </div>
                            </TableCell>
                            <TableCell className="">
                              <div className={classes.dataColumns}>{props.map_statuses[d.status]}</div>
                            </TableCell>

                            <TableCell className="text-right">
                              <Button
                                color="primary"
                                variant="outlined"
                                size="small"
                                style={{
                                  marginRight: 5,
                                }}
                                onClick={() => {
                                  window.location.href = pages.ADMIN_STORES_EDIT(d.id)
                                }}>
                                編集
                              </Button>

                              <Button
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={() => {
                                  handleURLCopy(d.slug)
                                }}>
                                公開URL
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    {pagination && (
                      <Pagination
                        onChangePageHandler={onChangePageHandler}
                        currentPage={pagination.current_page}
                        prevPage={pagination.prev_page}
                        nextPage={pagination.next_page}
                        totalPages={pagination.total_pages}
                        totalCount={pagination.total_count}
                      />
                    )}
                  </>
                ) : (
                  <div style={{ padding: '16px 0', textAlign: 'center' }}>データがありません</div>
                )}
              </>
            )}
          </BasePanel>
        </PanelWrapper>
      </Wrapper>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme) => ({
  dataColumns: {
    maxHeight: 100,
    overflow: 'hidden',
    maxWidth: 300,
  },
}))

export default Index
