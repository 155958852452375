import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../constants'

interface IProps {
  header: IHeader[]
}

interface IHeader {
  label: string
  align: 'inherit' | 'left' | 'center' | 'right' | 'justify'
}

const Index: React.FC<IProps> = (props) => {
  return (
    <Root>
      <Table>
        <TableHead>
          <TableRow>
            {props.header.map((head, index) => (
              <TableCell align={head.align ? head.align : 'right'} key={`useful-table-head-${index}`}>
                {head.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody></TableBody>
      </Table>
    </Root>
  )
}

const Root = styled.div`
  //
`

export default Index
