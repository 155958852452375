import { sendGet, sendPut, sendPost } from '../httpClient'
import { parseResourceData } from '../JsonApiSerializer'

export const index = async (page: string) => {
  try {
    const { data } = await sendGet(`/admin/api/orders${page ? '?page=' + page : ''}`)
    const json = parseResourceData(data.json)
    const meta = data.json.meta
    return { json, meta }
  } catch (e) {
    throw e.response
  }
}

export const show = async (id: string) => {
  try {
    const { data } = await sendGet('/admin/api/orders/' + id)
    const json = parseResourceData(data.json)
    return { json }
  } catch (e) {
    throw e.response
  }
}

// export interface IInputCreate {
//   //
// }

export interface IInputUpdate {
  id: string
  status: string
}

export const update = async (formData: IInputUpdate) => {
  const { id, status } = formData

  try {
    const response = await sendPut('/admin/api/orders/' + id, {
      order: {
        status,
      },
    })
    return response
  } catch (e) {
    throw e.response || e
  }
}

export interface IInputSendDeliveryMail {
  shipper: string
  delivery_no: string
  image_1: any
  image_2: any
  note: string
}

export const sendDeliveryMail = async (formData: IInputSendDeliveryMail, id: string) => {
  const { shipper, delivery_no, image_1, image_2, note } = formData

  const params = new FormData()

  params.append('delivery_mail[shipper]', shipper)
  params.append('delivery_mail[delivery_no]', delivery_no)

  if (image_1[0]) {
    params.append('delivery_mail[image_1]', image_1[0])
  }
  if (image_2[0]) {
    params.append('delivery_mail[image_2]', image_2[0])
  }
  params.append('delivery_mail[note]', note)

  try {
    const response = await sendPost(`/admin/api/orders/${id}/delivery_mail`, params)
    return response
  } catch (e) {
    throw e.response
  }
}
