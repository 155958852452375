import * as React from 'react'
import * as constants from '../../constants'
import { makeStyles } from '@material-ui/core'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded'
import { format } from 'date-fns'

interface IProps {
  item: any
  prefecture?: string
  earliestDelivaryDate: string
  earliestDelivaryDays: string
  orderLimitDate: string
  deliveryLimitAt: string
  disabledDeliveryLimit?: boolean
}

export const ItemAdditionalInfo: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const prefecture = props.prefecture || '東京都'
  const { item } = props
  const todayOrTomorrow = item.today_or_tomorrow as string

  return (
    <div style={{ marginBottom: 32 }}>
      {item && item.category_values && (
        <div className={classes.categoryContainer}>
          <div className={classes.categoryTitle}>商品カテゴリ：</div>

          {item.category_values.map((category, index) => (
            <div key={`category-${index}`} className={classes.category}>
              {category.label}
            </div>
          ))}
        </div>
      )}

      {item.color && (
        <p style={{ fontSize: 12 }}>
          <span style={{ fontWeight: 'bold' }}>色</span>：{item.color}
        </p>
      )}
      {item.wrapping && (
        <p style={{ fontSize: 12 }}>
          <span style={{ fontWeight: 'bold' }}>ラッピング</span>：{item.wrapping}
        </p>
      )}

      {!props.disabledDeliveryLimit && item && props.earliestDelivaryDays ? (
        <div className={classes.root}>
          {props.earliestDelivaryDays && (
            <>
              <p style={{ fontSize: 12 }}>
                <span style={{ fontWeight: 'bold' }}>
                  {prefecture && <>{prefecture}への</>}
                  最短お届け日
                </span>
                ： 注文から
                {props.earliestDelivaryDays}
                日後
              </p>

              <div className={classes.line} />
              {props.earliestDelivaryDate ? (
                <div className={classes.limit}>
                  <p className={classes.msg}>
                    <LocalShippingIcon />
                    <span style={{ fontWeight: 'bold' }}>
                      {format(new Date(props.earliestDelivaryDate), 'MM月dd日')}
                    </span>
                    までにお届けするには
                  </p>
                  <p className={classes.msg}>
                    <HourglassEmptyRoundedIcon />
                    <span style={{ fontWeight: 'bold' }}>
                      {props.earliestDelivaryDate !== props.orderLimitDate
                        ? `${format(new Date(props.orderLimitDate), 'MM月dd日')}の${props.deliveryLimitAt}`
                        : todayOrTomorrow}
                    </span>
                    までにご注文ください
                  </p>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f3f3f3',
    color: '#555',
    marginBottom: 16,
    padding: '8px 0',

    '& p': {
      padding: '0px 16px',
      marginBottom: 4,
    },
  },

  line: {
    borderTop: '1px solid #e3e3e3',
    paddingTop: 8,
    marginTop: 8,
  },

  categoryContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '1rem',
    fontSize: 12,
  },

  categoryTitle: {
    fontWeight: 'bold',
    height: 20,
    display: 'flex',
    alignItems: 'center',
  },

  category: {
    fontSize: 11,
    background: constants.MAIN_COLOR_LIGHT,
    color: '#333',
    borderRadius: 11,
    padding: '0 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 8,
    marginBottom: 4,
    height: 20,
  },

  limit: {
    fontSize: 12,
    fontFamily: 'sans-serif',
  },

  msg: {
    padding: '4px 0',
    '& svg': {
      marginRight: 8,
      fill: constants.MAIN_COLOR,
    },
  },
}))
