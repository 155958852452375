import { makeStyles } from '@material-ui/core'
import React from 'react'

declare const window: T

interface Props {
  open: boolean
  amazonOrderReferenceId: string | null
  setAmazonOrderReferenceId: (id: string) => void
}

export const AmazonPayButton: React.FC = () => {
  return <div id="AmazonPayButton" />
}

export const AmazonPayButtonWidget: React.FC = (props: Props) => {
  const classes = useStyles()

  React.useEffect(() => {
    const id = getAmazonPayClientId()
    if (id && window['showButton']) {
      window['showButton'](onReady, onError)
    }
  }, [])

  const onReady = (readyOrderReferenceId: string) => {
    props.setAmazonOrderReferenceId(readyOrderReferenceId)
  }

  const onError = (error_message: string) => {
    console.log('error_message: ', error_message)
  }

  return (
    <>
      <div
        id="walletWidgetDiv"
        className={classes.widgetContainer}
        style={{
          display: props.open ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#999',
        }}>
        <p>お支払い方法がここに表示されます</p>
      </div>
    </>
  )
}

const getAmazonPayClientId = (): string => {
  const element: IElement = document.querySelector('meta[name=amazon-pay-client-id]')

  return element ? element.content : ''
}

interface IElement {
  content: string
}

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    maxWidth: 500,
    margin: '0 auto',
    display: 'flex',
    marginBottom: 8,
  },
  buttonContainer: {
    width: '50%',
  },

  buttonContainerLeft: {
    paddingRight: 4,
  },

  buttonContainerRight: {
    paddingLeft: 4,
  },

  button: {
    width: '100%',
    height: 50,
    borderRadius: 0,
  },

  widgetContainer: {
    height: 250,
    marginBottom: 8,
    border: '1px solid #e3e3e3',
    borderRadius: 5,
    marginLeft: 30,
  },
}))
