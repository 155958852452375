import * as React from 'react'
import { Box, makeStyles, TextField, StandardTextFieldProps, Typography } from '@material-ui/core'
import { DefaultThemeProvider, FormError } from '.'
import { Control, Controller, FieldError } from 'react-hook-form'

interface Props {
  name: string
  control: Control<unknown>
  imageUrl?: string
}

export const InputImage = (props: Props) => {
  const { name, control, imageUrl } = props
  const classes = useStyles()
  const [preview, setPreview] = React.useState(null)

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        render={({ name, onChange, value }) => (
          <label htmlFor={name} className={classes.label}>
            <div className={classes.imageContainer}>
              {preview || imageUrl ? (
                <img src={preview || imageUrl} alt={''} />
              ) : (
                <div className="no_image">ファイルを選択</div>
              )}
            </div>
            <input
              id={name}
              name={name}
              type="file"
              onChange={(e) => {
                onChange(e.currentTarget.files)
                setPreview(URL.createObjectURL(e.currentTarget.files[0]))

                // file: e.currentTarget.files[0],
                //   url: URL.createObjectURL(e.currentTarget.files[0]),
              }}
              accept="image/x-png,image/jpeg"
              style={{ display: 'none' }}
            />
          </label>
        )}
      />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  label: {
    cursor: 'pointer',
    marginRight: 8,
    '&:hover': {
      opacity: 0.7,
    },
  },

  imageContainer: {
    border: '2px dashed #aaa',
    borderRadius: 4,
    width: 200,
    height: 200,
    overflow: 'hidden',

    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 8,
    },

    '& .no_image': {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#e5e5e5',
      color: '#999',
    },
  },

  image: {
    // img {
    //   height: 100%;
    // }
    // &__no {
    //   height: 100%;
    //   width: 200px;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   background: #e5e5e5;
    //   color: #999;
    // }
  },
}))
