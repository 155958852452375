import * as React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import * as pages from '../../pages'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import PersonIcon from '@material-ui/icons/Person'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import SettingsIcon from '@material-ui/icons/Settings'
import { makeStyles } from '@material-ui/core'

interface IProps {
  signedIn?: boolean
  isAdmin?: boolean
  disabledToRoot?: boolean
}

export const Header: React.FC<IProps> = (props) => {
  const classes = useStyles({})

  const getURL = (name: string) => {
    return document.querySelector(`meta[name="${name}"]`).getAttribute('content')
  }
  return (
    <div className={classes.root}>
      <Navbar
        collapseOnSelect={true}
        expand="lg"
        // bg="dark"
        // variant="dark"
        className={classes.navbar}>
        <Navbar.Brand href={props.disabledToRoot ? '#' : '/'}>
          <img src="/images/logo.svg" alt="はなテック" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            {props.signedIn ? (
              <>
                <Nav.Link href={pages.MYPAGE}>
                  <div className={classes.iconContainer}>
                    <PersonIcon className={classes.iconSvg} />
                    <div className={classes.iconTitle}>マイページ</div>
                  </div>
                </Nav.Link>
                <div className={classes.spDropDown}>
                  <Nav.Link href={pages.NAME_TAGS}>名札一覧</Nav.Link>
                  <Nav.Link href={pages.CHANGE_PASSWORD}>パスワード変更</Nav.Link>
                  <Nav.Link href={pages.HISTORIES}>購入履歴</Nav.Link>
                  {/* <Nav.Link href={pages.REFERRAL_CODE}>紹介コード</Nav.Link> */}
                  <Nav.Link href={pages.SIGNOUT}>ログアウト</Nav.Link>
                </div>

                {props.isAdmin && (
                  <>
                    <Nav.Link href={pages.ADMIN_ORDERS}>
                      <div className={classes.iconContainer}>
                        <SettingsIcon className={classes.iconSvg} />
                        <div className={classes.iconTitle}>管理</div>
                      </div>
                    </Nav.Link>
                    <div className={classes.spDropDown}>
                      <Nav.Link href={pages.ADMIN_CATEGORIES}>カテゴリ</Nav.Link>
                      <Nav.Link href={pages.ADMIN_ITEMS}>アイテム</Nav.Link>
                      <Nav.Link href={pages.ADMIN_USERS}>ユーザー</Nav.Link>
                      <Nav.Link href={pages.ADMIN_INTRODUCERS}>紹介者</Nav.Link>
                      <Nav.Link href={pages.ADMIN_TRADERS}>手配業者</Nav.Link>
                      <Nav.Link href={pages.ADMIN_MONTH_SALES}>売上（月別）</Nav.Link>
                      <Nav.Link href={pages.ADMIN_INTRODUCER_SALES}>売上（紹介者別）</Nav.Link>
                      <Nav.Link href={'#'}>お問い合わせ</Nav.Link>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <Nav.Link href={pages.SIGNUP}>
                  <div className={classes.iconContainer}>
                    <PersonAddIcon className={classes.iconSvg} />
                    <div className={classes.iconTitle}>会員登録</div>
                  </div>
                </Nav.Link>
                <Nav.Link href={pages.SIGNIN}>
                  <div className={classes.iconContainer}>
                    <ExitToAppIcon className={classes.iconSvg} />
                    <div className={classes.iconTitle}>ログイン</div>
                  </div>
                </Nav.Link>
              </>
            )}

            <Nav.Link href={getURL('url-contact')}>
              <div className={classes.iconContainer}>
                <MailOutlineIcon className={classes.iconSvg} />
                <div className={classes.iconTitle}>お問い合わせ</div>
              </div>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '1px solid #e3e3e3',
    background: '#fefefe',
  },

  navbar: {
    '& img': {
      height: 30,
    },
  },

  spDropDown: {
    display: 'none',
    paddingLeft: 16,

    [theme.breakpoints.down('sm')]: {
      display: 'block',
      paddingLeft: 0,
    },

    '& a': {
      textAlign: 'center',
    },
  },

  iconContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },

  iconSvg: {
    height: 20,
  },

  iconTitle: {
    width: '100%',
    textAlign: 'center',
    fontSize: 11,
  },
}))
