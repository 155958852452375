import * as React from 'react'
import styled from 'styled-components'
import * as pages from '../../../pages'
import { Button, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { show, update, destroy, IInputUpdate } from '../../../services/admin/UserService'
import { makeStyles } from '@material-ui/core'
import { Wrapper, Title, PanelWrapper, FormContainer, FormInputs, FormLabel, BasePanel, Loading } from '../_components'
import { IOption } from '../../../services/admin/ItemService'
import { format } from 'date-fns'

interface IParams {
  id: string
}

interface IProps {
  params: IParams
  introducers: IOption[]
}

const Index: React.FC<IProps> = (props) => {
  const [loading, setLoading] = React.useState(true)
  const [processing, setProcessing] = React.useState(false)
  const [data, setData] = React.useState(null)
  const classes = useStyles()

  React.useEffect(() => {
    const f = async () => {
      if (props.params.id) {
        try {
          const { json } = await show(props.params.id)
          setData(json)
        } catch (e) {
          window.flashMessages.addErrorMessage('データが取得できませんでした')
        }
      }
      setLoading(false)
    }
    if (!data) {
      f()
    }
  }, [])

  // form
  const { register, handleSubmit, setValue, errors, getValues, setError } = useForm<IInputUpdate>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = async (inputData: IInputUpdate) => {
    setProcessing(true)
    try {
      if (data) {
        await update(inputData)
      }
      window.location.href = pages.ADMIN_USERS
    } catch (e) {
      setProcessing(false)
      window.flashMessages.addErrorMessage('エラーが発生しました')
    }
  }

  // const handleDelete = async (id: string) => {
  //   if (confirm('本当に削除しますか？')) {
  //     setProcessing(true)
  //     try {
  //       await destroy(id)
  //       window.location.href = pages.ADMIN_USERS
  //     } catch (e) {
  //       setProcessing(false)
  //       window.flashMessages.addErrorMessage('エラーが発生しました')
  //     }
  //   }
  // }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
        <Wrapper active="user">
          <Title
            back={pages.ADMIN_USERS}
            title={`ユーザー${data ? '更新' : '登録'}`}
            action={
              <Button variant="primary" type="submit" size="sm" style={{ color: 'white' }} disabled={processing}>
                {data ? '更新' : '登録'}
              </Button>
            }
          />
          {loading ? (
            <Loading />
          ) : (
            <PanelWrapper>
              <BasePanel>
                <PanelBody>
                  <>
                    <div className="panelBody__formContainer">
                      <FormContainer>
                        <FormLabel>ID</FormLabel>
                        <FormInputs>
                          <Form.Control name="id" readOnly ref={register({})} defaultValue={data && data.id} />
                        </FormInputs>
                      </FormContainer>
                      <FormContainer>
                        <FormLabel>メールアドレス</FormLabel>
                        <FormInputs>
                          <Form.Control name="email" readOnly defaultValue={data && data.email} />
                        </FormInputs>
                      </FormContainer>
                      <FormContainer>
                        <FormLabel>名前</FormLabel>
                        <FormInputs>
                          <Form.Control name="username" readOnly defaultValue={data && data.username} />
                        </FormInputs>
                      </FormContainer>
                      <FormContainer>
                        <FormLabel>郵便番号</FormLabel>
                        <FormInputs>
                          <Form.Control name="zipcode" readOnly defaultValue={data && data.zipcode} />
                        </FormInputs>
                      </FormContainer>
                      <FormContainer>
                        <FormLabel>住所</FormLabel>
                        <FormInputs>
                          <Form.Control
                            name="address"
                            readOnly
                            defaultValue={
                              data &&
                              `${data.prefecture ? data.prefecture : ''}${data.city ? data.city : ''}${
                                data.address ? data.address : ''
                              }`
                            }
                          />
                        </FormInputs>
                      </FormContainer>
                      <FormContainer>
                        <FormLabel>電話番号</FormLabel>
                        <FormInputs>
                          <Form.Control name="phone" readOnly defaultValue={data && data.phone} />
                        </FormInputs>
                      </FormContainer>
                      <FormContainer>
                        <FormLabel>担当者</FormLabel>
                        <FormInputs>
                          <Form.Control name="name" readOnly defaultValue={data && data.department} />
                        </FormInputs>
                      </FormContainer>
                      <FormContainer>
                        <FormLabel>紹介コード</FormLabel>
                        <FormInputs style={{ width: 300 }}>
                          <Form.Control
                            name="introducer_code"
                            defaultValue={data && data.introducer_code}
                            ref={register({})}
                            autoComplete="nope"
                            as="select"
                            custom={true}>
                            <option value="">選択してください</option>
                            {props.introducers &&
                              props.introducers.map((introducer, index) => (
                                <option key={`introducer-option-${index}`} value={introducer.value}>
                                  {introducer.label}
                                </option>
                              ))}
                          </Form.Control>
                        </FormInputs>
                      </FormContainer>
                      <FormContainer>
                        <FormLabel>登録日時</FormLabel>
                        <FormInputs>
                          <Form.Control
                            name="name"
                            readOnly
                            defaultValue={data && format(new Date(data.created_at), 'yyyy-MM-dd HH:mm:ss')}
                          />
                        </FormInputs>
                      </FormContainer>
                    </div>

                    {/* {data && (
                      <div className="panelBody__delete">
                        <Button
                          variant="danger"
                          onClick={() => {
                            handleDelete(data.id)
                          }}
                          disabled={processing}>
                          削除
                        </Button>
                      </div>
                    )} */}
                  </>
                </PanelBody>
                {!data && <p>No Data</p>}
              </BasePanel>
            </PanelWrapper>
          )}
        </Wrapper>
      </form>
    </React.Fragment>
  )
}

const PanelBody = styled.div`
  .panelBody {
    &__formContainer {
      &__inputContainer {
        &__img {
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px;

          img {
            height: 100%;
          }

          &__no {
            height: 100%;
            width: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #e5e5e5;
            color: #999;
          }
        }
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({}))

export default Index
