import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../../constants'
import * as pages from '../../../pages'
import { InputGroup, Button, Form } from 'react-bootstrap'
import { Wrapper, Pagination, Title, PanelWrapper, BasePanel } from '../_components'
import { Header, Footer } from '../../_components'
import { useForm } from 'react-hook-form'
import { create, IInputCreate } from '../../../services/admin/IntroducerService'

interface Props {}

const Index: React.FC<Props> = () => {
  const [processing, setProcessing] = React.useState(false)
  // form
  const { register, handleSubmit, setValue, errors, getValues, setError } = useForm<IInputCreate>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = async (data: IInputCreate) => {
    setProcessing(true)
    try {
      await create(data)
      window.location.href = pages.ADMIN_INTRODUCERS
    } catch (e) {
      setProcessing(false)
      window.flashMessages.addErrorMessage('エラーが発生しました')
    }
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
        <Wrapper active="introducer">
          <Title
            back={pages.ADMIN_INTRODUCERS}
            title={'紹介者登録'}
            action={
              <Button variant="primary" type="submit" size="sm" style={{ color: 'white' }} disabled={processing}>
                登録
              </Button>
            }
          />
          <PanelWrapper>
            <BasePanel>
              <PanelBody>
                <div className="panelBody__formContainer">
                  <div className="panelBody__formContainer__inputContainer w50">
                    <label className="required">事業者名</label>
                    <Form.Control
                      name="name"
                      ref={register({
                        required: '事業者名は必須です',
                      })}
                      autoComplete="nope"></Form.Control>
                    {errors.name && <small>{errors.name.message}</small>}
                  </div>
                  <div className="panelBody__formContainer__inputContainer w50">
                    <label className="required">担当者名</label>
                    <Form.Control
                      name="staff_name"
                      ref={register({
                        required: '担当者名は必須です',
                      })}
                      autoComplete="nope"></Form.Control>
                    {errors.staff_name && <small>{errors.staff_name.message}</small>}
                  </div>
                  <div className="panelBody__formContainer__inputContainer w50">
                    <label className="required">電話番号</label>
                    <Form.Control
                      name="phone"
                      ref={register({
                        required: '電話番号は必須です',
                      })}
                      autoComplete="nope"></Form.Control>
                    {errors.phone && <small>{errors.phone.message}</small>}
                  </div>
                  <div className="panelBody__formContainer__inputContainer w50">
                    <label className="required">メールアドレス</label>
                    <Form.Control
                      name="email"
                      type="email"
                      ref={register({
                        required: 'メールアドレスは必須です',
                      })}
                      autoComplete="nope"></Form.Control>
                    {errors.email && <small>{errors.email.message}</small>}
                  </div>
                  <div className="panelBody__formContainer__inputContainer w50">
                    <label className="required">紹介コード</label>
                    <Form.Control
                      name="code"
                      ref={register({
                        required: '紹介コードは必須です',
                      })}
                      autoComplete="nope"></Form.Control>
                    {errors.code && <small>{errors.code.message}</small>}
                  </div>
                </div>
              </PanelBody>
            </BasePanel>
          </PanelWrapper>
        </Wrapper>
      </form>
    </React.Fragment>
  )
}

const PanelBody = styled.div`
  .panelBody {
    &__formContainer {
      &__inputContainer {
        &__img {
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px;

          img {
            height: 100%;
          }

          &__no {
            height: 100%;
            width: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #e5e5e5;
            color: #999;
          }
        }
      }
    }
  }
`

export default Index
