import * as constants from '../../constants'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import * as React from 'react'
import red from '@material-ui/core/colors/red'

interface Props {
  children: React.ReactNode
}

const Index: React.FC<Props> = (props) => {
  return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
}

const theme = createMuiTheme({
  palette: {
    primary: {
      50: '#f0f9f4',
      100: '#d8efe3',
      200: '#bfe5d0',
      300: '#a5dabd',
      400: '#91d2ae',
      500: '#7ecaa0',
      600: '#76c598',
      700: '#6bbd8e',
      800: '#61b784',
      900: '#4eab73',
      A100: '#ffffff',
      A200: '#d6ffe6',
      A400: '#a3ffc7',
      A700: '#8affb7',
      contrastText: '#fff',
      // contrastDefaultColor: 'light',
    },

    secondary: red,
  },
})

export default Index
