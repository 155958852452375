import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../../constants'
import * as pages from '../../../pages'
import { Header, Footer, Breadcrumbs, DefaultTextField, DefaultButton, ImagePreview, Loading } from '../../_components'
import Menus from '../../_components/Menus'
import { useForm } from 'react-hook-form'
import { show, review, IInputReview } from '../../../services/ItemService'
import IWindow from '../../../interfaces/IWindow'
import { makeStyles, Typography, Box, CircularProgress } from '@material-ui/core'
import { Rating } from '@material-ui/lab'
import { MypageContainer } from '../../_components/MypageContainer'

declare const window: IWindow

interface Props {
  params: IParams
  itemReview: any
}

interface IParams {
  id: string
  item_id: string
}

const DEFAULT_RATING = 5

const Index: React.FC<Props> = (props) => {
  const classes = useStyles()

  const [data, setData] = React.useState(null)

  React.useEffect(() => {
    fetch()

    register('rating')
    setValue('rating', props.itemReview ? props.itemReview.rating : DEFAULT_RATING)
  }, [])

  const fetch = async () => {
    try {
      const { json: fetchedData } = await show(props.params.item_id)
      setData(fetchedData)
    } catch (e) {
      window.flashMessages.addErrorMessage('商品情報を取得できませんでした')
    }
  }

  // form
  const [loading, setLoading] = React.useState<boolean>(false)

  const { register, handleSubmit, setValue, errors, getValues, setError } = useForm<IInputReview>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = async (inputData: IInputReview) => {
    setLoading(true)
    await new Promise((resolve) => setTimeout(resolve, 1000))

    try {
      await review(inputData)
      window.flashMessages.addSuccessMessage('レビューを投稿しました')
    } catch (e) {
      window.flashMessages.addErrorMessage(e.data?.error ? e.data.error : 'エラーが発生しました')
    }

    setLoading(false)
  }

  const [rating, setRating] = React.useState<number | null>(props.itemReview ? props.itemReview.rating : DEFAULT_RATING)
  const onChangeRating = (newValue: number) => {
    setValue('rating', newValue)
    setRating(newValue)
  }

  return (
    <React.Fragment>
      <Header signedIn={true} />

      <MypageContainer active="histories" maxWidth={700}>
        <Breadcrumbs>
          <a href={pages.HISTORIES}>購入履歴</a> / <a href={pages.HISTORIES_SHOW(props.params.id)}>購入履歴詳細</a>/
          レビュー
        </Breadcrumbs>
        <div className={classes.contentContainer}>
          {data != null ? (
            <>
              <div className={classes.cardContainer}>
                <div className={classes.bodyContainer}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      marginBottom: 16,
                      paddingBottom: 16,
                      borderBottom: '1px solid #eee',
                    }}>
                    <ImagePreview src={data.image_urls[0]} />
                    <div className="value" style={{ paddingLeft: 8 }}>
                      <a href={pages.ITEMS_SHOW(data.slug)}>{data.name}</a>
                    </div>
                  </div>

                  <div style={{ width: '100%' }}>
                    <input name="order_id" ref={register()} defaultValue={props.params.id} hidden={true} />
                    <input name="item_id" ref={register()} defaultValue={props.params.item_id} hidden={true} />
                    <Box component="fieldset" mb={1} borderColor="transparent">
                      <Typography component="legend" className={'title'}>
                        おすすめ度
                      </Typography>
                      <Rating
                        name="rating"
                        value={rating}
                        onChange={(event, newValue) => {
                          onChangeRating(newValue)
                        }}
                        disabled={loading}
                      />
                    </Box>
                    <Typography component="legend" className={'title'}>
                      ニックネーム
                    </Typography>
                    <DefaultTextField
                      name="nickname"
                      placeholder="名無し"
                      defaultValue={props.itemReview ? props.itemReview.nickname : ''}
                      inputRef={register()}
                      disabled={loading}
                    />

                    <Typography component="legend" className={'title'}>
                      タイトル
                    </Typography>
                    <DefaultTextField
                      name="title"
                      defaultValue={props.itemReview ? props.itemReview.title : ''}
                      inputRef={register()}
                      disabled={loading}
                    />

                    <Typography component="legend" className={'title'}>
                      内容
                    </Typography>
                    <DefaultTextField
                      name="body"
                      multiline={true}
                      rows={4}
                      defaultValue={props.itemReview ? props.itemReview.body : ''}
                      inputRef={register({ required: '入力してください' })}
                      error={errors.body ? true : false}
                      helperText={errors.body ? errors.body.message : ''}
                      disabled={loading}
                    />

                    <div style={{ textAlign: 'center' }}>
                      <DefaultButton
                        onClick={handleSubmit(onSubmit)}
                        disabled={loading}
                        style={{ width: 100, height: 40 }}>
                        {loading ? <CircularProgress size={20} /> : '投稿する'}
                      </DefaultButton>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Loading />
          )}
        </div>
      </MypageContainer>

      <Footer />
    </React.Fragment>
  )
}

export default Index

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    margin: '0 auto',
    maxWidth: 500,
  },

  cardContainer: {
    backgroundColor: '#fff',
    width: '100%',
    marginBottom: 16,
    border: '1px solid #e3e3e3',
    borderRadius: 4,
    overflow: 'hidden',
  },

  bodyContainer: {
    padding: 16,

    '& .value': {
      fontSize: 12,
    },

    '& .title': {
      fontWeight: 'bold',
      fontSize: 11,
    },
  },
}))
