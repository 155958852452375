import * as React from 'react'
import { constants } from '../../../_assets'
import { makeStyles } from '@material-ui/core'
import { ItemAdditionalInfo, SquareImageWithSubs } from '../../_components'
import { ItemPriceWithLabel } from '../../_components/ItemPriceWithLabel'
import { User } from '../../../services/UserService'
import { Item } from '../../../services/ItemService'

interface Props {
  item: Item
  user: User
  handleOnCancel: () => void
}
export const OrderItem: React.FC<Props> = (props) => {
  const classes = useStyles()

  const [selectedImgIndex, setSelectedImgIndex] = React.useState(0)

  return (
    <div className={classes.root}>
      <SquareImageWithSubs
        urls={props.item.image_urls}
        selectedImgIndex={selectedImgIndex}
        setSelectedImgIndex={setSelectedImgIndex}
      />

      <div className={classes.itemInfoContainer}>
        <div className={classes.itemTitle}>{props.item.name}</div>
        <ItemPriceWithLabel user={props.user} item={props.item} />

        <ItemAdditionalInfo
          item={props.item}
          // prefecture={states.prefecture}
          // earliestDelivaryDate={states.earliestDelivaryDate}
          // earliestDelivaryDays={states.earliestDelivaryDays}
          // orderLimitDate={states.orderLimitDate}
          deliveryLimitAt={props.item.delivery_limit_at}
          // disabledDeliveryLimit={store ? true : false}
        />

        <div className={classes.itemDescription}>{props.item.description}</div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    // borderBottom: '1px solid #e3e3e3',
  },

  label: {
    width: '100%',
    marginBottom: 0,
  },

  input: {
    width: '50%',
    marginBottom: 16,
  },

  caution: {
    fontSize: 12,
    marginBottom: 4,
    color: '#555',
  },

  itemDescription: {
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
    fontSize: 14,
  },
}))
