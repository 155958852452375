import * as React from 'react'
import { constants } from '../../../_assets'
import { Card, makeStyles, Typography } from '@material-ui/core'
import { DefaultButton } from '../../_components'
import { ConfirmAddresses, ConfirmOptions, ConfirmSessions, OrderSectionNumber, OrderSectionTriangle } from '.'
import { FormAddresses } from './OrderAddresses'
import { FormOptions } from './OrderOptions'
import { User } from '../../../services/UserService'

interface Props {
  currentStep: number
  user: User
  formOptions: FormOptions
  formAddresses: FormAddresses
  processing: boolean
  handleSubmit: () => void
}

const SESSION_STEP = 5

export const OrderConfirms: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div
        className={classes.triangleContainer}
        style={{
          marginBottom: 16,
        }}>
        <OrderSectionTriangle />
      </div>

      <Card variant="outlined" className={classes.cardContainer}>
        <div className={classes.titleContainer}>
          <Typography className={classes.title} variant="h6" component="h3" style={{ fontWeight: 'bold' }}>
            <OrderSectionNumber title={'5'} active={props.step === props.currentStep} />
            注文確認
          </Typography>
        </div>

        <div
          className={classes.bodyContainer}
          style={{ display: SESSION_STEP === props.currentStep ? 'block' : 'none' }}>
          {props.user ? <ConfirmSessions user={props.user} /> : <></>}

          {props.formOptions ? <ConfirmOptions options={props.formOptions} addresses={props.formAddresses} /> : <></>}

          {props.formAddresses ? <ConfirmAddresses addresses={props.formAddresses} /> : <></>}

          <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
            <DefaultButton
              processing={props.processing ? 'true' : 'false'}
              buttonclassname={classes.buttonSubmit}
              onClick={props.handleSubmit}>
              注文確定
            </DefaultButton>
          </div>
        </div>
      </Card>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'none',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },

  cardContainer: {
    marginBottom: 16,
    overflow: 'visible',
  },

  titleContainer: (props) => ({
    backgroundColor: props.active ? constants.MAIN_COLOR_DARK : '#fff',
    padding: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),

  title: (props) => ({
    display: 'flex',
    alignItems: 'center',
    color: props.active ? '#fff' : constants.MAIN_COLOR_DARK,
  }),

  edit: {
    color: constants.MAIN_COLOR_DARK,
    backgroundColor: '#fff',
    borderColor: constants.MAIN_COLOR_DARK,
  },

  bodyContainer: {
    position: 'relative',
    borderTop: '1px solid #e3e3e3',
    padding: 16,
  },

  triangle: {
    position: 'absolute',
    top: -1,
    left: 20,
  },

  triangleContainer: {
    display: 'flex',
    justifyContent: 'center',
  },

  buttonSubmit: {
    width: 100,
    height: 36,
  },
}))
