import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import { FieldError } from 'react-hook-form'
import { DeepMap } from 'react-hook-form/dist/types/utils'
import { IOption } from '../../../services/admin/ItemService'

interface IProps {
  error: IError | FieldError
  //  | DeepMap<IOption, FieldError>[]
}

interface IError {
  message: string
}

export const FormError: React.FC<IProps> = (props) => {
  const classes = useStyles()

  return <>{props.error && <small className={classes.text}>{props.error.message}</small>}</>
}

const useStyles = makeStyles((theme) => ({
  text: {
    color: 'red',
    fontSize: 10,
    paddingLeft: 8,
  },
}))
