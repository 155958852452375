import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import { FieldError } from 'react-hook-form'

interface IProps {
  error?: IError | FieldError
}

interface IError {
  message: string
}

export const FormError: React.FC<IProps> = (props) => {
  const classes = useStyles()

  return <>{props.error ? <small className={classes.text}>{props.error.message}</small> : <></>}</>
}

const useStyles = makeStyles((theme) => ({
  text: {
    color: 'red',
    fontSize: 10,
    paddingLeft: 8,
  },
}))
