import { useRef, useCallback } from 'react'

export const useDebounce = (fn, delay = 100) => {
  const timer = useRef(null)

  const dispatch = useCallback(
    (_val) => {
      timer.current && clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        fn(_val)
      }, delay)
    },
    [fn, delay, timer]
  )

  return [dispatch]
}
