import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../../constants'
import * as pages from '../../../pages'
import { InputGroup, Button, Form } from 'react-bootstrap'
import { Wrapper, Pagination, Title, PanelWrapper, BasePanel } from '../_components'
import { Header, Footer } from '../../_components'
import { useForm } from 'react-hook-form'
import { create, IInputCreate } from '../../../services/admin/TraderService'
import { IOption } from '../../../services/admin/ItemService'
import Select from 'react-select'

interface Props {}

const Index: React.FC<Props> = () => {
  // form
  const { register, handleSubmit, setValue, errors, getValues, setError } = useForm<IInputCreate>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = async (data: IInputCreate) => {
    try {
      await create(data)
      window.location.href = pages.ADMIN_TRADERS
    } catch (e) {
      window.flashMessages.addErrorMessage('エラーが発生しました')
    }
  }

  React.useEffect(() => {
    register('holidays', {})
  }, [])
  const handleCategorySelect = (values: IOption[]) => {
    const holidayValues = []
    if (values) {
      values.forEach((val, index) => {
        holidayValues.push(val.value)
      })
    }
    setValue('holidays', holidayValues.toString())
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
        <Wrapper active="trader">
          <Title
            back={pages.ADMIN_TRADERS}
            title={'手配業者登録'}
            action={
              <Button variant="primary" type="submit" size="sm" style={{ color: 'white' }}>
                登録
              </Button>
            }
          />
          <PanelWrapper>
            <BasePanel>
              <PanelBody>
                <div className="panelBody__formContainer">
                  <div className="panelBody__formContainer__inputContainer w50">
                    <label className="required">手配業者名</label>
                    <Form.Control
                      name="name"
                      ref={register({
                        required: '手配業者名は必須です',
                      })}
                      autoComplete="nope"></Form.Control>
                    {errors.name && <small>{errors.name.message}</small>}
                  </div>
                  <div className="panelBody__formContainer__inputContainer w50">
                    <label className="required">電話番号</label>
                    <Form.Control
                      name="phone"
                      ref={register({
                        required: '電話番号は必須です',
                      })}
                      autoComplete="nope"></Form.Control>
                    {errors.phone && <small>{errors.phone.message}</small>}
                  </div>
                  <div className="panelBody__formContainer__inputContainer w50">
                    <label className="required">メールアドレス</label>
                    <Form.Control
                      name="email"
                      type="email"
                      ref={register({
                        required: 'メールアドレスは必須です',
                      })}
                      autoComplete="nope"></Form.Control>
                    {errors.email && <small>{errors.email.message}</small>}
                  </div>

                  <div className="panelBody__formContainer__inputContainer w100">
                    <label className="required">休日</label>
                    <Select
                      closeMenuOnSelect={false}
                      defaultValue={''}
                      className="reactSelect"
                      placeholder="選択してください"
                      isMulti={true}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: constants.MAIN_COLOR,
                          primary: constants.MAIN_COLOR,
                        },
                      })}
                      options={constants.optionsHoliday()}
                      onChange={handleCategorySelect}
                    />
                  </div>
                </div>
              </PanelBody>
            </BasePanel>
          </PanelWrapper>
        </Wrapper>
      </form>
    </React.Fragment>
  )
}

const PanelBody = styled.div`
  .panelBody {
    &__formContainer {
      &__inputContainer {
        &__img {
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px;

          img {
            height: 100%;
          }

          &__no {
            height: 100%;
            width: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #e5e5e5;
            color: #999;
          }
        }
      }
    }
  }
`

export default Index
