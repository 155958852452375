import * as React from 'react'
import { makeStyles, Theme, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { DefaultButton, DefaultTextField, FormError } from '../../_components'
import { Controller, useForm } from 'react-hook-form'
import { IInputMypage, updateMypage, User } from '../../../services/UserService'
import { getAddressByzipcode } from '../../../services/postAddress'
import Select from 'react-select'
import { optionPrefectures } from '../../../utils/prefecture'
import { constants } from '../../../_assets'

interface Props {
  user: User
  afterOnSubmit?: () => void
  buttonLabel?: string
  guestEmail?: string
  disableAlert?: boolean
}

export const ProfileForms: React.FC<Props> = (props) => {
  const classes = useStyles()

  const { user } = props

  const { handleSubmit, setValue, errors, getValues, control } = useForm<IInputMypage>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const onSubmit = async (data: IInputMypage) => {
    try {
      await updateMypage(data, props.guestEmail)
      if (!props.disableAlert) {
        window.flashMessages.addSuccessMessage('プロフィールを更新しました')
      }

      if (props.afterOnSubmit) {
        props.afterOnSubmit()
      }
    } catch (e) {
      window.flashMessages.addErrorMessage('エラーが発生しました')
    }
  }

  const handleAutoInput = async () => {
    setGetAddressError(false)
    const zipcode = getValues('zipcode')

    if (zipcode) {
      try {
        const res = await getAddressByzipcode(zipcode)
        setValue('prefecture', res.address1)
        setValue('city', res.address2)
        setValue('address', res.address3)
      } catch (e) {
        setGetAddressError(true)
      }
    }
  }
  const [getAddressError, setGetAddressError] = React.useState(false)

  // personal use
  const [checkedIsPersonalUse, setCheckedIsPersonalUse] = React.useState(
    user.is_personal_use !== null ? user.is_personal_use : true,
  )
  React.useEffect(() => {
    if (user) {
      setCheckedIsPersonalUse(user.is_personal_use !== null ? user.is_personal_use : true)
      setValue('is_personal_use', user.is_personal_use !== null ? user.is_personal_use : true)
    }
  }, [user])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <div style={{ width: '100%' }}>
        <label className={classes.label}>利用方法</label>
        <Controller
          name="is_personal_use"
          control={control}
          defaultValue={user?.is_personal_use !== undefined ? user.is_personal_use : true}
          render={({ onChange, value, name, ref }) => (
            <RadioGroup
              row={true}
              aria-label="is_personal_use"
              name="is_personal_use"
              value={value}
              onChange={(e) => {
                const c = e.target.value === 'true' ? true : false
                onChange(c)
                setCheckedIsPersonalUse(c)
              }}>
              <FormControlLabel value={true} control={<Radio color="primary" />} label="個人利用" />
              <FormControlLabel value={false} control={<Radio color="primary" />} label="法人利用" />
            </RadioGroup>
          )}
        />
      </div>
      {!checkedIsPersonalUse && (
        <>
          <div className={classes.w50}>
            <label className={`${classes.label} ${classes.labelRequired}`}>法人名（屋号）</label>
            <Controller
              name="company_name"
              control={control}
              rules={{ required: '法人名（屋号）は必須です' }}
              defaultValue={user?.company_name}
              render={({ onChange, value, name, ref }) => (
                <DefaultTextField
                  name={name}
                  placeholder="株式会社◯◯"
                  error={errors.company_name}
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                  memo={!checkedIsPersonalUse ? '*領収書等帳票の宛名になります' : undefined}
                />
              )}
            />
          </div>
          <div className={classes.w50}>
            <label className={`${classes.label} ${classes.labelRequired}`}>法人名フリガナ</label>
            <Controller
              name="company_name_kana"
              control={control}
              rules={{ required: '法人名フリガナは必須です' }}
              defaultValue={user?.company_name_kana}
              render={({ onChange, value, name }) => (
                <DefaultTextField
                  name={name}
                  value={value}
                  placeholder="カブシキガイシャ◯◯"
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                  error={errors.company_name_kana}
                />
              )}
            />
          </div>
          <div className={classes.w50}>
            <label className={classes.label}>部署名</label>
            <Controller
              name="department"
              control={control}
              defaultValue={user?.department}
              render={({ onChange, value, name }) => (
                <DefaultTextField
                  name={name}
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                  error={errors.department}
                />
              )}
            />
          </div>
          <div className={classes.w50} />
        </>
      )}

      <div className={classes.w50}>
        <label className={`${classes.label} ${classes.labelRequired}`}>
          {checkedIsPersonalUse ? '氏名' : '担当者名'}
        </label>
        <Controller
          name="username"
          control={control}
          rules={{ required: `${checkedIsPersonalUse ? '氏名' : '担当者名'}は必須です` }}
          defaultValue={user?.username}
          render={({ onChange, value, name }) => (
            <DefaultTextField
              name={name}
              value={value}
              onChange={(e) => {
                onChange(e.target.value)
              }}
              error={errors.username}
              memo={checkedIsPersonalUse ? '*領収書等帳票の宛名になります' : undefined}
            />
          )}
        />
      </div>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <div style={{ width: '50%' }}>
          <label className={`${classes.label} ${classes.labelRequired}`}>郵便番号</label>
          <Controller
            name="zipcode"
            control={control}
            rules={{ required: '郵便番号は必須です' }}
            defaultValue={user?.zipcode}
            render={({ onChange, value, name }) => (
              <DefaultTextField
                name={name}
                value={value}
                onChange={(e) => {
                  onChange(e.target.value)
                }}
                error={errors.zipcode}
              />
            )}
          />
        </div>
        <div style={{ width: '50%', marginTop: 8 }}>
          <DefaultButton
            variant="outlined"
            onClick={handleAutoInput}
            style={{
              whiteSpace: 'nowrap',
              marginLeft: 8,
              borderColor: '#ccc',
              height: 40,
              paddingLeft: 20,
              paddingRight: 20,
            }}>
            住所自動入力
          </DefaultButton>
        </div>
      </div>

      <div className={classes.w50}>
        <label className={`${classes.label} ${classes.labelRequired}`}>都道府県</label>
        <div>
          <Controller
            name="prefecture"
            control={control}
            rules={{ required: '都道府県は必須です' }}
            defaultValue={user.prefecture}
            render={({ onChange, value, name, ref }) => (
              <div className={classes.selectContainer}>
                <Select
                  inputRef={ref}
                  styles={{
                    valueContainer: (base, state) => ({
                      ...base,
                      paddingLeft: 14,
                    }),
                  }}
                  placeholder="都道府県"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: constants.MAIN_COLOR,
                      primary: constants.MAIN_COLOR,
                    },
                  })}
                  options={optionPrefectures()}
                  value={optionPrefectures().find((c) => c.value === value)}
                  onChange={(val) => {
                    onChange(val.value)
                    onChangePrefecture(val.value)
                  }}
                />
                <FormError error={errors.prefecture} />
              </div>
            )}
          />

          {getAddressError && (
            <small
              style={{
                paddingLeft: 8,
                paddingBottom: 4,
                color: 'red',
              }}>
              住所が上手く取得できませんでした
            </small>
          )}
        </div>
      </div>
      <div className={classes.w50}>
        <label className={`${classes.label} ${classes.labelRequired}`}>市区町村</label>
        <Controller
          name="city"
          control={control}
          rules={{ required: '市区町村は必須です' }}
          defaultValue={user?.city}
          render={({ onChange, value, name }) => (
            <DefaultTextField
              name={name}
              value={value}
              onChange={(e) => {
                onChange(e.target.value)
              }}
              error={errors.city}
            />
          )}
        />
      </div>
      <div style={{ width: '100%' }}>
        <label className={`${classes.label} ${classes.labelRequired}`}>それ以降</label>
        <Controller
          name="address"
          control={control}
          rules={{ required: 'それ以降の住所は必須です' }}
          defaultValue={user?.address}
          render={({ onChange, value, name }) => (
            <DefaultTextField
              name={name}
              value={value}
              onChange={(e) => {
                onChange(e.target.value)
              }}
              error={errors.address}
            />
          )}
        />
      </div>
      <div className={classes.w50}>
        <label className={`${classes.label} ${classes.labelRequired}`}>電話番号（携帯可）</label>
        <Controller
          name="phone"
          control={control}
          rules={{ required: '電話番号（携帯可）は必須です' }}
          defaultValue={user?.phone}
          render={({ onChange, value, name }) => (
            <DefaultTextField
              name={name}
              value={value}
              onChange={(e) => {
                onChange(e.target.value)
              }}
              error={errors.phone}
            />
          )}
        />

        <div></div>
      </div>

      <div style={{ width: '100%', textAlign: 'right' }}>
        <DefaultButton type="submit">{props.buttonLabel || '更新'}</DefaultButton>
      </div>
    </form>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  label: {
    marginBottom: 0,
  },

  labelRequired: {
    '&:after': {
      content: '"（必須）"',
      color: 'red',
      fontSize: 12,
    },
  },

  w50: {
    width: '49%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  selectContainer: {
    marginBottom: 16,
  },
}))
