import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../../constants'
import { Button } from 'react-bootstrap'

interface IProps {
  children: React.ReactNode
}

const Index: React.FC<IProps> = (props) => {
  return (
    <React.Fragment>
      <Root>{props.children}</Root>
    </React.Fragment>
  )
}

const Root = styled.div`
  width: 100%;
  padding: 0 16px 16px;
`

export default Index
