import * as React from 'react'
import { constants } from '../../../_assets'
import { makeStyles } from '@material-ui/core'
import { DefaultButton } from '../../_components'
import { OrderInput } from '.'
import { IInputSignup, signup } from '../../../services/UserService'
import { Controller, useForm } from 'react-hook-form'
interface Props {
  handleOnCancel: () => void
  setGuestEmail: (email: string) => void
}
export const OrderSessionsSignUp: React.FC<Props> = (props) => {
  const classes = useStyles()

  const { handleSubmit, errors, control, getValues } = useForm<IInputSignup>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const onSubmit = async (data: IInputSignup) => {
    try {
      await signup(data)
      props.setGuestEmail(data.email)
    } catch (e) {
      if (e.data?.error) {
        alert(`エラー\n${e.data.error}`)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <div>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{ required: 'メールアドレスは必須です' }}
          render={({ onChange, value }) => (
            <OrderInput
              label={'メールアドレス'}
              description={
                '@icloud.comのメールアドレスは、確認メール・発送メールをお受け取りいただけない場合がございますので、別のアドレスにてお願い致します。'
              }
              type="email"
              value={value}
              onChange={(e) => {
                onChange(e.target.value)
              }}
              error={errors.email}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{
            required: 'パスワードは必須です',
          }}
          render={({ onChange, value }) => (
            <OrderInput
              label={'パスワード'}
              type="password"
              value={value}
              onChange={(e) => {
                onChange(e.target.value)
              }}
              error={errors.password}
            />
          )}
        />

        <Controller
          name="passwordRe"
          control={control}
          defaultValue=""
          rules={{
            required: 'パスワード（再入力）は必須です',

            validate: {
              same: (value) =>
                value === getValues('password') ||
                'パスワードが一致しません。\nパスワードをご確認の上、もう一度入力し直してください。',
            },
          }}
          render={({ onChange, value }) => (
            <OrderInput
              label={'パスワード（再入力）'}
              type="password"
              value={value}
              onChange={(e) => {
                onChange(e.target.value)
              }}
              error={errors.passwordRe}
            />
          )}
        />

        <div style={{ width: '100%', display: 'flex' }}>
          <DefaultButton style={{ marginRight: 16 }} type="submit">
            会員登録
          </DefaultButton>
          <DefaultButton onClick={props.handleOnCancel}>キャンセル</DefaultButton>
        </div>
      </div>
    </form>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    // color: '#0B2B4F',
    // backgroundColor: '#fff',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // borderRadius: '50%',
    // width: 30,
    // height: 30,
    // marginRight: 8,
  },

  label: {
    width: '100%',
    marginBottom: 0,
  },

  input: {
    width: '50%',
    marginBottom: 16,
  },

  caution: {
    fontSize: 12,
    marginBottom: 4,
    color: '#555',
  },
}))
