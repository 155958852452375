import * as React from 'react'
import { PaymentMethod } from '../../../constants'
import * as pages from '../../../pages'
import { Header, Footer, Breadcrumbs, ImagePreview } from '../../_components'
import { show } from '../../../services/OrderService'
import IWindow from '../../../interfaces/IWindow'
import { makeStyles } from '@material-ui/core'
import { format } from 'date-fns'
import { MypageContainer } from '../../_components/MypageContainer'
import { CardContainer, CardContainerHeader, CardContainerBody } from '../'
import { constants } from '../../../_assets'

declare const window: IWindow

interface Props {
  params: IParams
}

interface IParams {
  id: string
}

const tableHeaders = [
  { label: '対応状況' },
  { label: '注文番号' },
  { label: '購入日時' },
  { label: '購入方法' },
  { label: '購入金額' },
  { label: 'お届け指定日' },
  {},
]

const Index: React.FC<Props> = (props) => {
  const classes = useStyles()
  const [user, setUser] = React.useState(null)
  const [data, setData] = React.useState(null)

  React.useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    try {
      const { json: fetchedData } = await show(props.params.id)
      setData(fetchedData)
    } catch (e) {
      window.flashMessages.addErrorMessage('購入履歴を取得できませんでした')
    }
  }

  return (
    <React.Fragment>
      <Header signedIn={true} />

      <MypageContainer active="histories" maxWidth={700}>
        <Breadcrumbs>
          <a href={pages.HISTORIES}>購入履歴</a> / 購入履歴詳細
        </Breadcrumbs>
        {data != null && (
          <>
            <CardContainer>
              <CardContainerHeader>
                <div className="flexBoxContainer">
                  <div className="flexBox">
                    <div className="title">購入日</div>
                    <div className="value">{format(new Date(data.created_at), 'yyyy年MM月dd日')}</div>
                  </div>
                  <div className="flexBox">
                    <div className="title">合計</div>
                    <div className="value">¥{data.price.toLocaleString()}</div>
                  </div>
                </div>

                <div className="flexBoxContainer">
                  <div className="flexBox">
                    <div className="title">注文状況</div>
                    <div className="value">{constants.getOrderStatusName(data.status)}</div>
                  </div>

                  <div>
                    <div className="title">注文番号: {data.order_no}</div>
                    <div className="value" style={{ textAlign: 'right' }}>
                      {(data.payment_method === PaymentMethod.Card ||
                        data.payment_method === PaymentMethod.AmazonPay) &&
                        data.status === constants.OrderStatus.Delivered && (
                          <a href={pages.RECEIPT(data.order_no)} target="_blank" rel="noreferrer">
                            領収書
                          </a>
                        )}
                    </div>
                  </div>
                </div>
              </CardContainerHeader>
              <CardContainerBody>
                <div style={{ width: '100%', display: 'flex' }}>
                  <ImagePreview src={data.item.image_url} size={90} />

                  <div className={classes.infoContainer}>
                    <div className="title">商品名</div>
                    <div className="value itemName">
                      <a href={pages.ITEMS_SHOW(data.item.slug)}>{data.item.name}</a>

                      {data.status === constants.OrderStatus.Delivered && (
                        <a href={pages.ITEM_REVIEW(data.order_no, data.item.slug)} style={{ marginLeft: 16 }}>
                          (レビューする)
                        </a>
                      )}
                    </div>

                    <div className="title">名札</div>
                    <div className="value" style={{ whiteSpace: 'pre-wrap' }}>
                      {data.name_tag}
                    </div>

                    <div className="title">受取人名</div>
                    <div className="value">{data.recipient}</div>

                    <div className="title">郵便番号</div>
                    <div className="value">〒 {data.zipcode}</div>

                    <div className="title">住所</div>
                    <div className="value">
                      {data.address1} {data.address2}
                    </div>

                    <div className="title">電話番号</div>
                    <div className="value">{data.phone}</div>

                    {data.dear && data.dear.replace(' ', '') != '' && (
                      <>
                        <div className="title">担当部署 担当者</div>
                        <div className="value">{data.dear}</div>
                      </>
                    )}

                    {data.congrat && (
                      <>
                        <div className="title">お祝いの言葉</div>
                        <div className="value">{data.congrat.name}</div>
                      </>
                    )}

                    {data.congrat_label && (
                      <>
                        <div className="title">お祝いの言葉</div>
                        <div className="value">{data.congrat_label}</div>
                      </>
                    )}

                    {data.wrapping && (
                      <>
                        <div className="title">ラッピング</div>
                        <div className="value">{data.wrapping}</div>
                      </>
                    )}

                    {data.wrapping_label && (
                      <>
                        <div className="title">ラッピング</div>
                        <div className="value">{data.wrapping_label}</div>
                      </>
                    )}

                    <div className="title">お届け指定日時</div>
                    <div className="value">
                      {data.delivery_date
                        ? `${format(new Date(data.delivery_date), 'yyyy年MM月dd日')} ${data.delivery_time || ''}`
                        : '最短お届け'}
                    </div>

                    <div className="title">備考</div>
                    <div className="value">{data.note ? data.note : '特になし'}</div>

                    <div className="title">支払い方法</div>
                    <div className="value">
                      {constants.getPaymentMethodName(data.payment_method, data.user.is_personal_use)}
                    </div>
                  </div>
                </div>
              </CardContainerBody>
            </CardContainer>
          </>
        )}
      </MypageContainer>

      <Footer />
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    paddingLeft: 8,
    width: '100%',

    '& .title': {
      fontWeight: 'bold',
      fontSize: 11,
    },

    '& .value': {
      // fontSize: 12,
      paddingLeft: 4,
      paddingRight: 8,
      marginBottom: 8,
    },

    '& .itemName': {
      color: constants.MAIN_COLOR,
      textDecoration: 'underline',
    },
  },
}))

export default Index
