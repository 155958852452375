import * as React from 'react'
import { constants } from '../../../_assets'
import { makeStyles } from '@material-ui/core'

interface IProps {
  children: React.ReactNode
}

const Index: React.FC<IProps> = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.form}>{props.children}</div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 8,

    [theme.breakpoints.down('sm')]: {
      padding: '8px 0',
    },
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',

    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
}))

export default Index
