import * as React from 'react'
import * as constants from '../../constants'
import * as pages from '../../pages'
import { SquareImageWithSubs, Header, Footer, ItemAdditionalInfo, Breadcrumbs, ReviewList } from '../_components'
// import { Button } from 'react-bootstrap'
import { show, earliestDeliveryDates } from '../../services/ItemService'
import { getUser } from '../../services/UserService'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { ItemPriceWithLabel } from '../_components/ItemPriceWithLabel'
import { DefaultButton } from '../_components/Button'
import { ItemStockLabel } from '../_components/ItemStockLabel'
interface IParams {
  id: string
}

interface IProps {
  params: IParams
  can_order: boolean
}

const Index: React.FC<IProps> = (props) => {
  const classes = useStyles()

  const [user, setUser] = React.useState({ id: null })
  const [data, setData] = React.useState(null)

  React.useEffect(() => {
    fetch()
  }, [])

  React.useEffect(() => {
    if (data && data.slug) {
      calcDeliveryDates()
    }
  }, [data])

  const calcDeliveryDates = async () => {
    const response = await earliestDeliveryDates(data.slug)
    setStates({
      prefecture: response.params.prefecture,
      earliestDelivaryDate: response.earliest_delivary_date,
      earliestDelivaryDays: response.earliest_delivary_days,
      orderLimitDate: response.order_limit_date,
    })
  }

  // states
  const [states, setStates] = React.useState({
    prefecture: '',
    earliestDelivaryDate: null,
    earliestDelivaryDays: null,
    orderLimitDate: null,
  })

  const fetch = async (page?: string) => {
    try {
      const { json: fetchedData } = await show(props.params.id)
      setData(fetchedData)
      const fetchedUser = await getUser()
      setUser(fetchedUser)
    } catch (e) {
      window.flashMessages.addErrorMessage('データが取得できませんでした')
      // setTimeout(() => {
      //   window.location.href = '/'
      // }, 700)
    }
  }

  const handleDisabled = () => {
    return !data || (Number.isInteger(data.stock) && data.stock <= 0)
  }

  const handleOrder = (id: string) => {
    window.location.href = `${pages.ORDERS_NEW}?id=${id}`
  }

  const [selectedImgIndex, setSelectedImgIndex] = React.useState(0)

  return (
    <React.Fragment>
      <Header signedIn={user && user.id ? true : false} />

      <div className={classes.root}>
        <div className={classes.rootContainer}>
          <Breadcrumbs>
            <a href="/">Home</a> / <a href="/categories">カテゴリ一覧</a> / <a href="/items">商品一覧</a> / 商品詳細
          </Breadcrumbs>

          {data ? (
            <>
              <div className={classes.leftContainer}>
                <SquareImageWithSubs
                  urls={data.image_urls}
                  selectedImgIndex={selectedImgIndex}
                  setSelectedImgIndex={setSelectedImgIndex}
                  size={400}
                />

                {data.movie_tag && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.movie_tag,
                    }}
                    className={classes.movieContainer}
                  />
                )}
              </div>
              <div className={classes.rightContainer}>
                <div className={classes.itemTitle}>{data.name}</div>
                <ItemPriceWithLabel user={user} item={data} />

                <div
                  style={{
                    marginBottom: 16,
                  }}>
                  <ItemStockLabel stock={data.stock} />
                </div>

                <ItemAdditionalInfo
                  item={data}
                  prefecture={states.prefecture}
                  earliestDelivaryDate={states.earliestDelivaryDate}
                  earliestDelivaryDays={states.earliestDelivaryDays}
                  orderLimitDate={states.orderLimitDate}
                  deliveryLimitAt={data.delivery_limit_at}
                />

                <div className={classes.submitContainer}>
                  <DefaultButton
                    variant={'contained'}
                    onClick={() => {
                      data && handleOrder(data.slug)
                    }}
                    disabled={handleDisabled()}
                    style={{
                      background: handleDisabled()
                        ? '#f3f3f3'
                        : 'linear-gradient(147deg, rgba(126,202,160,1) 0%, rgba(60,207,209,1) 100%)',
                    }}>
                    注文する
                  </DefaultButton>
                </div>
                <div className={classes.itemDescription}>{data && data.description}</div>
              </div>
              <ReviewList itemId={data.slug} showImage={true} />
              <div className={classes.otherContainer}>
                <DefaultButton
                  onClick={() => {
                    window.location.href = pages.ITEMS_INDEX
                  }}>
                  カテゴリ一覧へ
                </DefaultButton>
              </div>
            </>
          ) : (
            <div className={classes.progressContainer}>
              <CircularProgress />
            </div>
          )}
        </div>
      </div>

      <Footer />
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme) => ({
  //
  root: {
    backgroundColor: constants.BG_COLOR_GRAY,
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    padding: '0 64px',

    [theme.breakpoints.down('sm')]: {
      padding: '0 0 32px',
    },
  },

  rootContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    maxWidth: 960,
  },

  leftContainer: {
    width: '50%',
    padding: 32,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
      alignItems: 'flex-start',
    },
  },

  rightContainer: {
    width: '50%',
    padding: 32,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0 16px 16px',
      // alignItems: 'flex-start',
    },
  },

  itemTitle: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },

  submitContainer: {
    marginBottom: 32,
    textAlign: 'center',
    '& button': {
      width: '100%',
      height: 50,
      borderRadius: 0,
      color: 'white',
    },
  },

  itemDescription: {
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
    fontSize: 14,
  },

  otherContainer: {
    padding: 32,
  },

  movieContainer: {
    marginBottom: 8,
    padding: 2,
    '& iframe': {
      width: '100%',
    },
  },

  progressContainer: {
    alignItems: 'center',
    display: 'flex',
    maxHeight: '50vh',
    minHeight: '50vh',

    '& .MuiCircularProgress-colorPrimary': {
      color: `${constants.MAIN_COLOR} !important`,
    },
  },
}))

export default Index
