import { CircularProgress } from '@material-ui/core'
import * as React from 'react'
import * as constants from '../../../constants'

interface Props {}

const Index: React.FC<Props> = () => {
  return (
    <div
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        height: 300,
      }}>
      <CircularProgress size={20} />
    </div>
  )
}

export default Index
