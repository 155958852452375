import { sendDelete, sendPost, sendGet, sendPut } from './httpClient'
import { parseResourceData } from './JsonApiSerializer'

export const index = async (page?: string) => {
  try {
    const { data } = await sendGet('/api/categories')
    const json = parseResourceData(data.json)
    const meta = data.json.meta
    return { json, meta }
  } catch (e) {
    throw e.response
  }
}

export const show = async (id: string) => {
  try {
    const { data } = await sendGet('/api/categories/' + id)
    const json = parseResourceData(data.json)
    return { json }
  } catch (e) {
    throw e.response
  }
}
