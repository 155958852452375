import { sendGet, sendPut } from '../httpClient'
import { parseResourceData } from '../JsonApiSerializer'

export const index = async (page?: string) => {
  try {
    const { data } = await sendGet(`/admin/api/users${page ? '?page=' + page : ''}`)
    const json = parseResourceData(data.json)
    const meta = data.json.meta
    return { json, meta }
  } catch (e) {
    throw e.response
  }
}

export const show = async (id: string) => {
  try {
    const { data } = await sendGet('/admin/api/users/' + id)
    const json = parseResourceData(data.json)
    return { json }
  } catch (e) {
    throw e.response
  }
}

export interface IInputUpdate {
  id: string
  introducer_code: string
}

export const update = async (formData: IInputUpdate) => {
  const { id, introducer_code } = formData
  try {
    const response = await sendPut('/admin/api/users/' + id, {
      user: {
        introducer_code,
      },
    })
    return response
  } catch (e) {
    throw e.response || e
  }
}

export const destroy = async () => {
  console.log('未実装')
}
