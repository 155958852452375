import * as React from 'react'
import { constants } from '../../_assets'
import { makeStyles } from '@material-ui/core'
import { discountAmount } from '../../utils/calc'
import { Item } from '../../services/ItemService'
import { User } from '../../services/UserService'

interface IProps {
  item: Item
  user?: User
}

const Index: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { user, item } = props

  return (
    <span className={classes.root}>
      <span className={user?.user_benefit && user.user_benefit.benefit.is_valid && classes.lineThrough}>
        ¥{item.price.toLocaleString()}
      </span>
      {user?.user_benefit && user.user_benefit.benefit.is_valid && (
        <>
          <br />
          <span className={classes.discount}>
            <span className={classes.discountDescription}>({user.user_benefit.benefit.description})</span>¥
            {(item.price - discountAmount(item.price, user.user_benefit.benefit.value)).toLocaleString()}
          </span>
        </>
      )}

      <span className={classes.itemPriceSuffix}>（税込）</span>
    </span>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 'bold',
  },
  lineThrough: {
    textDecoration: 'line-through',
  },
  discount: {
    fontWeight: 'bold',
    color: constants.SECONDARY_COLOR,
  },
  discountDescription: {
    fontSize: '0.8rem',
  },

  itemPriceSuffix: {
    fontSize: 16,
  },
}))

export default Index
