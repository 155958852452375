import * as React from 'react'
import { constants } from '../../../_assets'
import { makeStyles, Theme } from '@material-ui/core'

interface Props {
  title: string
  active: boolean
}

export const OrderSectionNumber: React.FC<Props> = (props) => {
  const classes = useStyles({ active: props.active })

  return <div className={classes.root}>{props.title}</div>
}

const useStyles = makeStyles<Theme, { active: boolean }>((theme) => ({
  root: (props) => ({
    color: props.active ? '#394B40' : '#fff',
    backgroundColor: props.active ? '#fff' : '#394B40',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    width: 24,
    height: 24,
    marginRight: 8,
    fontSize: 14,
  }),
}))
