import * as React from 'react'
import * as constants from '../../../constants'
import * as pages from '../../../pages'
import { Button, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { show, IOption } from '../../../services/admin/ItemService'
import { makeStyles } from '@material-ui/core'
import {
  Wrapper,
  Title,
  PanelWrapper,
  FormContainer,
  FormInputs,
  FormLabel,
  BasePanel,
  Loading,
  FormImage,
} from '../_components'
import { DefaultTextField } from '../../_components'
import { ImagePreview } from '../../_components'
import {
  createItemReview,
  updateItemReview,
  IInputItemReview,
  showItemReview,
} from '../../../services/admin/ItemReviewService'
import { Rating } from '@material-ui/lab'

interface IParams {
  item_id: string
  id?: string
  backUrl?: string
}

interface IProps {
  params: IParams
  categories: IOption[]
  traders: IOption[]
}
const DEFAULT_RATING = 5

const Index: React.FC<IProps> = (props) => {
  const [loading, setLoading] = React.useState(true)
  const [processing, setProcessing] = React.useState(false)
  const [item, setItem] = React.useState(null)
  const [review, setReview] = React.useState(null)
  const classes = useStyles()

  React.useEffect(() => {
    register('rating')
    setValue('rating', DEFAULT_RATING)
    register('itemId')
    setValue('itemId', props.params.item_id)

    const f = async () => {
      if (props.params.item_id) {
        try {
          const { json } = await show(props.params.item_id)
          setItem(json)

          if (props.params.id) {
            const { json: reviewJson } = await showItemReview(props.params.id)
            setReview(reviewJson)
            setValue('rating', reviewJson.rating)
            setRating(reviewJson.rating)
            register('id')
            setValue('id', props.params.id)
          }
        } catch (e) {
          window.flashMessages.addErrorMessage('データが取得できませんでした')
        }
      }
      setLoading(false)
    }
    if (!item) {
      f()
    }
  }, [])

  const { register, handleSubmit, setValue, errors, getValues, setError } = useForm<IInputItemReview>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const [rating, setRating] = React.useState<number | null>(DEFAULT_RATING)
  const onChangeRating = (newValue: number) => {
    setValue('rating', newValue)
    setRating(newValue)
  }

  const onSubmit = async (inputData: IInputItemReview) => {
    setProcessing(true)
    try {
      if (!props.params.id) {
        await createItemReview(inputData)
        window.flashMessages.addSuccessMessage('レビューを投稿しました')
      } else {
        await updateItemReview(inputData)
        window.flashMessages.addSuccessMessage('レビューを更新しました')
      }

      window.location.href = pages.ADMIN_ITEMS_REVIEW(props.params.item_id)
    } catch (e) {
      window.flashMessages.addErrorMessage(e.data?.error ? e.data.error : 'エラーが発生しました')
    }
    setProcessing(false)
  }

  return (
    <React.Fragment>
      <Wrapper active="item">
        <Title
          back={props.params.backUrl ? props.params.backUrl : pages.ADMIN_ITEMS_REVIEW(props.params.item_id)}
          title={`アイテムレビュー登録`}
          action={
            <Button
              variant="primary"
              size="sm"
              style={{ color: 'white' }}
              disabled={processing}
              onClick={handleSubmit(onSubmit)}>
              {review ? '更新' : '登録'}
            </Button>
          }
        />
        {loading ? (
          <Loading />
        ) : (
          <>
            <PanelWrapper>
              <BasePanel>
                {/*  */}
                <div
                  style={{
                    padding: 16,
                    display: 'flex',
                  }}>
                  <ImagePreview src={item.image_1_url} size={100} />
                  <div style={{ padding: '0 8px' }}>{item.name}</div>
                </div>
              </BasePanel>
            </PanelWrapper>

            <PanelWrapper>
              <BasePanel>
                <div
                  style={{
                    padding: 16,
                  }}>
                  <FormContainer>
                    <FormLabel>おすすめ度</FormLabel>
                    <FormInputs>
                      <Rating
                        name="rating"
                        value={rating}
                        onChange={(event, newValue) => {
                          onChangeRating(newValue)
                        }}
                        disabled={processing}
                      />
                    </FormInputs>
                  </FormContainer>

                  <FormContainer>
                    <FormLabel>ニックネーム</FormLabel>
                    <FormInputs>
                      <DefaultTextField
                        defaultValue={review ? review.nickname : ''}
                        name="nickname"
                        placeholder="名無し"
                        inputRef={register()}
                        disabled={processing}
                      />
                    </FormInputs>
                  </FormContainer>

                  <FormContainer>
                    <FormLabel>タイトル</FormLabel>
                    <FormInputs>
                      <DefaultTextField
                        defaultValue={review ? review.title : ''}
                        name="title"
                        inputRef={register()}
                        disabled={processing}
                      />
                    </FormInputs>
                  </FormContainer>

                  <FormContainer>
                    <FormLabel>内容</FormLabel>
                    <FormInputs>
                      <DefaultTextField
                        defaultValue={review ? review.body : ''}
                        name="body"
                        multiline={true}
                        rows={4}
                        inputRef={register({ required: '入力してください' })}
                        error={errors.body ? true : false}
                        helperText={errors.body ? errors.body.message : ''}
                        disabled={processing}
                      />
                    </FormInputs>
                  </FormContainer>

                  <FormContainer>
                    <FormLabel>非表示</FormLabel>
                    <FormInputs>
                      <Form.Check
                        type="checkbox"
                        defaultChecked={review && review.deleted_at ? true : false}
                        name="is_delete"
                        onChange={(event) => {
                          setValue('is_delete', event.currentTarget.checked)
                        }}
                        ref={register({})}
                        label=""
                      />
                    </FormInputs>
                  </FormContainer>

                  <FormContainer>
                    <FormLabel>レビュー画像</FormLabel>
                    <FormImage name="image" inputRef={register({})} src={review && review.default_image_url} />
                  </FormContainer>

                  <hr />

                  <FormContainer>
                    <FormLabel>ギフト</FormLabel>
                    <FormInputs>
                      <Form.Check
                        type="checkbox"
                        defaultChecked={review && review.gifted_at ? true : false}
                        name="is_gifted"
                        onChange={(event) => {
                          setValue('is_gifted', event.currentTarget.checked)
                        }}
                        ref={register({})}
                        label=""
                      />
                    </FormInputs>
                  </FormContainer>
                </div>
              </BasePanel>
            </PanelWrapper>
          </>
        )}
      </Wrapper>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme) => ({
  //
  table: {
    //
  },
}))

export default Index
