import { sendDelete, sendPost, sendGet, sendPut } from '../httpClient'
import { parseResourceData } from '../JsonApiSerializer'

export const indexStores = async (page?: string) => {
  try {
    const { data } = await sendGet(`/admin/api/stores?${page ? 'page=' + page : ''}`)
    const json = parseResourceData(data.json)
    const meta = data.json.meta
    return { json, meta }
  } catch (e) {
    throw e.response
  }
}

export const showStore = async (id: string) => {
  try {
    const { data } = await sendGet('/admin/api/stores/' + id)
    const json = parseResourceData(data.json)
    return { json }
  } catch (e) {
    throw e.response
  }
}

export interface IInputStore {
  id?: string

  name: string
  description: string

  recipient: string
  zipcode: string
  address1: string
  address2: string
  phone: string

  owner_name: string
  owner_description: string
  site_url: string
  status: string

  delivery_at: string
  until_at: string
  closed_at: string

  color_theme_main: string
  color_theme_sub: string
  color_theme_extra: string
  color_text_main: string
  color_text_sub: string
  color_text_extra: string

  video_url1: string
  video_url2: string
  video_url3: string

  disable_congrat: boolean
  disable_invoice: boolean

  soegaki: string
  soegaki_label: string
}

export const createStore = async (formData: IInputStore) => {
  const params = new FormData()

  for (const [key, value] of Object.entries(formData)) {
    if (value) {
      if (value instanceof FileList) {
        if (value[0]) {
          params.append(`store[${key}]`, value[0])
        }
      } else if (key === 'items') {
        formData[key].map((values, index) => {
          params.append(`items[values][]`, values.value)
        })
      } else {
        params.append(`store[${key}]`, value)
      }
    }
  }

  try {
    const response = await sendPost('/admin/api/stores', params)
    return response
  } catch (e) {
    console.log('e: ', e)
    throw e.response || e
  }
}

export const updateStore = async (formData: IInputStore) => {
  const params = new FormData()

  for (const [key, value] of Object.entries(formData)) {
    if (key !== 'id' && value !== null) {
      if (value instanceof FileList) {
        if (value[0]) {
          params.append(`store[${key}]`, value[0])
        }
      } else if (key === 'items') {
        formData[key].map((values, index) => {
          params.append(`items[values][]`, values.value)
        })
      } else {
        params.append(`store[${key}]`, value)
      }
    }
  }

  try {
    const response = await sendPut(`/admin/api/stores/${formData.id}`, params)
    return response
  } catch (e) {
    console.log('e: ', e)
    throw e.response || e
  }
}

export const destroyStore = async (id: string) => {
  try {
    const response = await sendDelete('/admin/api/stores/' + id)
    return response
  } catch (e) {
    throw e.response
  }
}
