import * as React from 'react'
import { makeStyles } from '@material-ui/core'

interface IProps {
  input: React.ReactNode
  button: React.ReactNode
}

export const InputWithButton: React.FC<IProps> = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {props.input}
      {props.button}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',

    '& input': {
      borderRight: 'none !important',
      height: 50,
      borderRadius: 0,
      border: '1px solid #aaa',
    },

    '& button': {
      background: '#fff',
      color: '#333',
      borderRadius: 0,
      borderColor: '#aaa',
      whiteSpace: 'nowrap',
      padding: '0 24px',
    },
  },
}))
