import * as React from 'react'
import { constants } from '../../../_assets'
import { makeStyles } from '@material-ui/core'
import { FieldError } from 'react-hook-form'
import { FormError } from '../../_components'

interface Props {
  label?: string
  description?: string
  required?: boolean
  placeholder?: string
  type?: string
  ref?: React.Ref<T>
  value?: string
  onChange?: React.ChangeEventHandler<T>
  error?: FieldError
  maxLength?: number
  disabled?: boolean
  suffix?: string
}

export const OrderInput: React.FC<Props> = (props) => {
  const classes = useStyles({ required: props.required })

  return (
    <div className={classes.formContainer}>
      {props.label ? <label className={classes.label}>{props.label}</label> : <></>}
      {props.description ? <p className={classes.description}>{props.description}</p> : <></>}
      <div className={classes.inputContainer}>
        <input
          className={classes.input}
          required={props.required}
          placeholder={props.placeholder}
          type={props.type}
          ref={props.ref}
          value={props.value || ''}
          onChange={props.onChange}
          maxLength={props.maxLength}
          disabled={props.disabled}
        />
        {props.suffix ? <div className={classes.suffix}>{props.suffix}</div> : <></>}
      </div>
      <FormError error={props.error} />
    </div>
  )
}

const useStyles = makeStyles<Theme, { required: boolean }>((theme) => ({
  formContainer: {
    marginBottom: 8,
    width: '100%',
  },

  label: (props) => ({
    width: '100%',
    marginBottom: 0,

    '&:after': {
      content: props.required ? '"（必須）"' : '',
      color: 'red',
      fontSize: 12,
    },
  }),

  description: {
    fontSize: 12,
    marginBottom: 4,
    color: '#555',
  },

  inputContainer: {
    height: 40,
    border: '1px solid #ccc',
    display: 'flex',
  },

  input: {
    width: '100%',
    height: 38,
    padding: 16,
    border: 'none',
  },

  suffix: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
    background: '#f3f3f3',
  },
}))
