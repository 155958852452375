import * as React from 'react'
import { constants } from '../../_assets'
import { makeStyles } from '@material-ui/core'
import { ItemPrice } from '.'
import { Item } from '../../services/ItemService'
import { User } from '../../services/UserService'

interface IProps {
  item: Item
  user?: User
}
export const ItemPriceWithLabel: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { user, item } = props

  return (
    <div className={classes.itemPriceContainer}>
      <div className={classes.itemPriceTitle}>販売価格</div>
      <ItemPrice user={user} item={item} />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  itemPriceContainer: {
    fontSize: 30,
    marginBottom: 16,
    textAlign: 'left',
    display: 'flex',
    alignItems: 'baseline',
    color: constants.DANGER_COLOR,
    flexWrap: 'wrap',
    lineHeight: '24px',
    borderBottom: '1px solid #eee',
    paddingBottom: 16,

    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },

  itemPriceTitle: {
    fontSize: '0.7rem',
    color: '#999',
    width: '100%',
  },
}))
