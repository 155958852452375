import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../../constants'
import * as pages from '../../../pages'
import { Table, Button } from 'react-bootstrap'
import { Wrapper, Pagination, Title, PanelWrapper, BasePanel, Loading } from '../_components'
import { Header, Footer } from '../../_components'
import { index } from '../../../services/admin/BenefitService'

interface IProps {
  params: IParams
}

interface IParams {
  page: string
}

const Index: React.FC<IProps> = (props) => {
  const [data, setData] = React.useState(null)
  const [pagination, setPagination] = React.useState<any | null>(null)
  const [states, setStates] = React.useState({ loading: true })

  React.useEffect(() => {
    fetch()
  }, [])

  const fetch = async (page?: string) => {
    try {
      const { json, meta } = await index(page)
      setData(json)
      setPagination(meta)
      setStates({ ...states, loading: false })
    } catch (e) {
      window.flashMessages.addErrorMessage('データが取得できませんでした')
    }
  }

  const onChangePageHandler = async (page) => {
    fetch(page)
  }

  return (
    <React.Fragment>
      <Wrapper active="benefit">
        <Title
          title={'特典コード一覧'}
          action={
            <Button
              variant="primary"
              type="submit"
              size="sm"
              style={{ color: 'white' }}
              onClick={() => {
                window.location.href = pages.ADMIN_BENEFITS_NEW
              }}>
              新規登録
            </Button>
          }
        />

        {states.loading ? (
          <Loading />
        ) : (
          <PanelWrapper>
            <BasePanel>
              <PanelBody>
                {data && (
                  <>
                    {data.length > 0 ? (
                      <>
                        <Table className="panelBody__table">
                          <thead>
                            <tr>
                              <th>タイトル</th>
                              <th>特典コード</th>
                              <th style={{ textAlign: 'right' }}>割引率（％）</th>
                              <th>登録有効期限</th>
                              <th>割引適用期限</th>
                              <th style={{ textAlign: 'right' }}>適用数</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((d, index) => (
                              <tr key={`order-${index}`}>
                                <td>{d.title}</td>
                                <td>{d.code}</td>
                                <td style={{ textAlign: 'right' }}>{d.value}</td>
                                <td>{d.register_limit_date}</td>
                                <td>{d.use_limit_date}</td>
                                <td style={{ textAlign: 'right' }}>{d.num_used}</td>

                                <td className="panelBody__table__td buttons">
                                  <Button
                                    variant="light"
                                    onClick={() => {
                                      window.location.href = pages.ADMIN_BENEFITS_EDIT(d.id)
                                    }}>
                                    編集
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        {pagination && (
                          <Pagination
                            onChangePageHandler={onChangePageHandler}
                            currentPage={pagination.current_page}
                            prevPage={pagination.prev_page}
                            nextPage={pagination.next_page}
                            totalPages={pagination.total_pages}
                            totalCount={pagination.total_count}
                          />
                        )}
                      </>
                    ) : (
                      <div style={{ padding: '16px 0', textAlign: 'center' }}>データがありません</div>
                    )}
                  </>
                )}
              </PanelBody>
            </BasePanel>
          </PanelWrapper>
        )}
      </Wrapper>
    </React.Fragment>
  )
}

const PanelBody = styled.div`
  .panelBody {
    padding: 0 16px;
  }
`

export default Index
