import * as React from 'react'
import { useForm } from 'react-hook-form'
import { Loading, ThemeContainer, FormError } from '../../_components'
import { makeStyles, Button, MuiThemeProvider, TextField } from '@material-ui/core'
import IWindow from '../../../interfaces/IWindow'
import { IInputBenefitCode, addBenefitCode, deleteBenefitCode } from '../../../services/BenefitService'

declare const window: IWindow

interface Props {
  user: any
  setUser: React.Dispatch<any>
}

const Index: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { user } = props
  // form
  const { register, handleSubmit, setValue, errors, getValues, setError } = useForm<IInputBenefitCode>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const onSubmit = async (data: IInputBenefitCode) => {
    try {
      const { json } = await addBenefitCode(data)
      window.flashMessages.addSuccessMessage('特典コードを登録しました')
      props.setUser({ ...user, user_benefit: { benefit: { ...json } } })
    } catch (e) {
      if (e.data && e.data.error) {
        window.flashMessages.addErrorMessage(e.data.error)
      } else {
        window.flashMessages.addErrorMessage('特典コード登録エラーが発生しました')
      }
    }
  }

  const handleDelete = async () => {
    if (confirm('本当に削除しますか？')) {
      try {
        await deleteBenefitCode()
        window.flashMessages.addSuccessMessage('特典コードを削除しました')
        props.setUser({ ...user, user_benefit: null })
      } catch (e) {
        if (e.data && e.data.error) {
          window.flashMessages.addErrorMessage(e.data.error)
        } else {
          window.flashMessages.addErrorMessage('特典コード登録エラーが発生しました')
        }
      }
    }
  }

  return (
    <ThemeContainer>
      <div className={classes.rootContainer}>
        <div>
          <div className={classes.title}>特典コード</div>

          {user.user_benefit ? (
            <div>{user.user_benefit.benefit.description || '適用されております'}</div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={classes.formContainer}>
                <div className={classes.inputContainer}>
                  <TextField
                    name="code"
                    inputRef={register({
                      required: '特典コードを入力してください',
                    })}
                    error={errors.code ? true : false}
                    variant="outlined"
                    size="small"
                    classes={{ root: classes.inputRoot }}
                  />
                </div>
                <Button type="submit" variant="contained" color="primary" className={classes.buttonStyle}>
                  登録
                </Button>
              </div>
              <FormError error={errors.code} />
            </form>
          )}
        </div>

        <div>
          {user.user_benefit && (
            <Button variant="outlined" color="secondary" size="small" onClick={handleDelete}>
              削除する
            </Button>
          )}
        </div>
      </div>
    </ThemeContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    marginBottom: 32,
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  formContainer: {
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  inputContainer: {
    width: '100%',
    maxWidth: 300,
    paddingRight: 16,
  },

  buttonStyle: {
    width: 100,
    minWidth: 100,
    borderRadius: 0,
  },

  inputRoot: {
    backgroundColor: '#fff',
    width: '100%',
    '& .MuiOutlinedInput-root ': {
      borderRadius: 0,
    },
  },
}))

export default Index
