import { sendGet } from './httpClient'
import { parseResourceData } from './JsonApiSerializer'

export interface Store {
  recipient: string
  prefecture: string
  address1: string
  address2: string
  phone: string
  delivery_date: string
  delivery_time_from: string
  delivery_time_to: string
  disable_congrat?: boolean
  disable_invoice?: boolean
  soegaki?: string
  soegaki_label?: string
}

export const showStore = async (id: string) => {
  try {
    const { data } = await sendGet('/api/stores/' + id)
    const json = parseResourceData(data.json)
    return { json }
  } catch (e) {
    throw e.response
  }
}
