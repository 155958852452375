import * as React from 'react'
import * as constants from '../../../constants'
import * as pages from '../../../pages'
import { InputGroup, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Select from 'react-select'
import { create, show, update, destroy, IInputUpdate, IOption } from '../../../services/admin/ItemService'
import ChipInput from 'material-ui-chip-input'
import { makeStyles, TextField, Button } from '@material-ui/core'
import { optionShippers } from '../../../utils/shipper'
import { Wrapper, Title, PanelWrapper, FormError, FormInputs, FormLabel, BasePanel, Loading } from '../_components'
import { InputImage } from '../../_components'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { ja } from 'date-fns/locale'

interface IParams {
  id: string
}

interface IProps {
  params: IParams
  categories: IOption[]
  traders: IOption[]
}

const Index: React.FC<IProps> = (props) => {
  const [loading, setLoading] = React.useState(true)
  const [processing, setProcessing] = React.useState(false)
  const [data, setData] = React.useState(null)
  const classes = useStyles()

  React.useEffect(() => {
    register('category', { required: 'カテゴリは必須です' })
    register('wrapping', {})

    const f = async () => {
      if (props.params.id) {
        try {
          const { json } = await show(props.params.id)
          setData(json)
          setValue('category', json.category_values)
          setStates({ publicDate: json.public_date })
        } catch (e) {
          window.flashMessages.addErrorMessage('データが取得できませんでした')
        }
      }
      setLoading(false)
    }
    if (!data) {
      f()
    }
  }, [])

  // form
  const { register, handleSubmit, setValue, errors, control } = useForm<IInputUpdate>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const handleCategorySelect = (values: IOption[]) => {
    setValue('category', values)
  }

  const onSubmit = async (inputData: IInputUpdate) => {
    setProcessing(true)
    try {
      if (data) {
        await update(inputData)
      } else {
        await create(inputData)
      }
      window.location.href = pages.ADMIN_ITEMS
    } catch (e) {
      setProcessing(false)
      window.flashMessages.addErrorMessage('エラーが発生しました')
    }
  }

  const handleDelete = async (id: string) => {
    if (confirm('本当に削除しますか？')) {
      setProcessing(true)
      try {
        await destroy(id)
        window.location.href = pages.ADMIN_ITEMS
      } catch (e) {
        setProcessing(false)
        window.flashMessages.addErrorMessage('エラーが発生しました')
      }
    }
  }

  const onChangeWrapping = (tags) => {
    setValue('wrapping', tags)
  }

  // states
  const [states, setStates] = React.useState({
    publicDate: null,
  })

  const handleDateChange = (date: Date) => {
    setStates({ ...states, publicDate: date })
    setValue('public_date', date ? date.toString() : '')
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
        <Wrapper active="item">
          <Title
            back={pages.ADMIN_ITEMS}
            title={`アイテム${data ? '更新' : '登録'}`}
            action={
              <Button color="primary" variant="outlined" type="submit" disabled={processing}>
                {data ? '更新' : '登録'}
              </Button>
            }
          />
          {loading ? (
            <Loading />
          ) : (
            <PanelWrapper>
              <BasePanel>
                {/* {data && ( */}
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {data && <input type="hidden" name="id" defaultValue={data.id} ref={register({})} />}

                  <div className="mb-4 pr-2">
                    <FormLabel required={true}>カテゴリ</FormLabel>
                    <Select
                      closeMenuOnSelect={false}
                      defaultValue={data && data.category_values}
                      className="reactSelect"
                      placeholder="選択してください"
                      isMulti={true}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: constants.MAIN_COLOR,
                          primary: constants.MAIN_COLOR,
                        },
                      })}
                      options={props.categories}
                      onChange={handleCategorySelect}
                    />
                    <FormError error={errors.category && { message: 'カテゴリエラー' }} />
                  </div>

                  <div className="mb-4 pr-2 w-full">
                    <FormLabel required={true}>商品名</FormLabel>
                    <Form.Control
                      name="name"
                      defaultValue={data && data.name}
                      ref={register({
                        required: '商品名は必須です',
                      })}
                      autoComplete="nope"></Form.Control>
                    <FormError error={errors.name} />
                  </div>

                  <div className="mb-4 pr-2 w-half">
                    <FormLabel>商品コード</FormLabel>
                    <Form.Control
                      name="code"
                      defaultValue={data && data.code}
                      ref={register({})}
                      autoComplete="nope"></Form.Control>
                    <FormError error={errors.code} />
                  </div>

                  <div className="mb-4 pr-2 w-half">
                    <FormLabel>色</FormLabel>
                    <FormInputs style={{ width: 200 }}>
                      <Form.Control
                        name="color"
                        defaultValue={data && data.color}
                        ref={register({})}
                        autoComplete="nope"
                        placeholder="ホワイト"></Form.Control>
                    </FormInputs>
                  </div>

                  <div className="mb-4 pr-2 w-half">
                    <FormLabel>業者</FormLabel>
                    <Form.Control
                      name="trader_id"
                      defaultValue={data && data.trader_id}
                      ref={register({})}
                      autoComplete="nope"
                      as="select"
                      custom={true}>
                      <option value="">選択してください</option>
                      {props.traders.map((trader, index) => (
                        <option key={`trader-option-${index}`} value={trader.value}>
                          {trader.label}
                        </option>
                      ))}
                    </Form.Control>
                  </div>

                  <div className="mb-4 pr-2 w-half">
                    <FormLabel required={true}>税込価格</FormLabel>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">¥</InputGroup.Text>
                      </InputGroup.Prepend>

                      <Form.Control
                        name="price"
                        defaultValue={data && data.price}
                        type="number"
                        ref={register({
                          required: '税込価格は必須です',
                        })}
                        autoComplete="nope"></Form.Control>
                    </InputGroup>
                    <FormError error={errors.price} />
                  </div>

                  <div className="mb-4 pr-2 w-half">
                    <FormLabel>送料</FormLabel>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">¥</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        className="borderLeftNone"
                        defaultValue={data && data.delivery_fee}
                        name="delivery_fee"
                        type="number"
                        placeholder=""
                        ref={register({})}
                        autoComplete="nope"></Form.Control>
                    </InputGroup>
                  </div>

                  <div className="mb-4 pr-2 w-half">
                    <FormLabel>作業日数</FormLabel>
                    <InputGroup>
                      <Form.Control
                        name="delivery_days"
                        defaultValue={data && data.delivery_days}
                        type="number"
                        ref={register({})}
                        autoComplete="nope"></Form.Control>
                      <InputGroup.Append>
                        <InputGroup.Text id="inputGroupPrepend">日</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>

                  <div className="mb-4 pr-2 w-half">
                    <FormLabel required={true}>表示順</FormLabel>
                    <Form.Control
                      name="position"
                      defaultValue={data && data.position}
                      type="number"
                      ref={register({})}
                      autoComplete="nope"
                    />
                  </div>

                  <div className="mb-4 pr-2 w-half">
                    <FormLabel>在庫数</FormLabel>
                    <Form.Control
                      name="stock"
                      defaultValue={data && data.stock}
                      type="number"
                      ref={register({})}
                      min={0}
                      autoComplete="nope"
                    />
                  </div>

                  <div className="mb-4 pr-2 w-full">
                    <FormLabel>ラッピング</FormLabel>
                    <ChipInput
                      variant="outlined"
                      defaultValue={data && data.wrapping && data.wrapping.split(',')}
                      onChange={(chips) => onChangeWrapping(chips)}
                      newChipKeys={[]}
                      style={{ width: '100%' }}
                      classes={{
                        root: classes.chipRoot,
                      }}
                    />
                  </div>

                  <div className="mb-4 pr-2 w-half">
                    <FormLabel>配達業者</FormLabel>
                    <Form.Control
                      name="shipper"
                      defaultValue={data && data.shipper}
                      ref={register({})}
                      autoComplete="nope"
                      as="select"
                      custom={true}>
                      <option value="">選択してください</option>
                      {optionShippers.map((shipper, index) => (
                        <option key={`shipper-option-${index}`} value={shipper.value}>
                          {shipper.label}
                        </option>
                      ))}
                    </Form.Control>
                  </div>

                  <div className="mb-4 pr-2 w-half">
                    <FormLabel required={true}>配達繰越し時間</FormLabel>
                    <TextField
                      type="time"
                      name="delivery_limit_at"
                      defaultValue={data && data.delivery_limit_at}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={
                        {
                          // step: 300, // 5 min
                        }
                      }
                      inputRef={register({
                        required: '配達繰越し時間は必須です',
                      })}
                      classes={{ root: classes.chipRoot }}
                      style={{ display: 'block' }}
                    />
                    <FormError error={errors.delivery_limit_at} />
                  </div>

                  <div className="mb-4 pr-2 w-full">
                    <FormLabel>説明</FormLabel>
                    <Form.Control
                      name="description"
                      defaultValue={data && data.description}
                      ref={register({})}
                      autoComplete="nope"
                      as="textarea"
                      row="5"></Form.Control>
                  </div>

                  <div className="mb-4 pr-2 w-half">
                    <FormLabel>おすすめ商品</FormLabel>
                    <Form.Check
                      type="checkbox"
                      defaultChecked={data && data.is_recommended}
                      name="is_recommended"
                      onChange={(event) => {
                        setValue('is_recommended', event.currentTarget.checked)
                      }}
                      ref={register({})}
                      label=""
                    />
                  </div>

                  <div className="mb-4 pr-2 w-half">
                    <FormLabel>非公開設定</FormLabel>
                    <Form.Check
                      type="checkbox"
                      defaultChecked={data && data.is_private}
                      name="is_private"
                      onChange={(event) => {
                        setValue('is_private', event.currentTarget.checked)
                      }}
                      ref={register({})}
                      label=""
                    />
                  </div>

                  <div className="mb-4 pr-2 w-half">
                    <FormLabel>公開日</FormLabel>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>
                      <KeyboardDatePicker
                        className={classes.datePicker}
                        margin="normal"
                        id="date-picker-dialog"
                        format="yyyy/MM/dd"
                        value={states.publicDate}
                        minDate={new Date()}
                        onChange={handleDateChange}
                        placeholder="2020/01/01"
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        autoOk={true}
                        disableToolbar={true}
                        minDateMessage="選択可能な日付をお選びください"
                        maxDateMessage="選択可能な日付をお選びください"
                        invalidDateMessage="日付のフォーマットが不正です"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        style={{
                          marginTop: 0,
                          marginBottom: 0,
                        }}
                        name="public_date"
                        inputRef={register({})}
                        autoComplete="nope"
                      />
                    </MuiPickersUtilsProvider>
                    <FormError error={errors.public_date} />
                  </div>

                  <div className="mb-4 pr-2 w-full">
                    <FormLabel>Youtube iframeタグ</FormLabel>
                    <Form.Control
                      name="movie_tag"
                      defaultValue={data && data.movie_tag}
                      ref={register({})}
                      autoComplete="nope"></Form.Control>
                    <FormError error={errors.movie_tag} />
                  </div>

                  <div className="mb-4 pr-2 w-full">
                    <FormLabel>画像</FormLabel>
                    <div>
                      <InputImage name="image_1" control={control} imageUrl={data && data.image_1_url} />
                      <InputImage name="image_2" control={control} imageUrl={data && data.image_2_url} />
                      <InputImage name="image_3" control={control} imageUrl={data && data.image_3_url} />
                      <InputImage name="image_4" control={control} imageUrl={data && data.image_4_url} />
                      <InputImage name="image_5" control={control} imageUrl={data && data.image_5_url} />
                    </div>
                  </div>

                  <div className="w-full" style={{ borderTop: '1px solid #e3e3e3' }}>
                    {data && (
                      <div className="flex justify-center items-center p-4">
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => {
                            handleDelete(data.id)
                          }}
                          disabled={processing}>
                          削除
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                {/* )} */}
              </BasePanel>
            </PanelWrapper>
          )}
        </Wrapper>
      </form>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme) => ({
  chipRoot: {
    '& .MuiInputBase-root': {
      height: '100%',
    },

    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d8efe3 !important',
      boxShadow: '0 0 0 0.2rem rgba(126, 202, 160, 0.25)',
    },

    '& .MuiOutlinedInput-input': {
      height: 26,
    },
  },

  datePicker: {
    width: '100%',
    '& input': {
      paddingLeft: 12,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      border: '1px solid #aaa',
      borderRight: 'none !important',
      background: '#fff',
      height: 35,
      fontFamily: 'initial',
    },

    '& .MuiInput-root': {
      height: 50,
    },

    '& .MuiInputAdornment-root': {
      margin: 0,
    },

    '& .muibuttonbase-root': {
      margin: '0 !important',
    },

    '& button': {
      border: '1px solid #aaa !important',
      borderRadius: 0,
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
    },

    '& p': {
      paddingLeft: 8,
    },
  },
}))

export default Index
