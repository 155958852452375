import * as React from 'react'
import { constants } from '../../../_assets'
import { makeStyles } from '@material-ui/core'
import { User } from '../../../services/UserService'
import { ConfirmSection, ConfirmSectionContent, ConfirmSectionLabel } from './ConfirmSection'

interface Props {
  user?: User
  guestEmail?: string
}

export const ConfirmSessions: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <ConfirmSection title={'注文ユーザー'}>
      <ConfirmSectionLabel label={'メールアドレス'} />
      <ConfirmSectionContent content={props.user ? props.user.email : props.guestEmail} />
    </ConfirmSection>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // justifyContent: 'center',
  },
}))
