import * as React from 'react'
import { Button, makeStyles } from '@material-ui/core'

const SocialLogins: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.buttonContainer}>
        <Button
          type="submit"
          className={classes.button}
          variant="outlined"
          onClick={() => {
            window.location.href = '/users/auth/google_oauth2'
          }}>
          <img src="/images/svg/google-sm.svg" />
          Googleでログイン
        </Button>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          type="submit"
          className={classes.button}
          variant="outlined"
          onClick={() => {
            window.location.href = '/users/auth/facebook'
          }}>
          <img src="/images/svg/facebook-sm.svg" />
          Facebookでログイン
        </Button>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: '16px 0',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },

  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  button: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 20,
    borderColor: '#aaa',
    color: '#333',
    '& img': {
      marginRight: 8,
    },
    minWidth: 210,
    marginBottom: 8,
  },
}))

export default SocialLogins
