import { CircularProgress } from '@material-ui/core'
import * as React from 'react'
import { constants } from '../../_assets'

interface Props {}

const Index: React.FC<Props> = () => {
  return (
    <div
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        height: 100,
      }}>
      <CircularProgress size={20} style={{ color: constants.MAIN_COLOR }} />
    </div>
  )
}

export default Index
