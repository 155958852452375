import * as React from 'react'
import { constants } from '../../../_assets'
import { makeStyles } from '@material-ui/core'

interface IProps {
  label?: string
  name: string
  src?: string
  inputRef?: any
  setValue?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const FormImage: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const [previewImage, setPreviewImage] = React.useState(null)
  const inputFileRef = React.useRef<HTMLInputElement>()

  return (
    <label>
      <label>{props.label}</label>
      <div className={classes.root}>
        {props.src || previewImage ? (
          <img className={classes.image} src={previewImage ? previewImage.url : props.src} alt={props.label} />
        ) : (
          <div className={classes.noImage}>No Image</div>
        )}
      </div>
      <input
        type="file"
        name={props.name}
        style={{
          display: 'none',
        }}
        onChange={(event) => {
          setPreviewImage({
            file: event.currentTarget.files[0],
            url: URL.createObjectURL(event.currentTarget.files[0]),
          })
          if (props.setValue) {
            props.setValue(event)
          }
        }}
        accept="image/x-png,image/jpeg"
        ref={props.inputRef}
      />
    </label>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 200,
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
  },

  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  noImage: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#e5e5e5',
    color: '#999',
  },
}))
