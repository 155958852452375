import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../../constants'
import * as pages from '../../../pages'
import { InputGroup, Button, Form } from 'react-bootstrap'
import { Wrapper, Pagination, PanelWrapper, Title, BasePanel } from '../_components'
import { Header, Footer } from '../../_components'
import { useForm } from 'react-hook-form'
import { create, IInputCreate } from '../../../services/admin/CategoryService'

interface Props {}

const Index: React.FC<Props> = () => {
  const [processing, setProcessing] = React.useState(false)
  // form
  const { register, handleSubmit, setValue, errors, getValues, setError } = useForm<IInputCreate>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = async (data: IInputCreate) => {
    setProcessing(true)
    try {
      await create(data)
      window.location.href = pages.ADMIN_CATEGORIES
    } catch (e) {
      setProcessing(false)
      window.flashMessages.addErrorMessage('エラーが発生しました')
    }
  }

  const [previewImage, setPreviewImage] = React.useState(null)

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
        <Wrapper active="category">
          <Title
            back={pages.ADMIN_CATEGORIES}
            title={'カテゴリ登録'}
            action={
              <Button variant="primary" type="submit" size="sm" style={{ color: 'white' }} disabled={processing}>
                登録
              </Button>
            }
          />
          <PanelWrapper>
            <BasePanel>
              <PanelBody>
                <div className="panelBody__formContainer">
                  <div className="panelBody__formContainer__inputContainer w50">
                    <label className="required">カテゴリ名</label>
                    <Form.Control
                      name="name"
                      ref={register({
                        required: 'カテゴリ名は必須です',
                      })}
                      autoComplete="nope"></Form.Control>
                    {errors.name && <small>{errors.name.message}</small>}
                  </div>
                  <div className="w50 dflex">
                    <div className="panelBody__formContainer__inputContainer w50">
                      <label className="required">表示順</label>
                      <Form.Control
                        name="position"
                        type="number"
                        defaultValue={'0'}
                        ref={register({
                          required: ' 表示順は必須です',
                        })}
                        autoComplete="nope"></Form.Control>
                      {errors.position && <small>{errors.position.message}</small>}
                    </div>
                  </div>
                  <div className="panelBody__formContainer__inputContainer w50">
                    <label>画像</label>
                    <div className="panelBody__formContainer__inputContainer__img">
                      {previewImage ? (
                        <img src={previewImage.url} alt={'img'} />
                      ) : (
                        <div className="panelBody__formContainer__inputContainer__img__no">No Image</div>
                      )}
                    </div>

                    <input
                      name="image"
                      type="file"
                      ref={register({})}
                      onChange={(event) => {
                        setPreviewImage({
                          file: event.currentTarget.files[0],
                          url: URL.createObjectURL(event.currentTarget.files[0]),
                        })
                      }}
                      accept="image/x-png,image/jpeg"
                    />
                  </div>
                </div>
              </PanelBody>
            </BasePanel>
          </PanelWrapper>
        </Wrapper>
      </form>
    </React.Fragment>
  )
}

const PanelBody = styled.div`
  .panelBody {
    &__formContainer {
      padding: 16px;

      &__inputContainer {
        &__img {
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px;

          img {
            height: 100%;
          }

          &__no {
            height: 100%;
            width: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #e5e5e5;
            color: #999;
          }
        }
      }
    }
  }
`

export default Index
