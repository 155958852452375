import { makeStyles } from '@material-ui/core'
import * as React from 'react'
import * as constants from '../../constants'
import LazyLoad from 'react-lazyload'

interface IProps {
  size?: number
  src: string
}

export const ImagePreview: React.FC<IProps> = (props) => {
  const classes = useStyles({
    size: props.size || 90,
  })

  return (
    <div className={classes.imageContainer}>
      {props.src ? (
        <LazyLoad>
          <img src={props.src} alt={'img'} />
        </LazyLoad>
      ) : (
        <div className={classes.noImage}>No Image</div>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    width: ({ size }: { size: number }) => size,
    minWidth: ({ size }: { size: number }) => size,
    height: ({ size }: { size: number }) => size,
    minHeight: ({ size }: { size: number }) => size,
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: '#f3f3f3',
    borderRadius: 10,
    fontSize: 10,
    color: '#aaa',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .lazyload-wrapper': {
      height: '100%',
      width: '100%',
    },

    '& img': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      textAlign: 'center',
      transition: 'all 0.3s',
    },
  },
  noImage: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    background: '#eee',
    justifyContent: 'center',
    color: '#ccc',
  },
}))
