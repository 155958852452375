import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../constants'
import * as pages from '../../pages'
import { Header, Footer } from '../_components'
import { Form, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { signup, IInputSignup } from '../../services/UserService'
import SocialLogins from './_components/socialLogins'

interface IParams {
  introducer: string
}

interface Props {
  params: IParams
}

const Index: React.FC<Props> = (props) => {
  const [signuped, setSignuped] = React.useState<string>('')

  // form
  const { register, handleSubmit, setValue, errors, getValues, setError } = useForm<IInputSignup>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const onSubmit = async (data: IInputSignup) => {
    try {
      const res = await signup(data, props.params.introducer)
      setSignuped(data.email)
    } catch (e) {
      setError('passwordRe', { type: 'manual', message: e.data.error })
    }
  }

  return (
    <React.Fragment>
      <Header />

      <Root>
        <div className="rootContainer">
          <div className="signinContainer">
            <img className="signinContainer__logo" src="/images/logo.svg" alt="logo" />

            {!signuped ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="メールアドレス"
                    ref={register({ required: 'メールアドレスは必須です' })}
                  />
                  {errors.email && <small style={{ color: 'red' }}>{errors.email.message}</small>}
                </div>

                <div>
                  <Form.Control
                    name="password"
                    type="password"
                    placeholder="パスワード"
                    ref={register({ required: 'パスワードは必須です' })}
                  />

                  {errors.password && <small style={{ color: 'red' }}>{errors.password.message}</small>}
                </div>
                <div>
                  <Form.Control
                    name="passwordRe"
                    type="password"
                    placeholder="パスワード（再入力）"
                    ref={register({
                      required: 'パスワード（再入力）は必須です',
                      validate: {
                        same: (value) =>
                          value === getValues('password') ||
                          'パスワードが一致しません。\nパスワードをご確認の上、もう一度入力し直してください。',
                      },
                    })}
                  />
                  {errors.passwordRe && (
                    <small style={{ color: 'red', whiteSpace: 'pre-wrap' }}>{errors.passwordRe.message}</small>
                  )}
                </div>

                <Button type="submit" className="mb-2">
                  会員登録
                </Button>
              </form>
            ) : (
              <Complete>
                <h3>確認メールを送信しました</h3>
                <p>
                  {signuped} 宛に確認メールを送信しました
                  <br />
                  送信された確認メール内のURLにアクセスし、
                  <br />
                  登録の完了をお願いいたします
                </p>
                <a href={pages.CONFIRM}>メールが届かない方はこちら</a>
              </Complete>
            )}

            <div className="sessions">
              <a href={pages.SIGNIN}>ログインはこちら</a>
            </div>

            <div className="federals">
              <div className="federals__title">または</div>
              <p>他のサービスと連携して会員登録</p>
              <SocialLogins />
            </div>
          </div>
        </div>
      </Root>

      <Footer />
    </React.Fragment>
  )
}

const Root = styled.div`
  background-color: ${constants.BG_COLOR_GRAY};
  min-height: calc(100vh - ${constants.HEIGHT_HEADER + constants.HEIGHT_FOOTER}px);
  display: flex;
  justify-content: center;
  padding: 32px;
  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    padding: 8px;
  }

  .rootContainer {
    width: 100%;
    max-width: 400px;

    .signinContainer {
      background-color: #fff;
      // width: 400px;
      padding: 16px;
      box-shadow: 0 3px 8px rgba(50, 50, 50, 0.17);

      h3 {
        margin: 16px 0;
        text-align: center;
        font-size: 25px;
      }

      &__logo {
        width: 100%;
        margin: 16px 0 32px;
        height: 60px;
        object-fit: contain;
      }

      form {
        div {
          margin-bottom: 16px;
          input {
            height: 50px;
            border-radius: 0;
            background-color: #f2f2f2;
          }
        }

        button {
          width: 100%;
          border-radius: 0;
          height: 50px;
          color: #fff;
        }
      }

      .sessions {
        margin: 16px 0;
        display: flex;
        flex-wrap: wrap;

        a {
          width: 100%;
          margin-bottom: 4px;
        }
      }

      .federals {
        p {
          text-align: center;
        }

        &__title {
          display: flex;
          align-items: center;
          &::before {
            content: '';
            flex-grow: 1;
            height: 1px;
            display: block;
            margin-right: 0.4em;
            background: linear-gradient(-90deg, #aaa, transparent);
          }

          &::after {
            content: '';
            flex-grow: 1;
            height: 1px;
            display: block;
            margin-left: 0.4em;
            background: linear-gradient(90deg, #aaa, transparent);
          }
        }

        &__buttons {
          display: flex;
          padding: 16px 0;
          justify-content: center;
          flex-wrap: wrap;

          &__button {
            display: flex;
            align-items: center;
            border-radius: 20px;
            border-color: #aaa;
            color: #333;
            img {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
`

const Complete = styled.div`
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
  h3 {
    color: ${constants.MAIN_COLOR};
    font-size: 20px;
    font-weight: bold;
  }

  p {
    font-size: 12px;
  }

  a {
    padding-bottom: 8px;
    display: block;
  }
`

export default Index
