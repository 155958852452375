import * as React from 'react'
import * as constants from '../../constants'
import * as pages from '../../pages'
import { Header, Footer, GoToTop, SquareImage, Breadcrumbs } from '../_components'
import { index } from '../../services/CategoryService'
import { useStyles } from '../home'

interface IProps {
  signedIn: boolean
  isAdmin: boolean
}

const Index: React.FC<IProps> = (props) => {
  const classes = useStyles()

  const [categories, setCategories] = React.useState(null)

  React.useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    try {
      const { json: fetchedData } = await index()
      setCategories(fetchedData)
    } catch (e) {
      window.flashMessages.addErrorMessage('データが取得できませんでした')
    }
  }

  return (
    <React.Fragment>
      <Header signedIn={props.signedIn} isAdmin={props.isAdmin} />

      <div className={classes.root}>
        <div className={classes.rootContainer}>
          <Breadcrumbs>
            <a href="/">Home</a> / カテゴリ一覧
          </Breadcrumbs>

          {categories &&
            categories.map((category, index) => (
              <div
                key={`item-${index}`}
                className={classes.imageContainer}
                onClick={() => {
                  window.location.href = `/items?category=${category.slug}`
                }}>
                <SquareImage src={category.image_url} />
                <div className={classes.categoryTitle}>{category.name}</div>
              </div>
            ))}
        </div>
      </div>

      <Footer />
      <GoToTop />
    </React.Fragment>
  )
}

export default Index
