import * as React from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import { constants } from '../../_assets'

interface Props {
  children: React.ReactNode
}

export const DefaultThemeProvider: React.FC<Props> = (props: Props) => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: constants.MAIN_COLOR,
      },
      secondary: {
        main: constants.SECONDARY_COLOR,
      },
    },
    typography: {
      fontFamily: "'Times New Roman', 'YuMincho', 'Hiragino Mincho ProN', 'Yu Mincho', 'MS PMincho', serif",
    },

    overrides: {
      MuiOutlinedInput: {
        root: {
          borderRadius: 0,
          height: 40,
          borderColor: 'red',
        },
      },
      PrivateNotchedOutline: {
        root: {
          borderColor: 'red',
        },
      },
    },
    // props: {
    //   MuiTextField: {
    //     style: {

    //     },
    //     classes: {
    //       root: {
    //         borderRadius: 0,
    //         height: 40,
    //         padding: '0 16px',
    //       },
    //     },
    //     // fieldset: {

    //     // },
    //   },
    // },
  })

  return (
    <ThemeProvider theme={theme}>
      <>{props.children}</>
    </ThemeProvider>
  )
}
