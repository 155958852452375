import { makeStyles } from '@material-ui/core'
import * as React from 'react'

interface Props {
  children: React.ReactNode
}

export const SectionTitleContainer: React.FC = (props: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.categoryContainer}>
      <>{props.children}</>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  categoryContainer: {
    marginTop: 16,
    marginBottom: 16,
    display: 'flex',
    // flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',

    '& h3': {
      width: '100%',
      fontSize: '1.2rem',
      fontWeight: 'bold',
    },

    '& h4': {
      width: '100%',
      fontSize: '0.9rem',
      color: '#ccc',
    },

    '& a': {
      fontSize: '0.8rem',
    },

    [theme.breakpoints.down('sm')]: {
      background: '#fbfbfb',
      position: 'sticky',
      top: 0,
      zIndex: 1000,
      padding: 8,
      borderBottom: '1px solid #e3e3e3',
    },
  },
}))
