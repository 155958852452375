import * as React from 'react'
import { makeStyles, TableRow } from '@material-ui/core'

interface IProps {
  children: React.ReactNode
}

export const TableOverlayRow: React.FC<IProps> = (props) => {
  const classes = useStyles()

  return <TableRow className={classes.row}>{props.children}</TableRow>
}

const useStyles = makeStyles((theme) => ({
  row: {
    position: 'relative',
    '&:hover': {
      '& .buttonsOverlay': {
        opacity: 1,
      },
    },
  },
}))
