import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../constants'
import * as pages from '../../pages'

interface IProps {
  active?: string
}

interface ILink {
  page: string
  label: string
  active: string
}

const Index: React.FC<IProps> = (props) => {
  const links: ILink[] = [
    {
      page: pages.MYPAGE,
      label: 'プロフィールを編集',
      active: 'mypage',
    },
    {
      page: pages.NAME_TAGS,
      label: '名札登録',
      active: 'name_tags',
    },
    {
      page: pages.CHANGE_PASSWORD,
      label: 'パスワードを変更',
      active: 'change_password',
    },
    {
      page: pages.HISTORIES,
      label: '購入履歴',
      active: 'histories',
    },

    // {
    //   page: pages.REFERRAL_CODE,
    //   label: '特典コード',
    //   active: 'referral_code',
    // },
  ]
  return (
    <Root>
      {links.map((link, index) => (
        <div
          className={`__mypageMenu ${props.active ? (props.active === link.active ? 'active' : '') : 'active'}`}
          onClick={() => {
            window.location.href = link.page
          }}
          key={`mypage-link-${index}`}>
          {link.label}
        </div>
      ))}

      <div
        className={`__mypageMenu`}
        onClick={() => {
          window.location.href = pages.SIGNOUT
        }}>
        ログアウト
      </div>
    </Root>
  )
}

const Root = styled.div`
  width: 300px;
  min-width: 300px;
  padding: 0 16px;

  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    display: none;
  }

  .__mypageMenu {
    font-size: 20px;
    height: 50px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 8px 16px;
    cursor: pointer;
    white-space: nowrap;

    &.active {
      background-color: #e5e5e5;
    }

    &:hover {
      background-color: #e5e5e5;
    }
  }
`

export default Index
