import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../constants'
import * as pages from '../../pages'
import { Header, Footer, Pagination, Loading } from '../_components'
import { index as indexItem } from '../../services/ItemService'
import { format } from 'date-fns'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import { makeStyles } from '@material-ui/core'
import { SquareImage, Breadcrumbs } from '../_components'
import { useStyles as homeUseStyles } from '../home'

interface IProps {
  signedIn: boolean
  categories: any
}

export const priceTypes = [
  { type: 0, minPrice: 0, maxPrice: 3000, label: '〜 ¥3,000' },
  { type: 1, minPrice: 3000, maxPrice: 4999, label: '¥3,000〜' },
  { type: 2, minPrice: 5000, maxPrice: 9999, label: '¥5,000〜' },
  { type: 2, minPrice: 10000, maxPrice: 14999, label: '¥10,000〜' },
  { type: 2, minPrice: 15000, maxPrice: 19999, label: '¥15,000〜' },
  { type: 2, minPrice: 20000, maxPrice: 24999, label: '¥20,000〜' },
  { type: 2, minPrice: 25000, maxPrice: 29999, label: '¥25,000〜' },
  { type: 2, minPrice: 30000, maxPrice: 49999, label: '¥30,000〜' },
  { type: 2, minPrice: 50000, maxPrice: 9999999, label: '¥50,000〜' },
]

const Index: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const homeClasses = homeUseStyles()
  const urlParams = new URLSearchParams(window.location.search)
  const categoryParam = urlParams.get('category')
  const [items, setItems] = React.useState(null)
  const [pagination, setPagination] = React.useState<null | any>(null)

  React.useEffect(() => {
    fetch()
  }, [])

  const fetch = async (page?: string) => {
    try {
      const { json: fetchedItem, meta } = await indexItem(
        categoryParam,
        page,
        urlParams.get('min_price'),
        urlParams.get('max_price'),
      )
      setItems(fetchedItem)
      setPagination(meta)
    } catch (e) {
      window.flashMessages.addErrorMessage('データが取得できませんでした')
    }
  }

  const onChangePageHandler = (page) => {
    fetch(page)
    window.scrollTo({
      top: 100,
    })
  }

  const handleNavigateCategory = (categorySlug?: string) => {
    window.location.href = `${pages.ITEMS_INDEX}?category=${categorySlug ? categorySlug : ''}`
  }

  const handleOnClicPriceType = (minPrice?: number, maxPrice?: number) => {
    window.location.href = `${pages.ITEMS_INDEX}?category=${categoryParam || ''}&min_price=${
      minPrice || '0'
    }&max_price=${maxPrice || ''}`
  }

  return (
    <React.Fragment>
      <Header signedIn={props.signedIn} />

      <Root>
        <div className={classes.main}>
          <div className={classes.categoryContainer}>
            <div className={classes.categoryTitle}>カテゴリ一覧</div>

            <div
              style={{
                border: '1px solid #ccc',
                borderRadius: 5,
                overflow: 'hidden',
              }}>
              <div
                className={`${classes.categoryMenu} ${
                  pagination && !pagination.category ? classes.categoryMenuActive : ''
                }`}
                onClick={() => {
                  handleNavigateCategory()
                }}>
                すべて
              </div>

              {props.categories.map((category, index) => (
                <div
                  key={`web-cateogry-${index}`}
                  className={`${classes.categoryMenu} ${
                    pagination && pagination.category === category.slug ? classes.categoryMenuActive : ''
                  }`}
                  onClick={() => {
                    handleNavigateCategory(category.slug)
                  }}>
                  {category.name}
                </div>
              ))}
            </div>

            <div className={classes.categoryTitle}>料金で絞り込み</div>

            <div
              style={{
                border: '1px solid #ccc',
                borderRadius: 5,
                overflow: 'hidden',
              }}>
              {priceTypes.map((priceType, index) => (
                <div
                  key={`web-priceType-${index}`}
                  className={`${classes.categoryMenu} ${
                    priceType.minPrice.toString() === urlParams.get('min_price') &&
                    priceType.maxPrice.toString() === urlParams.get('max_price')
                      ? classes.categoryMenuActive
                      : ''
                  }`}
                  onClick={() => {
                    handleOnClicPriceType(priceType.minPrice, priceType.maxPrice)
                  }}>
                  {priceType.label}
                </div>
              ))}
            </div>
          </div>

          <div className="rootContainer">
            <Breadcrumbs>
              <a href="/">Home</a> / <a href="/categories">カテゴリ一覧</a> / 商品一覧
            </Breadcrumbs>

            {items ? (
              <>
                {items.length > 0 ? (
                  <>
                    {items.map((item, index) => (
                      <div
                        key={`item-${index}`}
                        className={homeClasses.imageContainer}
                        onClick={() => {
                          window.location.href = pages.ITEMS_SHOW(item.slug)
                        }}>
                        <SquareImage src={item.image_url} soldOut={Number.isInteger(item.stock) && item.stock <= 0} />
                        <div className={classes.itemName}>{item.name}</div>
                        <div className={classes.itemPrice}>¥{item.price.toLocaleString()}</div>
                        {item.earliest_delivary_date && item.earliest_delivary_date.earliest_delivary_date && (
                          <div className={classes.deliveryDate}>
                            <LocalShippingIcon />
                            最短お届け日：
                            {format(new Date(item.earliest_delivary_date.earliest_delivary_date), 'MM月dd日')}
                          </div>
                        )}
                      </div>
                    ))}
                    {[1, 2, 3].map((item, index) => (
                      <div key={`dummy-${item}`} className={homeClasses.imageContainer} />
                    ))}
                  </>
                ) : (
                  <div className={classes.notYet}>申し訳ございません。只今商品を準備中です</div>
                )}
              </>
            ) : (
              <Loading />
            )}

            {pagination && (
              <Pagination
                onChangePageHandler={onChangePageHandler}
                currentPage={pagination.current_page}
                prevPage={pagination.prev_page}
                nextPage={pagination.next_page}
                totalPages={pagination.total_pages}
                totalCount={pagination.total_count}
              />
            )}
          </div>
        </div>
      </Root>

      <Footer />
    </React.Fragment>
  )
}

const Root = styled.div`
  background-color: ${constants.BG_COLOR_GRAY};
  min-height: calc(100vh - ${constants.HEIGHT_HEADER}px);

  @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
    padding: 0 0 32px;
  }

  .rootContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;
  }
`

const useStyles = makeStyles((theme) => ({
  main: {
    padding: '0 64px 32px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },

    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  },

  categoryContainer: {
    paddingRight: 16,
    paddingLeft: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  categoryTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 8,
    minWidth: 220,
    marginTop: 8,
  },

  categoryMenu: {
    fontSize: 12,
    minWidth: 220,
    padding: '12px 8px',
    fontFamily: "'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック',sans-serif",
    fontWeight: 'bold',
    color: '#777',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    borderBottom: '1px solid #f5f5f5',

    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },

  categoryMenuActive: {
    // color: constants.MAIN_COLOR,
    backgroundColor: '#f5f5f5',
  },

  notYet: {
    color: constants.TEXT_COLOR,
    justifyContent: 'center',
    display: 'flex',
    marginTop: 50,
  },

  itemName: {
    color: '#777',
    fontSize: 12,
    padding: '0 8px',
    width: 300,
    maxHeight: 170,
    overflow: 'hidden',
  },

  itemPrice: {
    fontSize: 10,
    padding: '0 8px',
    width: 300,
  },

  deliveryDate: {
    color: '#555',
    fontSize: 10,
    padding: '0 8px',
    width: 300,
    '& svg': {
      fontSize: '1rem',
      marginRight: 8,
      fill: constants.MAIN_COLOR,
    },
  },
}))

export default Index
