import * as React from 'react'
import * as constants from '../../../constants'
import * as pages from '../../../pages'
import {
  Wrapper,
  Pagination,
  Title,
  PanelWrapper,
  BasePanel,
  TableOverlayRow,
  TableOverlayButtons,
  Badge,
} from '../_components'
import { index } from '../../../services/admin/OrderService'
import { format } from 'date-fns'
import { Button, Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core'

interface IProps {
  params: IParams
}

interface IParams {
  page: string
}

const Index: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const [data, setData] = React.useState(null)
  const [pagination, setPagination] = React.useState<any | null>(null)

  React.useEffect(() => {
    fetch()
  }, [])

  const fetch = async (page?: string) => {
    try {
      const { json, meta } = await index(page)
      setData(json)
      setPagination(meta)
    } catch (e) {
      window.flashMessages.addErrorMessage('データが取得できませんでした')
    }
  }

  const onChangePageHandler = async (page) => {
    fetch(page)
  }

  return (
    <React.Fragment>
      <Wrapper active="order">
        <Title title={'注文一覧'} />

        <PanelWrapper>
          <BasePanel>
            {/* <div className="panelHeader"></div> */}
            <div className="panelBody">
              {data && (
                <>
                  {data.length > 0 ? (
                    <>
                      <Table className="admin-table">
                        <TableHead>
                          <TableRow>
                            <TableCell>対応状況</TableCell>
                            <TableCell>注文番号</TableCell>
                            <TableCell>注文日時</TableCell>
                            <TableCell>お届け指定日</TableCell>
                            <TableCell className="w-200">商品名</TableCell>
                            <TableCell className="text-right">支払い総額</TableCell>
                            <TableCell>決済方法</TableCell>
                            <TableCell>備考</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.map((d, index) => (
                            <TableOverlayRow key={`order-${index}`}>
                              <TableCell className="text-nowrap">
                                <Badge
                                  title={constants.getOrderStatusName(d.status)}
                                  backgroundColor={constants.getOrderStatusColor(d.status)}
                                />
                              </TableCell>
                              <TableCell className="text-nowrap ">{d.order_no}</TableCell>
                              <TableCell className="text-nowrap ">
                                {format(new Date(d.created_at), 'yyyy-MM-dd HH:mm:ss')}
                              </TableCell>
                              <TableCell className=" ">{d.delivery_date ? d.delivery_date : '最短'}</TableCell>
                              <TableCell className=" ">{d.item.name}</TableCell>
                              <TableCell className="text-right">¥{d.price.toLocaleString()}</TableCell>
                              <TableCell className="text-nowrap">
                                {constants.getPaymentMethodName(d.payment_method, d.user.is_personal_use)}
                              </TableCell>
                              <TableCell className=" ">{d.note ? 'あり' : ''}</TableCell>
                              <TableCell>
                                <TableOverlayButtons>
                                  <Button
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                      window.location.href = pages.ADMIN_ORDERS_EDIT(d.id)
                                    }}>
                                    詳細
                                  </Button>
                                </TableOverlayButtons>
                              </TableCell>
                            </TableOverlayRow>
                          ))}
                        </TableBody>
                      </Table>
                      {pagination && (
                        <Pagination
                          onChangePageHandler={onChangePageHandler}
                          currentPage={pagination.current_page}
                          prevPage={pagination.prev_page}
                          nextPage={pagination.next_page}
                          totalPages={pagination.total_pages}
                          totalCount={pagination.total_count}
                        />
                      )}
                    </>
                  ) : (
                    <div style={{ padding: '16px 0', textAlign: 'center' }}>データがありません</div>
                  )}
                </>
              )}
            </div>
          </BasePanel>
        </PanelWrapper>
      </Wrapper>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme) => ({
  row: {
    position: 'relative',
    '&:hover': {
      '& $buttonsOverlay': {
        opacity: 1,
      },
    },
  },

  buttonsOverlay: {
    width: '100%',
    position: 'absolute',
    right: 0,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    pointerEvents: 'none',
    opacity: 0,
  },
  buttons: {
    background: 'white',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
    pointerEvents: 'auto',
  },
}))

export default Index
