import * as React from 'react'
import { makeStyles } from '@material-ui/core'

interface Props {
  title?: string
  backgroundColor?: string
  style?: React.CSSProperties
}

export const Badge: React.FC<Props> = ({ title, backgroundColor = '#f3f3f3', style }) => {
  const classes = useStyles()

  return (
    <>
      {title ? (
        <div>
          <span
            className={classes.root}
            style={{
              backgroundColor,
              ...style,
            }}>
            {title}
          </span>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    // 'inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none rounded-xlg whitespace-no-wrap'
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.25rem 0.5rem',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    borderRadius: 20,
    whiteSpace: 'nowrap',
  },
}))
