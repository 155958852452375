import * as React from 'react'
import { makeStyles, Theme } from '@material-ui/core'

interface Props {
  className?: string
}

export const OrderSectionTriangle: React.FC<Props> = (props) => {
  const classes = useStyles()

  return <div className={`${classes.root} ${props.className || ''}`} />
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '15px 15px 0 15px',
    borderColor: '#394b40 transparent transparent transparent',
  },
}))
