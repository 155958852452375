import * as React from 'react'
import { Button, Form } from 'react-bootstrap'
import { FormContainer, FormLabel, FormInputs } from '../../_components'
import { Controller, useForm } from 'react-hook-form'
import { IInputSendDeliveryMail, sendDeliveryMail } from '../../../../services/admin/OrderService'
import { makeStyles, Modal } from '@material-ui/core'
import { FormError } from '../../../_components'
import { optionShippers } from '../../../../utils/shipper'
import Select from 'react-select'
import { constants } from '../../../../_assets'

interface IParams {
  id: string
}

interface IProps {
  open: boolean
  onClose: () => void
  onSent: (deliveryNo: string) => void
  params: IParams
  defaultShipper: string
}

const Index: React.FC<IProps> = (props) => {
  // form
  const { register, handleSubmit, setValue, errors, getValues, setError, control } = useForm<IInputSendDeliveryMail>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = async (data: IInputSendDeliveryMail) => {
    setModalStates({ ...modalStates, processing: true })
    try {
      await sendDeliveryMail(data, props.params.id)
      setModalStates({ ...modalStates, done: true })
      props.onSent(data.delivery_no)
      props.onClose()
    } catch (e) {
      setModalStates({ ...modalStates, processing: false })
      window.flashMessages.addErrorMessage('エラーが発生しました')
    }
  }

  // modal
  const classes = useStyles()
  const [shipper, setShipper] = React.useState('')

  const [modalStates, setModalStates] = React.useState({
    processing: false,
    done: false,
    previewImage1: null,
    previewImage2: null,
  })

  const handleOnSent = () => {
    setModalStates({ ...modalStates, processing: false, done: false })
  }

  return (
    <>
      <Modal
        open={props.open}
        onClose={props.onClose}
        className={classes.modal}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}>
        <div className={classes.modalPaper}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.modalHeader}>配送メールを送る</div>
            <div className={classes.modalBody}>
              <FormContainer>
                <FormLabel required={true} rootStyle={{ width: '100%' }}>
                  配達業者
                </FormLabel>
                <FormInputs style={{ width: '100%', marginBottom: 8 }}>
                  <Controller
                    name="shipper"
                    control={control}
                    rules={{ required: '配達業者を選択してください' }}
                    defaultValue={props.defaultShipper}
                    render={({ onChange, value }) => (
                      <Select
                        className="reactSelect"
                        placeholder="選択してください"
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: constants.MAIN_COLOR,
                            primary: constants.MAIN_COLOR,
                          },
                        })}
                        options={optionShippers}
                        value={optionShippers.find((c) => c.value === value)}
                        onChange={(val) => {
                          onChange(val.value)
                          setShipper(val.value)
                        }}
                      />
                    )}
                  />
                </FormInputs>
                <FormError error={errors.shipper} />
              </FormContainer>

              <FormContainer>
                <FormLabel required={shipper === '直接お届け' ? false : true} rootStyle={{ width: '100%' }}>
                  伝票番号
                </FormLabel>
                <FormInputs style={{ width: '100%' }}>
                  <Form.Control
                    name="delivery_no"
                    ref={register({
                      required: shipper === '直接お届け' ? false : '伝票番号は必須です',
                    })}
                    autoComplete="nope"
                    readOnly={shipper === '直接お届け' ? true : false}
                  />
                  <FormError error={errors.delivery_no} />
                </FormInputs>
              </FormContainer>
              <FormContainer>
                <FormLabel rootStyle={{ width: '100%' }}>添付画像①</FormLabel>
                <div className={classes.modalImageContainer}>
                  {modalStates.previewImage1 ? (
                    <img src={modalStates.previewImage1.url} alt={'img_1'} className={classes.modalImage} />
                  ) : (
                    <div className={classes.modalNoImage}>No Image</div>
                  )}
                </div>

                <input
                  name="image_1"
                  type="file"
                  ref={register({
                    // required: '添付画像①は必須です',
                  })}
                  style={{ overflow: 'auto' }}
                  onChange={(event) => {
                    setModalStates({
                      ...modalStates,
                      previewImage1: {
                        file: event.currentTarget.files[0],
                        url: URL.createObjectURL(event.currentTarget.files[0]),
                      },
                    })
                  }}
                  accept="image/x-png,image/jpeg"
                />
                <FormError error={errors.image_1} />
              </FormContainer>
              <FormContainer>
                <FormLabel rootStyle={{ width: '100%' }}>添付画像②</FormLabel>
                <div className={classes.modalImageContainer}>
                  {modalStates.previewImage2 ? (
                    <img src={modalStates.previewImage2.url} alt={'img_2'} className={classes.modalImage} />
                  ) : (
                    <div className={classes.modalNoImage}>No Image</div>
                  )}
                </div>

                <input
                  name="image_2"
                  type="file"
                  ref={register({
                    // required: '添付画像②は必須です',
                  })}
                  style={{ overflow: 'auto' }}
                  onChange={(event) => {
                    setModalStates({
                      ...modalStates,
                      previewImage2: {
                        file: event.currentTarget.files[0],
                        url: URL.createObjectURL(event.currentTarget.files[0]),
                      },
                    })
                  }}
                  accept="image/x-png,image/jpeg"
                />
                <FormError error={errors.image_2} />
              </FormContainer>
              <FormContainer>
                <FormLabel rootStyle={{ width: '100%' }}>備考</FormLabel>
                <FormInputs style={{ width: '100%' }}>
                  <Form.Control name="note" as="textarea" row="5" autoComplete="nope" ref={register({})}></Form.Control>
                </FormInputs>
              </FormContainer>
            </div>
            <div className={classes.modalFooter}>
              <Button
                variant="secondary"
                size="sm"
                style={{ color: 'white' }}
                onClick={props.onClose}
                disabled={modalStates.processing}>
                あとで送る
              </Button>
              <Button
                variant="primary"
                type="submit"
                size="sm"
                style={{ color: 'white' }}
                disabled={modalStates.processing}>
                配信メール送信
              </Button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal open={modalStates.done} className={classes.modal} disableBackdropClick={true} disableEscapeKeyDown={true}>
        <div className={classes.modalPaper}>
          <div className={classes.modalBody} style={{ textAlign: 'center', padding: 50, color: '#333' }}>
            送信完了しました
          </div>

          <div className={classes.modalFooter} style={{ justifyContent: 'center' }}>
            <Button variant="primary" type="submit" size="sm" style={{ color: 'white' }} onClick={handleOnSent}>
              閉じる
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  modalPaper: {
    position: 'absolute',
    width: 400,
    maxWidth: '90vw',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    borderColor: '#555',
    boxShadow: theme.shadows[5],
  },

  modalHeader: {
    padding: theme.spacing(1, 2),
    borderBottom: '1px solid #ddd',
    fontWeight: 'bold',
    height: 50,
    maxHeight: 50,
    alignItems: 'center',
    display: 'flex',
  },

  modalBody: {
    padding: theme.spacing(1, 2),
    borderBottom: '1px solid #ddd',
    overflow: 'scroll',
    maxHeight: 'calc(80vh - 100px)',
  },

  modalImageContainer: {
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
  },

  modalImage: {
    height: '100%',

    objectFit: 'contain',
    maxWidth: '100%',
  },

  modalNoImage: {
    height: '100%',
    width: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#e5e5e5',
    color: '#999',
  },

  modalFooter: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'space-between',
    height: 50,
    maxHeight: 50,
  },
}))

export default Index
