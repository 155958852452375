import * as React from 'react'
import { constants } from '../../../_assets'
import { makeStyles } from '@material-ui/core'

interface Props {
  label?: string
  description?: string
  required?: boolean
  placeholder?: string
  type?: string
  ref?: React.Ref<T>
  onChange?: React.ChangeEventHandler<T>
}

export const OrderTextarea: React.FC<Props> = (props) => {
  const classes = useStyles({ required: props.required })

  return (
    <div className={classes.formContainer}>
      {props.label ? <label className={classes.label}>{props.label}</label> : <></>}
      {props.description ? <p className={classes.description}>{props.description}</p> : <></>}
      <div className={classes.inputContainer}>
        <textarea
          className={classes.input}
          required={props.required}
          placeholder={props.placeholder}
          type={props.type}
          rows={5}
          ref={props.ref}
          onChange={props.onChange}
        />
      </div>
    </div>
  )
}

const useStyles = makeStyles<Theme, { required: boolean }>((theme) => ({
  formContainer: {
    marginBottom: 8,
  },

  label: (props) => ({
    width: '100%',
    marginBottom: 0,

    '&:after': {
      content: props.required ? '"（必須）"' : '',
      color: 'red',
      fontSize: 12,
    },
  }),

  description: {
    fontSize: 12,
    marginBottom: 4,
    color: '#555',
  },

  inputContainer: {
    border: '1px solid #ccc',
  },

  input: {
    width: '100%',
    padding: 16,
    border: 'none',
    minHeight: 100,
  },
}))
