import { makeStyles } from '@material-ui/core'
import * as React from 'react'
import * as constants from '../../constants'
import { useDebounce } from '../../services/hook/useDebounce'
import LazyLoad from 'react-lazyload'

interface IProps {
  size?: number
  src: string
  soldOut?: boolean
}

export const SquareImage: React.FC<IProps> = (props) => {
  const classes = useStyles({
    size: props.size || 300,
  })

  const imageRef = React.useRef(null)

  const getMaxWidth = React.useCallback((ref) => {
    if (!imageRef.current) {
      return null
    }
    const itemHeight = imageRef.current.offsetWidth
    return itemHeight
  }, [])

  const setMaxHeight = React.useCallback(() => {
    if (imageRef.current) {
      imageRef.current.removeAttribute('style')
      const max = getMaxWidth(imageRef)
      if (max) {
        imageRef.current.style.height = `${max}px`
      }
    }
  }, [imageRef])

  const [onResizeHandler] = useDebounce(setMaxHeight)

  React.useEffect(() => {
    setMaxHeight()
  }, [])

  React.useEffect(() => {
    window.addEventListener('resize', onResizeHandler)
    return () => window.removeEventListener('resize', onResizeHandler)
  }, [])

  return (
    <div className={classes.imageContainer} ref={imageRef}>
      {props.src ? (
        <LazyLoad>
          <img src={props.src} alt={'img'} />
        </LazyLoad>
      ) : (
        <div className={classes.noImage}>No Image</div>
      )}
      {props.soldOut ? (
        <div
          style={{
            backgroundColor: '#ff000010',
            position: 'absolute',
            padding: 20,
          }}>
          SOLD OUT
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

interface ISquareImageWithSubsProps {
  size?: number
  urls: string[]
  selectedImgIndex: number
  setSelectedImgIndex: (index: number) => void
}

export const SquareImageWithSubs: React.FC<ISquareImageWithSubsProps> = (props) => {
  const size = props.size || 300
  const classes = useStyles({
    size,
  })

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: 16,
        }}>
        <SquareImage src={props.urls[props.selectedImgIndex]} size={size} />
      </div>

      {props.urls.length > 1 ? (
        <div className={classes.subImageContainer}>
          {props.urls.map((image_url, index) => (
            <React.Fragment key={`sub-img-${index}`}>
              {index !== props.selectedImgIndex && (
                <img
                  src={props.urls[index]}
                  alt={'img'}
                  onClick={() => {
                    props.setSelectedImgIndex(index)
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    width: ({ size }: { size: number }) => size,
    minWidth: ({ size }: { size: number }) => size,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('md')]: {
      width: '100% !important',
      minWidth: '100% !important',
    },

    [theme.breakpoints.down('sm')]: {
      // width: '100% !important',
      // minWidth: '100% !important',
      padding: 2,
    },

    '& .lazyload-wrapper': {
      height: '100%',
      width: '100%',
    },

    '& img': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      textAlign: 'center',
      transition: 'all 0.3s',
    },
  },
  noImage: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    background: '#eee',
    justifyContent: 'center',
    color: '#ccc',
  },

  // With subs
  subImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 100,
    padding: '0 8px',
    marginBottom: 8,

    '& img': {
      height: 100,
      width: 100,
      padding: 8,
      objectFit: 'cover',
      border: '1px solid #e3e3e3',
      marginRight: 8,
    },
  },
}))
