import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../../constants'
import * as pages from '../../../pages'
import { Wrapper, Pagination, Title, PanelWrapper, BasePanel } from '../_components'
import { index } from '../../../services/admin/IntroducerSalesService'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { ja } from 'date-fns/locale'
import {
  MuiThemeProvider,
  createMuiTheme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@material-ui/core'
import { format, startOfDay } from 'date-fns'

interface IProps {
  id: string
}

const Index: React.FC<IProps> = (props) => {
  const [data, setData] = React.useState(null)
  const [pagination, setPagination] = React.useState<any | null>(null)
  const [minDate, setMinDate] = React.useState(null)

  React.useEffect(() => {
    fetch()
  }, [])

  const fetch = async (page?: string, yearMonth?: string) => {
    try {
      const { json, minDate, meta } = await index(page, yearMonth)
      setData(json)
      setMinDate(new Date(format(new Date(minDate), 'yyyy-MM') + '-01'))
      setPagination(meta)
    } catch (e) {
      window.flashMessages.addErrorMessage('データが取得できませんでした')
    }
  }

  const onChangePageHandler = async (page) => {
    fetch(page, format(selectedDate, 'yyyy-MM'))
  }

  // calendar
  const [selectedDate, setSelectedDate] = React.useState(new Date())

  const handleDateChange = (value: Date) => {
    setSelectedDate(value)
    fetch(null, format(value, 'yyyy-MM'))
  }

  return (
    <React.Fragment>
      <Wrapper active="introducerSale">
        <Title title={'売上（紹介者別）'} />
        <PanelWrapper>
          <BasePanel>
            <PanelHeader>
              <div className="panelHeader__search">
                <MuiThemeProvider theme={theme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>
                    <DatePicker
                      views={['year', 'month']}
                      openTo="month"
                      format="yyyy年MM月"
                      inputVariant="outlined"
                      minDate={minDate ? new Date(minDate) : new Date()}
                      maxDate={new Date()}
                      minDateMessage="選択可能な日付をお選びください"
                      maxDateMessage="選択可能な日付をお選びください"
                      invalidDateMessage="日付のフォーマットが不正です"
                      value={selectedDate}
                      onChange={handleDateChange}
                    />
                  </MuiPickersUtilsProvider>
                </MuiThemeProvider>
                <span>の売上</span>
              </div>
            </PanelHeader>
            {data && (
              <>
                {data.length > 0 ? (
                  <>
                    <Table className="admin-table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>紹介者名</TableCell>
                          <TableCell>担当顧客数</TableCell>
                          <TableCell>月間注文数</TableCell>
                          <TableCell>月間総売上</TableCell>
                          <TableCell>分配金</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.map((d, index) => (
                          <TableRow key={`order-${index}`}>
                            <TableCell className="e">{d.name}</TableCell>
                            <TableCell className=" ">{d.num_customer}</TableCell>
                            <TableCell className="">{d.num_order}</TableCell>
                            <TableCell className="">¥{d.total_month.toLocaleString()}</TableCell>
                            <TableCell className="">¥{(d.total_month * 0.05).toLocaleString()}</TableCell>
                            <TableCell className="text-right">
                              <Button
                                color="primary"
                                variant="outlined"
                                size="small"
                                onClick={() => {
                                  window.location.href = pages.ADMIN_INTRODUCER_SALES_SHOW(
                                    d.id,
                                    format(startOfDay(selectedDate), 'yyyy-MM'),
                                  )
                                }}>
                                詳細
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    {pagination && (
                      <Pagination
                        onChangePageHandler={onChangePageHandler}
                        currentPage={pagination.current_page}
                        prevPage={pagination.prev_page}
                        nextPage={pagination.next_page}
                        totalPages={pagination.total_pages}
                        totalCount={pagination.total_count}
                      />
                    )}
                  </>
                ) : (
                  <div style={{ padding: '16px 0', textAlign: 'center' }}>データがありません</div>
                )}
              </>
            )}
          </BasePanel>
        </PanelWrapper>
      </Wrapper>
    </React.Fragment>
  )
}

const PanelHeader = styled.div`
  padding: 16px;
  .panelHeader {
    font-size: 16px;
    &__search {
      align-items: center;
      display: flex;

      input {
        padding: 10px 16px;
        width: 100px;
      }

      span {
        margin-left: 8px;
      }
    }
  }
`

const theme = createMuiTheme({
  palette: {
    primary: {
      main: constants.MAIN_COLOR,
    },
    secondary: {
      main: constants.SECONDARY_COLOR,
    },
  },
})

export default Index
