import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../constants'
import * as pages from '../../pages'
import { Header, Footer } from '../_components'
import { Form, Button, Table } from 'react-bootstrap'
import Menus from '../_components/Menus'
import { useForm } from 'react-hook-form'
import { IInputNameTag, create, update, destroy, indexNameTag } from '../../services/NameTagService'
import IWindow from '../../interfaces/IWindow'

declare const window: IWindow

interface Props {
  params: any
}

const Index: React.FC<Props> = (props) => {
  const urlParams = new URLSearchParams(window.location.search)
  const categoryParam = urlParams.get('category')

  const [edit, setEdit] = React.useState<boolean>(false)
  const [data, setData] = React.useState(null)

  React.useEffect(() => {
    fetch()
  }, [])

  // form
  const { register, handleSubmit, setValue, errors, getValues, setError } = useForm<IInputNameTag>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const handleNew = () => {
    setValue('id', null)
    setValue('name', null)
    setEdit(true)
  }

  const handleEdit = (id: string, name: string) => {
    setValue('id', id)
    setValue('name', name)
    setEdit(true)
  }

  const fetch = async () => {
    try {
      const fetchedData = await indexNameTag()
      setData(fetchedData)
    } catch (e) {
      window.flashMessages.addErrorMessage('名札データが取得できませんでした')
    }
  }

  const onSubmit = async (data: IInputNameTag) => {
    try {
      if (getValues('id')) {
        await update(data)
        window.flashMessages.addSuccessMessage('更新しました')
      } else {
        await create(data)
        window.flashMessages.addSuccessMessage('登録しました')
      }

      setValue(null)
      setEdit(false)
      fetch()
    } catch (e) {
      window.flashMessages.addErrorMessage('エラーが発生しました')
    }
  }

  const handleDelete = async () => {
    if (confirm('本当に削除しますか？')) {
      try {
        const id = getValues('id')
        await destroy(id)
        setValue(null)
        setEdit(false)
        fetch()
        window.flashMessages.addSuccessMessage('削除しました')
      } catch (e) {
        window.flashMessages.addErrorMessage('エラーが発生しました')
      }
    }
  }

  return (
    <React.Fragment>
      <Header signedIn={true} />

      <Root>
        <div className="rootContainer">
          <Menus active="name_tags" />
          <div className="rootContainer__body">
            <h3>名札登録</h3>

            <div className="rootContainer__body__nameTagContainer">
              <form onSubmit={handleSubmit(onSubmit)} style={edit ? {} : { display: 'none' }}>
                <div className="rootContainer__body__nameTagContainer__formContainer">
                  <input type="hidden" name="id" ref={register({})} />
                  <label className="required"> 名札</label>
                  <Form.Control name="name" ref={register({ required: '名札は必須です' })}></Form.Control>
                  {errors.name && <small style={{ color: 'red' }}>{errors.name.message}</small>}
                </div>

                <div className="rootContainer__body__nameTagContainer__buttons">
                  <Button
                    className="light"
                    onClick={() => {
                      setEdit(false)
                    }}
                    variant="light"
                    style={{ color: '#bbb', borderColor: '#bbb' }}>
                    キャンセル
                  </Button>

                  <Button className="main" type="submit">
                    {getValues('id') ? '更新' : '登録'}
                  </Button>
                </div>

                {getValues('id') && (
                  <div className="rootContainer__body__nameTagContainer__deleteButtons">
                    <Button
                      variant="outline-danger"
                      style={{ color: '#dc3545' }}
                      onClick={() => {
                        handleDelete()
                      }}>
                      削除
                    </Button>
                  </div>
                )}
              </form>
              <div
                style={
                  edit
                    ? { display: 'none', width: '100%' }
                    : {
                        width: '100%',
                      }
                }>
                <div className="rootContainer__body__nameTagContainer__table">
                  {data && (
                    <>
                      {data.length > 0 ? (
                        <Table>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>名札</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((d, index) => (
                              <tr key={`nameTag-${index}`}>
                                <td className="rootContainer__body__nameTagContainer__table__td num">{index + 1}</td>
                                <td className="rootContainer__body__nameTagContainer__table__td name">{d.name}</td>
                                <td className="rootContainer__body__nameTagContainer__table__td buttons">
                                  <Button
                                    variant="light"
                                    onClick={() => {
                                      handleEdit(d.id, d.name)
                                    }}>
                                    編集
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        <div style={{ textAlign: 'center', padding: '16px 0' }}>名札は登録されていません</div>
                      )}
                    </>
                  )}
                </div>

                <div className="rootContainer__body__nameTagContainer__buttons">
                  <Button className="main" onClick={handleNew}>
                    新規登録
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Root>

      <Footer />
    </React.Fragment>
  )
}

const Root = styled.div`
  background-color: ${constants.BG_COLOR_GRAY};
  min-height: calc(100vh - ${constants.HEIGHT_HEADER + constants.HEIGHT_FOOTER}px);
  display: flex;
  justify-content: center;
  padding: 32px 64px;

  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    padding: 32px 16px;
  }

  .rootContainer {
    width: 100%;
    display: flex;
    justify-content: center;

    &__body {
      padding: 0 16px;
      width: 100%;

      h3 {
        width: 100%;
        margin: 0 auto 16px;
        max-width: 760px;
      }

      &__nameTagContainer {
        max-width: 760px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;

        &__table {
          width: 100%;

          &__td {
            &.num {
              width: 50px;
              justify-content: center;
            }
            &.name {
              width: 100%;
              justify-content: left;
            }

            &.buttons {
              button {
                border-radius: 0;
                white-space: nowrap;
              }
            }
          }
        }

        form {
          width: 100%;
        }
        &__formContainer {
          width: 100%;
          padding: 8px;
          @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
            padding: 0;
          }
          label {
            width: 100%;

            &.required {
              &:after {
                content: '（必須）';
                color: red;
                font-size: 12px;
              }
            }
          }

          input {
            width: 100%;
            height: 50px;
            border-radius: 0;
            border: 1px solid #aaa;
            margin-bottom: 8px;
          }
        }

        &__buttons {
          width: 100%;
          display: flex;
          justify-content: space-evenly;

          @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
            flex-wrap: wrap;
            flex-direction: column-reverse;
          }

          button {
            height: 50px;
            width: 250px;
            border-radius: 0;
            white-space: nowrap;
            margin: 32px 0;
            color: #fff;

            &.main {
              background-color: ${constants.MAIN_COLOR};
            }

            &.light {
              transition: 0s;
            }

            @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
              width: 100%;
              margin: 8px 0;
            }
          }
        }

        &__deleteButtons {
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          border-top: 1px solid #ccc;

          button {
            height: 50px;
            width: 250px;
            border-radius: 0;
            white-space: nowrap;
            margin: 32px 0;
            color: #fff;

            &.main {
              background-color: ${constants.MAIN_COLOR};
            }

            &.light {
              transition: 0s;
            }

            @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
              width: 100%;
              margin: 8px 0;
            }
          }
        }
      }
    }

    //
  }
`

export default Index
