import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../../constants'
import { DefaultButton } from '../../_components'

interface IProps {
  back?: string
  title: string
  action?: React.ReactNode
}

const Index: React.FC<IProps> = (props) => {
  return (
    <React.Fragment>
      <Root>
        <div style={{ minWidth: 80 }}>
          {props.back && (
            <DefaultButton
              color="default"
              size="small"
              onClick={() => {
                window.location.href = props.back
              }}>
              戻る
            </DefaultButton>
          )}
        </div>
        <div>{props.title}</div>
        <div style={{ minWidth: 80, textAlign: 'right' }}>{props.action}</div>
      </Root>
    </React.Fragment>
  )
}

const Root = styled.div`
  height: 50px;
  align-items: center;
  width: 100%;
  display: flex;
  font-weight: bold;
  padding: 0 16px;
  justify-content: space-between;
`

export default Index
