import { range } from 'lodash'
import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../../constants'
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRightRounded'
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeftRounded'

interface IProps {
  currentPage: number
  prevPage: number
  nextPage: number
  totalPages: number
  totalCount: number
  onChangePageHandler?(page: number): void
}

const PAGE_LIMIT = 7

const Pagination: React.FC<IProps> = (props) => {
  const currentPage = Number(props.currentPage)
  const pages = range(currentPage - 1, currentPage - 1 + PAGE_LIMIT).filter(
    (page) => page > 1 && page < props.totalPages,
  )

  if (props.totalPages <= 1) {
    return null
  }

  return (
    <PaginationWrapper className="Pagination">
      {currentPage === 1 || props.prevPage === null ? (
        <span className="Pagination_Arrow disabled">
          <ArrowLeftIcon />
        </span>
      ) : (
        <a className="Pagination_Arrow _prev" onClick={() => props.onChangePageHandler(currentPage - 1)}>
          <ArrowLeftIcon />
        </a>
      )}
      <a
        className={`Pagination_Page ${currentPage === 1 ? 'active' : ''}`}
        onClick={() => props.onChangePageHandler(1)}>
        {1}
      </a>
      {pages[0] > 2 && <span>...</span>}
      {pages.map((page) => (
        <a
          key={page}
          className={`Pagination_Page ${page === currentPage ? 'active' : ''}`}
          onClick={() => props.onChangePageHandler(page)}>
          {page}
        </a>
      ))}
      {pages[pages.length - 1] + 1 < props.totalPages && <span>...</span>}
      <a
        className={`Pagination_Page ${currentPage === props.totalPages ? 'active' : ''}`}
        onClick={() => props.onChangePageHandler(props.totalPages)}>
        {props.totalPages}
      </a>
      {currentPage === props.totalPages || props.nextPage === null ? (
        <span className="Pagination_Arrow disabled">
          <ArrowRightIcon />
        </span>
      ) : (
        <a className="Pagination_Arrow _next" onClick={() => props.onChangePageHandler(currentPage + 1)}>
          <ArrowRightIcon />
        </a>
      )}
    </PaginationWrapper>
  )
}

const PaginationWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content center;
  padding: 16px 0;
  
  > span,
  > a {
    display: block;
    margin: 0 12px;
  }

  > span {
    color: #bbb;
  }

  .Pagination_Page,
  .Pagination_Arrow {
    display: block;
    margin: 0 4px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(${constants.MAIN_COLOR});
    cursor: pointer;

    > i {
      display: block;
    }

    &:hover:not(.disabled) {
      background-color: #F6F8F9;
    }

    &.active {
      color: #fff;
      background-color: ${constants.MAIN_COLOR};
    }

    &.disabled {
      color: #bbb;
      cursor: default;
    }
  }
`

export default Pagination
