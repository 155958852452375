import { sendDelete, sendPost, sendGet, sendPut } from './httpClient'
import { parseResourceData } from './JsonApiSerializer'

export interface Item {
  id: string
  slug: string
  price?: number
  description?: string
  stock?: number
}

export const recommended = async () => {
  try {
    const { data } = await sendGet('/api/recommended')
    const json = parseResourceData(data.json)
    return { json }
  } catch (e) {
    throw e.response
  }
}

export const index = async (category: string, page?: string, minPrice?: string, maxPrice?: string) => {
  try {
    const { data } = await sendGet(
      `/api/items?category=${category || ''}&page=${page || 1}${minPrice ? `&min_price=${minPrice}` : ''}${
        maxPrice ? `&max_price=${maxPrice}` : ''
      }`,
    )
    const json = parseResourceData(data.json)
    const meta = data.json.meta
    return { json, meta }
  } catch (e) {
    throw e.response
  }
}

export const show = async (id: string, storeId?: boolean) => {
  try {
    const { data } = await sendGet(`/api/items/${id}${storeId ? `?store_id=${storeId}` : ''}`)
    const json = parseResourceData(data.json)
    return { json }
  } catch (e) {
    throw e.response
  }
}

export const earliestDeliveryDates = async (id: string, prefecture?: string) => {
  try {
    const { data } = await sendGet(`/api/earliest_delivary_date?id=${id}&prefecture=${prefecture ? prefecture : ''}`)
    return { ...data }
  } catch (e) {
    throw e.response
  }
}

export interface IInputReview {
  order_id: string
  item_id: string
  rating: number
  title: string
  nickname: string
  body: string
}

export const review = async (formData: IInputReview) => {
  try {
    const { data } = await sendPost('/api/review', {
      order: {
        id: formData.order_id,
      },
      item: {
        id: formData.item_id,
      },
      review: {
        rating: formData.rating,
        title: formData.title,
        nickname: formData.nickname,
        body: formData.body,
      },
    })
    const json = parseResourceData(data.json)
    return { json }
  } catch (e) {
    throw e.response || e
  }
}
