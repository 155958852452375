import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import { constants } from '../../_assets'

interface IProps {
  stock?: number
}
export const ItemStockLabel: React.FC<IProps> = (props) => {
  const classes = useStyles()

  return (
    <>
      {Number.isInteger(props.stock) ? (
        <div
          className={classes.root}
          style={{
            backgroundColor: props.stock > 5 ? constants.MAIN_COLOR : constants.SECONDARY_COLOR,
          }}>
          <span className={classes.value}>
            {props.stock > 0 ? (
              <>
                {props.stock > 5 ? '在庫：' : '残り'}
                {props.stock}点
              </>
            ) : (
              'SOLD OUT'
            )}
          </span>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fa5171',
    borderRadius: 16,
    color: '#fff',
    fontSize: 12,
    fontWeight: 'bold',
    display: 'inline-block',
    padding: '0 8px',
  },

  value: {
    // fontSize: '0.7rem',
    // color: '#999',
    // width: '100%',
  },
}))
