import * as React from 'react'
import { constants } from '../../_assets'
import { makeStyles } from '@material-ui/core'

interface IProps {
  children: React.ReactNode
}

export const CommonContainer: React.FC<IProps> = (props) => {
  const classes = useStyles()

  return <div className={classes.container}>{props.children}</div>
}

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 'calc(100vh - 80px)',
  },
}))
