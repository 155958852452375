import * as React from 'react'
import { makeStyles } from '@material-ui/core'

interface IProps {
  children: React.ReactNode
}

const Index: React.FC<IProps> = (props) => {
  const classes = useStyles()

  return <div className={classes.root}>{props.children}</div>
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    width: '100%',
    borderRadius: 5,
    boxShadow: '0 3px 8px rgba(50, 50, 50, 0.17)',
    overflowY: 'hidden',
    padding: 16,
  },
}))

export default Index
