import * as React from 'react'
import { constants } from '../../../_assets'
import { Checkbox, FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core'
import { DefaultButton, FormError } from '../../_components'
import { OrderSection, OrderInput, OrderTextarea } from '.'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import { optionPrefectures } from '../../../utils/prefecture'
import { getAddressByzipcode } from '../../../services/postAddress'
import { earliestDeliveryDates } from '../../../services/ItemService'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation'
import DateFnsUtils from '@date-io/date-fns'
import { ja } from 'date-fns/locale'
import { format } from 'date-fns'
import { Store } from '../../../services/StoreService'
import { ModalAddressSelector } from './ModalAddressSelector'
import { User } from '../../../services/UserService'
import { HelpModal } from './HelpModal'

export interface FormAddresses {
  recipient: string
  zipcode: string
  prefecture: string
  address1: string
  address2: string
  phone: string
  dear: string
  deliveryDate: string
  deliveryTimeFrom: string
  deliveryTimeTo: string
  note: string
  isAffectNameTag?: boolean
}

interface Props {
  currentStep: number
  setCurrentStep: (step: number) => void
  handleNext: () => void
  setFormAddresses: (form: FormAddresses) => void
  itemSlug: string
  store?: Store
  user?: User
}

const SESSION_STEP = 3

export const OrderAddresses: React.FC<Props> = (props) => {
  const classes = useStyles()

  const { store, user } = props

  React.useEffect(() => {
    if (store) {
      // console.log('store: ', store)
      setValue('recipient', store.recipient)
      setValue('zipcode', store.zipcode)
      setValue('prefecture', store.prefecture)
      setValue('address1', store.address1)
      setValue('address2', store.address2)
      setValue('phone', store.phone)
      setValue('deliveryDate', format(new Date(store.delivery_at), 'yyyy/MM/dd'))
      setValue('deliveryTimeFrom', store.delivery_time_from)
      setValue('deliveryTimeTo', store.delivery_time_to)
    }
  }, [store])

  const { register, handleSubmit, setValue, errors, getValues, control } = useForm<FormAddresses>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const [loadingAddress, setLoadingAddress] = React.useState(false)

  const handleAutoInput = async (targetZipcode?: string) => {
    setLoadingAddress(true)

    const zipcode = targetZipcode || getValues('zipcode')
    if (zipcode) {
      try {
        const res = await getAddressByzipcode(zipcode)
        setValue('prefecture', res.address1)
        setValue('address1', res.address2)
        setValue('address2', res.address3)
        onChangePrefecture(res.address1)
      } catch (e) {
        console.log('e:', e)
        // setGetAddressError(true)
      }
    }
    setLoadingAddress(false)
  }

  const onChangePrefecture = async (prefecture?: string) => {
    setValue('deliveryDate', undefined)
    if (prefecture) {
      const response = await earliestDeliveryDates(props.itemSlug, prefecture)
      setStates({
        ...states,
        earliestDelivaryDate: response.earliest_delivary_date,
        earliestDelivaryDays: response.earliest_delivary_days,
        orderLimitDate: response.order_limit_date,
      })
    } else {
      setStates({
        ...states,
        earliestDelivaryDate: null,
        earliestDelivaryDays: null,
        orderLimitDate: null,
      })
    }
  }

  // Forms --->
  const [states, setStates] = React.useState({
    earliestDelivaryDate: null,
    earliestDelivaryDays: null,
    orderLimitDate: null,
  })

  const handleOnChangeZipcode = (value: string) => {
    const c = value.replace('-', '')
    if (c.length >= 7) {
      handleAutoInput(c)
    }
  }

  const [deliveryType, setDeliveryType] = React.useState(DeliveryType.Specify)

  const [optionDeliveryToTimes, setOptionDeliveryToTimes] = React.useState([])
  const onChangeOptionDeliveryFromTime = (value: string) => {
    const r = []
    let p = false
    optionDeliveryTimes.map((t) => {
      if (p) {
        r.push(t)
      } else {
        if (t.value === value) {
          p = true
        }
      }
    })

    setOptionDeliveryToTimes(r)

    const to = getValues('deliveryTimeTo') as string | null

    if (to) {
      const toNum = Number(to.replace(':', ''))

      const fromNum = Number(value.replace(':', ''))

      if (fromNum >= toNum) {
        setValue('deliveryTimeTo', null)
      }
    }
  }
  // <--- Forms
  // Use own address
  const handleUseOwnAddress = () => {
    if (user) {
      setValue('recipient', user.username)
      setValue('zipcode', user.zipcode)
      setValue('prefecture', user.prefecture)
      onChangePrefecture(user.prefecture)
      setValue('address1', user.city)
      setValue('address2', user.address)
      setValue('phone', user.phone)
    }
  }

  // History selector
  const [openModalAddressSelector, setOpenModalAddressSelector] = React.useState(false)
  const setAddress = (data: any) => {
    setValue('recipient', data.recipient)
    setValue('zipcode', data.zipcode)
    const address1 = data.address1.split(' ')
    setValue('prefecture', address1[0])
    onChangePrefecture(address1[0])
    setValue('address1', address1[1])
    setValue('address2', data.address2)
    setValue('phone', data.phone)
  }

  const onSubmit = (data: FormAddresses) => {
    props.setFormAddresses(data)
    props.handleNext()
  }

  return (
    <OrderSection
      step={SESSION_STEP}
      currentStep={props.currentStep}
      setCurrentStep={props.setCurrentStep}
      title={'お届け先の設定'}>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <section className={classes.section}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 8,
            }}>
            <div>
              <label style={{ marginBottom: 8 }}>お届け先</label>
            </div>
            <div>
              {store ? (
                <></>
              ) : (
                <>
                  <DefaultButton
                    // variant={isUseOwnAddress ? 'primary' : 'outline-primary'}
                    onClick={handleUseOwnAddress}
                    size="small">
                    登録住所へ送る
                  </DefaultButton>
                  <DefaultButton
                    color={'secondary'}
                    onClick={() => setOpenModalAddressSelector(true)}
                    style={{ marginLeft: 8 }}
                    size="small">
                    履歴から選択
                  </DefaultButton>
                </>
              )}
            </div>
          </div>
          <Controller
            name="recipient"
            control={control}
            rules={{ required: 'お名前/宛名は必須です' }}
            defaultValue=""
            render={({ onChange, value, ref }) => (
              <OrderInput
                ref={ref}
                placeholder={'お名前/宛名'}
                value={value}
                onChange={(e) => {
                  onChange(e.target.value)
                }}
                error={errors.recipient}
                disabled={store ? true : false}
                suffix={'様'}
              />
            )}
          />

          <Controller
            name="isAffectNameTag"
            control={control}
            defaultValue={false}
            render={({ onChange, value, name }) => (
              <div style={{ display: store ? 'none' : 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  style={{ marginBottom: 0 }}
                  control={
                    <Checkbox
                      color="primary"
                      name={name}
                      checked={value}
                      onChange={(event) => {
                        onChange(event.target.checked)
                      }}
                    />
                  }
                  label="お名前/宛名を名札に反映する"
                />
                <HelpModal title={'お名前/宛名を名札に反映するとは？'} images={['/images/help/atena.jpg']} />
              </div>
            )}
          />

          <div style={{ display: 'flex' }}>
            <Controller
              name="zipcode"
              control={control}
              rules={{ required: '郵便番号は必須です' }}
              defaultValue=""
              render={({ onChange, value, ref }) => (
                <OrderInput
                  ref={ref}
                  placeholder={'郵便番号'}
                  value={value}
                  maxLength={8}
                  onChange={(e) => {
                    onChange(e.target.value)
                    handleOnChangeZipcode(e.target.value)
                  }}
                  error={errors.zipcode}
                  disabled={store ? true : false}
                />
              )}
            />
            {!store ? (
              <DefaultButton
                variant="outlined"
                onClick={handleAutoInput}
                style={{
                  whiteSpace: 'nowrap',
                  marginLeft: 8,
                  borderColor: '#ccc',
                  height: 40,
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                disabled={loadingAddress}>
                住所自動入力
              </DefaultButton>
            ) : (
              <></>
            )}
          </div>

          <Controller
            name="prefecture"
            control={control}
            rules={{ required: '都道府県は必須です' }}
            defaultValue={null}
            render={({ onChange, value, name, ref }) => (
              <>
                {!store ? (
                  <div className={classes.selectContainer}>
                    <Select
                      // styles={customStyles}
                      inputRef={ref}
                      styles={{
                        valueContainer: (base, state) => ({
                          ...base,
                          paddingLeft: 14,
                        }),
                      }}
                      placeholder="都道府県"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: constants.MAIN_COLOR,
                          primary: constants.MAIN_COLOR,
                        },
                      })}
                      options={optionPrefectures()}
                      value={optionPrefectures().find((c) => c.value === value)}
                      onChange={(val) => {
                        onChange(val.value)
                        onChangePrefecture(val.value)
                      }}
                    />
                    <FormError error={errors.prefecture} />
                  </div>
                ) : (
                  <OrderInput
                    ref={ref}
                    placeholder={'都道府県'}
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value)
                    }}
                    error={errors.prefecture}
                    disabled={store ? true : false}
                  />
                )}
              </>
            )}
          />

          <Controller
            name="address1"
            control={control}
            rules={{ required: '住所は必須です' }}
            defaultValue=""
            render={({ onChange, value, ref }) => (
              <OrderInput
                ref={ref}
                placeholder={'住所'}
                value={value}
                onChange={(e) => {
                  onChange(e.target.value)
                }}
                error={errors.address1}
                disabled={store ? true : false}
              />
            )}
          />

          <Controller
            name="address2"
            control={control}
            rules={{ required: '住所詳細は必須です' }}
            defaultValue=""
            render={({ onChange, value, ref }) => (
              <OrderInput
                ref={ref}
                placeholder={'住所詳細'}
                value={value}
                onChange={(e) => {
                  onChange(e.target.value)
                }}
                error={errors.address2}
                disabled={store ? true : false}
              />
            )}
          />

          <Controller
            name="phone"
            control={control}
            rules={{ required: '電話番号は必須です' }}
            defaultValue=""
            render={({ onChange, value, ref }) => (
              <OrderInput
                ref={ref}
                placeholder={'電話番号'}
                value={value}
                onChange={(e) => {
                  onChange(e.target.value)
                }}
                error={errors.phone}
                disabled={store ? true : false}
              />
            )}
          />

          <Controller
            name="dear"
            control={control}
            defaultValue=""
            render={({ onChange, value, ref }) => (
              <OrderInput
                ref={ref}
                placeholder={'担当部署/担当者'}
                value={value}
                onChange={(e) => {
                  onChange(e.target.value)
                }}
                disabled={store ? true : false}
              />
            )}
          />
        </section>

        <section className={classes.section}>
          <RadioGroup
            aria-label="deliveryType"
            name="deliveryType"
            defaultValue={DeliveryType.Specify}
            value={deliveryType}
            onChange={(event) => {
              setDeliveryType(event.target.value)
            }}
            className={classes.radioContainer}>
            <FormControlLabel
              className={classes.radioLabel}
              control={<Radio color="primary" value={DeliveryType.Specify} />}
              label={'日時を指定'}
            />
            {!store ? (
              <FormControlLabel
                className={classes.radioLabel}
                control={<Radio color="primary" value={DeliveryType.Soon} />}
                label={'最短でお届け'}
              />
            ) : (
              <></>
            )}
          </RadioGroup>

          {deliveryType === DeliveryType.Specify ? (
            <>
              <div className={classes.pickersContainer}>
                <div
                  style={{
                    width: '40%',
                  }}>
                  <Controller
                    name="deliveryDate"
                    control={control}
                    rules={{ required: 'お届け日時は必須です' }}
                    defaultValue={null}
                    render={({ onChange, value, name, ref }) => (
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>
                        <KeyboardDatePicker
                          className={classes.picker}
                          margin="normal"
                          id="date-picker-dialog"
                          format="yyyy/MM/dd"
                          value={value}
                          minDate={new Date(states.earliestDelivaryDate)}
                          onChange={(val) => {
                            onChange(format(val, 'yyyy/MM/dd'))
                          }}
                          placeholder="お届け指定日"
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          disabled={store ? true : states.earliestDelivaryDate ? false : true}
                          autoOk={true}
                          disableToolbar={true}
                          minDateMessage=""
                          maxDateMessage=""
                          invalidDateMessage="日付のフォーマットが不正です"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          style={{
                            marginTop: 0,
                            marginBottom: 0,
                            backgroundColor: store ? '#f9f9f9' : '#fff',
                          }}
                          name="deliveryDate"
                          inputRef={props.inputRefDate}
                          autoComplete="nope"
                          keyboardIcon={
                            <div
                              className={classes.calenderIcon}
                              style={{ color: value ? '#333' : 'hsl(0,0%,50%)', overflow: 'hidden' }}>
                              {value
                                ? format(new Date(value), 'yyyy年MM月dd日')
                                : states.earliestDelivaryDate
                                ? '日付を選択'
                                : '都道府県を選択してください'}
                              <InsertInvitationIcon />
                            </div>
                          }
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  />
                </div>

                <div
                  style={{
                    width: '60%',
                  }}>
                  <div className={classes.pickersTimeContainer}>
                    <div className={classes.pickerTimeContainer}>
                      <Controller
                        name="deliveryTimeFrom"
                        control={control}
                        rules={{ required: 'お届け時刻は必須です' }}
                        defaultValue={null}
                        render={({ onChange, value, name, ref }) => (
                          <>
                            {!store ? (
                              <>
                                <Select
                                  // styles={customStyles}
                                  // inputRef={ref}
                                  styles={{
                                    valueContainer: (base, state) => ({
                                      ...base,
                                      paddingLeft: 14,
                                      height: 38,
                                    }),
                                    singleValue: (base, state) => ({
                                      ...base,
                                      fontSize: 12,
                                    }),
                                    placeholder: (base, state) => ({
                                      ...base,
                                      fontSize: 12,
                                    }),
                                  }}
                                  placeholder="選択する"
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: constants.MAIN_COLOR,
                                      primary: constants.MAIN_COLOR,
                                    },
                                  })}
                                  options={optionDeliveryTimes}
                                  value={optionDeliveryTimes.find((c) => c.value === value)}
                                  onChange={(val) => {
                                    onChange(val.value)
                                    onChangeOptionDeliveryFromTime(val.value)
                                  }}
                                />
                              </>
                            ) : (
                              <OrderInput
                                ref={ref}
                                placeholder={'お届け時刻'}
                                value={value}
                                onChange={(e) => {
                                  onChange(e.target.value)
                                }}
                                error={errors.deliveryTimeFrom}
                                disabled={store ? true : false}
                              />
                            )}
                          </>
                        )}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: '#999',
                        marginBottom: store ? 8 : 0,
                      }}>
                      -
                    </div>
                    <div className={classes.pickerTimeContainer}>
                      <Controller
                        name="deliveryTimeTo"
                        control={control}
                        rules={{ required: 'お届け時刻は必須です' }}
                        defaultValue={null}
                        render={({ onChange, value, name, ref }) => (
                          <>
                            {!store ? (
                              <>
                                <Select
                                  // styles={customStyles}
                                  // inputRef={ref}
                                  styles={{
                                    valueContainer: (base, state) => ({
                                      ...base,
                                      paddingLeft: 14,
                                      height: 38,
                                    }),
                                    singleValue: (base, state) => ({
                                      ...base,
                                      fontSize: 12,
                                    }),
                                    placeholder: (base, state) => ({
                                      ...base,
                                      fontSize: 12,
                                    }),
                                  }}
                                  placeholder="選択する"
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: constants.MAIN_COLOR,
                                      primary: constants.MAIN_COLOR,
                                    },
                                  })}
                                  options={optionDeliveryToTimes}
                                  value={optionDeliveryTimes.find((c) => c.value === value) || ''}
                                  onChange={(val) => {
                                    onChange(val.value)
                                  }}
                                />
                              </>
                            ) : (
                              <OrderInput
                                ref={ref}
                                placeholder={'お届け時刻'}
                                value={value}
                                onChange={(e) => {
                                  onChange(e.target.value)
                                }}
                                error={errors.deliveryTime}
                                disabled={store ? true : false}
                              />
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <FormError error={errors.deliveryDate} />
              <FormError error={errors.deliveryTimeFrom || errors.deliveryTimeTo} />
            </>
          ) : (
            <></>
          )}

          <label className={classes.memo}>※天候や在庫状況により指定日をお約束できない場合があります。</label>
          <label className={classes.memo}>※最短を選択された場合、予定日よりも早く届く場合があります。</label>
          <label className={classes.memo}>
            ※お花は生物です。再配達リスクを軽減する為、受取可能時間枠の指定をお願い致します。
          </label>
          <label className={classes.memo}>
            {'　'}
            不明な場合は指定無しも可能ですが、配達業者によっては再配達が有料になる場合があります。ご了承ください。
          </label>
        </section>

        <section className={classes.section}>
          <Controller
            name="note"
            control={control}
            defaultValue=""
            render={({ onChange, ref }) => (
              <OrderTextarea
                ref={ref}
                label={'備考'}
                placeholder={'備考'}
                onChange={(e) => {
                  onChange(e.target.value)
                }}
              />
            )}
          />
        </section>

        <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
          <DefaultButton type="submit">次へ</DefaultButton>
        </div>
      </form>

      <ModalAddressSelector
        open={openModalAddressSelector}
        setOpen={setOpenModalAddressSelector}
        handleSetAddress={setAddress}
      />
    </OrderSection>
  )
}

export const optionDeliveryTimes = [
  { label: '09:00', value: '09:00' },
  { label: '09:30', value: '09:30' },
  { label: '10:00', value: '10:00' },
  { label: '10:30', value: '10:30' },
  { label: '11:00', value: '11:00' },
  { label: '11:30', value: '11:30' },
  { label: '12:00', value: '12:00' },
  { label: '12:30', value: '12:30' },
  { label: '13:00', value: '13:00' },
  { label: '13:30', value: '13:30' },
  { label: '14:00', value: '14:00' },
  { label: '14:30', value: '14:30' },
  { label: '15:00', value: '15:00' },
  { label: '15:30', value: '15:30' },
  { label: '16:00', value: '16:00' },
  { label: '16:30', value: '16:30' },
  { label: '17:00', value: '17:00' },
  { label: '17:30', value: '17:30' },
  { label: '18:00', value: '18:00' },
  { label: '18:30', value: '18:30' },
  { label: '19:00', value: '19:00' },
  { label: '19:30', value: '19:30' },
  { label: '20:00', value: '20:00' },
  { label: '20:30', value: '20:30' },
  { label: '21:00', value: '21:00' },
]

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },

  section: {
    marginBottom: 16,
    width: '100%',
  },

  formContainer: {
    width: '100%',
  },

  selectContainer: {
    marginBottom: 8,
  },

  memo: {
    fontSize: 10,
    color: '#555',
    display: 'block',
    marginBottom: 0,
  },

  // deliveryDates
  pickersContainer: {
    display: 'flex',
  },

  pickersTimeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  pickerContainer: {
    width: '49%',
  },

  pickerTimeContainer: {
    width: '48%',
  },

  picker: {
    width: '100%',
    paddingRight: 4,

    '& input': {
      display: 'none',
    },

    '& .MuiFormControl-root': {},

    '& .MuiInput-root': {
      height: 40,
    },

    '& .MuiInputAdornment-root': {
      margin: 0,
      width: '100%',
    },

    // '& .muibuttonbase-root': {
    //   margin: '0 !important',
    // },

    '& button': {
      border: '1px solid #ccc !important',
      borderRadius: 0,
      height: 40,
      width: '100%',
    },
  },

  calenderIcon: {
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',

    '& svg': {
      marginLeft: 8,
    },
  },

  radioContainer: {
    flexDirection: 'row',
  },
}))

enum DeliveryType {
  Soon = 'SOON',
  Specify = 'SPECIFY',
}
