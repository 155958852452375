import * as React from 'react'
import * as constants from '../../constants'
import * as pages from '../../pages'
import { Header, Footer, SquareImage, CommonContainer, DefaultButton } from '../_components'
import { showStore } from '../../services/StoreService'
import { getUser } from '../../services/UserService'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { useStyles as homeUseStyles } from '../home'
import SimpleImageSlider from 'react-simple-image-slider'
import { format } from 'date-fns'

interface IParams {
  id: string
}

interface IProps {
  params: IParams
  can_order: boolean
}

const Index: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const homeClasses = homeUseStyles()

  const [user, setUser] = React.useState({ id: null })
  const [data, setData] = React.useState(null)
  const [slideImages, setSlideImages] = React.useState([])

  React.useEffect(() => {
    fetch()
  }, [])

  React.useEffect(() => {
    if (data && data.slug) {
      // calcDeliveryDates()
    }
  }, [data])

  const fetch = async (page?: string) => {
    try {
      const { json: fetchedData } = await showStore(props.params.id)
      setData(fetchedData)

      const fetchedSlideImages = []
      if (fetchedData.image_slide1_url) {
        fetchedSlideImages.push({ url: fetchedData.image_slide1_url })
      }

      if (fetchedData.image_slide2_url) {
        fetchedSlideImages.push({ url: fetchedData.image_slide2_url })
      }

      if (fetchedData.image_slide3_url) {
        fetchedSlideImages.push({ url: fetchedData.image_slide3_url })
      }
      setSlideImages(fetchedSlideImages)

      const fetchedUser = await getUser()
      setUser(fetchedUser)
    } catch (e) {
      window.flashMessages.addErrorMessage('データが取得できませんでした')
      // setTimeout(() => {
      //   window.location.href = '/'
      // }, 700)
    }
  }

  return (
    <React.Fragment>
      <Header signedIn={user && user.id ? true : false} disabledToRoot={true} />

      <CommonContainer>
        {data ? (
          <>
            <div className={classes.slideContainer}>
              <>
                {slideImages.length > 0 ? (
                  <SimpleImageSlider
                    width={'100%'}
                    height={'100%'}
                    images={slideImages}
                    showBullets={true}
                    showNavs={true}
                  />
                ) : (
                  <>スライド画像</>
                )}
              </>
            </div>

            <div className={classes.sectionContainer}>
              {/* 店舗説明 */}
              <div className={classes.storeContainer}>
                <div className={classes.descriptionImageContainer}>
                  <img src={data.image_store_url} alt={'店舗'} className={classes.descriptionImage} />
                </div>
                <div className={classes.descriptionContainer}>
                  <h4 className={classes.title}>{data.name}</h4>
                  <p
                    style={{
                      whiteSpace: 'pre-wrap',
                      padding: '0 16px',
                    }}>
                    {data.description}
                  </p>
                </div>
              </div>

              {data.site_url ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: 16,
                  }}>
                  <DefaultButton
                    variant={'contained'}
                    onClick={() => {
                      window.open(data.site_url)
                    }}
                    style={{
                      color: '#fff',
                      borderRadius: 0,
                      width: 300,
                      height: 50,
                    }}>
                    more
                  </DefaultButton>
                </div>
              ) : (
                <></>
              )}

              {/* オーナー挨拶 */}
              <div className={classes.ownerContainer}>
                <div className={classes.descriptionContainer}>
                  <h4 className={classes.title}>{data.owner_name}</h4>
                  <p
                    style={{
                      whiteSpace: 'pre-wrap',
                      padding: '0 16px',
                    }}>
                    {data.owner_description}
                  </p>
                </div>

                <div className={classes.descriptionImageContainer}>
                  <img src={data.image_owner_url} alt={'オーナー'} className={classes.descriptionImage} />
                </div>
              </div>

              {/* 動画 */}
              {data.video_url1 ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.video_url1,
                  }}
                  className={classes.movieContainer}
                />
              ) : (
                <></>
              )}

              {/* はなテック説明 */}
              <div
                style={{
                  marginBottom: 32,
                }}>
                <h4
                  className={classes.title}
                  style={{
                    textAlign: 'center',
                  }}>
                  はなテックで簡単に贈り物をしよう！
                </h4>
                <p
                  style={{
                    whiteSpace: 'pre-wrap',
                    textAlign: 'center',
                    padding: '0 16px',
                  }}>
                  {
                    '会員登録していれば、あとはログインして商品を選択するだけで送り先の入力は不要！\n商品に添える名札を設定（省略可）したら、あとは決済するだけ！'
                  }
                </p>
                {/* はなテック説明 | ステップ */}
                <div
                  className={classes.stepContainer}
                  style={{
                    backgroundColor: '#f5f5f5',
                    padding: 16,
                  }}>
                  <div className={classes.stepImage}>
                    <img
                      src="/images/stores/step1.svg"
                      alt="step1"
                      style={{
                        width: '100%',
                      }}
                    />
                  </div>
                  <div className={classes.stepImage}>
                    <img
                      src="/images/stores/step2.svg"
                      alt="step2"
                      style={{
                        width: '100%',
                      }}
                    />
                  </div>
                  <div className={classes.stepImage}>
                    <img
                      src="/images/stores/step3.svg"
                      alt="step3"
                      style={{
                        width: '100%',
                      }}
                    />
                  </div>
                  <div className={classes.stepImage}>
                    <img
                      src="/images/stores/step4.svg"
                      alt="step4"
                      style={{
                        width: '100%',
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* 商品選択 */}
              <h4
                className={classes.title}
                style={{
                  textAlign: 'center',
                }}>
                贈り物を選ぶ
              </h4>
              <p
                style={{
                  textAlign: 'center',
                }}>
                {`<注文期限日は${format(new Date(data.until_at), 'yyyy年MM月dd日')}までとなっております>`}
              </p>
              <div className={classes.stepContainer}>
                {/* 商品一覧 */}
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-evenly',
                  }}>
                  {data.store_items.map((store_item, index) => (
                    // <div>{store_item.item.name}</div>
                    <div
                      key={`store_item-${index}`}
                      className={homeClasses.imageContainer}
                      onClick={() => {
                        if (Number.isInteger(store_item.item.stock) && store_item.item.stock <= 0) {
                          return
                        }
                        window.location.href = `${pages.ORDERS_NEW}?id=${store_item.item.slug}&store=${data.slug}`
                      }}>
                      <SquareImage
                        src={store_item.item.image_url}
                        soldOut={Number.isInteger(store_item.item.stock) && store_item.item.stock <= 0}
                      />
                      <div className={classes.itemName}>{store_item.item.name}</div>
                      <div className={classes.itemPrice}>¥{store_item.item.price.toLocaleString()}</div>
                      {/* {item.earliest_delivary_date && item.earliest_delivary_date.earliest_delivary_date && (
                          <div className={classes.deliveryDate}>
                            <LocalShippingIcon />
                            最短お届け日：
                            {format(new Date(item.earliest_delivary_date.earliest_delivary_date), 'MM月dd日')}
                          </div>
                        )} */}
                    </div>
                  ))}
                </div>
              </div>

              {/*  */}
            </div>
          </>
        ) : (
          <div className={classes.progressContainer}>
            <CircularProgress />
          </div>
        )}
      </CommonContainer>

      <Footer hideItemLinks hidePriceLinks />
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme) => ({
  slideContainer: {
    width: '100%',
    height: '80vh',
    backgroundColor: '#f9f9f9',
    position: 'relative',
    marginBottom: 32,

    [theme.breakpoints.down('sm')]: {
      height: '50vh',
    },

    [theme.breakpoints.down('xs')]: {
      height: '30vh',
    },

    '& > div': {
      '& > div': {
        '& > div': {
          '& > div': {
            backgroundPosition: 'center',
          },
        },
      },
    },
  },

  sectionContainer: {
    padding: '16px 64px',

    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },

  progressContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    maxHeight: '50vh',
    minHeight: '50vh',

    '& .MuiCircularProgress-colorPrimary': {
      color: `${constants.MAIN_COLOR} !important`,
    },
  },

  storeContainer: {
    display: 'flex',
    marginBottom: 32,

    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      flexDirection: 'column-reverse',
    },
  },

  ownerContainer: {
    display: 'flex',
    marginBottom: 32,

    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      // flexDirection: 'column-reverse',
    },
  },

  descriptionImageContainer: {
    width: '50%',
    backgroundColor: '#eee',
    height: 300,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  descriptionImage: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },

  descriptionContainer: {
    width: '50%',
    padding: '0px 16px',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  stepContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-around',
    },
  },

  stepImage: {
    width: '20%',
    [theme.breakpoints.down('sm')]: {
      width: '40%',
    },
  },

  title: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },

  movieContainer: {
    margin: '32px auto',
    padding: 2,
    height: '70vh',

    [theme.breakpoints.down('sm')]: {
      height: '50vh',
    },
    '& iframe': {
      width: '100%',
      height: '100%',
    },
  },

  itemName: {
    color: '#777',
    fontSize: 12,
    padding: '0 8px',
    width: 300,
    maxHeight: 170,
    overflow: 'hidden',
  },

  itemPrice: {
    fontSize: 10,
    padding: '0 8px',
    width: 300,
  },
}))

export default Index
