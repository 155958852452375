import * as React from 'react'
import { FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core'
import { DefaultButton } from '../../_components'
import { OrderSection } from '.'
import { AmazonPayButton, AmazonPayButtonWidget } from './AmazonPayButton'
import { StripeCardSelectProvider } from './StripeCardSelect'
import { PaymentMethod } from '../../../constants'

enum HowToPay {
  UseCard = 'USE_CARD',
  UseInvoice = 'USE_INVOICE',
  UseAmazonPay = 'USE_AMAZON_PAY',
}

export interface FormPayments {
  paymentMethod: PaymentMethod
  cardToken?: string
  amazonOrderReferenceId?: string
}

interface Props {
  currentStep: number
  processing: boolean
  handleNext: () => void
  handleSubmit: () => void
  setFormPayments: (formPayments: FormPayments) => void
  hasSession: boolean
  guestEmail?: string
  disableInvoicePayment?: boolean
}

const SESSION_STEP = 4

export const OrderPayments: React.FC<Props> = (props) => {
  const classes = useStyles()

  const [submittable, setSubmittable] = React.useState(false)

  const [paymentMethod, setPaymentMethod] = React.useState(HowToPay.UseCard)

  const updateSubmittable = (howToPay: HowToPay, targetCardToken: string, targetAmazonOrderReferenceId: string) => {
    switch (howToPay) {
      case HowToPay.UseInvoice:
        props.setFormPayments({
          paymentMethod: PaymentMethod.Invoice,
        })
        setSubmittable(true)
        break
      case HowToPay.UseCard:
        props.setFormPayments({
          paymentMethod: PaymentMethod.Card,
          cardToken: targetCardToken,
        })
        if (targetCardToken) {
          setSubmittable(true)
        } else {
          setSubmittable(false)
        }
        break
      case HowToPay.UseAmazonPay:
        props.setFormPayments({
          paymentMethod: PaymentMethod.AmazonPay,
          amazonOrderReferenceId: targetAmazonOrderReferenceId,
        })

        break
    }
  }

  // Card
  const [cardToken, setCardToken] = React.useState<string | null>(null)
  const handleSetCardToken = (token?: string) => {
    setCardToken(token)
    updateSubmittable(HowToPay.UseCard, token, undefined)
  }

  // AmazonPay
  const [amazonOrderReferenceId, setAmazonOrderReferenceId] = React.useState<null | string>(null)
  const handleSetAmazonOrderReferenceId = (token?: string) => {
    setAmazonOrderReferenceId(token)
    updateSubmittable(HowToPay.UseAmazonPay, undefined, token)
  }

  return (
    <OrderSection
      step={SESSION_STEP}
      currentStep={props.currentStep}
      setCurrentStep={props.setCurrentStep}
      title={'お支払いの設定'}
      disableNextStep={true}>
      <div className={classes.root}>
        <RadioGroup
          aria-label="method"
          name="method"
          defaultValue={HowToPay.UseCard}
          value={paymentMethod}
          onChange={(event) => {
            setPaymentMethod(event.target.value)
            updateSubmittable(event.target.value, cardToken, amazonOrderReferenceId)
          }}
          className={classes.inputContainer}>
          {/* クレジットカード */}
          <FormControlLabel
            className={classes.radioLabel}
            control={<Radio color="primary" value={HowToPay.UseCard} />}
            // className={classes.formControlLabel}
            label={
              <div className={classes.radioLabelContainer}>
                <div className={classes.label}>クレジットカード</div>
                <p className={classes.description}>
                  ご利用明細は注文後、注文履歴から閲覧が可能です。領収書については、ご契約のクレジットカード会社発行のご利用明細書を領収書としてご利用ください。別途発行は承っておりませんのでご理解お願いいたします。
                </p>
              </div>
            }
          />
          <StripeCardSelectProvider
            showForm={paymentMethod === HowToPay.UseCard ? true : false}
            setCardToken={handleSetCardToken}
            hasSession={props.hasSession}
            guestEmail={props.guestEmail}
          />

          {/* 請求書 */}
          {!props.disableInvoicePayment ? (
            <FormControlLabel
              className={classes.radioLabel}
              control={<Radio color="primary" value={HowToPay.UseInvoice} />}
              // className={classes.formControlLabel}
              label={
                <div className={classes.radioLabelContainer}>
                  <div className={classes.label}>請求書</div>
                  <p className={classes.description}>
                    {`※個人登録のお客様は、振込が確認できてからの発送となります。\n※法人登録で初めてご注文されるお客様は、当社より電話確認させて頂く場合があります`}
                  </p>
                </div>
              }
            />
          ) : (
            <></>
          )}

          {/* AmazonPay */}
          <FormControlLabel
            className={classes.radioLabel}
            control={<Radio color="primary" value={HowToPay.UseAmazonPay} />}
            // className={classes.formControlLabel}
            label={
              <div className={classes.radioLabelContainer}>
                <AmazonPayButton />
              </div>
            }
          />

          <AmazonPayButtonWidget
            open={paymentMethod === HowToPay.UseAmazonPay ? true : false}
            amazonOrderReferenceId={amazonOrderReferenceId}
            setAmazonOrderReferenceId={handleSetAmazonOrderReferenceId}
          />
        </RadioGroup>

        <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
          <DefaultButton
            disabled={!submittable}
            processing={props.processing ? 'true' : 'false'}
            buttonclassname={classes.buttonSubmit}
            onClick={props.handleSubmit}>
            注文確定
          </DefaultButton>
          <DefaultButton
            id="payment-next"
            disabled={!submittable}
            buttonclassname={classes.buttonNext}
            onClick={props.handleNext}>
            次へ
          </DefaultButton>
        </div>
      </div>
    </OrderSection>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },

  inputContainer: {
    width: '100%',
    // paddingRight: 16,
  },

  label: {
    borderBottom: 0,
  },

  description: {
    fontSize: 14,
    color: '#999',
    whiteSpace: 'pre-wrap',
  },

  radioLabelContainer: {
    paddingTop: 9,
  },

  radioLabel: {
    alignItems: 'flex-start',
  },

  buttonSubmit: {
    width: 100,
    height: 36,
    display: 'block',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  buttonNext: {
    display: 'none',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}))
