import * as React from 'react'
import styled from 'styled-components'
import { PaymentMethod } from '../../constants'
import * as pages from '../../pages'
import { Header, Footer } from '../_components'
import { Form, Button } from 'react-bootstrap'
import { constants } from '../../_assets'

interface IParams {
  order: any
}

interface IProps {
  params: IParams
  order: any
  isPersonalUse: boolean
}

const Index: React.FC<IProps> = (props) => {
  const renderMessage = () => {
    switch (props.order.payment_method) {
      case PaymentMethod.Card:
        return (
          <span>
            クレジットカード払いによる領収書は、弊社発送業務完了後、
            <br />
            マイページの購入履歴画面からダウンロードが可能となります。
          </span>
        )
      case PaymentMethod.AmazonPay:
        return (
          <span>
            AmazonPay払いによる領収書は、弊社発送業務完了後、
            <br />
            マイページの購入履歴画面からダウンロードが可能となります。
          </span>
        )

      case PaymentMethod.Invoice:
        return (
          <>
            <span>
              ※個人登録のお客様は
              <br />
              振込が確認できてからの発送となります。
            </span>
            <br />

            <span>
              ※法人登録で初めてご注文されるお客様は、
              <br />
              当社より電話確認させて頂く場合があります
            </span>
          </>
        )
    }
  }
  return (
    <React.Fragment>
      <Header signedIn={true} />

      <Root>
        <div className="rootOrderCompleteContainer">
          <div className="rootOrderCompleteContainer__img">
            <img src="/images/logo.svg" alt={'logo'} />
          </div>
          <div className="rootOrderCompleteContainer__orders">
            <div className="rootOrderCompleteContainer__orders__title">
              ご注文ありがとうございました
              <small>{renderMessage()}</small>
            </div>
            <div className="rootOrderCompleteContainer__orders__body">
              <span style={{ fontWeight: 'bold' }}>ご注文番号</span>
              <p className="rootOrderCompleteContainer__orders__body__no">{props.order.order_no}</p>
              <small>ご注文に関するお問い合わせの際にご利用ください</small>
              {(props.order.payment_method === PaymentMethod.Invoice ||
                props.order.payment_method === PaymentMethod.InvoiceMonth) && (
                <div className="">
                  <span style={{ fontWeight: 'bold' }}>ご請求書</span>
                  <p className="rootOrderCompleteContainer__orders__body__content">
                    <a href={pages.INVOICE(props.order.order_no)} target="_blank" rel="noreferrer">
                      請求書をダウンロードする
                    </a>
                  </p>
                </div>
              )}
              {props.order.status ? (
                <div>
                  <span style={{ fontWeight: 'bold' }}>現在のステータス</span>
                  <p className="rootOrderCompleteContainer__orders__body__content">
                    {constants.getOrderStatusName(props.order.status)}
                  </p>
                </div>
              ) : (
                <div className="rootOrderCompleteContainer__footer">
                  <p className="rootOrderCompleteContainer__footer__msg">
                    <span>登録されたメールアドレスに</span>
                    <span>ご注文受諾のメールが送信されますので、</span>
                    <span>発送まで今しばらくお待ちください</span>
                  </p>
                  <p>ご利用ありがとうございました</p>
                </div>
              )}

              {(props.order.payment_method === PaymentMethod.Card ||
                props.order.payment_method === PaymentMethod.AmazonPay) &&
                props.order.status === constants.OrderStatus.Delivered && (
                  <div>
                    <span style={{ fontWeight: 'bold' }}>領収書</span>
                    <p className="rootOrderCompleteContainer__orders__body__content">
                      <a href={pages.RECEIPT(props.order.order_no)} target="_blank" rel="noreferrer">
                        領収書をダウンロードする
                      </a>
                    </p>
                  </div>
                )}
            </div>
          </div>

          <div className="rootOrderCompleteContainer__buttons">
            <Button
              onClick={() => {
                window.location.href = '/'
              }}>
              ホームへ
            </Button>
          </div>
        </div>
      </Root>

      <Footer />
    </React.Fragment>
  )
}

const Root = styled.div`
  padding: 32px;
  background-color: ${constants.BG_COLOR_GRAY};
  min-height: calc(100vh - ${constants.HEIGHT_HEADER}px);
  // display: flex;
  // justify-content: center;

  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    padding: 0 0 32px;
  }

  .rootOrderCompleteContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    &__img {
      width: 100%;
      max-height: 100px;
      padding: 16px 64px;
      display: flex;
      justify-content: center;

      img {
        height: 100%;
        width: 100%;
      }
    }

    &__orders {
      //
      width: 100%;
      padding: 16px;
      text-align: center;

      &__title {
        font-size: 18px;
        font-weight: bold;
        border-bottom: 1px solid #eee;
        padding-bottom: 8px;
        max-width: 700px;
        margin: 0 auto;
        margin-bottom: 36px;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        small {
          max-width: 500px;
          padding: 8px;
          font-size: 12px;
          width: 100%;
        }
      }

      &__body {
        // font-size: 12px;
        line-height: 36px;

        &__no {
          font-weight: bold;
          margin-bottom: 0px;
          margin: 0 auto;
          border: 1px solid #ddd;
          padding: 8px;
          max-width: 350px;
        }

        &__content {
          margin: 0 auto;
          border: 1px solid #ddd;
          padding: 8px;
          max-width: 350px;
          margin-bottom: 16px;

          a {
            text-decoration: underline;
          }
        }
      }
    }

    &__footer {
      &__msg {
        font-size: 12px;
        line-height: 18px;
        margin-top: 32px;
        span {
          white-space: nowrap;
        }
      }
    }

    &__buttons {
      margin-bottom: 32px;
      button {
        height: 50px;
        color: #fff;
        width: 250px;
        border-radius: 0;
      }
      //
    }
  }
`

export default Index
