import * as React from 'react'
import { pages } from '../../../_assets'
import { Wrapper, Pagination, Title, PanelWrapper, BasePanel, Filter } from '../_components'
import { index } from '../../../services/admin/UserService'
import { format } from 'date-fns'
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'

interface IProps {
  params: IParams
}

interface IParams {
  page: string
}

const Index: React.FC<IProps> = (props) => {
  const [data, setData] = React.useState(null)
  const [pagination, setPagination] = React.useState<any | null>(null)

  React.useEffect(() => {
    fetch()
  }, [])

  const fetch = async (page?: string) => {
    try {
      const { json, meta } = await index(page)
      setData(json)
      setPagination(meta)
    } catch (e) {
      window.flashMessages.addErrorMessage('データが取得できませんでした')
    }
  }

  const onChangePageHandler = async (page) => {
    fetch(page)
  }

  return (
    <React.Fragment>
      <Wrapper active="user">
        <Title title={'ユーザー一覧'} />
        <Filter>
          <div>
            <span style={{ fontWeight: 'bold' }}>すべて</span>:
            <span style={{ color: '#999' }}>{pagination && `(${pagination.total_count})`}</span>
          </div>
        </Filter>
        <PanelWrapper>
          <BasePanel>
            {data && (
              <>
                {data.length > 0 ? (
                  <>
                    <Table className="admin-table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell>メールアドレス</TableCell>
                          <TableCell>法人名</TableCell>
                          <TableCell>郵便番号</TableCell>
                          <TableCell>住所</TableCell>
                          <TableCell>電話番号</TableCell>
                          <TableCell>部署</TableCell>
                          <TableCell>担当者</TableCell>
                          <TableCell>紹介コード</TableCell>
                          <TableCell>特典コード</TableCell>
                          <TableCell>登録日</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.map((d, index) => (
                          <TableRow key={`order-${index}`}>
                            <TableCell className="panelBody__table__td num">{d.id}</TableCell>
                            <TableCell className="panelBody__table__td name">{d.email}</TableCell>
                            <TableCell className="panelBody__table__td ">{d.company_name}</TableCell>
                            <TableCell className="panelBody__table__td ">{d.zipcode}</TableCell>
                            <TableCell className="panelBody__table__td ">{d.address}</TableCell>
                            <TableCell className="panelBody__table__td ">{d.phone}</TableCell>
                            <TableCell className="panelBody__table__td ">{d.department}</TableCell>
                            <TableCell className="panelBody__table__td ">{d.username}</TableCell>
                            <TableCell className="panelBody__table__td ">{d.introducer && d.introducer.code}</TableCell>
                            <TableCell className="panelBody__table__td ">{d.user_benefit?.benefit.code}</TableCell>
                            <TableCell className="panelBody__table__td" style={{ whiteSpace: 'nowrap' }}>
                              {format(new Date(d.created_at), 'yyyy-MM-dd')}
                            </TableCell>
                            <TableCell>
                              <Button
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={() => {
                                  window.location.href = pages.ADMIN_USERS_EDIT(d.id)
                                }}>
                                編集
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {pagination && (
                      <Pagination
                        onChangePageHandler={onChangePageHandler}
                        currentPage={pagination.current_page}
                        prevPage={pagination.prev_page}
                        nextPage={pagination.next_page}
                        totalPages={pagination.total_pages}
                        totalCount={pagination.total_count}
                      />
                    )}
                  </>
                ) : (
                  <div style={{ padding: '16px 0', textAlign: 'center' }}>データがありません</div>
                )}
              </>
            )}
          </BasePanel>
        </PanelWrapper>
      </Wrapper>
    </React.Fragment>
  )
}

export default Index
