import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../../constants'
import * as pages from '../../../pages'

interface IProps {
  active: string
}

const Index: React.FC<IProps> = (props) => {
  return (
    <React.Fragment>
      <Root>
        <ul className="Nav_List">
          <a href={pages.ADMIN_ORDERS}>
            <li className={props.active === 'order' ? 'active' : ''}>注文</li>
          </a>
          <a href={pages.ADMIN_CATEGORIES}>
            <li className={props.active === 'category' ? 'active' : ''}>カテゴリ</li>
          </a>
          <a href={pages.ADMIN_ITEMS}>
            <li className={props.active === 'item' ? 'active' : ''}>アイテム</li>
          </a>
          <a href={pages.ADMIN_USERS}>
            <li className={props.active === 'user' ? 'active' : ''}>ユーザー</li>
          </a>
          <a href={pages.ADMIN_INTRODUCERS}>
            <li className={props.active === 'introducer' ? 'active' : ''}>紹介者</li>
          </a>
          <a href={pages.ADMIN_TRADERS}>
            <li className={props.active === 'trader' ? 'active' : ''}>手配業者</li>
          </a>
          <a href={pages.ADMIN_MONTH_SALES}>
            <li className={props.active === 'monthSale' ? 'active' : ''}>売上（月別）</li>
          </a>
          <a href={pages.ADMIN_INTRODUCER_SALES}>
            <li className={props.active === 'introducerSale' ? 'active' : ''}>売上（紹介者別）</li>
          </a>
          <a href={pages.ADMIN_CONGRATS}>
            <li className={props.active === 'congrat' ? 'active' : ''}>お祝い文言</li>
          </a>
          {/* <a href={pages.ADMIN_BENEFITS}>
            <li className={props.active === 'benefit' ? 'active' : ''}>特典コード</li>
          </a> */}
          <a href={pages.ADMIN_ADMINS}>
            <li className={props.active === 'admin' ? 'active' : ''}>管理者</li>
          </a>
          <a href={pages.ADMIN_ITEM_REVIEWS}>
            <li className={props.active === 'itemReview' ? 'active' : ''}>商品レビュー</li>
          </a>
          <a href={pages.ADMIN_STORES}>
            <li className={props.active === 'store' ? 'active' : ''}>専用ストアページ</li>
          </a>
          <a href={'#'}>
            <li className={props.active === 'contact' ? 'active' : ''}>問い合わせ</li>
          </a>
        </ul>
      </Root>
    </React.Fragment>
  )
}

const Root = styled.div`
  background-color: ${constants.BG_COLOR_GRAY};
  min-height: calc(100vh - ${constants.HEIGHT_HEADER}px);
  max-height: calc(100vh - ${constants.HEIGHT_HEADER}px);
  overflow-y: hidden;

  padding: 16px 0;
  width: 250px;
  min-width: 250px;
  box-shadow: 0 3px 8px rgba(50, 50, 50, 0.17);

  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    display: none;
  }

  ul {
    list-style: none;
    padding: 0;
    a {
      color: ${constants.TEXT_COLOR};

      &:hover {
        text-decoration: none;
      }
    }
    li {
      height: 40px;
      display: flex;
      align-items: center;
      padding-left: 32px;
      font-size: 16px;
      margin-bottom: 8px;

      &.active {
        border-left: 4px solid ${constants.MAIN_COLOR};
        font-weight: bold;
        color: ${constants.MAIN_COLOR};
        background-color: #eee;
      }

      &:hover {
        background-color: #eee;
      }
    }
  }
`

export default Index
