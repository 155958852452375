import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../../constants'
import * as pages from '../../../pages'
import { InputGroup, Button, Form } from 'react-bootstrap'
import { Wrapper, Pagination, Title, PanelWrapper, BasePanel } from '../_components'
import { Header, Footer } from '../../_components'
import { useForm } from 'react-hook-form'
import { create, IInputCreate } from '../../../services/admin/CongratService'

interface Props {}

const Index: React.FC<Props> = () => {
  // form
  const { register, handleSubmit, setValue, errors, getValues, setError } = useForm<IInputCreate>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = async (data: IInputCreate) => {
    try {
      await create(data)
      window.location.href = pages.ADMIN_CONGRATS
    } catch (e) {
      window.flashMessages.addErrorMessage('エラーが発生しました')
    }
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
        <Wrapper active="congrat">
          <Title
            back={pages.ADMIN_CONGRATS}
            title={'お祝い文言登録'}
            action={
              <Button variant="primary" type="submit" size="sm" style={{ color: 'white' }}>
                登録
              </Button>
            }
          />
          <PanelWrapper>
            <BasePanel>
              <PanelBody>
                <div className="panelBody__formContainer">
                  <div className="panelBody__formContainer__inputContainer w100">
                    <label className="required">お祝い文言</label>
                    <Form.Control
                      name="name"
                      ref={register({
                        required: 'お祝い文言は必須です',
                      })}
                      autoComplete="nope"></Form.Control>
                    {errors.name && <small>{errors.name.message}</small>}
                  </div>
                  <div className="panelBody__formContainer__inputContainer w25">
                    <label className="required">表示順</label>
                    <Form.Control
                      name="position"
                      defaultValue="0"
                      type="number"
                      ref={register({
                        required: '表示順は必須です',
                      })}
                      autoComplete="nope"></Form.Control>
                    {errors.position && <small>{errors.position.message}</small>}
                  </div>
                </div>
              </PanelBody>
            </BasePanel>
          </PanelWrapper>
        </Wrapper>
      </form>
    </React.Fragment>
  )
}

const PanelBody = styled.div`
  .panelBody {
    &__formContainer {
      &__inputContainer {
        &__img {
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px;

          img {
            height: 100%;
          }

          &__no {
            height: 100%;
            width: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #e5e5e5;
            color: #999;
          }
        }
      }
    }
  }
`

export default Index
