import * as constants from '../../constants'
import { makeStyles } from '@material-ui/core'
import * as React from 'react'
import Menus from './Menus'

interface Props {
  active: 'histories'
  children: React.ReactNode
  maxWidth?: number
}

export const MypageContainer: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Menus active={props.active} />
      <div style={{ width: '100%', maxWidth: props.maxWidth || 'auto', margin: '0 auto' }}>{props.children}</div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: constants.BG_COLOR_GRAY,
    // minHeight: '', //calc(100vh - ${constants.HEIGHT_HEADER + constants.HEIGHT_FOOTER}px);
    display: 'flex',
    justifyContent: 'center',
    padding: '32px 64px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 16px',
    },
  },

  // @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
  //   padding: 0px;
  // }
}))
