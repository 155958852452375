import * as React from 'react'
import * as constants from '../../constants'
import * as pages from '../../pages'
import { Header, Footer, GoToTop, SquareImage } from '../_components'
import { index } from '../../services/CategoryService'
import { recommended } from '../../services/ItemService'
import { makeStyles } from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { priceTypes } from '../items'
import { recentReview } from '../../services/ItemReviewService'
import { ReviewItem } from '../_components/ReviewList'
import { SectionTitle, SectionTitleContainer } from './conponents'
import { NoticeItem } from './conponents/NoticeItem'

interface IProps {
  signedIn: boolean
  isAdmin: boolean
}

const Index: React.FC<IProps> = (props) => {
  const classes = useStyles()

  const [categories, setCategories] = React.useState(null)
  const [recommends, setRecommends] = React.useState(null)
  const [recents, setRecents] = React.useState(null)
  const [reviews, setReviews] = React.useState(null)

  React.useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    try {
      const { json: fetchedData } = await index()
      setCategories(fetchedData)

      const { json: fetchedRecommended } = await recommended()
      setRecommends(fetchedRecommended)

      const { json: fetchedRecentReview } = await recentReview()
      setReviews(fetchedRecentReview)
    } catch (e) {
      window.flashMessages.addErrorMessage('データが取得できませんでした')
    }
  }

  return (
    <React.Fragment>
      <Header signedIn={props.signedIn} isAdmin={props.isAdmin} />

      <div className={classes.topContainer}>
        <div className={classes.topBgContainer}>
          <div className={classes.phraseSpContainer}>
            <div className={classes.phraseSpTitle}>大切な方へ贈るお花がここにあります</div>
            <div className={classes.phraseSpLogoContainer}>
              <img src="/images/logo.svg" alt="はなテック" />
            </div>
          </div>
        </div>
        <div className={classes.phraseContainer}>
          <div className={classes.phraseTitle}>大切な方へ贈るお花がここにあります</div>
          <div className={classes.phraseBody}>
            お花には人々を笑顔にする力があります。いろんなお花があるからこそ、私達はプロフェッショナルとして良いものをお客様に届けたいと願っています。
          </div>

          <div className={classes.phraseLogoContainer}>
            <img src="/images/logo.svg" alt="はなテック" />
          </div>
        </div>
      </div>

      <div className={classes.root}>
        <div className={classes.rootContainer}>
          <SectionTitleContainer>
            <SectionTitle id={'category'} title={'お花の種類から選ぶ'} subTitle={'Choose from the types of flowers'} />

            <div className={classes.categoryContainerLink}>
              <a href="/categories">
                すべて<span className={classes.categoryContainerLinkPrefix}>のカテゴリ</span>を見る
                <ArrowRightIcon />
              </a>
            </div>
          </SectionTitleContainer>
          {categories &&
            categories.map((category, index) => {
              if (index < 8) {
                return (
                  <div
                    key={`item-${index}`}
                    className={classes.imageContainer}
                    onClick={() => {
                      window.location.href = `${pages.ITEMS_INDEX}?category=${category.slug}`
                    }}>
                    <SquareImage src={category.image_url} />
                    <div className={classes.categoryTitle}>{category.name}</div>
                  </div>
                )
              }
            })}

          <SectionTitleContainer>
            <SectionTitle id={'price'} title={'価格で選ぶ'} subTitle={'Choose by price'} />
          </SectionTitleContainer>

          {priceTypes.map((priceType, index) => (
            <div key={`item-price-${index}`} className={classes.priceContainer}>
              {/* <SquareImage src={null} /> */}
              <div
                className={classes.priceTitle}
                onClick={() => {
                  window.location.href = `${pages.ITEMS_INDEX}?min_price=${priceType.minPrice}&max_price=${priceType.maxPrice}`
                }}>
                {priceType.label}
              </div>
            </div>
          ))}

          {recommends && recommends.length > 0 && (
            <>
              <SectionTitleContainer>
                <SectionTitle id={'recommend'} title={'おすすめ商品'} subTitle={'Featured products'} />
              </SectionTitleContainer>

              {recommends &&
                recommends.map((item, index) => (
                  <div
                    key={`item-${index}`}
                    className={classes.imageContainer}
                    onClick={() => {
                      window.location.href = pages.ITEMS_SHOW(item.slug)
                    }}>
                    <SquareImage src={item.image_url} />
                    <div className={classes.categoryTitle}>{item.name}</div>
                  </div>
                ))}
            </>
          )}

          {reviews && reviews.length > 0 && (
            <>
              <SectionTitleContainer>
                <SectionTitle id={'review'} title={'お客様の声'} subTitle={'Customer reviews'} />
              </SectionTitleContainer>

              <div
                style={{
                  minHeight: 300,
                  maxHeight: 300,
                  overflow: 'scroll',
                  borderBottom: '1px solid #eee',
                  width: '100%',
                  marginBottom: 32,
                }}>
                {reviews.map((item, index) => (
                  <ReviewItem key={`review-${index}`} item={item} showImage={true} />
                ))}
              </div>
            </>
          )}

          {recents && recents.length > 0 && (
            <>
              <SectionTitleContainer>
                <SectionTitle id={'recent'} title={'最近見た商品'} subTitle={'Recently seen products'} />
              </SectionTitleContainer>
            </>
          )}

          <SectionTitleContainer>
            <SectionTitle id={'notice'} title={'お知らせ'} subTitle={'Notification'} />
          </SectionTitleContainer>

          <div className={classes.noticeContainer}>
            <NoticeItem
              title={'2021.04.05 レビュー機能を導入しました。'}
              body={`レビュー投稿方法をご紹介します。
１、発送完了メールにレビュー投稿のURLが添付されます。
２、マイページ購入履歴に「レビューを書く」から投稿可能。

新機能追加キャンペーンとしてレビューを投稿いただいた全てのユーザー様へ、「AMAZONギフト300円分」をプレゼント！投稿後メールでお送りします。

レビュー特典内容は変更する場合があります。あらかじめご了承下さい。`}
            />
          </div>
        </div>
      </div>

      <Footer />
      <GoToTop />
    </React.Fragment>
  )
}

export const useStyles = makeStyles((theme) => ({
  '@global': {
    '@keyframes bgAnime': {
      '25%': {
        transform: 'scale(1.015, 1.015)',
        backgroundImage: `url('images/slide_2.jpg')`,
      },

      // '33%': {
      //   // backgroundImage: `url('images/slide_2.png')`,
      // },

      '50%': {
        transform: 'scale(1, 1)',
        backgroundImage: `url('images/slide_3.jpg')`,
      },

      // '66%': {
      //   // transform: 'scale(1.05, 1.05)',
      //   // backgroundImage: `url('images/slide_3.png')`,
      // },

      '75%': {
        transform: 'scale(1.015, 1.015)',
        backgroundImage: `url('images/slide_4.jpg')`,
      },

      '100%': {
        transform: 'scale(1, 1)',
      },

      // '40%': {
      //   // transform: 'scale(1, 1)',
      // },

      // '50%': {
      //   // backgroundImage: `url('images/slide_3.jpg')`,
      //   transform: 'scale(1.05, 1.05)',
      // },

      // '60%': {
      //   transform: 'scale(1, 1)',
      // },

      // '70%': {
      //   transform: 'scale(1, 1)',
      // },
    },
  },

  root: {
    backgroundColor: constants.BG_COLOR_GRAY,
    minHeight: `calc(100vh - ${constants.HEIGHT_HEADER + constants.HEIGHT_FOOTER}px)`,
    padding: '0 64px',

    [theme.breakpoints.down('sm')]: {
      padding: 0,
      // '32px 0',
    },
  },

  rootContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      padding: '0 4px',
    },
  },

  categoryContainerLink: {
    // [theme.breakpoints.down('sm')]: {
    //   width: '100%',
    // },
  },

  categoryContainerLinkPrefix: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  imageContainer: {
    marginBottom: 32,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
    cursor: 'pointer',
    width: 300,

    [theme.breakpoints.down('md')]: {
      width: 200,
    },

    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },

  categoryTitle: {
    color: constants.TEXT_COLOR,
    width: '100%',
    fontSize: '1.0rem',
    transition: 'all 0.3s',
    padding: '8px 16px',
    textAlign: 'center',
    '& :hover': {
      transform: 'scale(1.1)',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
      fontWeight: 'bold',
      textAlign: 'left',
      // paddingLeft: 4,
    },
  },

  topContainer: {
    height: 500,
    overflow: 'hidden',
    backgroundColor: 'rgb(235,235,235)',
    marginBottom: 16,

    [theme.breakpoints.down('xs')]: {
      height: 300,
    },
  },

  topBgContainer: {
    height: '100%',
    backgroundImage: `url('images/slide_1.jpg'), url('images/slide_2.jpg'), url('images/slide_3.jpg'), url('images/slide_4.jpg')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    animation: 'bgAnime',
    animationDuration: '25s',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
    // animationDuration: 0.3,

    display: 'flex',
    alignItems: 'flex-start',
    padding: 32,
  },

  phraseContainer: {
    width: 350,
    maxWidth: 350,
    position: 'absolute',
    top: 150,
    left: 100,

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  phraseTitle: {
    fontSize: 32,
  },

  phraseSpContainer: {
    width: '75%',
    height: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    flexWrap: 'wrap',

    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  phraseSpTitle: {
    fontSize: 26,
  },

  phraseSpLogoContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',

    '& img': {
      height: 30,
    },
  },

  phraseBody: {
    fontSize: 16,
  },

  phraseLogoContainer: {
    height: 50,
    marginTop: 18,
    display: 'flex',
    justifyContent: 'center',

    '& img': {
      height: '100%',
    },
  },

  priceContainer: {
    marginBottom: 16,
    display: 'flex',
    // flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: '25%',
    height: 100,
    padding: 8,

    [theme.breakpoints.down('sm')]: {
      width: '50%',
      height: 80,
      marginBottom: 0,
    },

    '& :hover': {
      backgroundColor: '#f3f3f3',
    },
  },

  priceTitle: {
    border: '1px solid #e3e3e3',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
  },

  noticeContainer: {
    padding: 8,
    marginBottom: 32,
    width: '100%',
  },
}))

export default Index
