import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../constants'
import * as pages from '../../pages'
import { Header, Footer } from '../_components'
import { Form, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { confirmation, IInputConfirmation } from '../../services/UserService'

interface IParams {
  reset_password_token: string
}

interface IProps {
  params: IParams
}

const Index: React.FC<IProps> = (props) => {
  const [sent, setSend] = React.useState<boolean>(false)

  // form
  const { register, handleSubmit, setValue, errors, getValues, setError } = useForm<IInputConfirmation>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const onSubmit = async (data: IInputConfirmation) => {
    try {
      await confirmation(data)
      setSend(true)
    } catch (e) {
      setError('email', { type: 'manual', message: e.data.error })
    }
  }

  return (
    <React.Fragment>
      <Header />

      <Root>
        <div className="rootContainer">
          <div className="signinContainer">
            <img src="/images/logo.svg" alt="logo" />
            <p>メールアドレス確認を再送信する</p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                type="hidden"
                name="resetPasswordToken"
                defaultValue={props.params.reset_password_token}
                ref={register({})}
              />
              <div>
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="メールアドレス"
                  ref={register({ required: 'メールアドレスは必須です' })}
                />

                {errors.email && <small style={{ color: 'red' }}>{errors.email.message}</small>}
              </div>

              <Button type="submit" className="mb-2">
                再送信
              </Button>

              {sent && <p>再送しました</p>}
            </form>
          </div>
        </div>
      </Root>

      <Footer />
    </React.Fragment>
  )
}

const Root = styled.div`
  background-color: ${constants.BG_COLOR_GRAY};
  min-height: calc(100vh - ${constants.HEIGHT_HEADER + constants.HEIGHT_FOOTER}px);
  display: flex;
  justify-content: center;
  padding: 32px;

  .rootContainer {
    width: 100%;
    max-width: 400px;

    .signinContainer {
      background-color: #fff;
      // width: 400px;
      padding: 16px;
      box-shadow: 0 3px 8px rgba(50, 50, 50, 0.17);

      h3 {
        margin: 16px 0;
        text-align: center;
        font-size: 25px;
      }

      img {
        width: 100%;
        margin: 16px 0 32px;
        height: 60px;
        object-fit: contain;
      }

      form {
        div {
          margin-bottom: 16px;
          input {
            height: 50px;
            border-radius: 0;
            background-color: #f2f2f2;
          }
        }

        button {
          width: 100%;
          border-radius: 0;
          height: 50px;
          color: #fff;
        }
      }

      .sessions {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;

        a {
          width: 100%;
          margin-bottom: 4px;
        }
      }
    }
  }
`

export default Index
