import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../../constants'
import * as pages from '../../../pages'
import { DefaultThemeProvider, Header } from '../../_components'
import SideMenus from './SideMenus'

interface IProps {
  active: string
  children: React.ReactNode
}

const Index: React.FC<IProps> = (props) => {
  return (
    <DefaultThemeProvider>
      <Header signedIn={true} isAdmin={true} />
      <Root>
        <SideMenus active={props.active} />
        <div className="rootContainer">{props.children}</div>
      </Root>
    </DefaultThemeProvider>
  )
}

const Root = styled.div`
  background-color: ${constants.BG_COLOR_GRAY};

  display: flex;
  justify-content: center;

  .rootContainer {
    width: 100%;

    min-height: calc(100vh - ${constants.HEIGHT_HEADER}px);
    max-height: calc(100vh - ${constants.HEIGHT_HEADER}px);
    overflow: scroll;
  }
`

export default Index
