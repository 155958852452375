import * as React from 'react'
import { constants } from '../../_assets'
import { makeStyles } from '@material-ui/core'

interface IProps {}

const Index: React.FC<IProps> = (props) => {
  const classes = useStyles()

  const handleOnClick = () => {
    scrollToTop(300)
  }

  return (
    <div className={classes.container} onClick={handleOnClick}>
      ⬆
    </div>
  )
}

function scrollToTop(duration) {
  // cancel if already on top
  if (document.scrollingElement.scrollTop === 0) return

  const cosParameter = document.scrollingElement.scrollTop / 2
  let scrollCount = 0,
    oldTimestamp = null

  function step(newTimestamp) {
    if (oldTimestamp !== null) {
      // if duration is 0 scrollCount will be Infinity
      scrollCount += (Math.PI * (newTimestamp - oldTimestamp)) / duration
      if (scrollCount >= Math.PI) return (document.scrollingElement.scrollTop = 0)
      document.scrollingElement.scrollTop = cosParameter + cosParameter * Math.cos(scrollCount)
    }
    oldTimestamp = newTimestamp
    window.requestAnimationFrame(step)
  }
  window.requestAnimationFrame(step)
}

const useStyles = makeStyles((theme) => ({
  container: {
    cursor: 'pointer',
    position: 'fixed',

    right: 50,
    bottom: 50,
    background: constants.MAIN_COLOR,
    color: '#fff',
    opacity: 0.5,
    borderRadius: '50%',
    minWidth: 50,
    minHeight: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    transitionDuration: '0.5s',
    '&:hover': {
      opacity: 1,
    },

    [theme.breakpoints.down('sm')]: {
      right: 25,
      bottom: 25,
    },
  },
}))

export default Index
