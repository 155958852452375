import { sendPost, sendPut, sendGet, sendDelete } from './httpClient'
import { parseResourceData } from './JsonApiSerializer'

export interface NameTag {
  id: string
  name: string
}

export const indexNameTag = async (): Promise<NameTag[] | undefined> => {
  try {
    const { data } = await sendGet('/api/name_tags')
    const json = parseResourceData(data.json)
    return json as NameTag[]
  } catch (e) {
    throw e.response
  }
}

export interface IInputNameTag {
  id: string
  name: string
}

export const create = async (formData: IInputNameTag) => {
  const { name } = formData

  try {
    const response = await sendPost('/api/name_tags', {
      name_tag: { name },
    })
    return response
  } catch (e) {
    throw e.response
  }
}

export const update = async (formData: IInputNameTag) => {
  const { id, name } = formData

  try {
    const response = await sendPut('/api/name_tags/' + id, {
      name_tag: { name },
    })
    return response
  } catch (e) {
    throw e.response
  }
}

export const destroy = async (id: string) => {
  try {
    const response = await sendDelete('/api/name_tags/' + id)
    return response
  } catch (e) {
    throw e.response
  }
}
