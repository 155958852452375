import { sendGet, sendPost, sendPut, sendDelete } from '../httpClient'
import { parseResourceData } from '../JsonApiSerializer'

export const index = async (page: string) => {
  try {
    const { data } = await sendGet(
      `/admin/api/benefits${page ? '?page=' + page : ''}`
    )
    const json = parseResourceData(data.json)
    const meta = data.json.meta
    return { json, meta }
  } catch (e) {
    throw e.response
  }
}

export const show = async (id: string) => {
  try {
    const { data } = await sendGet('/admin/api/benefits/' + id)
    const json = parseResourceData(data.json)
    return { json }
  } catch (e) {
    throw e.response
  }
}

export interface IInputCreate {
  code: string
  value: number
  title: string
  description: string
  register_limit_date: string
  use_limit_date: string
}

export const create = async (formData: IInputCreate) => {
  const {
    code,
    value,
    title,
    description,
    register_limit_date,
    use_limit_date,
  } = formData

  try {
    const response = await sendPost('/admin/api/benefits', {
      benefit: {
        code,
        value,
        title,
        description,
        register_limit_date,
        use_limit_date,
      },
    })
    return response
  } catch (e) {
    throw e.response
  }
}

export interface IInputUpdate extends IInputCreate {
  id: string
}

export const update = async (formData: IInputUpdate) => {
  const {
    id,
    code,
    value,
    title,
    description,
    register_limit_date,
    use_limit_date,
  } = formData

  try {
    const response = await sendPut('/admin/api/benefits/' + id, {
      benefit: {
        code,
        value,
        title,
        description,
        register_limit_date,
        use_limit_date,
      },
    })
    return response
  } catch (e) {
    throw e.response || e
  }
}

export const destroy = async (id: string) => {
  try {
    const response = await sendDelete('/admin/api/benefits/' + id)
    return response
  } catch (e) {
    throw e.response
  }
}
