import { sendDelete, sendPost, sendGet, sendPut } from '../httpClient'
import { parseResourceData } from '../JsonApiSerializer'

export const index = async (page: string) => {
  try {
    const { data } = await sendGet(`/admin/api/categories${page ? '?page=' + page : ''}`)
    const json = parseResourceData(data.json)
    const meta = data.json.meta
    return { json, meta }
  } catch (e) {
    throw e.response
  }
}

export const show = async (id: string) => {
  try {
    const { data } = await sendGet('/admin/api/categories/' + id)
    const json = parseResourceData(data.json)
    return { json }
  } catch (e) {
    throw e.response
  }
}

export interface IInputCreate {
  name: string
  position: number
  image: any
}

export const create = async (formData: IInputCreate) => {
  const { name, position, image } = formData

  const params = new FormData()
  params.append('category[name]', name)
  params.append('category[position]', `${position}`)

  if (image[0]) {
    params.append('category[image]', image[0])
  }

  try {
    const response = await sendPost('/admin/api/categories', params)
    return response
  } catch (e) {
    throw e.response
  }
}

export interface IInputUpdate extends IInputCreate {
  id: string
}

export const update = async (formData: IInputUpdate) => {
  const { id, name, position, image } = formData

  const params = new FormData()
  params.append('category[name]', name)
  params.append('category[position]', `${position}`)
  if (image[0]) {
    params.append('category[image]', image[0])
  }

  try {
    const response = await sendPut('/admin/api/categories/' + id, params)
    return response
  } catch (e) {
    throw e.response || e
  }
}

export const destroy = async (id: string) => {
  try {
    const response = await sendDelete('/admin/api/categories/' + id)
    return response
  } catch (e) {
    throw e.response
  }
}
