import * as React from 'react'
import { constants } from '../../../_assets'
import { makeStyles } from '@material-ui/core'
import { ConfirmSection, ConfirmSectionContent, ConfirmSectionLabel } from './ConfirmSection'
import { FormAddresses } from './OrderAddresses'

interface Props {
  addresses: FormAddresses
}

export const ConfirmAddresses: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <ConfirmSection title={'お届け先の設定'}>
      <ConfirmSectionLabel label={'お名前/宛名'} />
      <ConfirmSectionContent
        content={`${props.addresses.recipient}${props.addresses.isAffectNameTag ? '\n※名札に反映する' : ''}`}
      />

      <ConfirmSectionLabel label={'郵便番号'} />
      <ConfirmSectionContent content={props.addresses.zipcode} />
      <ConfirmSectionLabel label={'住所'} />
      <ConfirmSectionContent
        content={`${props.addresses.prefecture} ${props.addresses.address1} ${props.addresses.address2}`}
      />
      <ConfirmSectionLabel label={'電話番号'} />
      <ConfirmSectionContent content={props.addresses.phone} />
      <ConfirmSectionLabel label={'担当部署/担当者'} />
      <ConfirmSectionContent content={props.addresses.dear || '空欄'} gray={props.addresses.dear ? false : true} />
      <ConfirmSectionLabel label={'お届け日時'} />
      <ConfirmSectionContent
        content={
          props.addresses.deliveryDate
            ? `${props.addresses.deliveryDate} ${props.addresses.deliveryTimeFrom} - ${props.addresses.deliveryTimeTo}`
            : '最短でお届け'
        }
      />
      <ConfirmSectionLabel label={'備考'} />
      <ConfirmSectionContent content={props.addresses.note || '空欄'} gray={props.addresses.note ? false : true} />
    </ConfirmSection>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // justifyContent: 'center',
  },
}))
