export const APP_NAME = 'はなテック'

export const BASE_COLOR = '#ffffff'
export const MAIN_COLOR = '#7ecaa0'
export const MAIN_COLOR_LIGHT = '#eaf6f0'
export const MAIN_COLOR_DARK = '#394B40'
export const SECONDARY_COLOR = '#e75297'
export const DANGER_COLOR = '#dc3545'

export const BLACK_COLOR = '#344351'
export const TEXT_COLOR = '#575757'

export const BG_COLOR_GRAY = '#fbfbfb'
export const BG_COLOR_GRAY_DARK = '#eeeeee'

export const HEIGHT_HEADER = 65
export const HEIGHT_FOOTER = 250

export const BREAKPOINT_DESKTOP = 1088
export const BREAKPOINT_TABLET_LARGE = 960
export const BREAKPOINT_TABLET_SMALL = 720
export const BREAKPOINT_TABLET_MOBILE = 600

export const PAYMENT_METHOD_CARD_NAME = 'カード決済'
export const PAYMENT_METHOD_APPLE_PAY = 'apple_pay'
export const PAYMENT_METHOD_APPLE_PAY_NAME = 'Apple Pay'
export const PAYMENT_METHOD_INVOICE_NAME = '請求書払い'
export const PAYMENT_METHOD_INVOICE_MONTH = 'invoice_month'
export const PAYMENT_METHOD_INVOICE_MONTH_NAME = '請求書払い/末締め'
export const PAYMENT_METHOD_AMAZON_PAY_NAME = 'AmazonPay'
export const getPaymentMethodName = (method: string, is_personal_use: boolean): string => {
  switch (method) {
    case PaymentMethod.Card:
      return PAYMENT_METHOD_CARD_NAME
    case PAYMENT_METHOD_APPLE_PAY:
      return PAYMENT_METHOD_APPLE_PAY_NAME
    case PaymentMethod.Invoice:
      if (is_personal_use) {
        return '銀行振込'
      } else {
        return PAYMENT_METHOD_INVOICE_NAME
      }
    case PaymentMethod.InvoiceMonth:
      return PAYMENT_METHOD_INVOICE_MONTH_NAME
    case PaymentMethod.AmazonPay:
      return PAYMENT_METHOD_AMAZON_PAY_NAME
  }
}

export enum PaymentMethod {
  Card = 'card',
  Invoice = 'invoice',
  InvoiceMonth = 'invoice_month',
  AmazonPay = 'amazon_pay',
}

export enum OrderStatus {
  Ordered = 'ordered',
  Packing = 'packing',
  Delivered = 'delivered',
  Canceled = 'canceled',
  Declined = 'declined',
  Failed = 'failed',
  Refund = 'refund',
}

export const getOrderStatusName = (status?: string): string => {
  switch (status) {
    case OrderStatus.Ordered:
      return '注文受付'
    case OrderStatus.Packing:
      return '配送準備中'
    case OrderStatus.Delivered:
      return '配送済み'
    case OrderStatus.Canceled:
      return 'キャンセル'
    case OrderStatus.Declined:
      return '拒否'
    case OrderStatus.Failed:
      return '失敗'
    case OrderStatus.Refund:
      return '返金'
    default:
      return '注文受付'
  }
}

export const getOrderStatusColor = (status?: OrderStatus | string): string | undefined => {
  switch (status) {
    case OrderStatus.Ordered:
      return '#ea8f8f'
    case OrderStatus.Packing:
      return '#9ad29c'
    case OrderStatus.Delivered:
      return '#83c3f7'
    // case OrderStatus.Canceled:
    //   return 'キャンセル'
    // case OrderStatus.Declined:
    //   return '拒否'
    // case OrderStatus.Failed:
    //   return '失敗'
    // case OrderStatus.Refund:
    //   return '返金'
    default:
      return '#ea8f8f'
  }
}

export const optionsOrderStatus = (currentValue?: OrderStatus | null) => {
  switch (currentValue) {
    case OrderStatus.Ordered:
    case null:
      return [
        { value: OrderStatus.Ordered, label: getOrderStatusName() },
        { value: OrderStatus.Packing, label: getOrderStatusName(OrderStatus.Packing) },
        { value: OrderStatus.Canceled, label: getOrderStatusName(OrderStatus.Canceled) },
        { value: OrderStatus.Declined, label: getOrderStatusName(OrderStatus.Declined) },
        { value: OrderStatus.Failed, label: getOrderStatusName(OrderStatus.Failed) },
      ]
    case OrderStatus.Packing:
      return [
        { value: OrderStatus.Packing, label: getOrderStatusName(OrderStatus.Packing) },
        { value: OrderStatus.Delivered, label: getOrderStatusName(OrderStatus.Delivered) },
        { value: OrderStatus.Canceled, label: getOrderStatusName(OrderStatus.Canceled) },
        { value: OrderStatus.Declined, label: getOrderStatusName(OrderStatus.Declined) },
        { value: OrderStatus.Failed, label: getOrderStatusName(OrderStatus.Failed) },
      ]
    default:
      return [
        { value: OrderStatus.Ordered, label: getOrderStatusName() },
        { value: OrderStatus.Canceled, label: getOrderStatusName(OrderStatus.Canceled) },
        { value: OrderStatus.Declined, label: getOrderStatusName(OrderStatus.Declined) },
        { value: OrderStatus.Failed, label: getOrderStatusName(OrderStatus.Failed) },
      ]
  }
}

export const optionsHoliday = () => {
  return [
    { value: 'sun', label: '日曜日' },
    { value: 'mon', label: '月曜日' },
    { value: 'tue', label: '火曜日' },
    { value: 'wed', label: '水曜日' },
    { value: 'thu', label: '木曜日' },
    { value: 'fri', label: '金曜日' },
    { value: 'sat', label: '土曜日' },
  ]
}

export const getHolidayLabelByValue = (value: string) => {
  switch (value) {
    case 'sun':
      return '日曜日'
    case 'mon':
      return '月曜日'
    case 'tue':
      return '火曜日'
    case 'wed':
      return '水曜日'
    case 'thu':
      return '木曜日'
    case 'fri':
      return '金曜日'
    case 'sat':
      return '土曜日'
    default:
      return null
  }
}

export const optionsHolidayByValues = (values: string) => {
  const arrayValue = values.split(',')
  const result = []

  arrayValue.forEach((value) => {
    const label = getHolidayLabelByValue(value)
    if (label) {
      result.push({ value, label })
    }
  })

  return result
}
