import { sendPost, sendPut, sendGet, sendDelete } from './httpClient'
import { parseResourceData } from './JsonApiSerializer'

export interface IInputBenefitCode {
  code: string
}

export const addBenefitCode = async (formData: IInputBenefitCode) => {
  const { code } = formData

  try {
    const { data } = await sendPost('/api/benefit', {
      benefit: {
        code,
      },
    })
    const json = parseResourceData(data.json)
    return { json }
  } catch (e) {
    throw e.response
  }
}

export const deleteBenefitCode = async () => {
  try {
    await sendDelete('/api/benefit')
    return {}
  } catch (e) {
    throw e.response
  }
}
