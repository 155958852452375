import * as React from 'react'
import * as constants from '../../../constants'
import * as pages from '../../../pages'
import { Button, Form } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import { IOption } from '../../../services/admin/ItemService'
import { makeStyles } from '@material-ui/core'
import {
  Wrapper,
  Title,
  PanelWrapper,
  FormContainer,
  FormError,
  FormInputs,
  FormLabel,
  BasePanel,
  Loading,
} from '../_components'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { ja } from 'date-fns/locale'
import { createStore, destroyStore, showStore, updateStore, IInputStore } from '../../../services/admin/StoreService'
import { optionDeliveryTimes } from '../../orders/components/OrderAddresses'
import ChipInput from 'material-ui-chip-input'
import { InputImage } from '../../_components'

interface IParams {
  id: string
}

interface IProps {
  params: IParams
  categories: IOption[]
  traders: IOption[]
}

const Index: React.FC<IProps> = (props) => {
  const [loading, setLoading] = React.useState(true)
  const [processing, setProcessing] = React.useState(false)
  const [data, setData] = React.useState(null)
  const classes = useStyles()

  React.useEffect(() => {
    register('items', {})

    const f = async () => {
      if (props.params.id) {
        try {
          const { json } = await showStore(props.params.id)
          setData(json)
          setValue('items', json.item_values)
          setStates({ untilAt: json.until_at, deliveryAt: json.delivery_at })

          // setStates({ publicDate: json.public_date })
        } catch (e) {
          window.flashMessages.addErrorMessage('データが取得できませんでした')
        }
      }
      setLoading(false)
    }
    if (!data) {
      f()
    }
  }, [])

  // form
  const { register, handleSubmit, setValue, errors, control } = useForm<IInputStore>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const handleItemSelect = (values: IOption[]) => {
    setValue('items', values)
  }

  const onSubmit = async (inputData: IInputStore) => {
    console.log(inputData)
    setProcessing(true)
    try {
      if (data) {
        await updateStore(inputData)
      } else {
        await createStore(inputData)
      }
      window.location.href = pages.ADMIN_STORES
    } catch (e) {
      setProcessing(false)
      window.flashMessages.addErrorMessage('エラーが発生しました')
    }
  }

  const handleDelete = async (id: string) => {
    if (confirm('本当に削除しますか？')) {
      setProcessing(true)
      try {
        await destroyStore(id)
        window.location.href = pages.ADMIN_STORES
      } catch (e) {
        setProcessing(false)
        window.flashMessages.addErrorMessage('エラーが発生しました')
      }
    }
  }

  // states
  const [states, setStates] = React.useState({
    deliveryAt: null,
    untilAt: null,
  })

  const handleDateChange = (key: 'deliveryAt' | 'untilAt', date: Date) => {
    switch (key) {
      case 'untilAt':
        setStates({ ...states, untilAt: date })
        setValue('until_at', date ? date.toString() : '')
        break

      case 'deliveryAt':
        setStates({ ...states, deliveryAt: date })
        setValue('delivery_at', date ? date.toString() : '')
    }
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
        <Wrapper active="store">
          <Title
            back={pages.ADMIN_STORES}
            title={`専用ストアページ${data ? '更新' : '登録'}`}
            action={
              <Button variant="primary" type="submit" size="sm" style={{ color: 'white' }} disabled={processing}>
                {data ? '更新' : '登録'}
              </Button>
            }
          />
          {loading ? (
            <Loading />
          ) : (
            <PanelWrapper>
              <BasePanel>
                <>
                  {/* {data && ( */}
                  <>
                    {data && <input type="hidden" name="id" defaultValue={data.id} ref={register({})} />}
                    <div className="panelBody__formContainer">
                      <div></div>
                      <div
                        style={{
                          padding: 8,
                          fontSize: 12,
                        }}>
                        店舗情報
                      </div>

                      <FormContainer>
                        <FormLabel required={true}>店舗名</FormLabel>
                        <FormInputs>
                          <Form.Control
                            name="name"
                            defaultValue={data && data.name}
                            ref={register({
                              required: '必須です',
                            })}
                            autoComplete="nope"></Form.Control>
                          <FormError error={errors.name} />
                        </FormInputs>
                      </FormContainer>

                      <FormContainer>
                        <FormLabel>説明</FormLabel>
                        <FormInputs>
                          <Form.Control
                            name="description"
                            defaultValue={data && data.description}
                            ref={register({})}
                            autoComplete="nope"
                            as="textarea"
                            row="5"></Form.Control>
                        </FormInputs>
                      </FormContainer>

                      <div
                        style={{
                          borderTop: '1px solid #ccc',
                          width: '100%',
                          padding: 8,
                          fontSize: 12,
                          marginTop: 8,
                        }}>
                        オプション設定
                      </div>

                      <FormContainer>
                        <FormLabel>添え書き</FormLabel>
                        <FormInputs>
                          <Controller
                            name="soegaki"
                            control={control}
                            defaultValue={data && data.soegaki && data.soegaki.split(',')}
                            render={({ onChange }) => (
                              <ChipInput
                                variant="outlined"
                                defaultValue={data && data.soegaki && data.soegaki.split(',')}
                                onChange={(chips) => onChange(chips)}
                                newChipKeys={[]}
                                style={{ width: '100%' }}
                                classes={{
                                  root: classes.chipRoot,
                                }}
                              />
                            )}
                          />
                        </FormInputs>
                      </FormContainer>

                      <FormContainer>
                        <FormLabel>添え書きラベル</FormLabel>
                        <FormInputs>
                          <Controller
                            name="soegaki_label"
                            control={control}
                            defaultValue={data && data.soegaki_label}
                            render={({ name, onChange, value }) => (
                              <Form.Control
                                name={name}
                                value={value}
                                autoComplete="nope"
                                placeholder=""
                                onChange={onChange}
                              />
                            )}
                          />
                          <FormError error={errors.soegaki_label} />
                        </FormInputs>
                      </FormContainer>

                      <FormContainer>
                        <FormLabel>お祝いの言葉を非表示にする</FormLabel>
                        <FormInputs>
                          <Form.Check
                            type="checkbox"
                            defaultChecked={data && data.disable_congrat}
                            name="disable_congrat"
                            ref={register({})}
                            label=""
                          />
                        </FormInputs>
                      </FormContainer>

                      <FormContainer>
                        <FormLabel>請求書払いを非表示にする</FormLabel>
                        <FormInputs>
                          <Form.Check
                            type="checkbox"
                            defaultChecked={data && data.disable_invoice}
                            name="disable_invoice"
                            ref={register({})}
                            label=""
                          />
                        </FormInputs>
                      </FormContainer>

                      <div
                        style={{
                          borderTop: '1px solid #ccc',
                          width: '100%',
                          padding: 8,
                          fontSize: 12,
                          marginTop: 8,
                        }}>
                        オーナー情報
                      </div>

                      <FormContainer>
                        <FormLabel>オーナー名</FormLabel>
                        <FormInputs style={{ width: 200 }}>
                          <Form.Control
                            name="owner_name"
                            defaultValue={data && data.owner_name}
                            ref={register({
                              // required: '必須です',
                            })}
                            autoComplete="nope"
                            placeholder=""></Form.Control>
                          <FormError error={errors.owner_name} />
                        </FormInputs>
                      </FormContainer>

                      <FormContainer>
                        <FormLabel>オーナー説明</FormLabel>

                        <FormInputs>
                          <Form.Control
                            name="owner_description"
                            defaultValue={data && data.owner_description}
                            ref={register({})}
                            autoComplete="nope"
                            as="textarea"
                            row="5"></Form.Control>
                        </FormInputs>
                      </FormContainer>

                      <div
                        style={{
                          borderTop: '1px solid #ccc',
                          width: '100%',
                          padding: 8,
                          fontSize: 12,
                          marginTop: 8,
                        }}>
                        受取情報
                      </div>
                      <FormContainer>
                        <FormLabel required={true}>受取人名</FormLabel>
                        <FormInputs style={{ width: 200 }}>
                          <Form.Control
                            name="recipient"
                            defaultValue={data && data.recipient}
                            ref={register({
                              required: '必須です',
                            })}
                            autoComplete="nope"></Form.Control>
                          <FormError error={errors.recipient} />
                        </FormInputs>
                      </FormContainer>

                      <FormContainer>
                        <FormLabel required={true}>郵便番号</FormLabel>
                        <FormInputs style={{ width: 200 }}>
                          <Form.Control
                            name="zipcode"
                            defaultValue={data && data.zipcode}
                            ref={register({
                              required: '必須です',
                            })}
                            autoComplete="nope"
                            placeholder=""></Form.Control>
                          <FormError error={errors.zipcode} />
                        </FormInputs>
                      </FormContainer>

                      <FormContainer>
                        <FormLabel required={true}>都道府県</FormLabel>
                        <FormInputs>
                          <Form.Control
                            name="prefecture"
                            defaultValue={data && data.prefecture}
                            ref={register({
                              required: '必須です',
                            })}
                            autoComplete="nope"
                            placeholder=""></Form.Control>
                          <FormError error={errors.prefecture} />
                        </FormInputs>
                      </FormContainer>

                      <FormContainer>
                        <FormLabel required={true}>住所</FormLabel>
                        <FormInputs>
                          <Form.Control
                            name="address1"
                            defaultValue={data && data.address1}
                            ref={register({
                              required: '必須です',
                            })}
                            autoComplete="nope"
                            placeholder=""></Form.Control>
                          <FormError error={errors.address1} />
                        </FormInputs>
                      </FormContainer>

                      <FormContainer>
                        <FormLabel>住所詳細</FormLabel>
                        <FormInputs>
                          <Form.Control
                            name="address2"
                            defaultValue={data && data.address2}
                            ref={register({
                              // required: '必須です',
                            })}
                            autoComplete="nope"
                            placeholder=""></Form.Control>
                          <FormError error={errors.address2} />
                        </FormInputs>
                      </FormContainer>

                      <FormContainer>
                        <FormLabel required={true}>電話番号</FormLabel>
                        <FormInputs>
                          <Form.Control
                            name="phone"
                            defaultValue={data && data.phone}
                            ref={register({
                              required: '必須です',
                            })}
                            autoComplete="nope"
                            placeholder=""></Form.Control>
                          <FormError error={errors.phone} />
                        </FormInputs>
                      </FormContainer>

                      <FormContainer>
                        <FormLabel required={true}>受け取り希望日</FormLabel>
                        <FormInputs style={{ width: 200 }}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>
                            <KeyboardDatePicker
                              className={classes.datePicker}
                              margin="normal"
                              id="date-picker-dialog"
                              format="yyyy/MM/dd"
                              value={states.deliveryAt}
                              minDate={new Date()}
                              onChange={(value) => {
                                handleDateChange('deliveryAt', value)
                              }}
                              placeholder="2020/01/01"
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              autoOk={true}
                              disableToolbar={true}
                              minDateMessage="選択可能な日付をお選びください"
                              maxDateMessage="選択可能な日付をお選びください"
                              invalidDateMessage="日付のフォーマットが不正です"
                              InputProps={{
                                disableUnderline: true,
                              }}
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                              name="delivery_at"
                              inputRef={register({
                                required: '必須です',
                              })}
                              autoComplete="nope"
                            />
                          </MuiPickersUtilsProvider>
                          <FormError error={errors.until_at} />
                        </FormInputs>
                      </FormContainer>

                      <FormContainer>
                        <FormLabel required={true}>受け取り時刻(From)</FormLabel>
                        <FormInputs style={{ width: 200 }}>
                          <Controller
                            name="delivery_time_from"
                            control={control}
                            rules={{ required: '受け取り時刻は必須です' }}
                            defaultValue={data && data.delivery_time_from}
                            render={({ onChange, value, name, ref }) => (
                              <Select
                                closeMenuOnSelect={false}
                                className="reactSelect"
                                placeholder="選択してください"
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary25: constants.MAIN_COLOR,
                                    primary: constants.MAIN_COLOR,
                                  },
                                })}
                                options={optionDeliveryTimes}
                                value={optionDeliveryTimes.find((c) => c.value === value)}
                                onChange={(val) => {
                                  onChange(val.value)
                                }}
                              />
                            )}
                          />
                          <FormError error={errors.delivery_time_from} />
                        </FormInputs>
                      </FormContainer>

                      <FormContainer>
                        <FormLabel required={true}>受け取り時刻(To)</FormLabel>
                        <FormInputs style={{ width: 200 }}>
                          <Controller
                            name="delivery_time_to"
                            control={control}
                            rules={{ required: '受け取り時刻は必須です' }}
                            defaultValue={data && data.delivery_time_to}
                            render={({ onChange, value, name, ref }) => (
                              <Select
                                closeMenuOnSelect={false}
                                className="reactSelect"
                                placeholder="選択してください"
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary25: constants.MAIN_COLOR,
                                    primary: constants.MAIN_COLOR,
                                  },
                                })}
                                options={optionDeliveryTimes}
                                value={optionDeliveryTimes.find((c) => c.value === value)}
                                onChange={(val) => {
                                  onChange(val.value)
                                }}
                              />
                            )}
                          />
                          <FormError error={errors.delivery_time_to} />
                        </FormInputs>
                      </FormContainer>

                      <div
                        style={{
                          borderTop: '1px solid #ccc',
                          width: '100%',
                          padding: 8,
                          fontSize: 12,
                          marginTop: 8,
                        }}>
                        商品情報
                      </div>

                      <FormContainer>
                        <FormLabel>商品</FormLabel>
                        <FormInputs>
                          <Select
                            closeMenuOnSelect={false}
                            defaultValue={data && data.item_values}
                            className="reactSelect"
                            placeholder="選択してください"
                            isMulti={true}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: constants.MAIN_COLOR,
                                primary: constants.MAIN_COLOR,
                              },
                            })}
                            options={props.items}
                            onChange={handleItemSelect}
                          />
                        </FormInputs>
                      </FormContainer>

                      <div
                        style={{
                          borderTop: '1px solid #ccc',
                          width: '100%',
                          padding: 8,
                          fontSize: 12,
                          marginTop: 8,
                        }}>
                        その他情報
                      </div>

                      <FormContainer>
                        <FormLabel>サイトURL</FormLabel>
                        <FormInputs>
                          <Form.Control
                            name="site_url"
                            defaultValue={data && data.site_url}
                            ref={register({
                              // required: '必須です',
                            })}
                            autoComplete="nope"
                            placeholder=""></Form.Control>
                          <FormError error={errors.site_url} />
                        </FormInputs>
                      </FormContainer>

                      <FormContainer>
                        <FormLabel>Youtube iframeタグ</FormLabel>
                        <FormInputs>
                          <Form.Control
                            name="video_url1"
                            defaultValue={data && data.video_url1}
                            ref={register({})}
                            autoComplete="nope"
                            placeholder=""></Form.Control>
                          <FormError error={errors.video_url1} />
                        </FormInputs>
                      </FormContainer>

                      <div
                        style={{
                          borderTop: '1px solid #ccc',
                          width: '100%',
                          padding: 8,
                          fontSize: 12,
                          marginTop: 8,
                        }}>
                        公開設定
                      </div>

                      <FormContainer>
                        <FormLabel required={true}>公開期限</FormLabel>
                        <FormInputs style={{ width: 200 }}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>
                            <KeyboardDatePicker
                              className={classes.datePicker}
                              margin="normal"
                              id="date-picker-dialog"
                              format="yyyy/MM/dd"
                              value={states.untilAt}
                              minDate={new Date()}
                              onChange={(value) => {
                                handleDateChange('untilAt', value)
                              }}
                              placeholder="2020/01/01"
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              autoOk={true}
                              disableToolbar={true}
                              minDateMessage="選択可能な日付をお選びください"
                              maxDateMessage="選択可能な日付をお選びください"
                              invalidDateMessage="日付のフォーマットが不正です"
                              InputProps={{
                                disableUnderline: true,
                              }}
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                              name="until_at"
                              inputRef={register({
                                required: '必須です',
                              })}
                              autoComplete="nope"
                            />
                          </MuiPickersUtilsProvider>
                          <FormError error={errors.until_at} />
                        </FormInputs>
                      </FormContainer>
                      {/* 
                      <FormContainer>
                        <FormLabel required={true}>非公開</FormLabel>
                        <FormInputs>
                          <Form.Check
                            type="checkbox"
                            defaultChecked={data && data.closed_at}
                            name="closed_at"
                            onChange={(event) => {
                              setValue('closed_at', event.currentTarget.checked)
                            }}
                            ref={register({})}
                            label=""
                          />
                        </FormInputs>
                      </FormContainer> */}

                      <FormContainer>
                        <FormLabel>ステータス</FormLabel>
                        <FormInputs style={{ width: 200 }}>
                          {/* <Form.Control
                            name="status"
                            defaultValue={data && data.status}
                            ref={register({
                              // required: '必須です',
                            })}
                            autoComplete="nope"
                            placeholder=""></Form.Control> */}
                          <Form.Control
                            name="status"
                            defaultValue={data && data.status}
                            ref={register({})}
                            autoComplete="nope"
                            as="select"
                            custom={true}>
                            {props.statuses.map((s, index) => (
                              <option key={`status-option-${index}`} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </Form.Control>
                        </FormInputs>
                      </FormContainer>

                      {/* <div
                        style={{
                          borderTop: '1px solid #ccc',
                          width: '100%',
                          padding: 8,
                          fontSize: 12,
                          marginTop: 8,
                        }}>
                        カラー設定
                      </div>

                      <FormContainer>
                        <FormLabel>メインテーマカラー</FormLabel>
                        <FormInputs style={{ width: 200 }}>
                          <Form.Control
                            name="color_theme_main"
                            defaultValue={data && data.color_theme_main}
                            ref={register({
                              // required: '必須です',
                            })}
                            autoComplete="nope"
                            placeholder=""></Form.Control>
                          <FormError error={errors.color_theme_main} />
                        </FormInputs>
                      </FormContainer>

                      <FormContainer>
                        <FormLabel>サブテーマカラー</FormLabel>
                        <FormInputs style={{ width: 200 }}>
                          <Form.Control
                            name="color_theme_sub"
                            defaultValue={data && data.color_theme_sub}
                            ref={register({
                              // required: '必須です',
                            })}
                            autoComplete="nope"
                            placeholder=""></Form.Control>
                          <FormError error={errors.color_theme_sub} />
                        </FormInputs>
                      </FormContainer>

                      <FormContainer>
                        <FormLabel>サブサブテーマカラー</FormLabel>
                        <FormInputs style={{ width: 200 }}>
                          <Form.Control
                            name="color_theme_extra"
                            defaultValue={data && data.color_theme_extra}
                            ref={register({
                              // required: '必須です',
                            })}
                            autoComplete="nope"
                            placeholder=""></Form.Control>
                          <FormError error={errors.color_theme_extra} />
                        </FormInputs>
                      </FormContainer>

                      <FormContainer>
                        <FormLabel>メインテキストカラー</FormLabel>
                        <FormInputs style={{ width: 200 }}>
                          <Form.Control
                            name="color_text_main"
                            defaultValue={data && data.color_text_main}
                            ref={register({
                              // required: '必須です',
                            })}
                            autoComplete="nope"
                            placeholder=""></Form.Control>
                          <FormError error={errors.color_text_main} />
                        </FormInputs>
                      </FormContainer>

                      <FormContainer>
                        <FormLabel>サブテキストカラー</FormLabel>
                        <FormInputs style={{ width: 200 }}>
                          <Form.Control
                            name="color_text_sub"
                            defaultValue={data && data.color_text_sub}
                            ref={register({
                              // required: '必須です',
                            })}
                            autoComplete="nope"
                            placeholder=""></Form.Control>
                          <FormError error={errors.color_text_sub} />
                        </FormInputs>
                      </FormContainer>

                      <FormContainer>
                        <FormLabel>サブサブテキストカラー</FormLabel>
                        <FormInputs style={{ width: 200 }}>
                          <Form.Control
                            name="color_text_extra"
                            defaultValue={data && data.color_text_extra}
                            ref={register({
                              // required: '必須です',
                            })}
                            autoComplete="nope"
                            placeholder=""></Form.Control>
                          <FormError error={errors.color_text_extra} />
                        </FormInputs>
                      </FormContainer> */}

                      <div
                        style={{
                          borderTop: '1px solid #ccc',
                          width: '100%',
                          padding: 8,
                          fontSize: 12,
                          marginTop: 8,
                        }}>
                        画像設定
                      </div>

                      <div className="mb-4 pr-2 w-full">
                        <FormLabel>スライド画像</FormLabel>
                        <InputImage name="image_slide1" control={control} imageUrl={data && data.image_slide1_url} />
                        <InputImage name="image_slide2" control={control} imageUrl={data && data.image_slide2_url} />
                        <InputImage name="image_slide3" control={control} imageUrl={data && data.image_slide3_url} />
                      </div>

                      <div className="mb-4 pr-2 w-full">
                        <FormLabel>店舗画像</FormLabel>
                        <InputImage name="image_store" control={control} imageUrl={data && data.image_store_url} />
                      </div>

                      <div className="mb-4 pr-2 w-full">
                        <FormLabel>オーナー画像</FormLabel>
                        <InputImage name="image_owner" control={control} imageUrl={data && data.image_owner_url} />
                      </div>
                    </div>
                  </>
                  {data && (
                    <div className="panelBody__delete">
                      <Button
                        variant="danger"
                        onClick={() => {
                          handleDelete(data.id)
                        }}
                        disabled={processing}>
                        削除
                      </Button>
                    </div>
                  )}
                </>
              </BasePanel>
            </PanelWrapper>
          )}
        </Wrapper>
      </form>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme) => ({
  chipRoot: {
    '& .MuiInputBase-root': {
      height: '100%',
    },

    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d8efe3 !important',
      boxShadow: '0 0 0 0.2rem rgba(126, 202, 160, 0.25)',
    },

    '& .MuiOutlinedInput-input': {
      height: 26,
    },
  },

  datePicker: {
    width: '100%',
    '& input': {
      paddingLeft: 12,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      border: '1px solid #aaa',
      borderRight: 'none !important',
      background: '#fff',
      height: 35,
      fontFamily: 'initial',
    },

    '& .MuiInput-root': {
      height: 50,
    },

    '& .MuiInputAdornment-root': {
      margin: 0,
    },

    '& .muibuttonbase-root': {
      margin: '0 !important',
    },

    '& button': {
      border: '1px solid #aaa !important',
      borderRadius: 0,
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
    },

    '& p': {
      paddingLeft: 8,
    },
  },
}))

export default Index
