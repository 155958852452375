import * as React from 'react'
import * as pages from '../../../pages'
import {
  Wrapper,
  Pagination,
  Title,
  PanelWrapper,
  BasePanel,
  Badge,
  TableOverlayRow,
  TableOverlayButtons,
  TableCellImage,
} from '../_components'
import { index } from '../../../services/admin/ItemService'
import { Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

interface IProps {
  params: IParams
}

interface IParams {
  page: string
}

const Index: React.FC<IProps> = (props) => {
  const [data, setData] = React.useState(null)
  const [pagination, setPagination] = React.useState<any | null>(null)

  React.useEffect(() => {
    fetch()
  }, [])

  const fetch = async (page?: string) => {
    try {
      const { json, meta } = await index(page)
      setData(json)
      console.log(json)
      setPagination(meta)
    } catch (e) {
      window.flashMessages.addErrorMessage('データが取得できませんでした')
    }
  }

  const onChangePageHandler = async (page) => {
    fetch(page)
  }

  return (
    <React.Fragment>
      <Wrapper active="item">
        <Title
          title={'アイテム一覧'}
          action={
            <Button
              color="primary"
              size="small"
              variant="outlined"
              onClick={() => {
                window.location.href = pages.ADMIN_ITEMS_NEW
              }}>
              新規登録
            </Button>
          }
        />
        <PanelWrapper>
          <BasePanel>
            {data && (
              <>
                {data.length > 0 ? (
                  <>
                    <Table className="admin-table">
                      <TableHead>
                        <TableRow>
                          <TableCell>イメージ</TableCell>
                          <TableCell className="w-250">カテゴリ</TableCell>
                          <TableCell className="w-200">商品名</TableCell>
                          <TableCell className="text-right">価格</TableCell>
                          <TableCell className="text-right">作業日数</TableCell>
                          <TableCell>業者</TableCell>
                          <TableCell className="text-right">在庫数</TableCell>
                          <TableCell className="text-right">表示順</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.map((d, index) => (
                          <TableOverlayRow key={`order-${index}`}>
                            <TableCell className=" ">
                              <TableCellImage src={d.image_url} />
                            </TableCell>

                            <TableCell className="w-250">
                              <div className="flex flex-wrap">
                                {d.categories.map((c, cIndex) => (
                                  <Badge
                                    key={`item-category-${d.id}-${cIndex}`}
                                    title={c}
                                    style={{ marginRight: 4, marginBottom: 4 }}
                                  />
                                ))}
                              </div>
                            </TableCell>
                            <TableCell className=" name">
                              <a href={pages.ITEMS_SHOW(d.slug)}>{d.name}</a>
                            </TableCell>
                            <TableCell className="text-right text-nowrap">¥ {d.price.toLocaleString()}</TableCell>
                            <TableCell className="text-right">
                              {d.delivery_days ? `${d.delivery_days}日` : '-'}
                            </TableCell>
                            <TableCell className=" ">{d.trader && d.trader.name}</TableCell>
                            <TableCell className="text-right">{d.stock}</TableCell>
                            <TableCell className="text-right">{d.position}</TableCell>

                            <TableCell className="text-nowrap ">
                              <TableOverlayButtons>
                                <IconButton
                                  color="primary"
                                  size="small"
                                  style={{
                                    marginRight: 8,
                                  }}
                                  onClick={() => {
                                    window.location.href = pages.ADMIN_ITEMS_EDIT(d.id)
                                  }}>
                                  <EditIcon fontSize="small" />
                                </IconButton>

                                <Button
                                  color="primary"
                                  variant="outlined"
                                  size="small"
                                  className="text-underline"
                                  onClick={() => {
                                    window.location.href = pages.ADMIN_ITEMS_REVIEW(d.id)
                                  }}>
                                  レビュー
                                </Button>
                              </TableOverlayButtons>
                            </TableCell>
                          </TableOverlayRow>
                        ))}
                      </TableBody>
                    </Table>

                    {pagination && (
                      <Pagination
                        onChangePageHandler={onChangePageHandler}
                        currentPage={pagination.current_page}
                        prevPage={pagination.prev_page}
                        nextPage={pagination.next_page}
                        totalPages={pagination.total_pages}
                        totalCount={pagination.total_count}
                      />
                    )}
                  </>
                ) : (
                  <div style={{ padding: '16px 0', textAlign: 'center' }}>データがありません</div>
                )}
              </>
            )}
          </BasePanel>
        </PanelWrapper>
      </Wrapper>
    </React.Fragment>
  )
}

export default Index
