import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../../constants'
import * as pages from '../../../pages'
import { InputGroup, Button, Form } from 'react-bootstrap'
import { Wrapper, Pagination, Title, PanelWrapper, BasePanel } from '../_components'
import { Header, Footer } from '../../_components'
import { useForm } from 'react-hook-form'
import { show, update, destroy, IInputUpdate } from '../../../services/admin/CongratService'

interface IParams {
  id: string
}

interface IProps {
  params: IParams
}

const Index: React.FC<IProps> = (props) => {
  const [processing, setProcessing] = React.useState(false)
  const [data, setData] = React.useState(null)
  const [previewImage, setPreviewImage] = React.useState(null)

  React.useEffect(() => {
    const f = async () => {
      try {
        const { json } = await show(props.params.id)
        setData(json)
      } catch (e) {
        window.flashMessages.addErrorMessage('データが取得できませんでした')
      }
    }
    if (!data) {
      f()
    }
  }, [])

  // form
  const { register, handleSubmit, setValue, errors, getValues, setError } = useForm<IInputUpdate>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = async (data: IInputUpdate) => {
    setProcessing(true)
    try {
      await update(data)
      window.location.href = pages.ADMIN_CONGRATS
    } catch (e) {
      setProcessing(false)
      window.flashMessages.addErrorMessage('エラーが発生しました')
    }
  }

  const handleDelete = async (id: string) => {
    if (confirm('本当に削除しますか？')) {
      setProcessing(true)
      try {
        await destroy(id)
        window.location.href = pages.ADMIN_CONGRATS
      } catch (e) {
        setProcessing(false)
        window.flashMessages.addErrorMessage('エラーが発生しました')
      }
    }
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
        <Wrapper active="congrat">
          <Title
            back={pages.ADMIN_CONGRATS}
            title={'お祝い文言編集'}
            action={
              <Button variant="primary" type="submit" size="sm" style={{ color: 'white' }} disabled={processing}>
                更新
              </Button>
            }
          />
          <PanelWrapper>
            <BasePanel>
              <PanelBody>
                {data && (
                  <>
                    <input type="hidden" name="id" defaultValue={data.id} ref={register({})} />
                    <div className="panelBody__formContainer">
                      <div className="panelBody__formContainer__inputContainer w100">
                        <label className="required">お祝い文言</label>
                        <Form.Control
                          name="name"
                          defaultValue={data.name}
                          ref={register({
                            required: 'お祝い文言は必須です',
                          })}
                          autoComplete="nope"></Form.Control>
                        {errors.name && <small>{errors.name.message}</small>}
                      </div>
                      <div className="panelBody__formContainer__inputContainer w25">
                        <label className="required">表示順</label>
                        <Form.Control
                          name="position"
                          defaultValue={data.position}
                          type="number"
                          ref={register({
                            required: '表示順は必須です',
                          })}
                          autoComplete="nope"></Form.Control>
                        {errors.position && <small>{errors.position.message}</small>}
                      </div>
                    </div>

                    <div className="panelBody__delete">
                      <Button
                        variant="danger"
                        onClick={() => {
                          handleDelete(data.id)
                        }}
                        disabled={processing}>
                        削除
                      </Button>
                    </div>
                  </>
                )}
              </PanelBody>
            </BasePanel>
          </PanelWrapper>
        </Wrapper>
      </form>
    </React.Fragment>
  )
}

const PanelBody = styled.div`
  .panelBody {
    &__formContainer {
      &__inputContainer {
        &__img {
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px;

          img {
            height: 100%;
          }

          &__no {
            height: 100%;
            width: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #e5e5e5;
            color: #999;
          }
        }
      }
    }
  }
`

export default Index
