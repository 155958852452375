import { makeStyles } from '@material-ui/core'
import * as React from 'react'
import { constants } from '../../_assets'

interface Props {}

export const Breadcrumbs: React.FC<Props> = (props) => {
  const classes = useStyles()
  return <div className={classes.breadcrumbs}>{props.children}</div>
}

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    marginTop: 8,
    marginBottom: 8,
    fontWeight: 'bold',

    // padding: '4px 84px',
    [theme.breakpoints.down('sm')]: {
      padding: '4px 16px',
    },
    width: '100%',
    // backgroundColor: '#f5f5f5',
    fontSize: 12,

    '& a': {
      fontWeight: 'bold',
      color: '#ccc',
    },
  },
}))
