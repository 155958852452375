import { sendGet, sendPost, sendPut, sendDelete } from '../httpClient'
import { parseResourceData } from '../JsonApiSerializer'

export const index = async (page: string) => {
  try {
    const { data } = await sendGet(
      `/admin/api/traders${page ? '?page=' + page : ''}`
    )
    const json = parseResourceData(data.json)
    const meta = data.json.meta
    return { json, meta }
  } catch (e) {
    throw e.response
  }
}

export const show = async (id: string) => {
  try {
    const { data } = await sendGet('/admin/api/traders/' + id)
    const json = parseResourceData(data.json)
    return { json }
  } catch (e) {
    throw e.response
  }
}

export interface IInputCreate {
  name: string
  phone: string
  email: string
  holidays: string
}

export const create = async (formData: IInputCreate) => {
  const { name, phone, email, holidays } = formData

  try {
    const response = await sendPost('/admin/api/traders', {
      trader: {
        name,
        phone,
        email,
        holidays,
      },
    })
    return response
  } catch (e) {
    throw e.response
  }
}

export interface IInputUpdate extends IInputCreate {
  id: string
}

export const update = async (formData: IInputUpdate) => {
  const { id, name, phone, email, holidays } = formData

  try {
    const response = await sendPut('/admin/api/traders/' + id, {
      trader: {
        name,
        phone,
        email,
        holidays,
      },
    })
    return response
  } catch (e) {
    throw e.response || e
  }
}

export const destroy = async (id: string) => {
  try {
    const response = await sendDelete('/admin/api/traders/' + id)
    return response
  } catch (e) {
    throw e.response
  }
}
