import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../constants'
import { Header, Footer, Loading } from '../_components'
import Menus from '../_components/Menus'
import { getUser } from '../../services/UserService'
import IWindow from '../../interfaces/IWindow'
import { Refferral, Benefit } from './_components'

declare const window: IWindow

interface Props {
  params: any
}

const Index: React.FC<Props> = (props) => {
  const [user, setUser] = React.useState(null)

  React.useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    try {
      const fetchedData = await getUser()
      setUser(fetchedData)
    } catch (e) {
      window.flashMessages.addErrorMessage('プロフィール情報を取得できませんでした')
    }
  }

  return (
    <React.Fragment>
      <Header signedIn={true} />

      <Root>
        <div className="rootContainer">
          <Menus active="referral_code" />
          <div className="rootContainer__body">
            {!user ? (
              <Loading />
            ) : (
              <>
                <Refferral user={user} setUser={setUser} />
                <hr />
                <Benefit user={user} setUser={setUser} />
              </>
            )}
          </div>
        </div>
      </Root>

      <Footer />
    </React.Fragment>
  )
}

const Root = styled.div`
  background-color: ${constants.BG_COLOR_GRAY};
  min-height: calc(100vh - ${constants.HEIGHT_HEADER + constants.HEIGHT_FOOTER}px);
  display: flex;
  justify-content: center;
  padding: 32px 64px;

  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    padding: 32px 16px;
  }

  .rootContainer {
    width: 100%;
    display: flex;
    justify-content: center;

    &__body {
      padding: 0 16px;
      width: 100%;

      h3 {
        width: 100%;
        margin: 0 auto 16px;
        max-width: 760px;
      }

      &__mypageContainer {
        max-width: 760px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;

        &__formContainer {
          width: 100%;
          // padding: 8px;

          display: flex;
          justify-content: space-between;
          align-items: center;

          @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
            width: 100%;
          }

          // label {
          //   width: 100%;

          //   &.required {
          //     &:after {
          //       content: '（必須）';
          //       color: red;
          //       font-size: 12px;
          //     }
          //   }
          // }

          div {
            // margin-bottom: 16px;
            width: 100%;
            padding-right: 16px;
            input {
              height: 50px;
              border-radius: 0;
              background-color: #f2f2f2;
            }

            small {
              color: red;
            }
          }

          button {
            height: 50px;
            width: 100px;
            min-width: 100px;
            border-radius: 0;

            // margin: 32px 0;
            color: #fff;
          }
        }

        // &__buttons {
        //   width: 100%;
        //   justify-content: center;
        //   display: flex;
        //   button {
        //     height: 50px;
        //     width: 250px;

        //     border-radius: 0;

        //     margin: 32px 0;
        //     color: #fff;
        //   }
        // }
      }
    }
  }
`

export default Index
