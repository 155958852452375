import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../../constants'
import * as pages from '../../../pages'
import { create, show, update, destroy, IInputUpdate, IOption } from '../../../services/admin/ItemService'
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core'
import { Wrapper, Pagination, Title, PanelWrapper, BasePanel, Loading } from '../_components'
import { Header, Footer, SquareImage, DefaultButton } from '../../_components'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { ja } from 'date-fns/locale'
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation'
import { format } from 'date-fns'
import { ImagePreview } from '../../_components'
import { indexItemReview } from '../../../services/admin/ItemReviewService'
import { Rating } from '@material-ui/lab'

interface IParams {
  item_id: string
}

interface IProps {
  params: IParams
  categories: IOption[]
  traders: IOption[]
}

const Index: React.FC<IProps> = (props) => {
  const [loading, setLoading] = React.useState(true)
  const [processing, setProcessing] = React.useState(false)
  const [data, setData] = React.useState(null)
  const [reviews, setReviews] = React.useState(null)
  const classes = useStyles()
  const [pagination, setPagination] = React.useState<any>(null)

  React.useEffect(() => {
    const f = async () => {
      if (props.params.item_id) {
        try {
          const { json } = await show(props.params.item_id)
          setData(json)
          fetch()
        } catch (e) {
          window.flashMessages.addErrorMessage('データが取得できませんでした')
        }
      }
      setLoading(false)
    }
    if (!data) {
      f()
    }
  }, [])

  function createData(name: string, calories: number, fat: number, carbs: number, protein: number) {
    return { name, calories, fat, carbs, protein }
  }

  const fetch = async (page?: string) => {
    try {
      const { json, meta } = await indexItemReview(props.params.item_id, page)
      setReviews(json)
      setPagination(meta)
    } catch (e) {
      window.flashMessages.addErrorMessage('データが取得できませんでした')
    }
  }

  const onChangePageHandler = async (page) => {
    await fetch(page)
  }

  return (
    <React.Fragment>
      <Wrapper active="item">
        <Title
          back={pages.ADMIN_ITEMS}
          title={`アイテムレビュー`}
          action={
            <DefaultButton
              size="small"
              onClick={() => {
                window.location.href = pages.ADMIN_ITEMS_REVIEW_NEW(props.params.item_id)
              }}>
              登録
            </DefaultButton>
          }
        />
        {loading ? (
          <Loading />
        ) : (
          <>
            <PanelWrapper>
              <BasePanel>
                {/*  */}
                <div
                  style={{
                    padding: 16,
                    display: 'flex',
                  }}>
                  <ImagePreview src={data.image_1_url} size={100} />
                  <div style={{ padding: '0 8px' }}>{data.name}</div>
                </div>
              </BasePanel>
            </PanelWrapper>

            <PanelWrapper>
              <BasePanel>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>評価</TableCell>
                      <TableCell>タイトル</TableCell>
                      <TableCell>投稿者</TableCell>
                      <TableCell>内容</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reviews &&
                      reviews.map((row, index) => (
                        <TableRow key={`review-${index}`}>
                          <TableCell>
                            <Rating value={row.rating} readOnly={true} size="small" />
                          </TableCell>
                          <TableCell>
                            <div className={classes.dataColumns}>{row.nickname}</div>
                          </TableCell>
                          <TableCell>
                            <div className={classes.dataColumns}>{row.title}</div>
                          </TableCell>
                          <TableCell>
                            <div className={classes.dataColumns}>{row.body}</div>
                          </TableCell>
                          <TableCell align="right">
                            <DefaultButton
                              onClick={() => {
                                window.location.href = pages.ADMIN_ITEMS_REVIEW_EDIT(props.params.item_id, row.id)
                              }}>
                              編集
                            </DefaultButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </BasePanel>
            </PanelWrapper>

            {pagination && (
              <Pagination
                onChangePageHandler={onChangePageHandler}
                currentPage={pagination.current_page}
                prevPage={pagination.prev_page}
                nextPage={pagination.next_page}
                totalPages={pagination.total_pages}
                totalCount={pagination.total_count}
              />
            )}
          </>
        )}
      </Wrapper>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme) => ({
  //
  table: {
    //
  },
  dataColumns: {
    maxHeight: 100,
    overflow: 'hidden',
    maxWidth: 300,
  },
}))

export default Index
