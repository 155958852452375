import * as React from 'react'
import { CircularProgress, makeStyles, ThemeProvider } from '@material-ui/core'
import { constants, pages } from '../../_assets'
import { indexItemReview } from '../../services/ItemReviewService'
import { format } from 'date-fns'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { Rating } from '@material-ui/lab'
import { ImagePreview } from './ImagePreview'
import { DefaultThemeProvider } from '.'

interface Props {
  itemId: string
  showImage?: boolean
}

export const ReviewList = (props: Props) => {
  const classes = useStyles((theme) => ({}))

  const [data, setData] = React.useState(null)
  const [pagination, setPagination] = React.useState<null | any>(null)
  const [loading, setLoading] = React.useState(false)
  const [averageRating, setAverageRating] = React.useState(5)

  React.useEffect(() => {
    fetch()
  }, [])

  const fetch = async (page?: string) => {
    try {
      const { json: fetchedData, meta, averageRating: fetchedAverageRating } = await indexItemReview(props.itemId)
      setPagination(meta)
      setData(fetchedData)
      setAverageRating(fetchedAverageRating)
    } catch (e) {
      //
    }
  }

  const loadMore = async () => {
    setLoading(true)
    await new Promise((resolve) => setTimeout(resolve, 1000))

    try {
      const { json: fetchedData, meta } = await indexItemReview(props.itemId, pagination.next_page)
      setPagination(meta)
      setData([...data, ...fetchedData])
    } catch (e) {
      //
    }

    setLoading(false)
  }

  return (
    <DefaultThemeProvider>
      <>
        {data && data.length > 0 && (
          <div className={classes.root}>
            <div className={classes.rootTitle}>
              レビュー（{pagination.total_count}件）
              <Rating value={averageRating} size={'small'} readOnly={true} />
            </div>
            <div className={classes.rootBody}>
              {data.map((d, index) => (
                <ReviewItem key={`review-${index}`} item={d} showImage={props.showImage} />
              ))}

              {pagination.next_page && (
                <div className={classes.loadMore} onClick={loadMore}>
                  {loading ? <CircularProgress size={10} /> : <>さらに読み込む</>}
                </div>
              )}
            </div>
          </div>
        )}
      </>
    </DefaultThemeProvider>
  )
}

interface ItemProps {
  item: any
  showImage?: boolean
}

export const ReviewItem = (props: ItemProps) => {
  const d = props.item
  const classes = useStyles((theme) => ({}))

  const handleOnClickImage = () => {
    if (d.item_slug) {
      window.location.href = pages.ITEMS_SHOW(d.item_slug)
    }
  }

  return (
    <div className={classes.listRootContainer}>
      <div className={classes.listLeftContainer}>
        <div>
          <AccountCircleIcon style={{ color: '#999', width: 50, height: 50 }} />
        </div>
        <div style={{ padding: '0 8px' }}>
          <div className={classes.title}>{d.title}</div>
          <div className={classes.nickname}>{d.nickname || '購入者'} 様</div>
          <Rating value={d.rating} size={'small'} readOnly={true} />
        </div>
      </div>
      <div className={classes.listRightContainer}>
        <div
          style={{
            marginRight: props.showImage ? 16 : 0,
            cursor: 'pointer',
          }}
          onClick={props.showImage ? handleOnClickImage : undefined}>
          {props.showImage && <ImagePreview src={d.default_image_url} />}
        </div>
        <div
          style={{
            maxWidth: 'calc(100% - 106px)',
          }}>
          <div className={classes.body}>{d.body}</div>
          <div className={classes.createdAt}>{format(new Date(d.created_at), 'yyyy年MM月dd日')}</div>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '0 32px',
  },

  rootTitle: {
    color: '#333',
    borderBottom: '1px solid #eee',
    padding: '8px 0',
    display: 'flex',
    alignItems: 'center',
  },
  rootBody: {
    minHeight: 300,
    maxHeight: 300,
    overflow: 'scroll',
    borderBottom: '1px solid #eee',
  },

  listRootContainer: {
    display: 'flex',
    padding: '32px 16px',
    borderBottom: '1px solid #eee',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  listLeftContainer: {
    width: 200,
    minWidth: 200,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 8,
    },
  },

  listRightContainer: {
    display: 'flex',
    maxWidth: '100%',
  },

  title: {
    fontSize: 14,
    color: '#555',
    fontWeight: 'bold',
    maxHeight: 110,
    overflow: 'hidden',
  },

  nickname: {
    fontSize: 12,
    color: '#888',
    maxHeight: 75,
    overflow: 'hidden',
  },

  body: {
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
  },

  createdAt: {
    fontSize: 14,
    color: '#888',
    whiteSpace: 'nowrap',
  },

  loadMore: {
    border: '1px solid #ddd',
    color: '#555',
    width: 200,
    height: 40,
    // background: none;
    transition: 'all 0.3s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '16px auto',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#eee',
    },
  },
}))
