import * as React from 'react'
import { constants } from '../../../_assets'
import { makeStyles } from '@material-ui/core'
import { DefaultButton } from '../../_components'
import { OrderInput } from '.'
import { IInputSignin, signin } from '../../../services/UserService'
import { Controller, useForm } from 'react-hook-form'

interface Props {
  setStep: (step: number) => void
}

export const OrderSessionsSignIn: React.FC<Props> = (props) => {
  const classes = useStyles()

  const { handleSubmit, control } = useForm<IInputSignin>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const onSubmit = async (data: IInputSignin) => {
    try {
      await signin(data)
      window.location.reload()
    } catch (e) {
      if (e.data?.error) {
        alert(`エラー\n${e.data.error}`)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <div>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ onChange, ref, value }) => (
            <OrderInput
              ref={ref}
              required={true}
              value={value}
              placeholder={'メールアドレス'}
              onChange={(e) => {
                onChange(e.target.value)
              }}
              type="email"
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({ onChange, ref, value }) => (
            <OrderInput
              ref={ref}
              required={true}
              value={value}
              placeholder={'パスワード'}
              onChange={(e) => {
                onChange(e.target.value)
              }}
              type="password"
            />
          )}
        />

        {/* TODO: handle password forgot */}

        <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
          <DefaultButton type="submit">ログイン</DefaultButton>
        </div>
      </div>
    </form>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    // color: '#0B2B4F',
    // backgroundColor: '#fff',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // borderRadius: '50%',
    // width: 30,
    // height: 30,
    // marginRight: 8,
  },
}))
