import * as React from 'react'
import { Button, ButtonProps, CircularProgress, makeStyles } from '@material-ui/core'
import { DefaultThemeProvider } from '.'

interface Props extends ButtonProps {
  buttonclassname?: string
  processing?: string
}

export const DefaultButton = (props: Props) => {
  const classes = useStyles((theme) => ({}))

  return (
    <DefaultThemeProvider>
      {props.processing === 'true' ? (
        <Button
          variant="outlined"
          color="primary"
          {...props}
          className={`${classes.root} ${props.buttonclassnamev ? props.buttonclassname : ''}`}
          disabled={true}>
          <CircularProgress size={16} />
        </Button>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          {...props}
          className={`${classes.root} ${props.buttonclassname ? props.buttonclassname : ''}`}
        />
      )}
    </DefaultThemeProvider>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: 'nowrap',
    height: 36,
    minWidth: 80,
  },
}))
