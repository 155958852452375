import * as React from 'react'
import { makeStyles, Slide, Theme, Button } from '@material-ui/core'
import { DefaultThemeProvider } from '../../_components'

interface Props {
  show: boolean
  to: string
}

export const ButtonBackToBefore: React.FC<Props> = (props) => {
  const classes = useStyles()

  const handleOnClick = () => {
    window.location.href = decodeURIComponent(props.to)
  }

  return (
    <DefaultThemeProvider>
      <Slide direction="up" in={props.show} mountOnEnter unmountOnExit>
        <div className={classes.slideContainer}>
          <Button variant={'outlined'} color={'primary'} onClick={handleOnClick}>
            ご利用中のサービスへ戻る
          </Button>
        </div>
      </Slide>
    </DefaultThemeProvider>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  slideContainer: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#fff',
    borderTop: '1px solid #e3e3e3',
    width: '100%',
    height: 70,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
