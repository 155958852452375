import { sendGet } from '../httpClient'
import { parseResourceData } from '../JsonApiSerializer'

export const index = async (page: string, yearMonth: string) => {
  try {
    const { data } = await sendGet(
      `/admin/api/month_sales?page=${page ? page : '1'}&yearMonth=${
        yearMonth ? yearMonth : ''
      }`
    )

    const json = parseResourceData(data.json)
    const minDate = data.min_date
    const meta = data.json.meta
    const num_user = data.num_user
    const num_order = data.num_order
    const num_item = data.num_item
    const total = data.total
    return {
      json,
      minDate,
      meta,
      info: { num_user, num_order, num_item, total },
    }
  } catch (e) {
    throw e.response || e
  }
}
