import * as React from 'react'
import { constants } from '../../../_assets'
import { makeStyles } from '@material-ui/core'

interface IProps {
  style?: React.CSSProperties
  children: React.ReactNode
}

const Index: React.FC<IProps> = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root} style={props.style}>
      {props.children}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'calc(100% - 250px)',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export default Index
