import * as React from 'react'
import { constants } from '../../../_assets'
import { makeStyles } from '@material-ui/core'
import { ConfirmSection, ConfirmSectionContent, ConfirmSectionLabel } from './ConfirmSection'
import { FormOptions } from './OrderOptions'
import { FormAddresses } from './OrderAddresses'
import { Store } from '../../../services/StoreService'

interface Props {
  options: FormOptions
  disableCongrat?: boolean
  addresses?: FormAddresses
  store?: Store
}

export const ConfirmOptions: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <ConfirmSection title={'名札・オプションの設定'}>
      {props.options.soegaki ? (
        <>
          <ConfirmSectionLabel label={props.store?.soegaki_label || '添え書き'} />
          <ConfirmSectionContent content={props.options.soegaki} />
        </>
      ) : (
        <></>
      )}

      {props.addresses?.isAffectNameTag ? (
        <>
          <ConfirmSectionLabel label={'宛名'} />
          <ConfirmSectionContent content={`${props.addresses.recipient} 様`} />
        </>
      ) : (
        <></>
      )}

      {props.disableCongrat ? (
        <></>
      ) : (
        <>
          <ConfirmSectionLabel label={'お祝いの言葉'} />
          <ConfirmSectionContent
            content={props.options.congratLabel || '空欄'}
            gray={props.options.congratLabel ? false : true}
          />
        </>
      )}

      <ConfirmSectionLabel label={'名札'} />
      <ConfirmSectionContent
        content={props.options.nameTag || '名札無し'}
        gray={props.options.nameTag === '名札無し' ? true : false}
      />

      {props.options.wrapping ? (
        <>
          <ConfirmSectionLabel label={'ラッピング・花色'} />
          <ConfirmSectionContent content={props.options.wrapping} />
        </>
      ) : (
        <></>
      )}
    </ConfirmSection>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // justifyContent: 'center',
  },
}))
