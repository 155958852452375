import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../constants'
import * as pages from '../../pages'
import { Header, Footer } from '../_components'
import { Form, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { restore, IInputRestore } from '../../services/UserService'

interface Props {}

const Index: React.FC<Props> = () => {
  const [restored, setRestored] = React.useState<string>('')

  // form
  const { register, handleSubmit, setValue, errors, getValues, setError } = useForm<IInputRestore>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const onSubmit = async (data: IInputRestore) => {
    try {
      await restore(data)
      setRestored(data.email)
    } catch (e) {
      setError('email', { type: 'manual', message: e.data.error })
    }
  }

  return (
    <React.Fragment>
      <Header />

      <Root>
        <div className="rootContainer">
          <div className="signinContainer">
            <img src="/images/logo.svg" alt="logo" />
            {!restored ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="メールアドレス"
                    ref={register({ required: 'メールアドレスは必須です' })}
                  />
                  {errors.email && <small style={{ color: 'red' }}>{errors.email.message}</small>}
                </div>

                <Button type="submit" className="mb-2">
                  パスワード再発行
                </Button>
              </form>
            ) : (
              <Restored>
                <h3>再発行メールを送信しました</h3>
                <p>
                  {restored} 宛に確認メールを送信しました
                  <br />
                  送信された確認メール内のURLにアクセスし、
                  <br />
                  パスワードの変更をお願いいたします
                </p>
              </Restored>
            )}

            <div className="sessions">
              <a href={pages.SIGNIN}>ログインはこちら</a>
              <a href={pages.SIGNUP}>会員登録はこちら</a>
            </div>
          </div>
        </div>
      </Root>

      <Footer />
    </React.Fragment>
  )
}

const Root = styled.div`
  background-color: ${constants.BG_COLOR_GRAY};
  min-height: calc(100vh - ${constants.HEIGHT_HEADER + constants.HEIGHT_FOOTER}px);
  display: flex;
  justify-content: center;
  padding: 32px;

  .rootContainer {
    width: 100%;
    max-width: 400px;

    .signinContainer {
      background-color: #fff;
      // width: 400px;
      padding: 16px;
      box-shadow: 0 3px 8px rgba(50, 50, 50, 0.17);

      h3 {
        margin: 16px 0;
        text-align: center;
        font-size: 25px;
      }

      img {
        width: 100%;
        margin: 16px 0 32px;
        height: 60px;
        object-fit: contain;
      }

      form {
        div {
          margin-bottom: 16px;
          input {
            height: 50px;
            border-radius: 0;
            background-color: #f2f2f2;
          }
        }

        button {
          width: 100%;
          border-radius: 0;
          height: 50px;
          color: #fff;
        }
      }

      .sessions {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;

        a {
          width: 100%;
          margin-bottom: 4px;
        }
      }
    }
  }
`

const Restored = styled.div`
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
  h3 {
    color: ${constants.MAIN_COLOR};
    font-size: 20px;
    font-weight: bold;
  }

  p {
    font-size: 12px;
  }
`

export default Index
