import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../constants'
import * as pages from '../../pages'
import { Header, Footer } from '../_components'
import { Form, Button } from 'react-bootstrap'
import Menus from '../_components/Menus'
import { useForm } from 'react-hook-form'
import { IInputChangePassword, changePassword } from '../../services/UserService'
import IWindow from '../../interfaces/IWindow'

declare const window: IWindow

interface Props {
  //
}

const Index: React.FC<Props> = (props) => {
  // form
  const { register, handleSubmit, setValue, errors, getValues, setError } = useForm<IInputChangePassword>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const onSubmit = async (data: IInputChangePassword) => {
    try {
      await changePassword(data.password)
      window.flashMessages.addSuccessMessage('パスワードを更新しました')
    } catch (e) {
      window.flashMessages.addErrorMessage('エラーが発生しました')
    }
  }

  return (
    <React.Fragment>
      <Header signedIn={true} />

      <Root>
        <div className="rootContainer">
          <Menus active="change_password" />
          <div className="rootContainer__body">
            <h3>パスワードを変更</h3>

            <form onSubmit={handleSubmit(onSubmit)} className="rootContainer__body__mypageContainer">
              <div className="rootContainer__body__mypageContainer__formContainer">
                <label className="required"> 新しいパスワード</label>
                <Form.Control name="password" type="password" ref={register({ required: 'パスワードは必須です' })} />
                {errors.password && <small style={{ color: 'red' }}>{errors.password.message}</small>}
              </div>
              <div className="rootContainer__body__mypageContainer__formContainer">
                <label className="required">新しいパスワード（再入力）</label>
                <Form.Control
                  name="passwordRe"
                  type="password"
                  ref={register({
                    required: 'パスワード（再入力）は必須です',

                    validate: {
                      same: (value) =>
                        value === getValues('password') ||
                        'パスワードが一致しません。\nパスワードをご確認の上、もう一度入力し直してください。',
                    },
                  })}
                />
                {errors.passwordRe && (
                  <small style={{ color: 'red', whiteSpace: 'pre-wrap' }}>{errors.passwordRe.message}</small>
                )}
              </div>

              <div className="rootContainer__body__mypageContainer__buttons">
                <Button type="submit">更新</Button>
              </div>
            </form>
          </div>
        </div>
      </Root>

      <Footer />
    </React.Fragment>
  )
}

const Root = styled.div`
  background-color: ${constants.BG_COLOR_GRAY};
  min-height: calc(100vh - ${constants.HEIGHT_HEADER + constants.HEIGHT_FOOTER}px);
  display: flex;
  justify-content: center;
  padding: 32px 64px;

  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    padding: 32px 16px;
  }

  .rootContainer {
    width: 100%;
    display: flex;
    justify-content: center;

    &__body {
      padding: 0 16px;
      width: 100%;

      h3 {
        width: 100%;
        margin: 0 auto 16px;
        max-width: 760px;
      }

      &__mypageContainer {
        max-width: 760px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;

        &__formContainer {
          width: 50%;
          padding: 8px;
          @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
            width: 100%;
          }

          label {
            width: 100%;

            &.required {
              &:after {
                content: '（必須）';
                color: red;
                font-size: 12px;
              }
            }
          }

          input {
            width: 100%;
            height: 50px;
            border-radius: 0;
            border: 1px solid #aaa;
            margin-bottom: 8px;
          }
        }

        &__buttons {
          width: 100%;
          justify-content: center;
          display: flex;
          button {
            height: 50px;
            width: 250px;

            border-radius: 0;

            margin: 32px 0;
            color: #fff;
          }
        }
      }
    }

    //
  }
`

export default Index
