import * as React from 'react'
import { makeStyles } from '@material-ui/core'

interface Props {
  title: string
  styles?: React.CSSProperties
}

export const LabelWithLine: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root} style={props.style}>
      {props.title}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '&::before': {
      content: "''",
      flexGrow: 1,
      height: 1,
      display: 'block',
      marginRight: '0.4em',
      background: 'linear-gradient(-90deg, #aaa, transparent)',
    },

    '&::after': {
      content: "''",
      flexGrow: 1,
      height: 1,
      display: 'block',
      marginLeft: '0.4em',
      background: 'linear-gradient(90deg, #aaa, transparent)',
    },
  },
}))
