import * as React from 'react'
import { makeStyles } from '@material-ui/core'

interface IProps {
  src?: string
}

export const TableCellImage: React.FC<IProps> = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {props.src ? <img className={classes.image} src={props.src} alt="img" /> : <>No Image</>}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#eee',
    color: '#bbb',
    fontSize: 10,
    width: 70,
    height: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}))
