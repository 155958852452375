import * as React from 'react'
import * as pages from '../../../pages'
import { Wrapper, Pagination, Title, PanelWrapper, BasePanel } from '../_components'
import { indexItemReviews } from '../../../services/admin/ItemReviewService'
import { Rating } from '@material-ui/lab'
import { Button, makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'

interface IProps {
  params: IParams
}

interface IParams {
  page: string
}

const Index: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const [data, setData] = React.useState(null)
  const [pagination, setPagination] = React.useState<any | null>(null)

  React.useEffect(() => {
    fetch()
  }, [])

  const fetch = async (page?: string) => {
    try {
      const { json, meta } = await indexItemReviews(page)
      setData(json)
      setPagination(meta)
    } catch (e) {
      window.flashMessages.addErrorMessage('データが取得できませんでした')
    }
  }

  const onChangePageHandler = async (page) => {
    fetch(page)
  }

  return (
    <React.Fragment>
      <Wrapper active="itemReview">
        <Title title={'商品レビュー'} />
        <PanelWrapper>
          <BasePanel>
            {data && (
              <>
                {data.length > 0 ? (
                  <>
                    <Table className="admin-table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>評価</TableCell>
                          <TableCell>商品名</TableCell>
                          <TableCell>タイトル</TableCell>
                          <TableCell>投稿者</TableCell>
                          <TableCell>内容</TableCell>
                          <TableCell>ギフト</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.map((d, index) => (
                          <tr key={`order-${index}`}>
                            <TableCell className="">
                              <Rating value={d.rating} size="small" readOnly={true} />
                            </TableCell>
                            <TableCell className="">
                              <div className={classes.dataColumns}>
                                <a href={pages.ITEMS_SHOW(d.item.slug)} target="_blank">
                                  {d.item && d.item.name}
                                </a>
                              </div>
                            </TableCell>
                            <TableCell className="">
                              <div className={classes.dataColumns}>{d.title}</div>
                            </TableCell>
                            <TableCell className="">
                              <div className={classes.dataColumns}>{d.nickname}</div>
                            </TableCell>
                            <TableCell className="">
                              <div className={classes.dataColumns}>{d.body}</div>
                            </TableCell>

                            <TableCell className="">
                              <div className={classes.dataColumns}>{d.gifted_at ? <CheckRoundedIcon /> : <></>}</div>
                            </TableCell>

                            <TableCell className="">
                              <Button
                                color="primary"
                                variant="outlined"
                                size="small"
                                onClick={() => {
                                  window.location.href = pages.ADMIN_ITEMS_REVIEW_EDIT(
                                    d.item.id,
                                    d.id,
                                    pages.ADMIN_ITEM_REVIEWS,
                                  )
                                }}>
                                編集
                              </Button>
                            </TableCell>
                          </tr>
                        ))}
                      </TableBody>
                    </Table>

                    {pagination && (
                      <Pagination
                        onChangePageHandler={onChangePageHandler}
                        currentPage={pagination.current_page}
                        prevPage={pagination.prev_page}
                        nextPage={pagination.next_page}
                        totalPages={pagination.total_pages}
                        totalCount={pagination.total_count}
                      />
                    )}
                  </>
                ) : (
                  <div style={{ padding: '16px 0', textAlign: 'center' }}>データがありません</div>
                )}
              </>
            )}
          </BasePanel>
        </PanelWrapper>
      </Wrapper>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme) => ({
  dataColumns: {
    maxHeight: 100,
    overflow: 'hidden',
    maxWidth: 300,
  },
}))

export default Index
