import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../../constants'
import * as pages from '../../../pages'
import { InputGroup, Button, Form } from 'react-bootstrap'
import { Wrapper, Title, PanelWrapper, BasePanel } from '../_components'
import { Header, Footer } from '../../_components'
import { useForm } from 'react-hook-form'
import { create, IInputAdminCreate } from '../../../services/admin/AdminService'

interface Props {}

const Index: React.FC<Props> = () => {
  const [processing, setProcessing] = React.useState(false)
  // form
  const { register, handleSubmit, setValue, errors, getValues, setError } = useForm<IInputAdminCreate>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = async (data: IInputAdminCreate) => {
    setProcessing(true)
    try {
      await create(data)
      window.flashMessages.addSuccessMessage('管理者を追加しました')
      window.location.href = pages.ADMIN_ADMINS
    } catch (e) {
      setProcessing(false)
      window.flashMessages.addErrorMessage('エラーが発生しました')
    }
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
        <Wrapper active="admin">
          <Title
            back={pages.ADMIN_ADMINS}
            title={'管理者登録'}
            action={
              <Button variant="primary" type="submit" size="sm" style={{ color: 'white' }} disabled={processing}>
                登録
              </Button>
            }
          />
          <PanelWrapper>
            <BasePanel>
              <PanelBody>
                <div className="panelBody__formContainer">
                  <div className="panelBody__formContainer__inputContainer w50">
                    <label className="required">メールアドレス</label>
                    <Form.Control
                      name="email"
                      ref={register({
                        required: 'メールアドレスは必須です',
                      })}
                      autoComplete="nope"></Form.Control>
                    {errors.email && <small>{errors.email.message}</small>}
                  </div>
                </div>
              </PanelBody>
            </BasePanel>
          </PanelWrapper>
        </Wrapper>
      </form>
    </React.Fragment>
  )
}

const PanelBody = styled.div`
  .panelBody {
  }
`

export default Index
