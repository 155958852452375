import { sendDelete, sendPost, sendGet, sendPut } from '../httpClient'
import { parseResourceData } from '../JsonApiSerializer'

export const indexItemReviews = async (page?: string) => {
  try {
    const { data } = await sendGet(`/admin/api/item_reviews?${page ? 'page=' + page : ''}`)
    const json = parseResourceData(data.json)
    const meta = data.json.meta
    return { json, meta }
  } catch (e) {
    throw e.response
  }
}

export const indexItemReview = async (id: string, page?: string) => {
  try {
    const { data } = await sendGet(`/admin/api/item_reviews?id=${id}&${page ? 'page=' + page : ''}`)
    const json = parseResourceData(data.json)
    const meta = data.json.meta
    return { json, meta }
  } catch (e) {
    throw e.response
  }
}

export const showItemReview = async (id: string) => {
  try {
    const { data } = await sendGet('/admin/api/item_reviews/' + id)
    const json = parseResourceData(data.json)
    return { json }
  } catch (e) {
    throw e.response
  }
}

export interface IInputItemReview {
  id?: string
  itemId: string
  rating: number
  nickname: string
  title: string
  body: string
  is_delete: boolean
  is_gifted: boolean
  image: any
}

export const createItemReview = async (formData: IInputItemReview) => {
  const { itemId, rating, nickname, title, body, is_delete, image, is_gifted } = formData

  const params = new FormData()
  params.append('item[id]', itemId)
  params.append('item_review[rating]', rating)
  params.append('item_review[nickname]', nickname)
  params.append('item_review[title]', title)
  params.append('item_review[body]', body)
  if (image && image[0]) {
    params.append('item_review[image]', image[0])
  }
  params.append('other[is_delete]', is_delete)
  params.append('other[is_gifted]', is_gifted)

  try {
    const response = await sendPost('/admin/api/item_reviews', params)
    return response
  } catch (e) {
    throw e.response
  }
}

export const updateItemReview = async (formData: IInputItemReview) => {
  const { id, rating, nickname, title, body, is_delete, image, is_gifted } = formData

  const params = new FormData()
  params.append('item_review[rating]', rating)
  params.append('item_review[nickname]', nickname)
  params.append('item_review[title]', title)
  params.append('item_review[body]', body)
  if (image && image[0]) {
    params.append('item_review[image]', image[0])
  }
  params.append('other[is_delete]', is_delete)
  params.append('other[is_gifted]', is_gifted)

  try {
    const response = await sendPut(`/admin/api/item_reviews/${id}`, params)
    return response
  } catch (e) {
    throw e.response || e
  }
}

// export const destroy = async (id: string) => {
//   try {
//     const response = await sendDelete('/admin/api/items/' + id)
//     return response
//   } catch (e) {
//     throw e.response
//   }
// }
