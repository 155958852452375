import * as React from 'react'
import { makeStyles } from '@material-ui/core'

interface IProps {
  children: React.ReactNode
}

export const TableOverlayButtons: React.FC<IProps> = (props) => {
  const classes = useStyles()

  return (
    <div className={`${classes.buttonsOverlay} buttonsOverlay`}>
      <div className={classes.buttons}>{props.children}</div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  buttonsOverlay: {
    width: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    pointerEvents: 'none',
    opacity: 0,
    transitionDuration: '0.3s',
  },

  buttons: {
    background: 'white',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
    pointerEvents: 'auto',
  },
}))
