import { sendPost, sendPut, sendGet } from './httpClient'
import { parseResourceData } from './JsonApiSerializer'

export interface User {
  email: string
  is_personal_use: boolean
  company_name: string
  company_name_kana: string
  department: string
  username: string
  zipcode: string
  prefecture: string
  city: string
  address: string
  phone: string

  user_benefit?: UserBenefit
}

interface UserBenefit {
  benefit?: Benefit
}

interface Benefit {
  code?: string
  value?: number
  title?: string
  description?: string
  is_valid?: boolean
}

export interface IInputSignin {
  email: string
  password: string
}

export const signin = async (formData) => {
  const { email, password } = formData

  try {
    const response = await sendPost('/signin', {
      user: { email, password },
    })
    return true
  } catch (e) {
    throw e.response
  }
}

export interface IInputSignup {
  email: string
  password: string
  passwordRe: string
}

export const signup = async (formData, introducerCode?: string) => {
  const { email, password } = formData

  try {
    const response = await sendPost('/signup', {
      user: { email, password },
      introducer: { code: introducerCode || '' },
    })
    return response.data
  } catch (e) {
    throw e.response
  }
}

export interface IInputRestore {
  email: string
}

export const restore = async (formData: IInputRestore) => {
  const { email } = formData

  try {
    const response = await sendPost('/restore', {
      user: { email },
    })
    return response.data
  } catch (e) {
    throw e.response
  }
}

export interface IInputResetPassword {
  resetPasswordToken: string
  password: string
  passwordRe: string
}

export const resetPassword = async (formData: IInputResetPassword) => {
  const { resetPasswordToken, password } = formData

  try {
    const response = await sendPut('/reset_password', {
      user: { reset_password_token: resetPasswordToken, password },
    })
    return response.data
  } catch (e) {
    throw e.response
  }
}

export interface IInputConfirmation {
  email: string
}

export const confirmation = async (formData: IInputConfirmation) => {
  const { email } = formData

  try {
    const response = await sendPost('/confirm', {
      user: { email },
    })

    return response.data
  } catch (e) {
    throw e.response
  }
}

export const getUser = async (): Promise<User | undefined> => {
  try {
    const { data } = await sendGet('/api/mypages')
    const json = parseResourceData(data.json) as User
    return json
  } catch (e) {
    throw e.response || e
  }
}

export interface IInputMypage {
  is_personal_use: boolean
  company_name: string
  company_name_kana: string
  department: string
  username: string
  zipcode: string
  prefecture: string
  city: string
  address: string
  phone: string
}

export const updateMypage = async (formData: IInputMypage, guest_email?: string) => {
  const {
    is_personal_use,
    company_name,
    company_name_kana,
    department,
    username,
    zipcode,
    prefecture,
    city,
    address,
    phone,
  } = formData

  try {
    const response = await sendPut('/api/mypages', {
      user: {
        is_personal_use,
        company_name,
        company_name_kana,
        department,
        username,
        zipcode,
        prefecture,
        city,
        address,
        phone,
      },
      other: {
        guest_email: guest_email || '',
      },
    })

    return response.data
  } catch (e) {
    throw e.response
  }
}

export interface IInputReferralCode {
  referral_code: string
}

export const addReferralCode = async (formData: IInputReferralCode) => {
  const { referral_code } = formData

  try {
    const response = await sendPut('/api/referral_code', {
      user: {
        referral_code,
      },
    })

    return response.data
  } catch (e) {
    throw e.response
  }
}

export interface IInputChangePassword {
  password: string
  passwordRe: string
}

export const changePassword = async (password: string) => {
  try {
    const response = await sendPut('/api/change_password', {
      user: {
        password,
      },
    })

    return response.data
  } catch (e) {
    throw e.response
  }
}
