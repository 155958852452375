import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../../constants'
import * as pages from '../../../pages'
import { InputGroup, Button, Form } from 'react-bootstrap'
import {
  Wrapper,
  Pagination,
  Title,
  PanelWrapper,
  BasePanel,
  FormContainer,
  FormError,
  FormInputs,
  FormLabel,
  Loading,
} from '../_components'
import { Header, Footer } from '../../_components'
import { useForm } from 'react-hook-form'
import { create, update, IInputCreate, show } from '../../../services/admin/BenefitService'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { ja } from 'date-fns/locale'
import { makeStyles, CircularProgress } from '@material-ui/core'

interface IParams {
  id: string
}

interface IProps {
  params: IParams
}

const Index: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const [data, setData] = React.useState(null)

  React.useEffect(() => {
    const f = async () => {
      try {
        const { json } = await show(props.params.id)
        setData(json)
        setStates({
          ...states,
          loading: false,
          register_limit_date: json.register_limit_date,
          use_limit_date: json.use_limit_date,
        })
      } catch (e) {
        window.flashMessages.addErrorMessage('データが取得できませんでした')
      }
    }
    if (props.params.id && !data) {
      f()
    } else {
      setStates({ ...states, loading: false })
    }
  }, [])

  // form
  const { register, handleSubmit, setValue, errors, getValues, setError } = useForm<IInputCreate>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = async (data: IInputCreate) => {
    try {
      if (props.params.id) {
        await update({ ...data, id: props.params.id })
      } else {
        await create(data)
      }
      window.location.href = pages.ADMIN_BENEFITS
    } catch (e) {
      window.flashMessages.addErrorMessage('エラーが発生しました')
    }
  }

  // states
  const [states, setStates] = React.useState({
    loading: true,
    register_limit_date: null,
    use_limit_date: null,
  })

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
        <Wrapper active="benefit">
          <Title
            back={pages.ADMIN_BENEFITS}
            title={`特典コード${props.params.id ? '編集' : '登録'}`}
            action={
              <Button variant="primary" type="submit" size="sm" style={{ color: 'white' }}>
                {`${props.params.id ? '更新' : '登録'}`}
              </Button>
            }
          />
          <PanelWrapper>
            <BasePanel>
              {states.loading ? (
                <Loading />
              ) : (
                <PanelBody>
                  <div className="panelBody__formContainer">
                    <FormContainer>
                      <FormLabel>タイトル</FormLabel>
                      <FormInputs>
                        <Form.Control name="title" ref={register({})} defaultValue={data && data.title} />
                        <FormError error={errors.title} />
                      </FormInputs>
                    </FormContainer>

                    <FormContainer>
                      <FormLabel required={true}>特典コード</FormLabel>
                      <FormInputs>
                        <Form.Control
                          name="code"
                          ref={register({
                            required: '特典コードは必須です',
                          })}
                          defaultValue={data && data.code}
                        />
                        <FormError error={errors.code} />
                      </FormInputs>
                    </FormContainer>

                    <FormContainer>
                      <FormLabel required={true}>割引率（％）</FormLabel>
                      <FormInputs>
                        <Form.Control
                          name="value"
                          ref={register({
                            required: '割引率（％）は必須です',
                          })}
                          defaultValue={data && data.value}
                        />
                        <FormError error={errors.value} />
                      </FormInputs>
                    </FormContainer>

                    <FormContainer>
                      <FormLabel>登録有効期限</FormLabel>
                      <FormInputs style={{ width: 200 }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>
                          <KeyboardDatePicker
                            className={classes.datePicker}
                            margin="normal"
                            format="yyyy/MM/dd"
                            value={states.register_limit_date}
                            // minDate={new Date()}
                            onChange={(date: Date) => {
                              setStates({
                                ...states,
                                register_limit_date: date,
                              })
                              setValue('register_limit_date', date.toString())
                            }}
                            placeholder="2020/01/01"
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            autoOk={true}
                            disableToolbar={true}
                            minDateMessage="選択可能な日付をお選びください"
                            maxDateMessage="選択可能な日付をお選びください"
                            invalidDateMessage="日付のフォーマットが不正です"
                            InputProps={{
                              disableUnderline: true,
                            }}
                            style={{
                              marginTop: 0,
                              marginBottom: 0,
                            }}
                            name="register_limit_date"
                            inputRef={register({})}
                            autoComplete="nope"
                          />
                        </MuiPickersUtilsProvider>
                        <FormError error={errors.register_limit_date} />
                      </FormInputs>
                    </FormContainer>

                    <FormContainer>
                      <FormLabel>割引適用期限</FormLabel>
                      <FormInputs style={{ width: 200 }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>
                          <KeyboardDatePicker
                            className={classes.datePicker}
                            margin="normal"
                            format="yyyy/MM/dd"
                            value={states.use_limit_date}
                            // minDate={new Date()}
                            onChange={(date: Date) => {
                              setStates({ ...states, use_limit_date: date })
                              setValue('use_limit_date', date.toString())
                            }}
                            placeholder="2020/01/01"
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            autoOk={true}
                            disableToolbar={true}
                            minDateMessage="選択可能な日付をお選びください"
                            maxDateMessage="選択可能な日付をお選びください"
                            invalidDateMessage="日付のフォーマットが不正です"
                            InputProps={{
                              disableUnderline: true,
                            }}
                            style={{
                              marginTop: 0,
                              marginBottom: 0,
                            }}
                            name="use_limit_date"
                            inputRef={register({})}
                            autoComplete="nope"
                          />
                        </MuiPickersUtilsProvider>
                        <FormError error={errors.use_limit_date} />
                      </FormInputs>
                    </FormContainer>

                    <FormContainer>
                      <FormLabel>説明</FormLabel>
                      <Form.Control
                        name="description"
                        ref={register({})}
                        as="textarea"
                        row="5"
                        defaultValue={data && data.description}
                      />
                    </FormContainer>

                    {/* params.require(:benefit).permit(:code, :value, :title, :description, :register_limit_date, :use_limit_date) */}
                  </div>
                </PanelBody>
              )}
            </BasePanel>
          </PanelWrapper>
        </Wrapper>
      </form>
    </React.Fragment>
  )
}

const PanelBody = styled.div`
  .panelBody {
    &__formContainer {
      &__inputContainer {
        &__img {
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px;

          img {
            height: 100%;
          }

          &__no {
            height: 100%;
            width: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #e5e5e5;
            color: #999;
          }
        }
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
  datePicker: {
    width: '100%',
    '& input': {
      paddingLeft: 12,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      border: '1px solid #aaa',
      borderRight: 'none !important',
      background: '#fff',
      height: 35,
      fontFamily: 'initial',
    },

    '& .MuiInput-root': {
      height: 50,
    },

    '& .MuiInputAdornment-root': {
      margin: 0,
    },

    '& .muibuttonbase-root': {
      margin: '0 !important',
    },

    '& button': {
      border: '1px solid #aaa !important',
      borderRadius: 0,
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
    },

    '& p': {
      paddingLeft: 8,
    },
  },
}))

export default Index
