import { sendGet } from '../httpClient'
import { parseResourceData } from '../JsonApiSerializer'

export const index = async (page: string, yearMonth: string) => {
  try {
    const { data } = await sendGet(
      `/admin/api/introducer_sales?page=${page ? page : '1'}&yearMonth=${yearMonth ? yearMonth : ''}`,
    )

    const json = parseResourceData(data.json)
    const minDate = data.min_date
    const meta = data.json.meta
    return { json, minDate, meta }
  } catch (e) {
    throw e.response || e
  }
}

export const show = async (id: string, page: string, yearMonth: string) => {
  try {
    const { data } = await sendGet(
      `/admin/api/introducer_sales/${id}?page=${page ? page : '1'}&yearMonth=${yearMonth ? yearMonth : ''}`,
    )
    const json = parseResourceData(data.json)
    const minDate = data.min_date
    const introducer = parseResourceData(data.introducer)
    const meta = data.json.meta
    return { introducer, minDate, json, meta }
  } catch (e) {
    throw e.response || e
  }
}
