import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../../constants'
import * as pages from '../../../pages'
import { Button } from 'react-bootstrap'
import { Wrapper, Title, PanelWrapper, BasePanel } from '../_components'
import { Controller, useForm } from 'react-hook-form'
import { show, update, IInputUpdate } from '../../../services/admin/OrderService'
import { format } from 'date-fns'
import { FormError } from '../../_components'
import DeliveryMailModal from './_components/deliveryMailModal'
import Select from 'react-select'

interface IParams {
  id: string
}

interface IProps {
  params: IParams
}

const Index: React.FC<IProps> = (props) => {
  const [processing, setProcessing] = React.useState(false)
  const [data, setData] = React.useState(null)

  React.useEffect(() => {
    const f = async () => {
      try {
        const { json } = await show(props.params.id)
        setData(json)
        setOrderStatusOptions(constants.optionsOrderStatus(json.status))
      } catch (e) {
        window.flashMessages.addErrorMessage('データが取得できませんでした')
      }
    }
    if (!data) {
      f()
    }
  }, [])

  // form
  const { register, handleSubmit, errors, control } = useForm<IInputUpdate>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const [orderStatusOptions, setOrderStatusOptions] = React.useState([])

  const onSubmit = async (updateData: IInputUpdate) => {
    setProcessing(true)
    try {
      await update(updateData)
      setOrderStatusOptions(constants.optionsOrderStatus(updateData.status))
      setData({ ...data, status: updateData.status })
      if (updateData.status === constants.OrderStatus.Delivered) {
        setOpenDeliveryMailModal(true)
      }
      setProcessing(false)
      window.flashMessages.addSuccessMessage('更新しました')
    } catch (e) {
      setProcessing(false)
      window.flashMessages.addErrorMessage('エラーが発生しました')
    }
  }

  // modal
  const [openDeliveryMailModal, setOpenDeliveryMailModal] = React.useState(false)

  const handleCloseDeliveryMailModal = () => {
    setOpenDeliveryMailModal(!openDeliveryMailModal)
  }

  const handleOnSent = (deliveryNo: string) => {
    setData({
      ...data,
      order_delivery_note: {
        delivery_no: deliveryNo,
        send_at: new Date(),
      },
    })
  }

  return (
    <React.Fragment>
      {data && (
        <>
          <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
            <Wrapper active="order">
              <Title
                back={pages.ADMIN_ORDERS}
                title={'注文編集'}
                action={
                  <Button variant="primary" type="submit" size="sm" style={{ color: 'white' }} disabled={processing}>
                    更新
                  </Button>
                }
              />

              <PanelWrapper>
                <BasePanel>
                  <PanelBody>
                    <>
                      <h6>基本情報</h6>
                      <input type="hidden" name="id" defaultValue={data.id} ref={register({})} />
                      <div className="panelBody__formContainer">
                        <div className="w50" style={{ paddingRight: 32 }}>
                          <label className="required">ステータス</label>
                          {data.status === constants.OrderStatus.Delivered ? (
                            <p>配送済み</p>
                          ) : (
                            <>
                              {/* <Form.Control
                                name="status"
                                defaultValue={data.status}
                                ref={register({
                                  required: 'ステータスは必須です',
                                })}
                                autoComplete="nope"
                                as="select"
                                custom={true}>
                                {constants.optionsOrderStatus(data.status).map((option, index) => (
                                  <option key={`status-option-${option.value}-${index}`} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Form.Control> */}
                              <Controller
                                name="status"
                                control={control}
                                rules={{ required: 'ステータスは必須です' }}
                                defaultValue={data.status}
                                render={({ onChange, value }) => (
                                  <Select
                                    className="reactSelect"
                                    placeholder="選択してください"
                                    theme={(theme) => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        primary25: constants.MAIN_COLOR,
                                        primary: constants.MAIN_COLOR,
                                      },
                                    })}
                                    options={orderStatusOptions}
                                    value={orderStatusOptions.find((c) => c.value === value)}
                                    onChange={(val) => {
                                      onChange(val.value)
                                    }}
                                  />
                                )}
                              />

                              <FormError error={errors.status} />
                            </>
                          )}
                        </div>

                        <div className="w50">
                          <label>配送メール</label>
                          <p>
                            {data.status === constants.OrderStatus.Delivered ? (
                              <>
                                {data.order_delivery_note && data.order_delivery_note.send_at ? (
                                  `済：${data.order_delivery_note.delivery_no}`
                                ) : (
                                  <Button
                                    variant="primary"
                                    size="md"
                                    style={{ color: 'white' }}
                                    disabled={processing}
                                    onClick={() => {
                                      setOpenDeliveryMailModal(true)
                                    }}>
                                    配送メールを送る
                                  </Button>
                                )}
                              </>
                            ) : (
                              <span style={{ fontSize: 12, color: '#ccc' }}>配送処理時に更新されます</span>
                            )}
                          </p>
                        </div>
                        <div className="w50">
                          <label>注文日時</label>
                          <p>{format(new Date(data.created_at), 'yyyy-MM-dd HH:mm:ss')}</p>
                        </div>
                        <div className="w50">
                          <label>注文番号</label>
                          <p>{data.order_no}</p>
                        </div>
                        <div className="w50">
                          <label>注文者</label>
                          <p>{data.user.username}</p>
                        </div>
                        <div className="w50">
                          <label>支払い総額</label>
                          <p>¥{data.price.toLocaleString()}</p>
                        </div>
                      </div>

                      <h6>注文詳細</h6>
                      <div className={'panelBody__formContainer'}>
                        {data.order_items.length > 0 ? (
                          <>
                            {data.order_items.map((order_item, index) => (
                              <div
                                className={`panelBody__formContainer__item ${
                                  index != 0 && 'panelBody__formContainer__border'
                                }`}
                                key={`order_item_${index}`}>
                                <div className="w50">
                                  <label>詳細 {index + 1}</label>
                                  <p>
                                    {order_item.model === 'item' && (
                                      <a href={pages.ITEMS_SHOW(order_item.slug)} target="_blank" rel="noreferrer">
                                        {order_item.title}
                                      </a>
                                    )}
                                    {order_item.model === 'benefit' && (
                                      <a
                                        href={pages.ADMIN_BENEFITS_EDIT(order_item.reference_id)}
                                        target="_blank"
                                        rel="noreferrer">
                                        {order_item.title || '特典コード割引'}
                                      </a>
                                    )}
                                  </p>
                                </div>
                                <div className="w50">
                                  <label>税込価格</label>
                                  {order_item.model === 'item' && <p>¥{order_item.value_1.toLocaleString()}</p>}
                                  {order_item.model === 'benefit' && <p>¥{order_item.value_2.toLocaleString()}</p>}
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <>
                            <div className="w50">
                              <label>商品名</label>
                              <p>
                                <a href={pages.ITEMS_SHOW(data.item.slug)} target="_blank" rel="noreferrer">
                                  {data.item.name}
                                </a>
                              </p>
                            </div>
                            <div className="w50">
                              <label>税込価格</label>
                              <p>¥{data.price.toLocaleString()}</p>
                            </div>
                          </>
                        )}
                      </div>

                      <h6>注文情報</h6>
                      <div className="panelBody__formContainer">
                        <div className="w100">
                          <label>添え書き（宛名）</label>
                          <p>{data.soegaki || '-'}</p>
                        </div>
                        <div className="w100">
                          <label>お祝いの言葉</label>
                          <p>{data.congrat ? data.congrat.name : data.congrat_label || '-'}</p>
                        </div>
                        <div
                          className="w100"
                          style={{
                            borderBottom: '1px solid #e3e3e3',
                            marginBottom: 16,
                          }}>
                          <label>名札</label>
                          <p>{data.name_tag || '-'}</p>
                        </div>

                        <div className="w100">
                          <label>受取人名</label>
                          <p>{data.recipient} 様</p>
                        </div>
                        <div className="w50">
                          <label>郵便番号</label>
                          <p>{data.zipcode}</p>
                        </div>
                        <div className="w50">
                          <label>住所1</label>
                          <p>{data.address1}</p>
                        </div>
                        <div className="w50">
                          <label>住所2</label>
                          <p>{data.address2}</p>
                        </div>
                        <div className="w50">
                          <label>電話番号</label>
                          <p>{data.phone}</p>
                        </div>
                        <div className="w50">
                          <label>担当部署 担当者</label>
                          <p>{data.dear ? data.dear : '-'}</p>
                        </div>
                        <div className="w50">
                          <label>お届け指定日</label>
                          <p>{data.delivery_date ? `${data.delivery_date} ${data.delivery_time}` : '最短'}</p>
                        </div>

                        <div className="w50">
                          <label>ラッピング</label>
                          <p>{data.wrapping ? data.wrapping : data.wrapping_label || '-'}</p>
                        </div>
                        <div className="w100">
                          <label>備考</label>
                          <p>{data.note}</p>
                        </div>
                        <div className="w50">
                          <label>支払い方法</label>
                          <p>{constants.getPaymentMethodName(data.payment_method, data.user.is_personal_use)}</p>
                        </div>
                        <div className="w50">
                          <label>紹介者コード</label>
                          <p>{data.referral_code ? data.referral_code : '-'}</p>
                        </div>
                      </div>
                    </>
                  </PanelBody>
                </BasePanel>
              </PanelWrapper>
            </Wrapper>
          </form>

          <DeliveryMailModal
            open={openDeliveryMailModal}
            onClose={handleCloseDeliveryMailModal}
            onSent={handleOnSent}
            params={{ id: data.id }}
            defaultShipper={data.item.shipper}
          />
        </>
      )}
    </React.Fragment>
  )
}

const PanelBody = styled.div`
  h6 {
    padding: 16px;
    border-bottom: 1px solid #e3e3e3;
    font-size: 14px;
    font-weight: bold;
  }
  .panelBody {
    &__formContainer {
      padding: 8px 32px;

      label {
        font-size: 12px;
        font-weight: bold;
      }

      p {
        white-space: pre-wrap;
      }

      &__item {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }

      &__border {
        border-top: 1px solid #e3e3e3;
        padding-top: 8px;
      }
    }
  }
`
export default Index
