import * as React from 'react'
import { Box, makeStyles, TextField, StandardTextFieldProps, Typography } from '@material-ui/core'
import { DefaultThemeProvider, FormError } from '.'
import { FieldError } from 'react-hook-form'

interface Props extends StandardTextFieldProps {
  label?: string
  error?: FieldError
  memo?: string
}

export const DefaultTextField = (props: Props) => {
  const classes = useStyles((theme) => ({}))

  return (
    <DefaultThemeProvider>
      <Box component="fieldset" mb={2} borderColor="transparent">
        <Typography component="legend">{props.label}</Typography>
        <TextField
          variant="outlined"
          color="primary"
          fullWidth={true}
          {...props}
          label={null}
          className={classes.inputContainer}
          classes={{
            root: classes.input,
          }}
          inputProps={{}}
        />
        {props.memo ? <label className={classes.memo}>{props.memo}</label> : <></>}
        <FormError error={props.error} />
      </Box>
    </DefaultThemeProvider>
  )
}

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    '& fieldset': {
      // borderRadius: 0,
    },
  },

  input: {
    //
    '& input': {},
  },

  memo: {
    color: '#999',
    paddingLeft: 8,
    fontSize: 12,
    marginBottom: 0,
  },
}))
