import { sendGet, sendPost, sendPut, sendDelete } from '../httpClient'
import { parseResourceData } from '../JsonApiSerializer'

export const index = async (page: string) => {
  try {
    const { data } = await sendGet(
      `/admin/api/congrats${page ? '?page=' + page : ''}`
    )
    const json = parseResourceData(data.json)
    const meta = data.json.meta
    return { json, meta }
  } catch (e) {
    throw e.response
  }
}

export const show = async (id: string) => {
  try {
    const { data } = await sendGet('/admin/api/congrats/' + id)
    const json = parseResourceData(data.json)
    return { json }
  } catch (e) {
    throw e.response
  }
}

export interface IInputCreate {
  name: string
  position: number
}

export const create = async (formData: IInputCreate) => {
  const { name, position } = formData

  try {
    const response = await sendPost('/admin/api/congrats', {
      congrat: {
        name,
        position,
      },
    })
    return response
  } catch (e) {
    throw e.response
  }
}

export interface IInputUpdate extends IInputCreate {
  id: string
}

export const update = async (formData: IInputUpdate) => {
  const { id, name, position } = formData

  try {
    const response = await sendPut('/admin/api/congrats/' + id, {
      congrat: {
        name,
        position,
      },
    })
    return response
  } catch (e) {
    throw e.response || e
  }
}

export const destroy = async (id: string) => {
  try {
    const response = await sendDelete('/admin/api/congrats/' + id)
    return response
  } catch (e) {
    throw e.response
  }
}
