import { Collapse, IconButton, makeStyles } from '@material-ui/core'
import * as React from 'react'
import KeyboardArrowUpRounded from '@material-ui/icons/KeyboardArrowUpRounded'
import KeyboardArrowDownRounded from '@material-ui/icons/KeyboardArrowDownRounded'
import { DefaultThemeProvider } from '../../_components'

interface Props {
  title: string
  body: string
}

export const NoticeItem: React.FC = (props: Props) => {
  const classes = useStyles()

  const [expanded, setExpanded] = React.useState(false)
  return (
    <DefaultThemeProvider>
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.title}>{props.title}</div>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              setExpanded(!expanded)
            }}>
            {expanded ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
          </IconButton>
        </div>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <div className={classes.body}>{props.body}</div>
        </Collapse>
      </div>
    </DefaultThemeProvider>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {},

  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #e3e3e3',
    marginBottom: 16,
  },

  title: {
    fontWeight: 'bold',
  },

  body: {
    paddingLeft: 32,
    whiteSpace: 'pre-wrap',
  },
}))
