import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { DefaultButton, UsefulModal } from '../../_components'
import { constants } from '../../../_assets'

interface Props {
  title: string
  images: string[]
}

export const HelpModal: React.FC<Props> = (props) => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)

  return (
    <div className={classes.root}>
      <div className={classes.iconContainer} onClick={() => setOpen(true)}>
        <HelpOutlineIcon className={classes.iconSvg} />
      </div>

      <UsefulModal open={open}>
        <div className={classes.modalTitle}>{props.title}</div>

        <div className={classes.modalBody}>
          {props.images.map((image, index) => (
            <img key={`help-modal-${props.title}-${index}`} src={image} alt="help" className={classes.img} />
          ))}
        </div>

        <div className={classes.modalFooter}>
          <div className={classes.buttonContainer}>
            <DefaultButton onClick={() => setOpen(false)}>閉じる</DefaultButton>
          </div>
        </div>
      </UsefulModal>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },

  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },

  iconSvg: {
    fill: constants.MAIN_COLOR,
    height: 20,
    cursor: 'pointer',
  },

  modalTitle: {
    padding: 16,
    borderBottom: '1px solid #e3e3e3',
  },

  modalBody: {
    padding: 16,
  },

  img: {
    width: '100%',
  },

  modalFooter: {
    padding: 16,
    borderTop: '1px solid #e3e3e3',
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
