import { sendPost, sendGet, sendDelete } from './httpClient'
import { parseResourceData } from './JsonApiSerializer'

export const getCard = async () => {
  try {
    const { data } = await sendGet('/api/cards')
    const json = parseResourceData(data.json)
    return { json }
  } catch (e) {
    throw e.response
  }
}

export const createCard = async (stripeToken: string) => {
  try {
    const { data } = await sendPost('/api/cards', {
      stripeToken,
    })
    const json = parseResourceData(data.json)
    return { json }
  } catch (e) {
    throw e.response
  }
}

export const createCardByGuest = async (stripeToken: string, email: string) => {
  try {
    const { data } = await sendPost('/api/cards_create_by_guest', {
      stripeToken,
      email,
    })
    const json = parseResourceData(data.json)
    return { json }
  } catch (e) {
    throw e.response
  }
}

// export const deleteCard = async (stripeToken: string) => {
//   try {
//     const { data } = await sendDelete('/api/cards', {
//       stripeToken,
//     })
//     const json = parseResourceData(data.json)
//     return { json }
//   } catch (e) {
//     throw e.response
//   }
// }
