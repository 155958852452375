import * as React from 'react'
import * as pages from '../../../pages'
import { Wrapper, Title, PanelWrapper, BasePanel } from '../_components'
import { index, destroy } from '../../../services/admin/AdminService'
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'

interface Props {}

const Index: React.FC<Props> = () => {
  const [data, setData] = React.useState(null)

  React.useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    try {
      const fetchedData = await index()
      setData(fetchedData)
    } catch (e) {
      window.flashMessages.addErrorMessage('管理者一覧データが取得できませんでした')
    }
  }

  const handleDelete = async (id: string) => {
    if (confirm('本当に削除しますか？')) {
      try {
        await destroy(id)
        window.flashMessages.addSuccessMessage('削除しました')
        fetch()
      } catch (e) {
        window.flashMessages.addErrorMessage('エラーが発生しました')
      }
    }
  }

  return (
    <React.Fragment>
      <Wrapper active="admin">
        <Title
          title={'管理者一覧'}
          action={
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              size="small"
              onClick={() => {
                window.location.href = pages.ADMIN_ADMINS_NEW
              }}>
              新規登録
            </Button>
          }
        />
        <PanelWrapper>
          <BasePanel>
            <Table className="admin-table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>メールアドレス</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.map((d, index) => (
                    <TableRow key={`order-${index}`}>
                      <TableCell className="panelBody__table__td">{d.user.email}</TableCell>
                      <TableCell className="panelBody__table__td buttons">
                        <Button
                          color="secondary"
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            handleDelete(d.id)
                          }}>
                          削除
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </BasePanel>
        </PanelWrapper>
      </Wrapper>
    </React.Fragment>
  )
}

export default Index
