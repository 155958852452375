import * as React from 'react'
import { CardElement, Elements, injectStripe, StripeProvider } from 'react-stripe-elements'
import styled from 'styled-components'
import { createCard, createCardByGuest, getCard } from '../../../services/CardService'
import { makeStyles, RadioGroup, Radio, Theme } from '@material-ui/core'
import { DefaultButton } from '../../_components'

interface IProps {
  stripe?: T
  showForm: boolean
  setCardToken: (token: string) => void
  hasSession: boolean
  guestEmail?: string
}

const createOptions = () => {
  return {
    hidePostalCode: true,
    style: {
      base: {
        fontSize: '16px',
        color: '#333',
        letterSpacing: '0.5px',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: 'red',
      },
    },
  }
}

const StripeCardSelect: React.FC<IProps> = (props) => {
  const classes = useStyles()

  const [card, setCard] = React.useState(null)
  const [processing, setProcessing] = React.useState(false)
  const [registered, setRegistered] = React.useState(false)

  React.useEffect(() => {
    if (props.hasSession) {
      fetch()
    }
  }, [props.hasSession])

  const fetch = async () => {
    const { json } = await getCard()
    if (!json) {
      setCardPaymentType('NEW_CARD')
    } else {
      props.setCardToken(json.stripe_card_id)
    }
    setCard(json)
  }

  const registerCard = async () => {
    setProcessing(true)
    setRegistered(false)

    try {
      const response = await props.stripe.createToken()
      if (!response.token) {
        setProcessing(false)
        return
      } else {
        if (props.guestEmail) {
          await createCardByGuest(response.token.id, props.guestEmail)
          props.setCardToken(response.token.id)
        } else {
          await createCard(response.token.id)
          props.setCardToken(response.token.id)
        }
        setProcessing(false)
        setRegistered(true)
        window.flashMessages.addSuccessMessage('カード情報を登録しました')
      }
    } catch (e) {
      alert(`お使いのカードはご利用になれません\n${e?.data?.error || ''}`)
      setProcessing(false)
    }
  }

  const [cardPaymentType, setCardPaymentType] = React.useState<PAYMENT_METHOD>(PAYMENT_METHOD.RegisteredCard)

  const handleChangeRadio = (type: PAYMENT_METHOD) => {
    setCardPaymentType(type)

    switch (type) {
      case PAYMENT_METHOD.NewCard:
        props.setCardToken(null)

        break
      case PAYMENT_METHOD.RegisteredCard:
        props.setCardToken(card.stripe_card_id)

        break
    }
  }

  return (
    <CardForm className="cardForm" style={{ display: props.showForm ? 'block' : 'none' }}>
      <RadioGroup
        aria-label="cardPaymentType"
        name="cardPaymentType"
        value={cardPaymentType}
        onChange={(event) => {
          handleChangeRadio(event.target.value)
        }}
        className={classes.inputContainer}>
        <div>
          {card && (
            <label className={classes.radioLabel}>
              <Radio
                color="primary"
                name="useRegisteredCard"
                value={PAYMENT_METHOD.RegisteredCard}
                checked={cardPaymentType === PAYMENT_METHOD.RegisteredCard}
              />

              <span className="ml-2">登録カード情報</span>
              <div
                style={{
                  paddingLeft: 50,
                  color: '#999',
                }}>
                {card.brand} - 末尾: {card.last4} - 有効期限: {card.exp_month}/{card.exp_year}
              </div>
            </label>
          )}
          <label className={classes.radioLabel}>
            <Radio
              color="primary"
              name="useRegisteredCard"
              value={PAYMENT_METHOD.NewCard}
              checked={cardPaymentType === PAYMENT_METHOD.NewCard}
            />

            <span className="ml-2 first:ml-0">新しいカード</span>
          </label>
        </div>
        {cardPaymentType === PAYMENT_METHOD.NewCard ? (
          <div>
            <CardElement {...createOptions()} />
          </div>
        ) : (
          <></>
        )}
      </RadioGroup>

      {!registered && cardPaymentType === PAYMENT_METHOD.NewCard ? (
        <div className={classes.buttonContainer}>
          <DefaultButton
            variant="outlined"
            color="primary"
            onClick={registerCard}
            processing={processing ? 'true' : undefined}>
            登録
          </DefaultButton>
        </div>
      ) : (
        <></>
      )}
    </CardForm>
  )
}

const CardForm = styled.div`
  border: 1px solid #e3e3e3;
  padding: 16px;
  border-radius: 5px;
  margin-bottom: 16px;
  margin-left: 24px;

  span.exp {
    margin-left: 24px;
  }

  & + .Button {
    margin-top: 16px;
  }

  .StripeElement {
    display: block;
    margin: 10px auto;
    max-width: 500px;
    padding: 10px 14px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border-radius: 4px;
    background: white;
  }

  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    transition: all 150ms ease;
  }

  .StripeElement.PaymentRequestButton {
    padding: 0;
  }
`

const StripeInjected = injectStripe(StripeCardSelect)
export const StripeCardSelectProvider: React.FC<IProps> = (props) => {
  return (
    <StripeProvider apiKey={getStripePublicKey()}>
      <Elements>
        <StripeInjected {...props} />
      </Elements>
    </StripeProvider>
  )
}

function getStripePublicKey(): any {
  const element: any = document.querySelector('meta[name=stripe-public-key]')

  return element ? element.content : ''
}

const useStyles = makeStyles((theme: Theme) => ({
  radioLabel: {
    position: 'relative',
    transitionDuration: '.15s',
    display: 'block',
    cursor: 'pointer',
  },

  radioContainer: {
    position: 'absolute',
    width: '1.25rem',
    left: 0,
    top: 0,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
}))

enum PAYMENT_METHOD {
  RegisteredCard = 'REGISTERED_CARD',
  NewCard = 'NEW_CARD',
}
