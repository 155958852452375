import * as React from 'react'
import { Button } from 'react-bootstrap'
import { Modal, Fade, makeStyles, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { index } from '../../../services/AddressService'

interface IProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleSetAddress: (data: any) => void
}

export const ModalAddressSelector: React.FC<IProps> = (props) => {
  const classes = useStyles()

  React.useEffect(() => {
    fetch()
  }, [])

  const fetch = async (page?: string) => {
    try {
      const { json: fetchedData } = await index()
      setAddresses(fetchedData)
    } catch (e) {
      // window.flashMessages.addErrorMessage('データが取得できませんでした')
    }
  }

  const [addresses, setAddresses] = React.useState([])

  const handleClose = () => {
    props.setOpen(false)
  }

  const handleSubmit = async () => {
    setStates({ ...states, processing: true })
    if (states.address === null) {
      setStates({ ...states, error: 'どれか選択してください', processing: false })
    } else {
      const data = addresses.filter((addr) => addr.id === parseInt(states.address))
      await props.handleSetAddress(data[0])
      props.setOpen(false)
      setStates({ ...states, processing: false })
    }
  }

  const [states, setStates] = React.useState({
    address: null,
    error: '',
    processing: false,
  })

  const [address, setAddress] = React.useState(null)

  const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStates({ ...states, address: (event.target as HTMLInputElement).value })
  }

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      closeAfterTransition={true}
      BackdropProps={{
        timeout: 500,
      }}
      disableBackdropClick={true}>
      <Fade in={props.open}>
        <div className={classes.paper}>
          <div className={classes.header}>履歴からお届け先情報を選択</div>
          <div className={classes.body}>
            <RadioGroup aria-label="address" name="address" value={states.address} onChange={handleChangeAddress}>
              {addresses.map((item, index) => (
                <FormControlLabel
                  key={`address-${item.id}`}
                  value={`${item.id}`}
                  control={<Radio color="primary" />}
                  className={classes.formControlLabel}
                  label={
                    <div style={{ fontSize: 10 }}>
                      <div style={{ fontWeight: 'bold' }}>{item.recipient}</div>
                      <div>〒{item.zipcode}</div>
                      <div>
                        {item.address1} {item.address2}
                      </div>
                    </div>
                  }
                />
              ))}
            </RadioGroup>
          </div>
          {states.error && <div className={classes.errorMessage}>{states.error}</div>}

          <div className={classes.footer}>
            <Button variant={'outline-secondary'} onClick={handleClose} disabled={states.processing}>
              キャンセル
            </Button>
            <Button
              variant={'outline-primary'}
              onClick={handleSubmit}
              style={{ marginLeft: 16 }}
              disabled={states.processing || !states.address}>
              選択
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: 450,
    maxWidth: '90%',
    border: 'none',

    '&:focus': {
      outline: 'none',
    },
  },

  header: {
    padding: 16,
    borderBottom: '1px solid #e5e5e5',
    fontWeight: 'bold',
  },

  body: {
    padding: 16,
  },

  formControlLabel: {
    borderBottom: '1px solid #e5e5e5',
    paddingBottom: 8,
    marginLeft: 0,
    marginRight: 0,
  },

  footer: {
    padding: 16,
    paddingTop: 0,
    display: 'flex',
    justifyContent: 'center',
  },

  errorMessage: {
    textAlign: 'center',
    fontSize: 12,
    color: 'red',
    marginBottom: 8,
  },
}))
