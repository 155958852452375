import * as React from 'react'
import { constants } from '../../../_assets'
import { makeStyles } from '@material-ui/core'
import { DefaultButton, FormError, UsefulModal } from '../../_components'
import { Controller, useForm } from 'react-hook-form'
import { indexNameTag, NameTag } from '../../../services/NameTagService'
import Select from 'react-select'
import { OrderSection } from '.'
import { Store } from '../../../services/StoreService'
import { HelpModal } from './HelpModal'

export interface FormOptions {
  soegaki?: string
  nameTag: string
  congrat: string
  congratLabel: string
  wrapping: string
}

interface Props {
  currentStep: number
  setCurrentStep: (step: number) => void
  handleNext: () => void
  item?: any
  setFormOptions: (form: FormOptions) => void
  hasSession: boolean
  store?: Store
}

const SESSION_STEP = 2

export const OrderOptions: React.FC<Props> = (props) => {
  const classes = useStyles()

  const { register, handleSubmit, setValue, errors, getValues, setError, control } = useForm<FormOptions>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const [nameTags, setNameTags] = React.useState<NameTag[] | undefined>(undefined)

  React.useEffect(() => {
    if (props.hasSession) {
      // Fetch nameTags
      const f = async () => {
        const fetchedData = await indexNameTag()
        setNameTags(fetchedData)
      }

      f()
    }
  }, [props.hasSession])

  const [openNameTag, setOpenNameTag] = React.useState<boolean>(false)
  const [isNoNameTag, setIsNoNameTag] = React.useState<boolean>(false)

  const [openCongrat, setOpenCongrat] = React.useState<boolean>(false)

  const onSubmit = (data: FormOptions) => {
    props.setFormOptions(data)
    props.handleNext()
  }

  return (
    <>
      <OrderSection
        step={SESSION_STEP}
        currentStep={props.currentStep}
        setCurrentStep={props.setCurrentStep}
        title={'名札・オプションの設定'}>
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          {props.store?.soegaki ? (
            <section className={classes.section} style={{ display: props.store?.soegaki ? 'block' : 'none' }}>
              <label className={classes.label}>{props.store?.soegaki_label || '添え書き'}</label>
              <Controller
                name="soegaki"
                control={control}
                rules={{
                  required: props.store?.soegaki.split(',').length > 1 ? '添え書きは必須です' : false,
                }}
                defaultValue={''}
                render={({ onChange, value, name, ref }) => (
                  <Select
                    className={classes.select}
                    placeholder="選択してください"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: constants.MAIN_COLOR,
                        primary: constants.MAIN_COLOR,
                      },
                    })}
                    options={
                      [
                        { value: '', label: '選択してください' },
                        ...props.store?.soegaki.split(',').map((s) => {
                          return { value: s, label: s }
                        }),
                      ] || []
                    }
                    value={(
                      props.store?.soegaki.split(',').map((s) => {
                        return { value: s, label: s }
                      }) || []
                    ).find((c) => c.value === value)}
                    onChange={(val) => onChange(val.value)}
                  />
                )}
              />

              <FormError error={errors.soegaki} />
            </section>
          ) : (
            <></>
          )}

          <section className={classes.section} style={{ display: props.store?.disable_congrat ? 'none' : 'block' }}>
            <label className={classes.label}>お祝いの言葉</label>
            <div>
              <div className={classes.nameTagInputContainer}>
                <Controller
                  name="congratLabel"
                  control={control}
                  defaultValue={''}
                  render={({ onChange, value, name, ref }) => (
                    <input
                      className={classes.input}
                      value={value}
                      name={name}
                      ref={ref}
                      onChange={(val) => onChange(val.target.value)}
                      autoComplete="nope"
                    />
                  )}
                />

                <DefaultButton
                  variant="text"
                  onClick={() => {
                    setOpenCongrat(true)
                  }}
                  style={{ height: 40, width: 100, fontWeight: 'bold', textDecoration: 'underline' }}>
                  選択
                </DefaultButton>
              </div>

              <FormError error={errors.congratLabel} />
            </div>
          </section>

          <section className={classes.section}>
            <div className={classes.labelWithIconContainer}>
              <label className={`${classes.label} ${classes.required}`}>名札</label>
              <HelpModal title={'名札とは？'} images={['/images/help/nameTag.jpg']} />
            </div>
            <div>
              <div className={classes.nameTagInputContainer}>
                <input
                  className={classes.input}
                  placeholder={isNoNameTag ? '名札無し' : '株式会社〇〇 代表取締役 坂元 花'}
                  name="nameTag"
                  ref={register({
                    required: isNoNameTag ? false : '名札は必須です',
                  })}
                  // defaultValue={data && data.name_tag}
                  autoComplete="nope"
                  disabled={isNoNameTag}
                />

                <DefaultButton
                  variant="text"
                  onClick={() => {
                    setOpenNameTag(true)
                  }}
                  style={{ height: 40, width: 100, fontWeight: 'bold', textDecoration: 'underline' }}>
                  選択
                </DefaultButton>
              </div>

              <FormError error={errors.nameTag} />
            </div>
          </section>

          <section className={classes.section}>
            {props.item?.wrapping_options.length > 1 ? (
              <div className="rootContainer__orderNewInfos__labelContainer__formContainer__nameTag">
                <label className={`${classes.label} ${classes.required}`}>ラッピングまたは花色</label>
                <Controller
                  name="wrapping"
                  control={control}
                  rules={{
                    required: props.item?.wrapping_options.length > 1 ? 'ラッピングまたは花色は必須です' : false,
                  }}
                  defaultValue={''}
                  render={({ onChange, value, name, ref }) => (
                    <Select
                      className={classes.select}
                      placeholder="選択してください"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: constants.MAIN_COLOR,
                          primary: constants.MAIN_COLOR,
                        },
                      })}
                      options={props.item?.wrapping_options || []}
                      value={(props.item?.wrapping_options || []).find((c) => c.value === value)}
                      onChange={(val) => onChange(val.value)}
                    />
                  )}
                />
                <FormError error={errors.wrapping} />
              </div>
            ) : (
              <></>
            )}
          </section>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
            <DefaultButton type="submit">次へ</DefaultButton>
          </div>
        </form>
      </OrderSection>

      <UsefulModal open={openCongrat}>
        <>
          <div className={classes.modalHeader}>お祝いの言葉を選択</div>
          <div className={classes.modalBody}>
            {/* <div
              className={`${classes.modalItem} ${isNoNameTag ? classes.modalItemActive : ''}`}
              onClick={() => {
                setValue('congratLabel', nextFlag ? '名札無し' : '')
                setOpenNameTag(false)
              }}>
              選択無し
            </div> */}

            {(props.item?.congrat_options || []).map((congrat, index) => (
              <div
                key={`congrat-${index}`}
                className={classes.modalItem}
                onClick={() => {
                  setIsNoNameTag(false)

                  setTimeout(function () {
                    setValue('congratLabel', congrat.label)
                    setOpenCongrat(false)
                  }, 500)
                }}>
                {congrat.label}
              </div>
            ))}
          </div>
          <div className={classes.modalButtons}>
            <DefaultButton
              color="secondary"
              variant="outlined"
              onClick={() => {
                setValue('congratLabel', '')
                setOpenCongrat(false)
              }}>
              クリア
            </DefaultButton>
            <DefaultButton
              variant="outlined"
              onClick={() => {
                setOpenCongrat(false)
              }}>
              キャンセル
            </DefaultButton>
          </div>
        </>
      </UsefulModal>

      <UsefulModal open={openNameTag}>
        <>
          <div className={classes.modalHeader}>名札を選択</div>
          <div className={classes.modalBody}>
            <div
              className={`${classes.modalItem} ${isNoNameTag ? classes.modalItemActive : ''}`}
              onClick={() => {
                const nextFlag = !isNoNameTag
                setIsNoNameTag(nextFlag)
                setValue('nameTag', nextFlag ? '名札無し' : '')
                setOpenNameTag(false)
              }}>
              名札無しとして登録
            </div>

            {nameTags && nameTags.length > 0 ? (
              nameTags.map((nameTag, index) => (
                <div
                  key={`nameTag-${index}`}
                  className={classes.modalItem}
                  onClick={() => {
                    setIsNoNameTag(false)

                    setTimeout(function () {
                      setValue('nameTag', nameTag.name)
                      setOpenNameTag(false)
                    }, 500)
                  }}>
                  {nameTag.name}
                </div>
              ))
            ) : (
              <div className={classes.noNameTag}>登録されている名札はありません</div>
            )}
          </div>
          <div className={classes.modalButtons}>
            <DefaultButton
              color="secondary"
              variant="outlined"
              onClick={() => {
                setIsNoNameTag(false)
                setValue('nameTag', '')
                setOpenNameTag(false)
              }}>
              クリア
            </DefaultButton>
            <DefaultButton
              variant="outlined"
              onClick={() => {
                setOpenNameTag(false)
              }}>
              キャンセル
            </DefaultButton>
          </div>
        </>
      </UsefulModal>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // justifyContent: 'center',
  },

  section: {
    marginBottom: 16,
  },

  label: {
    // width: '100%',
    marginBottom: 0,
  },

  labelWithIconContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  required: {
    '&:after': {
      content: '"（必須）"',
      color: 'red',
      fontSize: 12,
    },
  },

  input: {
    width: '100%',
    height: 40,
    padding: 16,
    border: 'none',
  },

  description: {
    fontSize: 14,
    color: '#555',
  },

  // nameTags
  nameTagInputContainer: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc',
  },

  // Modals
  modalHeader: {
    padding: 16,
    borderBottom: '1px solid #e3e3e3',
  },

  modalBody: {
    padding: 16,
    paddingTop: 0,
    maxHeight: 400,
    overflow: 'scroll',
  },

  modalItem: {
    height: 40,
    borderBottom: '1px solid #e3e3e3',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 8,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f3f3f3',
    },
  },

  modalItemActive: {
    backgroundColor: '#f3f3f3',
  },

  modalButtons: {
    padding: 16,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
  },

  noNameTag: {
    fontSize: 12,
    textAlign: 'center',
    padding: '16px 0',
  },

  select: {
    height: 40,
  },
}))
