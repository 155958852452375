import * as React from 'react'
import { Modal, Fade, makeStyles, Card } from '@material-ui/core'

interface IProps {
  open: boolean
}

export const UsefulModal: React.FC<IProps> = (props) => {
  const classes = useStyles()

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      closeAfterTransition={true}
      BackdropProps={{
        timeout: 500,
      }}
      disableBackdropClick={true}>
      <Fade in={props.open}>
        <Card className={classes.paper}>{props.children as React.ReactElement}</Card>
      </Fade>
    </Modal>
  )
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: 450,
    border: 'none',
    margin: 16,

    '&:focus': {
      outline: 'none',
    },
  },
}))
