import * as React from 'react'
import { useForm } from 'react-hook-form'
import { Loading, ThemeContainer, FormError } from '../../_components'
import { makeStyles, Button, MuiThemeProvider, TextField } from '@material-ui/core'
import IWindow from '../../../interfaces/IWindow'
import { addReferralCode, IInputReferralCode } from '../../../services/UserService'

declare const window: IWindow

interface Props {
  user: any
  setUser: React.Dispatch<any>
}

const Index: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { user } = props
  // form
  const { register, handleSubmit, setValue, errors, getValues, setError } = useForm<IInputReferralCode>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const onSubmit = async (data: IInputReferralCode) => {
    try {
      await addReferralCode(data)
      window.flashMessages.addSuccessMessage('紹介コードを登録しました')
      props.setUser({ ...user, introducer: { code: data.referral_code } })
    } catch (e) {
      window.flashMessages.addErrorMessage('入力された紹介コードが見つかりませんでした')
    }
  }

  return (
    <ThemeContainer>
      <div style={{ marginBottom: 32 }}>
        <div className={classes.title}>紹介コード</div>
        {!user ? (
          <Loading />
        ) : (
          <>
            {user.introducer ? (
              <div>適用されております</div>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={classes.formContainer}>
                  <div className={classes.inputContainer}>
                    <TextField
                      name="referral_code"
                      inputRef={register({
                        required: '紹介コードを入力してください',
                      })}
                      error={errors.referral_code ? true : false}
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                      classes={{ root: classes.inputRoot }}
                    />
                  </div>
                  <Button type="submit" variant="contained" color="primary" className={classes.buttonStyle}>
                    登録
                  </Button>
                </div>
                <FormError error={errors.referral_code} />
              </form>
            )}
          </>
        )}
      </div>
    </ThemeContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  formContainer: {
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  inputContainer: {
    width: '100%',
    maxWidth: 300,
    paddingRight: 16,
  },

  buttonStyle: {
    width: 100,
    minWidth: 100,
    borderRadius: 0,
  },

  inputRoot: {
    backgroundColor: '#fff',
    width: '100%',
    '& .MuiOutlinedInput-root ': {
      borderRadius: 0,
    },
  },
}))

export default Index
