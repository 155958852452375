import * as React from 'react'
import * as constants from '../../constants'
import * as pages from '../../pages'
import { Header, Footer } from '../_components'
import Menus from '../_components/Menus'
import { getUser } from '../../services/UserService'
import IWindow from '../../interfaces/IWindow'
import { ButtonBackToBefore } from './_components/ButtonBackToBefore'
import { MuiThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core'
import { ProfileForms } from './_components/Profiles'

declare const window: IWindow

interface Props {
  params: RouteParams
}

interface RouteParams {
  back?: string
}

const Index: React.FC<Props> = (props) => {
  const classes = useStyles()
  const [loading, setLoading] = React.useState(true)
  const [user, setUser] = React.useState(null)

  React.useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    setLoading(true)
    try {
      const fetchedData = await getUser()
      setUser(fetchedData)
      if (!fetchedData.zipcode) {
        window.flashMessages.addSuccessMessage('ご利用のためにプロフィールを設定してください')
      }
    } catch (e) {
      window.flashMessages.addErrorMessage('プロフィール情報を取得できませんでした')
    }
    setLoading(false)
  }

  // back button
  const [showBackToBefore, setShowBackToBefore] = React.useState(false)

  return (
    <React.Fragment>
      <MuiThemeProvider theme={theme}>
        <Header signedIn={true} />

        <div className={classes.root}>
          <Menus active="mypage" />
          <div className={classes.content}>
            <h3>マイページ</h3>

            {!loading && user ? (
              <ProfileForms
                user={user}
                afterOnSubmit={() => {
                  setShowBackToBefore(true)
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </div>

        <Footer />
        {props.params.back ? <ButtonBackToBefore show={showBackToBefore} to={props.params.back} /> : <></>}
      </MuiThemeProvider>
    </React.Fragment>
  )
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: constants.MAIN_COLOR,
    },
    secondary: {
      main: constants.SECONDARY_COLOR,
    },
  },
})

export default Index

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: constants.BG_COLOR_GRAY,
    minHeight: `calc(100vh - ${constants.HEIGHT_HEADER + constants.HEIGHT_FOOTER}px)`,
    display: 'flex',
    justifyContent: 'center',
    padding: '32px 64px',

    [theme.breakpoints.down('sm')]: {
      padding: '32px 16px',
    },
  },

  content: {
    width: '100%',
    maxWidth: 700,
    padding: '0 32px',
  },
}))
