import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../../constants'
import * as pages from '../../../pages'
import { Header, Footer, Pagination } from '../../_components'
import { Table, Button } from 'react-bootstrap'

interface IProps {
  // id: string
}

const Index: React.FC<IProps> = (props) => {
  return (
    <React.Fragment>
      <Wrapper>
        <Panel>
          <div className="panelHeader">お問い合わせ一覧</div>
          <div className="panelBody">UI無し</div>
        </Panel>
      </Wrapper>
    </React.Fragment>
  )
}

const Wrapper = styled.div``

const Panel = styled.div`
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 3px 8px rgba(50, 50, 50, 0.17);

  .panelHeader {
    //
  }

  .panelBody {
    //
  }
`

export default Index
