import { sendDelete, sendPost, sendGet, sendPut } from '../httpClient'
import { parseResourceData } from '../JsonApiSerializer'

export const index = async (page: string) => {
  try {
    const { data } = await sendGet(`/admin/api/items${page ? '?page=' + page : ''}`)
    const json = parseResourceData(data.json)
    const meta = data.json.meta
    return { json, meta }
  } catch (e) {
    throw e.response
  }
}

export const show = async (id: string) => {
  try {
    const { data } = await sendGet('/admin/api/items/' + id)
    const json = parseResourceData(data.json)
    return { json }
  } catch (e) {
    throw e.response
  }
}

export interface IOption {
  value: string
  label: string
}

export interface IInputCreate {
  category: IOption[]
  name: string
  code: string
  trader: string
  price: number
  delivery_fee: number
  delivery_days: number
  delivery_limit_at: string
  delivery_time: string
  position: number
  color: string
  wrapping: string
  description: string
  image_1: any
  image_2: any
  image_3: any
  image_4: any
  image_5: any
  shipper: string
  is_recommended: boolean
  is_private: boolean
  public_date: string
  trader_id: string
  movie_tag: string
  stock: number
}

export const create = async (formData: IInputCreate) => {
  try {
    const response = await sendPost('/admin/api/items', setFormParams(formData))
    return response
  } catch (e) {
    throw e.response
  }
}

export interface IInputUpdate extends IInputCreate {
  id: string
}

export const update = async (formData: IInputUpdate) => {
  try {
    const response = await sendPut('/admin/api/items/' + formData.id, setFormParams(formData))
    return response
  } catch (e) {
    throw e.response || e
  }
}

export const destroy = async (id: string) => {
  try {
    const response = await sendDelete('/admin/api/items/' + id)
    return response
  } catch (e) {
    throw e.response
  }
}

const setFormParams = (formData: IInputCreate | IInputUpdate): FormData => {
  const params = new FormData()

  Object.keys(formData).forEach((key) => {
    switch (key) {
      case 'category':
        formData[key].map((values, index) => {
          params.append(`category[values][]`, values.value)
        })
        break
      case 'wrapping':
        if (formData[key]) {
          params.append('item[wrapping]', formData[key].toString())
        }
        break
      case 'image_1':
        if (formData[key] && formData[key][0]) {
          params.append('item[image_1]', formData[key][0])
        }
        break
      case 'image_2':
        if (formData[key] && formData[key][0]) {
          params.append('item[image_2]', formData[key][0])
        }
        break
      case 'image_3':
        if (formData[key] && formData[key][0]) {
          params.append('item[image_3]', formData[key][0])
        }
        break
      case 'image_4':
        if (formData[key] && formData[key][0]) {
          params.append('item[image_4]', formData[key][0])
        }
        break
      case 'image_5':
        if (formData[key] && formData[key][0]) {
          params.append('item[image_5]', formData[key][0])
        }
        break
      case 'public_date':
        params.append(`item[${key}]`, formData[key])
        break
      case 'stock':
        if (formData[key]) {
          params.append(`item[${key}]`, formData[key])
        } else {
          params.append(`item[${key}]`, '')
        }
        break
      default:
        // if (formData[key]) {
        params.append(`item[${key}]`, formData[key])
        // }
        break
    }
  })

  return params
}
